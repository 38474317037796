<template>
    <v-card hover
            elevation="0"
            :class="['ma-0', 'commentItem', 'pa-0', color==='Active' ? 'commentActive' : 'noBackground']"
            style="cursor: default;"
            shaped
            @click="commentClick(commentItem)"
    >
        <v-row>
            <v-col cols="auto" class="pa-0">
                <v-avatar class="pa-2 ma-0" size="80"><svg width="720" height="720" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <metadata>
                        <rdf:RDF>
                            <cc:Work rdf:about="">
                                <dc:format>image/svg+xml</dc:format>
                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                <dc:title/>
                            </cc:Work>
                        </rdf:RDF>
                    </metadata>
                    <g transform="translate(0 -106.5)">
                        <g transform="translate(2.5034 -7.6052)">
                            <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                            <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                        </g>
                    </g>
                    <g transform="translate(0 -106.5)">
                        <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((commentItem && commentItem.author.pictureUrl && commentItem.author.pictureUrl.indexOf('https')===-1) ? commentItem.author.pictureUrl : '0')+'.png'"/>
                    </g>
                </svg></v-avatar>


            </v-col>
            <v-col class="pa-2 pr-10" >
                <span class="text-h6">{{commentItem.author.name}}</span>

                <div style="float:right">
                <v-avatar v-if="commentItem.box"
                          tile
                          size="64"
                          class="ma-2"
                          color="grey"
                ><v-img contain :src="entrypoint+commentItem[['@id']]+'/showThumbnail/icon'+(webpSupported ? '.webp' : '.jpeg')"></v-img></v-avatar>
                </div>
                <div class="text-caption">{{this.moment(Date.parse(commentItem['dateCreated'])).fromNow()}}</div>
                <span class="comment-text" v-html="commentItem.text"></span>


                <div v-if="commentItem['category'] && typeof commentItem.categoryAttributes !== 'undefined' && commentItem.categoryAttributes !== null">
                    <v-list two-line v-if="attributes" class="background: transparent">
                        <v-list-item class="pa-0"
                            v-for="attribute in attributes.filter((a)=>{ return typeof commentItem.categoryAttributes !== 'undefined' && commentItem.categoryAttributes !== null && typeof commentItem.categoryAttributes[a.code] !== 'undefined' && commentItem.categoryAttributes[a.code] !== ''})"
                            :key="attribute.code" ripple
                            :to="attribute.type==='Location' ? {name: 'MapShowGeo', params: { geo: commentItem.categoryAttributes[attribute.code].lat+','+commentItem.categoryAttributes[attribute.code].lon }} : null"
                        >
                            <v-list-item-content class="pa-0">
                                <v-list-item-title v-html="attribute.name"></v-list-item-title>
                                <v-list-item-subtitle v-if="attribute.type==='Location'"
                                                      v-html="commentItem.categoryAttributes[attribute.code].display_name"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle v-else-if="attribute.type==='AlbumLink'">
                                    <v-btn small color="secondary" :to="{ name: 'ImageGalleryShow', params: { id: commentItem.categoryAttributes[attribute.code]['@id'] }}">{{commentItem.categoryAttributes[attribute.code].headline }}</v-btn>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else
                                                      v-html="commentItem.categoryAttributes[attribute.code]"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-avatar class="ma-2">
                                <v-icon v-if="attribute.type==='Location'">mdi-map</v-icon>
                                <v-icon v-if="attribute.type==='AlbumLink'">mdi-image-multiple</v-icon>
                                <v-icon v-if="attribute.type==='Date'">mdi-calendar</v-icon>
                                <v-icon v-if="attribute.type==='Time'">mdi-clock</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>

                    </v-list>
                </div>


            </v-col>
        </v-row>

        <v-card-actions class="pa-0">
            <v-list-item>
                <div> <span v-if="commentItem['category'] && categories">
                            <v-chip small
                                    class="ma-2"
                                    label
                                    @click="setCategoryFilter(commentItem['category'])"
                            >{{ getCategoryById(commentItem['category']).name }}
                            </v-chip>
                        </span></div>

                <v-row
                    align="center"
                    justify="end"
                >

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-badge

                                :content="commentItem.upvoteCount!=null ? commentItem.upvoteCount : '0'"
                                overlap bottom
                                offset-x="15"
                                offset-y="18"
                            >
                                <v-btn v-on="{ ...tooltip }" icon
                                       @click.stop="upvoteComment(commentItem)">
                                    <v-icon>mdi-thumb-up</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <span>{{ $t('app.Comment.VoteUp') }}</span>
                    </v-tooltip>
                    <!--<span class="mr-1">·</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" icon
                                   @click.stop="downvoteComment(commentItem)">
                                <v-icon>mdi-thumb-down</v-icon>
                            </v-btn>
                            <span class="subheading" v-if="commentItem.downvoteCount!=null">{{commentItem.downvoteCount}}</span>
                            <span class="subheading mr-2" v-else>0</span>
                        </template>
                        <span>{{ $t('app.Comment.VoteDown') }}</span>
                    </v-tooltip>

                    <span class="mr-1">·</span>-->
                    <v-menu v-if="personItem && personItem['@id']===commentItem.author['@id']" light>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                        icon
                                        dark
                                        v-on="{ ...tooltip, ...menu }"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Comment.MoreOptions') }}...</span>
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="personItem && personItem['@id']===commentItem.author['@id']"
                                @click="editComment(commentItem)"
                            >
                                <v-list-item-title>{{ $t('app.Comment.EditComment') }}...
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="personItem['@id']===commentItem.author['@id']"
                                @click="deleteComment(commentItem)"
                            >
                                <v-list-item-title>{{ $t('app.Comment.DeleteComment') }}...
                                </v-list-item-title>
                            </v-list-item>
                            <!--<v-list-item
                                @click="reportInappropriateComment(commentItem)"
                            >
                                <v-list-item-title>Als unangemessen melden...</v-list-item-title>
                            </v-list-item>-->

                            <v-tooltip top v-if="commentItem.box">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-list-item
                                        @click="showGallerySelectDialog(commentItem)"
                                        v-on="{...onTooltip}"
                                    ><v-icon>icon-Star</v-icon>
                                        <v-list-item-title>{{ $t('app.Comment.AddCommentToGallery') }}...</v-list-item-title>
                                    </v-list-item>
                                </template>
                                <span>{{ $t('app.Gallery.Add') }}...</span>
                            </v-tooltip>

                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item>
        </v-card-actions>
        <v-dialog v-model="gallerySelectDialog" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add')}}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGalery"
                    >
                        {{$t('app.Gallery.AddShort')}}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-card>
</template>
<style>
.comment-text p{
    margin-bottom: 0px;
}

.noBackground{
    background-color: transparent !important;
}
.commentActive{
    background-color: rgba(100,100,100,0.4) !important;
}
.commentItem img{
    max-width:320px;
    max-height:320px;
}


</style>

<script>


    import { mapGetters } from 'vuex'
    import {ENTRYPOINT} from '@/config/entrypoint';
    import isWebpSupported from '../../utils/isWebpSupported'
    import moment from 'moment';
    export default {
        props: {
            commentItem: {
                type: Object,
                required: true
            },
            activeComment: {
                type: String,
                required: false
            },
            color: {
                type: String,
                required: false
            },
            commentClick: {
                type: Function,
                required: true
            },
            editComment: {
                type: Function,
                required: true
            },
            deleteComment: {
                type: Function,
                required: true
            },
            upvoteComment: {
                type: Function,
                required: true
            },
            downvoteComment: {
                type: Function,
                required: true
            },
            reportInappropriateComment: {
                type: Function,
                required: true
            },
            violentations: {
                type: Object,
                required: false
            },
            addCommentToGallery: {
                type: Function,
                required: false
            },
            imagegalleries: {
                type: Array,
                required: false
            },
            categories: {
                type: Array,
                required: false
            }
        },
        computed: mapGetters({
            personItem: 'person/show/retrieved',
        }),
        watch:{
            activeComment: function() {
                this.$nextTick(() => {
                    let top = 0
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        let vele = this.$refs['comment_' + this.activeComment][0]
                        top = vele.$el.offsetTop - 100
                        this.$refs['commentsContainer'].scrollTo(0, top)
                    }
                })
            },
        },
        data: () => ({
            entrypoint: ENTRYPOINT,
            webpSupported: true,
            attributes: [],
            selectedGallery: null,
            gallerySelectDialog: false,
            selectedSlide: null
        }),
        created () {
            this.webpSupported = isWebpSupported()
            this.updateAttributes();
        },
        methods: {
            getCategoryById (id) {
                return this.categories.filter(category => category['@id'] === id)[0]
            },
            updateAttributes () {
                let attributesObj = {}
                if (typeof this.categories!=='undefined' && this.categories.length > 0) {
                    this.categories.forEach((catObject) => {
                        if (typeof (catObject) !== 'undefined') {
                            const translation = this.getTranslation(catObject)
                            if (typeof translation.attributesDefinition === 'object' && translation.attributesDefinition !== null) {
                                translation.attributesDefinition.forEach((aD) => {
                                    attributesObj[aD.code] = aD
                                })
                            }
                        }
                    })
                }
                this.attributes = []
                for (let key in attributesObj) {
                    if (Object.prototype.hasOwnProperty.call(attributesObj,key)) {
                        this.attributes.push(attributesObj[key])
                    }
                }
            },
            getTranslation (item) {
                let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
                return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
            },

            showGallerySelectDialog(slide){
                this.selectedSlide=slide;
                this.gallerySelectDialog=true;
            },
            selectGalery(){
                if (this.selectedGallery===null) return;
                let imagegallery=null;
                this.imagegalleries.forEach((item)=>{
                    if (item['@id']===this.selectedGallery){
                        imagegallery=item;
                        return true;
                    }
                });
                this.gallerySelectDialog=false;
                this.addCommentToGallery(imagegallery,this.selectedSlide)
            },
            moment: function (date) {
                return moment(date).locale('de');
            }
        }


    }
</script>
