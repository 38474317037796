<template>
    <div class="collage-wrapper">
        <h4 class="pa-3 font-weight-thin font-italic collage-title">
            {{ $t('app.NotRestoredPics') }}
        </h4>
        <div v-if="isLoading">Loading images...</div>
        <div class="grid-wrapper" v-if="images.length>0">
            <div
                v-for="(image, index) in images"
                :key="index"
                :class="image.class"
            >
                <img
                    class="collage-img"
                    :style="{ transform: `rotate(${image.rotation})`, objectFit: image.position }"
                    :src="image.src"
                    alt=""
                >
            </div>
        </div>
    </div>
    </template>

<script>
import {ENTRYPOINT} from '@/config/entrypoint'
export default {
    name: "CollageImages",
    props: {
        itemId: {
            type: String,
            required: true
        },
        item:{
            type: Object,
            required: true
        }
    },
    components: {},
    data() {
        return {
            entrypoint: ENTRYPOINT,
            images: [], // Array to store image objects with dimensions and other properties
            isLoading: true, // Optional: Flag to manage loading state
        };
    },
    methods: {
        // Function to get image dimensions
        getImageSize(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve({ width: img.width, height: img.height });
                img.onerror = reject;
                img.src = src;
            });
        },
        async loadImageDetails(image) {
            const src = this.getImageSrc(image); // Implement this function based on your setup
            try {
                const { width, height } = await this.getImageSize(src);
                return {
                    src,
                    class: height > width ? 'tall' : 'big',
                    rotation: '0deg',
                    position: 'contain'
                };
            } catch (error) {
                window.console.error('Error loading image', error);
                return {
                    src: src,
                    class: 'big', // Fallback class
                    rotation: '0deg',
                    position: 'contain'
                };
            }
        },
        async initializeImages(previewThumbnails) {
            try {
                this.images = await Promise.all(previewThumbnails.map(img => this.loadImageDetails(img)));
            } catch (error) {
                window.console.error('Error initializing images', error);
                this.error = 'Failed to load images'; // Set error message
            } finally {
                this.isLoading = false; // Ensure loading state is cleared
            }
        },
        getImageSrc(src) {
            return `${this.entrypoint}/media/photographs/photograph_collection/raw/${this.itemId}/${src}.jpeg`
        },
        /**
         * This method is for opening an image in a larger view
         */
        // collageClick (item) {
        //     this.$router.push({name: 'CollageShow', params: {id: item,collectionId: this.itemId}});
        // },
    },
    watch: {
        item: {
            immediate: true, // Run watcher immediately for the initial case
            async handler(newItem) {
                if (newItem && newItem.previewThumbnails) {
                    await this.initializeImages(newItem.previewThumbnails);
                }
            },
        },
    },
}
</script>
<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.collage-wrapper {
    background-color:#ebe9eb;
}
.collage-title {
    background-color: #fef2e6f2;
    color: #282c39;
}

/* Main CSS */
.grid-wrapper {
    padding-bottom:54px;
}
.grid-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-shadow: inset 0px -2px 20px 13px #fef2e6f2;
    border-radius: 4px;

}
.grid-wrapper > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0 0 20px #fbf4e445);
    box-shadow: inset 0px -2px 20px 13px #fbf4e445;
    padding: 8px;

}

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}
.grid-wrapper .wide {
    grid-column: span 2;
}
.grid-wrapper .tall {
    grid-row: span 2;
}
.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}
@media screen and (max-width: 700px) {
    .grid-wrapper .tall {
        grid-row: span 2;
        grid-column: span 2;
    }
    .grid-wrapper .big{
        grid-row: span 1;
        grid-column: span 3;
    }
}
</style>

