import * as types from './mutation_types'

export default {
  [types.ORDER_DELETE_SET_DELETED] (state, deleted) {
    Object.assign(state, { deleted })
  },

  [types.ORDER_DELETE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.ORDER_DELETE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.ORDER_CANCEL_SET_CANCELED] (state, canceled) {
    Object.assign(state, { canceled })
  },

  [types.ORDER_CANCEL_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.ORDER_CANCEL_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.ORDER_DELETE_RESET] (state) {
    Object.assign(state, {
      deleted: null,
      error: '',
      isLoading: false
    })
  }
}
