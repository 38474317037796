<template>
    <div style="position:fixed; top:0; width: 100vw; height: 100vh; overflow:auto">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-card>
            <v-card-title>{{ $t('app.ReportCollectionThankYou') }}</v-card-title>
            <v-card-text>{{ $t('app.ReportCollectionThankYouText1') }}</v-card-text>
            <v-card-text>{{ $t('app.ReportCollectionThankYouText2') }}</v-card-text>
            <v-card-text>{{ $t('app.ReportCollectionThankYouText3') }}</v-card-text>
            <v-card-actions>
                <router-link
                    :to="{ name: 'HomeList' }"
                    class="btn btn-default">{{ $t('app.back') }}
                </router-link>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {mapActions} = createNamespacedHelpers('photographcollection/create')
import { mapGetters } from 'vuex'

export default {

    data () {
        return {
            item: {
                licenseName: 'not defined yet',
                copyrightHoldingPerson: this.token_data ? '/people/'+this.token_data.person_id : null,
            }
        }
    },

    computed: mapGetters({
        error: 'photographcollection/create/error',
        isLoading: 'photographcollection/create/isLoading',
        created: 'photographcollection/create/created',
        violations: 'photographcollection/create/violations',
        personItem: 'person/show/retrieved',
        token_data: 'user/token/token_data'
    }),
    created() {

        if (this.token_data && this.token_data.person_id!==null) {
            this.item.copyrightHoldingPerson= '/people/'+this.token_data.person_id;
            window.console.log(this.item,this.token_data);
        }else{
            window.console.log('no tokenData!')
        }
    },

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }

            this.$router.push({name: 'PhotographCollectionUpdate', params: {id: created['@id']}})
        },
        token_data: function () {
            this.item.copyrightHoldingPerson= '/people/'+this.token_data.person_id;
            window.console.log(this.item);
        },
    },

    methods: {
        ...mapActions([
            'create'
        ]),

        onSendForm () {
            this.create(this.item)
        },

        updateField (field, value) {
            window.console.log(field, value)
            Object.assign(this.item, {[field]: value})
        }
    }
}
</script>
