<template>
    <div if="item">
        <form  @submit.prevent="handleSubmit(item)" style="overflow-y:auto">
            <v-card v-if="step===0">
                <v-card-title>{{$t('app.FeedbackDialogDescription1')}}</v-card-title>
                <v-card-subtitle>{{$t('app.FeedbackDialogSubDescription1')}}</v-card-subtitle>
                <v-card-text>
                    <v-textarea
                    label="Nachricht"
                    v-model="feedbackText"
                    outlined
                    auto-grow
                ></v-textarea>
                </v-card-text>
                <v-card-text>{{$t('app.FeedbackDialogReportErrorText')}} <v-btn text small @click="handleShowReportBug">{{$t('app.ReportBug')}}</v-btn></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeWin">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="step = 1"
                    >
                        {{$t('app.continue')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
            <v-card v-if="step===1">
                <v-card-title>{{$t('app.FeedbackDialogDescription2')}}</v-card-title>
                <v-card-text>
                    <v-rating
                        v-model="feedbackRating"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        half-increments
                        hover
                        large
                    ></v-rating>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="step=0">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="handleSubmit"
                    >
                        {{$t('app.FeedbackSubmit')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
    </form>
    </div>
</template>

<style>
.noBackground{
    background-color: transparent !important;
}
</style>

<script>


export default {
    components: {
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },
        handleUpdateField: {
            type: Function,
            required: true
        },
        handleClose: {
            type: Function,
            required: true
        },
        handleShowReportBug: {
            type: Function,
            required: true
        },
        editRectangle: {
            type: Function,
            required: false
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => {}
        }
    },

    computed: {

        item () {
            return this.initialValues || this.values
        },

        violations () {
            return this.errors || {}
        }
    },
    data: () => ({
        step: 0,
        feedbackRating: null,
        feedbackText: '',
    }),
    created () {
        this.step=0;
    },
    beforeDestroy () {
        window.console.log('beforeDestroy')
        this.step=0;
    },
    beforeMount () {
        this.step=0;
    },
    watch: {
        item: {
            handler:function() {
                this.feedbackRating = this.item.additionalData.rating ?? null;
                this.feedbackText = this.item.text;
                if(this.item.text==='') this.step=0;
            },
            deep: true
        },
        feedbackText: function () {
            this.handleUpdateField('text',this.feedbackText);
        },
        feedbackRating: function () {
            this.handleUpdateField('additionalData',{rating: this.feedbackRating});
        },

    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        closeWin(){
            this.step=0;
            this.handleClose();
        },

    }
}
</script>
