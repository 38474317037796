<template>
    <v-dialog  v-model="item" content-class="updateRetrosnapDialog" fullscreen
              transition="fade-transition">
        <v-snackbar
            v-if="created"
            v-model="created"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ created['@id'] }} created.
        </v-snackbar>
        <v-snackbar
            v-if="updated"
            v-model="updated"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ updated['@id'] }} updated.
        </v-snackbar>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="deleteLoading"
            v-model="deleteLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
      <v-icon>mdi-alert</v-icon> {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon> {{ deleteError }}
        </v-snackbar>
        <v-card>
            <div style="top:0; width:100%; height:100%; position: fixed; text-align:center; overflow:auto" >
            <v-row scrollable>
                <v-col md="6" >
                    <v-card v-if="retrieved" class="masonry-item" >
                        <v-img
                                :src="entrypoint+'/thumbnail/'+(colorized ? 'colorized.' : '')+'display/'+retrieved['photograph']['filePath'].replace(/\.jpeg/,'.webp')"
                                :lazy-src="entrypoint+'/thumbnail/'+(colorized ? 'colorized.' : '')+'lazy/'+retrieved['photograph']['filePath']"
                               @click="carouselItemClick(item)"
                        >
                            <v-img class="fadingEffect"
                                   :src="entrypoint+'/thumbnail/'+(colorized ? 'colorized.' : '')+'display/'+retrieved['filePath'].replace(/\.jpeg/,'.webp')"
                                   :lazy-src="entrypoint+'/thumbnail/'+(colorized ? 'colorized.' : '')+'lazy/'+retrieved['filePath']"
                            ></v-img>

                        </v-img>
                        <!--</div>-->
                        <v-btn @click.stop="$router.go(-1)" icon style="position:fixed; top:10px;right:10px">
                            <v-icon>mdi-close</v-icon>
                            {{ item['text'] }}
                        </v-btn>
                        <v-card-actions class="" style="position:fixed; bottom:20px;">
                            <v-btn @click.stop="colorized=!colorized" icon>
                                <v-icon v-if="colorized">mdi-invert-colors-off</v-icon>
                                <v-icon v-if="!colorized">mdi-invert-colors</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col md="6">
                    <v-card class="pa-2"
                            outlined
                            tile>
                        <RetrosnapForm
                            v-if="item"
                            :handle-submit="onSendForm"
                            :handle-update-field="updateField"
                            :values="item"
                            :errors="violations"
                            :initial-values="retrieved"/>
                        <v-btn small color="error"
                               @click="del"><span>{{ $t('app.RetroSnapDelete') }}</span>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<style>
    .homevcardactions i {
        text-shadow: 0 1px 2px black;
    }

    .comparify-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .v-item-group.v-bottom-navigation .v-btn {
        max-width: unset !important;
    }

</style>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import RetrosnapForm from './Form.vue'
    import {ENTRYPOINT} from '../../config/entrypoint';


    export default {
        components: {
            RetrosnapForm
        },

        data() {
            return {
                item: {},
                entrypoint: ENTRYPOINT,
                colorized: false,
                sheet: true
            }
        },

        computed: {
            ...mapGetters({
                isLoading: 'retrosnap/update/isLoading',
                error: 'retrosnap/update/error',
                deleteError: 'retrosnap/del/error',
                deleteLoading: 'retrosnap/del/isLoading',
                created: 'retrosnap/create/created',
                deleted: 'retrosnap/del/deleted',
                retrieved: 'retrosnap/update/retrieved',
                updated: 'retrosnap/update/updated',
                violations: 'retrosnap/update/violations'
            })
        },

        watch: {
            // eslint-disable-next-line object-shorthand,func-names
            deleted: function (deleted) {
                if (!deleted) {
                    return
                }

                this.$router.push({name: 'HomeList'})
            },
            updated: function (updated) {
                if (!updated) {
                    return
                }

                this.$router.push({name: 'HomeList'})
            }
        },

        beforeDestroy() {
            this.reset()
        },

        created() {
            this.retrieve(decodeURIComponent(this.$route.params.id))
        },

        methods: {
            ...mapActions({
                createReset: 'retrosnap/create/reset',
                deleteItem: 'retrosnap/del/del',
                delReset: 'retrosnap/del/reset',
                retrieve: 'retrosnap/update/retrieve',
                updateReset: 'retrosnap/update/reset',
                update: 'retrosnap/update/update',
                updateRetrieved: 'retrosnap/update/updateRetrieved',
                updated: 'retrosnap/update/updated'
            }),

            del() {
                if (window.confirm('Are you sure you want to delete this retrosnap ?')) {
                    this.deleteItem(this.retrieved)
                }
            },

            reset() {
                this.updateReset()
                this.delReset()
                this.createReset()
            },

            onSendForm() {
                this.update()
            },

            updateField(field, value) {
                this.updateRetrieved({[field]: value})
            }
        }
    }
</script>
