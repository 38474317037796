<template>
    <v-form @submit.prevent="handleSubmit(item)">
        <div class="form-group">
            <v-text-field
                label="Name"
                id="photographcollection_headline"
                v-model="item.headline"
                :class="['form-control', isInvalid('headline') ? 'is-invalid' : '']"
                type="text"
                outlined
                placeholder="name of the collection"/>
            <div
                v-if="isInvalid('headline')"
                class="invalid-feedback">{{ violations.headline }}
            </div>
        </div>
        <div class="form-group">
            <v-textarea
                id="photographcollection_about"
                v-model="item.about"
                placeholder="the subject matter of the content."
                label="About"
                outlined
            />

            <div
                v-if="isInvalid('about')"
                class="invalid-feedback">{{ violations.about }}
            </div>
        </div>
        <div class="form-group pb-8">
            <tiptap-vuetify
                v-model="item.text"
                :extensions="tiptapExtensions"
                placeholder="the textual content of this CreativeWork"
                :toolbar-attributes="{ color: $vuetify.theme.dark ? $vuetify.theme.themes.dark.secondary : $vuetify.theme.themes.light.secondary }"
                :card-props="{ outlined: true }"
                label="Text"
            />
            <div
                v-if="isInvalid('text')"
                class="invalid-feedback">{{ violations.text }}
            </div>
        </div>
        <!--
        <div class="form-group">

            <v-autocomplete
                :loading="personsLoading"
                value=""
                label="Source (Owner)"
                :search-input.sync="selectedOwnerSearch"
                cache-items
                hide-no-data
                clearable
                :items="persons"
                v-model="item.owner"
                item-value="@id"
                item-text="name"
                placeholder="Source (Owner)"
                outlined
            />


            <div
                v-if="isInvalid('owner')"
                class="invalid-feedback">{{ violations.owner }}
            </div>
        </div>
        <div class="form-group">

            <v-autocomplete
                :loading="personsLoading"
                value=""
                label="Copyright Holding Person"
                :search-input.sync="selectedPersonSearch"
                cache-items
                hide-no-data
                clearable
                :items="persons"
                v-model="item.copyrightHoldingPerson"
                item-value="@id"
                item-text="name"
                placeholder="Copyright Holding Person"
                outlined
            />


            <div
                v-if="isInvalid('copyrightHoldingPerson')"
                class="invalid-feedback">{{ violations.copyrightHoldingPerson }}
            </div>
        </div>
        <div class="form-group">
            <v-select
                label="Copyright Holding Organization"
                item-text="name"
                item-value="@id"
                :items="organizations"
                outlined
                placeholder="Copyright Holding Organization"
                v-model="item.copyrightHoldingOrganization"
                :class="[isInvalid('copyrightHoldingOrganization') ? 'is-invalid' : '']"
            />
            <div
                v-if="isInvalid('copyrightHoldingOrganization')"
                class="invalid-feedback">{{ violations.copyrightHoldingOrganization }}
            </div>
        </div>
        <div class="form-group">
            <v-text-field
                label="License Name"
                id="photographcollection_licenseName"
                v-model="item.licenseName"
                :class="['form-control', isInvalid('licenseName') ? 'is-invalid' : '']"
                type="text"
                outlined
                placeholder="The license name that applies to this content"/>
            <div
                v-if="isInvalid('licenseName')"
                class="invalid-feedback">{{ violations.licenseName }}
            </div>
        </div>
        <div class="form-group">
            <v-text-field
                label="License Url"
                id="photographcollection_licenseUrl"
                v-model="item.licenseUrl"
                :class="['form-control', isInvalid('licenseUrl') ? 'is-invalid' : '']"
                type="url"
                outlined
                placeholder="The Link to the license document"/>
            <div
                v-if="isInvalid('licenseUrl')"
                class="invalid-feedback">{{ violations.licenseUrl }}
            </div>
        </div>
        <div class="form-group">
            <v-text-field
                label="Collection Copyright Url"
                id="photographcollection_collectionCopyrightUrl"
                v-model="item.collectionCopyrightUrl"
                :class="['form-control', isInvalid('collectionCopyrightUrl') ? 'is-invalid' : '']"
                type="url"
                outlined
                placeholder="The Link to the Profile or Website of the copyright-holder"/>
            <div
                v-if="isInvalid('collectionCopyrightUrl')"
                class="invalid-feedback">{{ violations.collectionCopyrightUrl }}
            </div>
        </div>
        -->




        <div class="form-group">
            <v-range-slider
                v-if="item.fromYear!==null && item.toYear!==null"
                :value="yearRange"
                min="1850"
                max="1980"
                label="Year of origin"
                @input="(value) => { handleUpdateField('fromYear', value[0]); handleUpdateField('toYear', value[1]);}"
            >
                <template v-slot:append v-if="item.fromYear!==null && item.toYear!==null">
                    {{item.fromYear}}-{{item.toYear}}
                </template>

            </v-range-slider>
        </div>

        <div class="form-group">
            <MapWidget

                :handle-update-field="handleGeoUpdate"
                :value="item.place"
                :class="[isInvalid('place') ? 'is-invalid' : '']"
            ></MapWidget>
            <div
                v-if="isInvalid('geo')"
                class="invalid-feedback">{{ violations.geo }}
            </div>
        </div>
        <v-btn color="primary" class="mt-5" small dark type="submit"
        ><v-icon>mdi-content-save</v-icon>{{ $t('app.Submit') }}
        </v-btn>
    </v-form>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import MapWidget from '../map/Widget'
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Underline,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    History
} from 'tiptap-vuetify'

export default {
    components: {
        TiptapVuetify,
        MapWidget
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },

        handleUpdateField: {
            type: Function,
            required: true
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {
            }
        },

        initialValues: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            tiptapExtensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Italic,
                Bold,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Paragraph,
                HardBreak
            ],
            yearRange: [1850,1980],
            personsLoading: false,
            selectedPersonSearch: null,
            selectedOwnerSearch: null,

        }

    },
    computed: {
        ...mapGetters({
            persons: 'person/list/items',
        }),
        // eslint-disable-next-line
        item() {
            let values =this.initialValues ? this.initialValues : this.values;
            delete values['photographs']
            return values;
        },
        ...mapGetters({
            people: 'person/list/items',
            organizations: 'organization/list/items'
        }),
        violations() {
            return this.errors || {}
        }
    },
    created() {
        this.getPersons()
        this.getOrganizations()
    },
    watch: {
        item: function () {
            this.yearRange = [this.item.fromYear, this.item.toYear];
        },
        selectedPersonSearch(val) {
            val && this.queryPersons(val)
        },
        selectedOwnerSearch(val) {
            val && this.queryPersons(val)
        },
        persons:function(){
            this.personsLoading = false;
        },
    },
    methods: {
        isInvalid(key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        ...mapActions({
            getPersons: 'person/list/default',
            getOrganizations: 'organization/list/default',
        }),
        handleGeoUpdate(field, value) {
            //this.$set(this.item, 'geo', value)
            //this.$set(this.item.geo, 'latitude', value.latitude)
            //this.$set(this.item.geo, 'longitude', value.longitude)
            this.handleUpdateField(field, value)
        },
        queryPersons (v) {
            /****************************/
            this.personsLoading = true;
            this.getPersons('people?name=' + (v || '').toLowerCase());
        },
    }
}
</script>
