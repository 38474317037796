<template>
    <div>
        <transition>
            <v-snackbar
                v-if="isLoading"
                v-model="isLoading"
                :top="true"
                color="info"
            >Loading...
            </v-snackbar>
        </transition>
        <v-dialog
            v-model="registereddialog"
            fullscreen
        >
            <v-card class="mx-auto" v-if="error=='Error: User activation failed: User is already active'">
                <v-card-title
                    class="text-h5"
                    primary-title
                >{{ $t('app.activatedtitleallreadyactive') }}
                </v-card-title>
                <v-card-text color="error"><p>{{ $t('app.activatednoticeallreadyactiveerror') }}</p>
                    <p>
                        <v-btn color="primary" href="/login">{{ $t('app.activatedloginbutton') }}</v-btn>
                    </p>
                    <p v-html="$t('app.activatednoticeallreadyactiveerror2')"></p>
                    <p></p>
                    <p>{{ $t('app.activatednoticeallreadyactiveThankyou') }}</p></v-card-text>
            </v-card>
            <v-card class="mx-auto" v-else-if="error!==''">
                <v-card-title
                    class="text-h5"
                    primary-title
                >{{ $t('app.activatedtitleerror') }}
                </v-card-title>
                <v-card-text color="error"><p>{{ $t('app.activatednoticeerror') }}</p>
                    <p>
                        <v-icon>mdi-alert</v-icon>
                        {{ error }}
                    </p>
                    <p v-html="$t('app.activatednoticeerror2')"></p>
                    <p></p>
                    <p>{{ $t('app.activatednoticeerrorThankyou') }}</p></v-card-text>
            </v-card>
            <v-card class="mx-auto" v-if="user">
                <v-card-title
                    class="text-h5"
                    primary-title
                >{{ $t('app.activatedtitle') }}
                </v-card-title>
                <v-card-text><p>{{ $t('app.activatednotice') }}</p>
                    <v-btn color="primary" href="/login">{{ $t('app.activatedloginbutton') }}</v-btn>
                </v-card-text>
                <v-card-text>
                    <p>{{ $t('app.activatednoticeThankyou') }}</p></v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import fetch from '../../utils/fetch'

export default {

    data () {
        return {
            user_id: this.$route.params.id,
            invitationcode: this.$route.params.invitationcode,
            registereddialog: true,
            error: '',
            errorMessage: '',
            user: null,
            isLoading: true
        }
    },
    watch: {
        error () {
            if (this.error !== '') {
                const params = {
                    props: {
                        'event_category': this.error,
                        'event_label': this.error,
                        logged_in: false,
                        'appinstalled': window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches
                    }
                }
                window.console.log(params)
                window.plausible = window.plausible || function () { (window.plausible.q = window.plausible.q || []).push(arguments) }

                window.plausible('error', params)
            }
        },
    },

    created () {
        fetch('users/' + this.user_id + '/activate/' + this.invitationcode, {
            method: 'PUT'
        })
            .then((response) => {
                this.isLoading = false
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((data) => {
                window.console.log(data['isActive'])
                this.user = data

            })
            .catch((error) => {
                window.console.log(error)
                this.setError(error)
            })
    },

    methods: {
        setError (message) {
            this.error = message
            this.errorMessage = message
        }
    }
}
</script>
