<template>
    <v-row
        class="d-flex align-center justify-center "
        :class="!displayColumn ? '' : 'flex-column'"
        no-gutters
    >
        <v-col
            cols="auto"
            class="d-flex justify-center mt-2 ml-5"
        >
            <v-img
                :src="require('@/assets/img/button-HastDuBilder2.png')"
                alt="Report Collection"
                max-width="350"
                height="auto"
                class="clickable-img"
                @click="handleReportClick"
            ></v-img>
        </v-col>

        <v-col
            cols="auto"
            class="d-flex justify-center align-center mt-5 mb-5 ml-5"
        >
            <v-img
                :src="require('@/assets/img/button-browseCollections2.png')"
                alt="Browse Collections"
                max-width="350"
                height="auto"
                class="clickable-img"
                cover
                @click="handleBrowseClick"
            ></v-img>
        </v-col>
    </v-row>
</template>
<style>
.clickable-img {
    cursor: pointer; /* Change cursor to pointer to indicate clickable area */
    transition: transform 0.3s ease; /* Smooth transform for hover effect */
    border-radius: 8px;
}

.clickable-img:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */

}
</style>
<script >
export default {
    props: ['displayColumn'],
    methods: {
        handleBrowseClick() {
            this.$router.push({name: 'CollectionsList'})
        },
        handleReportClick() {
            window.location.href = ' https://nimmerso.app/report_collection';
        },
    },
};
</script>

