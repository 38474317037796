<template>

        <v-dialog
            v-model="userResetPasswordDialog"
            fullscreen
            content-class="justify-center text-center"
            fill-height
            justify="center"
            class="justify-center text-center"
            fluid
        >

            <div class="userResetPasswordDialog justify-center text-center">

                <v-card-title
                    class="text-h5 "
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>

                <v-card-title class="text-h5 text-center justify-center">
                    {{$t('app.UserResetPassword.Title')}}
                </v-card-title>
                <v-card-subtitle class="text-center justify-center">
                    {{$t('app.UserResetPassword.Description')}}
                </v-card-subtitle>
                <v-card-text class="text-center justify-center">

                    <v-alert type="error" v-if="error!==''">
                        {{error}}
                    </v-alert>
                    <v-form ref="form" lazy-validation>
                        <v-text-field
                            v-model="password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.password"
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            :label="$t('app.Password')"
                            outlined
                            prepend-inner-icon="mdi-lock"
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                    </v-form>
                </v-card-text>


                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="setPassword"
                        raised
                        block
                    >
                        {{$t('app.UserResetPassword.SetPassword')}}
                    </v-btn>
                </v-card-actions>
                <v-card-actions class="text-center justify-center"><v-btn text small :to="{name:'Login'}">{{$t('app.ResetPassword.PasswordRemembered')}}</v-btn></v-card-actions>
                <nimmerso-footer></nimmerso-footer>
            </div>
        </v-dialog>

</template>
<style>
    .userResetPasswordDialog {
        /*background: transparent !important;
        margin-left: auto !important;
        margin-right: auto !important;
         */
        background: rgba(0,0,0,0.75);
        max-width: 600px;
        min-width: 300px;
        overflow: auto;
        height: 100vh;
        font-family: "Roboto Condensed", Arial, sans-serif;
    }
    .userResetPasswordDialog:after {
        background-image: url('~@/assets/img/Login_background.jpg');
        content: "";
        background-size: cover;
        background-position: center;
        background-color: gray;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: -1;
    }
</style>

<script>
    import fetch from '../../utils/fetch'
    import {mapActions} from "vuex";
    import NimmersoFooter from "../NimmersoFooter"
    export default {
        name: 'Login',
        components: {
            NimmersoFooter
        },
        data() {
            return {
                showPassword: false,
                userResetPasswordDialog: true,
                password: '',
                error: '',
                rules: {
                    required: value => !!value || this.$t('app.Login.Required'),
                    min: v => v.length >= 6 || this.$t('app.Login.PasswordHint'),
                    password: [
                        v => (v || '').length >= 8 || this.$t('app.PasswordLength'),
                    ],
                }
            }
        },
        methods: {
            ...mapActions({
                settoken: 'user/token/settoken',
                setrefreshtoken: 'user/token/setrefreshtoken',
                gettoken: 'user/token/gettoken',
                gettokenexpiretime: 'user/token/gettokenexpiretime',
                gettokendata: 'user/token/gettokendata',
                removetoken: 'user/token/removetoken',
                removerefreshtoken: 'user/token/removerefreshtoken'
            }),
            setError(message) {
                this.error = message;
            },
            register() {

            },
            setPassword() {
                this.userResetPasswordDialog = false;
                const id = (this.$route.params.id)
                const passwordResetToken = (this.$route.params.passwordResetToken)
                return fetch('/reset_password/'+id+'/'+passwordResetToken, {
                    method: 'PUT',
                    body: JSON.stringify({password: this.password}),
                    headers: new Headers({'Content-Type': 'application/json'}),
                })
                    .then((response) => {
                        if (response.status < 200 || response.status >= 300) {
                            this.setError(response.statusText);
                            throw new Error(response.statusText);
                        }else if (response.status===200){
                            this.$router.push({name: 'UserResetPasswordOk'})
                        }

                        return response.json();
                    })
                    .catch((error) => {
                        this.userResetPasswordDialog = true;
                        this.setError(error);
                    });

            }
        }
    }
</script>

<style lang="css">
    body {
        background: #605B56;
    }


    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>
