// src/plugins/vuetify.js

// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
//import 'vuetify/dist/vuetify.min.css'
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FFFFFF', //'#1891FA', //'#D3E9FC',//'#E3E1E2',
        secondary: '#8D7F77', //'#FFE89A', //'#8D7F77',
        accent: '#E2E0E1', //'#D3E9FC',
        error: '#AE5046',
        cardbg: '#3E3844',
        buttonIcon: '#000',
        active: '#1891FA'
      },
      light: {
        primary: '#3E3844',
        secondary: '#8D7F77',
        accent: '#E20000',
        error: '#AE5046',
        cardbg: '#E2E0E1',
        buttonIcon: '#E2E0E1'
      }
    }
  }
})
