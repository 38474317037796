
import Privacy from '../components/page/Privacy'
import AGB from '../components/page/AGB'
import Impressum from '../components/page/Impressum'

export default [

  { name: 'Privacy', path: '/privacy', component: Privacy, meta: { navHidden: true, requiresAuth: false } },
  { name: 'AGB', path: '/agb', component: AGB, meta: { navHidden: true, requiresAuth: false } },
  { name: 'Impressum', path: '/impressum', component: Impressum, meta: { navHidden: true, requiresAuth: false } },
]
