<template>
    <v-menu
        origin="center center"
        transition="scale-transition"
        @click.native.stop.prevent
        max-height="400"
    >
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip top>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn icon v-on="{ ...onMenu, ...onTooltip}">
                        <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Share') }}...</span>
            </v-tooltip>
        </template>
        <v-list class="shareButtonList">
            <v-subheader>{{ $t('app.Share') }}</v-subheader>
            <v-list-item @click="copyLink">
                <v-list-item-title><v-icon color="#FFFFFF">mdi-content-copy</v-icon> Copy to Clipboard<input type="hidden" ref="copyField" value=""></v-list-item-title>
            </v-list-item>
                <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :style="{backgroundColor: network.color}"
                    :url="url"
                    :title="title"
                    :description="description"
                    :quote="quote"
                    :hashtags="hashtags"
                    :twitterUser="twitterUser"
                    :media="media"
                    @open="trackEvent"
                >
                <v-list-item >
                    <v-list-item-title><v-icon :color="network.color">{{network.icon}}</v-icon> {{ network.name }}</v-list-item-title>
                </v-list-item>
                </ShareNetwork>
        </v-list>
    </v-menu>
</template>
<style>
.shareButtonList a{
    text-decoration: none;
}
</style>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            url: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String
            },
            quote: {
                type: String,
            },
            hashtags: {
                type: String,
            },
            twitterUser: {
                type: String
            },
            media: {
                type: String
            }
        },
        data () {
            return {
                networks: [
                    //{network: 'baidu', name: 'Baidu', icon: 'mdi-paw', color: '#2529d8'},
                    //{network: 'buffer', name: 'Buffer', icon: 'mdi-buffer', color: '#323b43'},

                    //{network: 'evernote', name: 'Evernote', icon: 'mdi-evernote', color: '#2dbe60'},
                    {network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: '#1877f2'},
                    {network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366'},
                    {network: 'pinterest', name: 'Pinterest', icon: 'mdi-pinterest', color: '#bd081c'},
                    {network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2'},
                    {network: 'telegram', name: 'Telegram', icon: 'mdi-telegram', color: '#0088cc'},
                    {network: 'skype', name: 'Skype', icon: 'mdi-skype', color: '#00aff0'},
                    //{network: 'flipboard', name: 'Flipboard', icon: 'mdi-flipboard', color: '#e12828'},
                    //{network: 'hackernews', name: 'HackerNews', icon: 'mdi-y-combinator', color: '#ff4000'},
                    //{network: 'instapaper', name: 'Instapaper', icon: 'mdi-format-italic', color: '#428bca'},
                    //{network: 'line', name: 'Line', icon: 'mdi-line', color: '#00c300'},
                    {network: 'linkedin', name: 'LinkedIn', icon: 'mdi-linkedin', color: '#007bb5'},
                    {network: 'xing', name: 'Xing', icon: 'mdi-xing', color: '#026466'},
                    /*{
                        network: 'odnoklassniki',
                        name: 'Odnoklassniki',
                        icon: 'mdi-odnoklassniki',
                        color: '#ed812b'
                    },*/

                    //{network: 'pocket', name: 'Pocket', icon: 'mdi-get-pocket', color: '#ef4056'},
                    //{network: 'quora', name: 'Quora', icon: 'mdi-quora', color: '#a82400'},
                    //{network: 'reddit', name: 'Reddit', icon: 'mdi-reddit', color: '#ff4500'},

                    {network: 'email', name: 'Email', icon: 'mdi-mail', color: '#333333'},
                    {network: 'sms', name: 'SMS', icon: 'mdi-message-processing', color: '#333333'},
                    /*{
                        network: 'stumbleupon',
                        name: 'StumbleUpon',
                        icon: 'mdi-stumbleupon',
                        color: '#eb4924'
                    },*/

                    //{network: 'tumblr', name: 'Tumblr', icon: 'mdi-tumblr', color: '#35465c'},

                    //{network: 'viber', name: 'Viber', icon: 'mdi-viber', color: '#59267c'},
                    //{network: 'vk', name: 'Vk', icon: 'mdi-vk', color: '#4a76a8'},
                    //{network: 'weibo', name: 'Weibo', icon: 'mdi-weibo', color: '#e9152d'},

                    //{network: 'wordpress', name: 'Wordpress', icon: 'mdi-wordpress', color: '#21759b'},

                    //{network: 'yammer', name: 'Yammer', icon: 'mdi-yammer', color: '#0072c6'}
                ],
            }
        },
        computed: {
            // eslint-disable-next-line
            item () {
                return this.initialValues || this.values
            },
            ...mapGetters({
                token: 'user/token/token',
            })
        },
        methods: {
            trackEvent(network){
                /*
                if (window['gtag']){
                    this.$gtag.event('social_share_click', {
                        'event_category': network,
                        'event_label': 'Social Share Click '+network
                        })
                }
                */
                const params =  {
                    props: {
                        'event_category': network,
                        'event_label': 'Social Share Click ' + network,
                        logged_in: this.token !== null,
                        'appinstalled': window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches
                    }
                };
                window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }

                window.plausible('social_share_click', params);


            },
            copyLink(){
                this.trackEvent('copy_to_clipboard')
                let copyField = this.$refs['copyField'];
                copyField.value=window.location.href;
                copyField.setAttribute('type', 'text');
                copyField.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    alert('Link was copied ' + msg);
                } catch (err) {
                    alert('Oops, unable to copy');
                }

                /* unselect the range */
                copyField.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            }
        }

    }
</script>
