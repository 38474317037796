<template>

        <v-card class="bgBlur">
            <v-btn style="z-index:10001; right: 0; top:0;" absolute top right icon
                   @click="doCommentDialogClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-divider></v-divider>
            <v-card-text>
                <CommentForm
                    :handle-submit="onSendCommentForm"
                    :handle-update-field="updateCommentField"
                    :edit-rectangle="editRectangle"
                    :values="newComment"
                    :errors="violations"/>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn v-if="editRectangle" v-on="onTooltip" icon small @click.stop="editRectangle" :color="newComment.box!==null ?'primary' :''" >
                            <v-icon>mdi-vector-rectangle</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Comment.ImageSection') }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="submitComment"
                >{{ $t('app.Comment.Submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>

</template>

<script>


    import CommentForm from './Form'
    export default {
        props: {
            newComment: {
                type: Object,
                required: true
            },
            onSendCommentForm: {
                type: Function,
                required: false
            },
            updateCommentField: {
                type: Function,
                required: false
            },
            doCommentDialogClose: {
                type: Function,
                required: true
            },
            editRectangle: {
                type: Function,
                required: false
            },
            submitComment: {
                type: Function,
                required: true
            },
            errors: {
                type: Object,
                default: () => {}
            },
        },
        components: {
            CommentForm
        },
        computed: {
            violations () {
                return this.errors || {}
            }
        },
        methods: {

        }


    }
</script>
