<template>
    <div class="text-center">
        <v-snackbar
            v-if="created"
            v-model="created"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ created['@id'] }} created.
        </v-snackbar>
        <v-snackbar
            v-if="updated"
            v-model="updated"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ updated['@id'] }} updated.
        </v-snackbar>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="deleteLoading"
            v-model="deleteLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon> {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon> {{ deleteError }}
        </v-snackbar>
        <v-dialog
            fullscreen
            transition="dialog-right-transition"
            v-model="settingsDialog" scrollable
        >

                <v-card>
                    <v-card-title class="pa-0">
                        <v-toolbar dark >
                            <v-btn icon dark @click.stop="$router.go(-1)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{$t('app.settings.Settings')}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click.stop="$router.go(-1)">OK</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                    </v-card-title>
                    <v-card-text style="height: 300px;">

                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.Account')}}
                            </v-col>
                            <v-col  cols="6" >
                                <v-row>
                                    <v-col cols="12" md="6" lg="4" xl="2">
                                        <v-text-field
                                            v-model="username"
                                            :label="$t('app.Email')"
                                            :disabled="!editEmail"
                                            :rules="[v => !!v || this.$t('app.EmailIsRequired'), v => /.+@.+\..+/.test(v) || this.$t('app.EmailMustBeValid')]"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="8" xl="10">
                                        <!--<v-btn v-if="!editEmail" @click="editEmail=true">{{$t('app.settings.Edit')}}</v-btn>
                                        <v-btn v-if="editEmail" @click="editEmail=false">{{$t('app.settings.Set')}}</v-btn>-->
                                    </v-col>
                                </v-row>


                            </v-col>
                            <v-col  cols="6" >
                                <v-row>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <div @click="showChooseAvatar" v-on="{ ...tooltip }" style="cursor: pointer; margin-right:10px; width:160px; height:160px">
                                                <v-avatar class="pa-2 ma-0" size="160"><svg width="720" height="720" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <metadata>
                                                        <rdf:RDF>
                                                            <cc:Work rdf:about="">
                                                                <dc:format>image/svg+xml</dc:format>
                                                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                                                <dc:title/>
                                                            </cc:Work>
                                                        </rdf:RDF>
                                                    </metadata>
                                                    <g transform="translate(0 -106.5)">
                                                        <g transform="translate(2.5034 -7.6052)">
                                                            <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                                            <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                                        </g>
                                                    </g>
                                                    <g transform="translate(0 -106.5)">
                                                        <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((retrieved && retrieved.pictureUrl && retrieved.pictureUrl.indexOf('https')===-1) ? retrieved.pictureUrl : '0')+'.png'"/>
                                                    </g>
                                                </svg></v-avatar>
                                            </div>
                                        </template>
                                        <span>{{ $t('app.settings.ChooseAvatar') }}</span>
                                    </v-tooltip>



                                </v-row>
                            </v-col>

                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="6" lg="4" xl="2" v-if="editPassword">
                                        <v-text-field
                                            :label="$t('app.Password')"
                                            v-model="password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[v => (v || '').length >= 8 || this.$t('app.PasswordLength')]"
                                            :type="showPassword ? 'text' : 'password'"
                                            name="password"
                                            @click:append="() => (showPassword = !showPassword)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="8" xl="10">
                                        <v-btn class="my-5" v-if="editPassword" @click="setPassword">{{$t('app.settings.Set')}}</v-btn>
                                        <v-btn class="my-5" v-if="!editPassword" @click="editPassword=true">{{$t('app.settings.ChangePassword')}}</v-btn>
                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>
                        <PersonForm
                            v-if="item"
                            :handle-submit="onSendPersonForm"
                            :handle-update-field="updatePersonField"
                            :values="item"
                            :errors="violations"
                            :initial-values="retrieved" />
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.PushNotifications')}}
                            </v-col>
                            <v-col cols="2" >
                                <v-switch
                                    @change="updateSettingShowNotifications"
                                    :input-value="settings['showNotifications']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{ $t('app.settings.showNotifications') }}</span>
                                <p>{{$t('app.settings.NotificationDescription')}}:</p>
                                <ul>
                                    <li>{{$t('app.settings.NotificationLi1')}}</li>
                                    <li>{{$t('app.settings.NotificationLi2')}}</li>
                                    <li>{{$t('app.settings.NotificationLi3')}}</li>
                                    <li>{{$t('app.settings.NotificationLi4')}}</li>

                                </ul>
                                <p>
                                    <strong>{{$t('app.settings.Note')}}:</strong> {{$t('app.settings.NotificationNote')}}
                                </p>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.Location')}}
                            </v-col>
                            <v-col cols="2" >
                                <v-switch
                                    @change="updateSettingGetLocation"
                                    :input-value="settings['getLocation']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{ $t('app.settings.getLocation') }}</span>
                                <p>{{$t('app.settings.LocationDescription')}}</p>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.EmailNotification')}}
                            </v-col>
                            <v-col cols="2" >
                                <v-switch @change="updateSettingEmailsLogins"
                                          :input-value="settings['receiveEmailsLogins']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{$t('app.settings.Logins')}}</span>
                                <p>{{$t('app.settings.LoginsDescription')}}</p>
                            </v-col>

                            <v-col cols="2" >
                                <v-switch @change="updateSettingEmailsUpdates"
                                          :input-value="settings['receiveEmailsUpdates']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{$t('app.settings.Updates')}}</span>
                                <p>{{$t('app.settings.UpdatesDescription')}}</p>
                            </v-col>

                            <v-col cols="2" >
                                <v-switch @change="updateSettingEmailsSurveys"
                                          :input-value="settings['receiveEmailsSurveys']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{$t('app.settings.Surveys')}}</span>
                                <p>{{$t('app.settings.SurveysDescription')}}</p>
                            </v-col>

                            <v-col cols="2" >
                                <v-switch @change="updateSettingEmailsOffers"
                                          :input-value="settings['receiveEmailsOffers']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <span class=".subtitle-1">{{$t('app.settings.Offers')}}</span>
                                <p>{{$t('app.settings.OffersDescription')}}</p>
                            </v-col>

                        </v-row>
                        <v-divider></v-divider>

                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.PrivacySettings')}}
                            </v-col>
<!--
                            <v-col cols="12" class=".subtitle-2">
                                {{$t('app.settings.PrivacyFunctional')}}
                            </v-col>
                            <v-col cols="12">
                                {{$t('app.settings.PrivacyFunctionalDescription')}}
                            </v-col>
                            <v-col cols="2">
                                <v-switch @change="updateSettingGoogleAnalytics"
                                          :input-value="settings['googleAnalytics']"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <v-expansion-panels style="z-index: auto">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>{{$t('app.settings.PrivacyGoogleAnalytics')}}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <privacy-google-analytics/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                            <v-divider></v-divider>
-->



                            <v-col cols="12" class="text-subtitle-2">
                                {{$t('app.settings.PrivacyEssential')}}
                            </v-col>
                            <v-col cols="12">
                                {{$t('app.settings.PrivacyEssentialDescription')}}
                            </v-col>
                            <v-col cols="2" >
                                <v-switch disabled
                                          :input-value="true"
                                ></v-switch>
                            </v-col>
                            <v-col cols="10">
                                <v-expansion-panels style="z-index: auto">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Nimmerso
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <privacy-nimmerso/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="text-h6">
                                {{$t('app.settings.DeleteAccountTitle')}}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" >
                                <v-btn
                                    color="error"
                                    :to="{name:'DeleteAccount'}"
                                >
                                    {{$t('app.settings.DeleteAccount')}}
                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="showWebPushDeniedInfo"
                    max-width="400"
                >
                    <v-card>
                        <v-card-title class="text-h5">{{$t('app.settings.PushMessagingBlocked')}}</v-card-title>
                        <v-card-text>
                            {{$t('app.settings.PushMessagingBlockedDescription')}}
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click.stop="showWebPushDeniedInfo = false"
                            >
                                OK
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            <v-dialog
                v-model="showGetLocationDeniedInfo"
                max-width="400"
            >
                <v-card>
                    <v-card-title class="text-h5">{{$t('app.settings.LocationBlocked')}}</v-card-title>
                    <v-card-text>
                        {{$t('app.settings.LocationBlockedDescription')}}
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click.stop="showGetLocationDeniedInfo = false"
                        >
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="showChooseAvatarDialog"
                max-width="300"
                max-height="300"
            >
                <v-card>
                    <v-card-title>{{ $t('app.settings.ChooseAvatar') }}</v-card-title>
                    <v-card-text>
                        <v-carousel
                            hide-delimiters
                            height="200"
                            v-model="pictureUrl"
                        >
                            <v-carousel-item
                                v-for="i in 9"
                                :key="i"

                            >
                                <v-avatar class="pa-2 ma-0 ml-7" size="200"><svg width="720" height="720" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <metadata>
                                        <rdf:RDF>
                                            <cc:Work rdf:about="">
                                                <dc:format>image/svg+xml</dc:format>
                                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                                <dc:title/>
                                            </cc:Work>
                                        </rdf:RDF>
                                    </metadata>
                                    <g transform="translate(0 -106.5)">
                                        <g transform="translate(2.5034 -7.6052)">
                                            <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                            <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                        </g>
                                    </g>
                                    <g transform="translate(0 -106.5)">
                                        <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+(i-1)+'.png'"/>
                                    </g>
                                </svg></v-avatar>


                            </v-carousel-item>
                        </v-carousel>
                    </v-card-text>
                    <v-card-actions><v-spacer></v-spacer><v-btn @click="saveAvatar">{{ $t('app.Save') }}</v-btn><v-spacer></v-spacer></v-card-actions>

                </v-card>

            </v-dialog>

        </v-dialog>
    </div>
</template>

<script>

    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import PrivacyNimmerso from '../privacy/Nimmerso'
    import PersonForm from './PersonForm.vue'
    import fetch from '../../utils/fetch'
    import {ENTRYPOINT} from "../../config/entrypoint";
    export default {
        name: 'Login',
        components: {
            PrivacyNimmerso,
            PersonForm
        },
        computed: {
            ...mapState(['settings', 'user/token','user']),
            ...mapGetters({
                settings: 'settings/items',
                token: 'user/token/token',
                token_data: 'user/token/token_data',
                userRetrieved: 'user/show/retrieved',
                isLoading: 'person/update/isLoading',
                error: 'person/update/error',
                deleteError: 'person/del/error',
                deleteLoading: 'person/del/isLoading',
                created: 'person/create/created',
                deleted: 'person/del/deleted',
                retrieved: 'person/update/retrieved',
                updated: 'person/update/updated',
                violations: 'person/update/violations'
            })
        },
        data() {
            return {
                entrypoint: ENTRYPOINT,
                webPushClient: null,
                username: 'Anonymous',
                password: '',
                showPassword: false,
                settingsDialog: true,
                editEmail: false,
                editPassword: false,
                showWebPushDeniedInfo: false,
                showGetLocationDeniedInfo: false,
                settingShowNotifications: false,
                settingGetLocation: false,
                item: {},
                avatarError: false,
                showChooseAvatarDialog: false,
                pictureUrl: 1,
            }
        },
        watch: {
            settings: function () {
                this.settingShowNotifications = this.settings['showNotifications']
                this.settingGetLocation = this.settings['getLocation']
                window.console.log(this.settingShowNotifications);
            },
            settingShowNotifications: function () {
                this.webPushClient=window.webPushClient;
                this.$nextTick(() => {

                    if (this.settingShowNotifications === true && this.webPushClient.getPermissionState() === 'denied') {
                        this.showWebPushDeniedInfo = true;
                        this.settingShowNotifications = false;
                        this.setsettings({showNotifications: false});
                        this.$store.commit('settings/setsettings', {showNotifications: false})
                    } else {
                        //window.console.log(this.webPushClient.getPermissionState());
                        this.setsettings({showNotifications: this.settingShowNotifications === true});
                        this.$store.commit('settings/setsettings', {showNotifications: this.settingShowNotifications === true})

                        if (this.settingShowNotifications === true) {
                            this.webPushClient.subscribe([]).then((subscriptions)=>{ window.console.log(subscriptions)},(error)=>{
                                window.console.log(error)
                                this.showWebPushDeniedInfo = true;
                                this.settingShowNotifications = false;
                                this.setsettings({showNotifications: false});
                                this.$store.commit('settings/setsettings', {showNotifications: false})
                            });
                        } else {
                            this.webPushClient.unsubscribe([]);
                        }
                    }
                });
            },
            settingGetLocation: function(){
                this.$nextTick(() => {
                    if (this.settingGetLocation === true) {
                        navigator.permissions.query({name: 'geolocation'}).then( (result)=> {
                            window.console.log(result.state);
                            if (result.state == 'granted') {
                                window.console.log(result.state);
                            } else if (result.state == 'prompt') {
                                window.console.log(result.state);
                                navigator.geolocation.getCurrentPosition((cp)=>{
                                    window.console.log(cp);
                                }, (cp)=>{
                                    window.console.log(cp);
                                    this.showGetLocationDeniedInfo = true;
                                    this.settingGetLocation = false;
                                    this.setsettings({getLocation: false});
                                    this.$store.commit('settings/setsettings', {getLocation: false})
                                },{timeout:10000});
                            } else if (result.state == 'denied') {
                                window.console.log(result.state);
                                this.showGetLocationDeniedInfo = true;
                                this.settingGetLocation = false;
                                this.setsettings({getLocation: false});
                                this.$store.commit('settings/setsettings', {getLocation: false})
                            }
                            result.onchange = function () {
                                window.console.log(result.state);
                            }
                        });
                    }
                });
            },
            token: function () {
                this.getUsername();
            },
            userRetrieved: function(){
                window.console.log('userRetrieved',this.userRetrieved);
                this.settings['receiveEmailsLogins'] = typeof this.userRetrieved.receiveEmailsLogins!=='undefined' ? this.userRetrieved.receiveEmailsLogins : false;
                this.settings['receiveEmailsUpdates'] = typeof this.userRetrieved.receiveEmailsUpdates!=='undefined' ? this.userRetrieved.receiveEmailsUpdates : false;
                this.settings['receiveEmailsSurveys'] = typeof this.userRetrieved.receiveEmailsSurveys!=='undefined' ? this.userRetrieved.receiveEmailsSurveys : false;
                this.settings['receiveEmailsOffers'] = typeof this.userRetrieved.receiveEmailsOffers!=='undefined' ? this.userRetrieved.receiveEmailsOffers : false;

                this.setsettings({receiveEmailsLogins: typeof this.userRetrieved.receiveEmailsLogins!=='undefined' ? this.userRetrieved.receiveEmailsLogins : false});
                this.setsettings({receiveEmailsUpdates: typeof this.userRetrieved.receiveEmailsUpdates!=='undefined' ? this.userRetrieved.receiveEmailsUpdates : false});
                this.setsettings({receiveEmailsSurveys: typeof this.userRetrieved.receiveEmailsSurveys!=='undefined' ? this.userRetrieved.receiveEmailsSurveys : false});
                this.setsettings({receiveEmailsOffers: typeof this.userRetrieved.receiveEmailsOffers!=='undefined' ? this.userRetrieved.receiveEmailsOffers : false});

                this.$store.commit('settings/setsettings', {receiveEmailsLogins: typeof this.userRetrieved.receiveEmailsLogins!=='undefined' ? this.userRetrieved.receiveEmailsLogins : false})
                this.$store.commit('settings/setsettings', {receiveEmailsUpdates: typeof this.userRetrieved.receiveEmailsUpdates!=='undefined' ? this.userRetrieved.receiveEmailsUpdates : false})
                this.$store.commit('settings/setsettings', {receiveEmailsSurveys: typeof this.userRetrieved.receiveEmailsSurveys!=='undefined' ? this.userRetrieved.receiveEmailsSurveys : false})
                this.$store.commit('settings/setsettings', {receiveEmailsOffers: typeof this.userRetrieved.receiveEmailsOffers!=='undefined' ? this.userRetrieved.receiveEmailsOffers : false})
            },
            retrieved:function(){
                this.pictureUrl=this.retrieved.pictureUrl ? parseInt(this.retrieved.pictureUrl) : 1;
            }
        },
        created() {
            this.getUsername();
        },
        methods: {
            ...mapMutations({
                setsettings: 'settings/setsettings'
            }),
            ...mapActions({
                gettoken: 'user/token/gettoken',
                gettokendata: 'user/token/gettokendata',

                retrieve: 'person/update/retrieve',
                userRetrieve: 'user/show/retrieve',
                updateReset: 'person/update/reset',
                update: 'person/update/update',
                updateRetrieved: 'person/update/updateRetrieved'

            }),
            setError(message) {
                this.error = message;
            },
            getUsername() {
                if (this.token_data) {
                    this.username = this.token_data.username;
                    this.retrieve('/people/' + this.token_data.person_id);
                    this.userRetrieve('/users/' + this.token_data.id);


                } else {
                    window.console.log('no tokenData!')
                }

            },
            updateSettingEmailsLogins(e) {
                this.updateSetting('receiveEmailsLogins',e===true);
            },
            updateSettingEmailsUpdates(e) {
                this.updateSetting('receiveEmailsUpdates',e===true);
            },
            updateSettingEmailsSurveys(e) {
                this.updateSetting('receiveEmailsSurveys',e===true);
            },
            updateSettingEmailsOffers(e) {
                this.updateSetting('receiveEmailsOffers',e===true);
            },
            updateSettingGoogleAnalytics(e) {
                this.setsettings({googleAnalytics: e === true});
                this.$store.commit('settings/setsettings', {googleAnalytics: e === true})
            },
            updateSettingShowNotifications(e){
                this.setsettings({showNotifications: e === true});
                this.$store.commit('settings/setsettings', {showNotifications: e === true})
            },
            updateSettingGetLocation(e){
                this.setsettings({getLocation: e === true});
                this.$store.commit('settings/setsettings', {getLocation: e === true})
            },
            updatePersonField (field, value) {
                this.updateRetrieved({ [field]: value })
            },
            onSendPersonForm(fields){
                this.updateRetrieved(fields)
                this.update()
            },
            setPassword(){
                window.console.log(this.password);
                this.updateSetting('password',this.password);
            },
            updateSetting (setting,value) {
                if (this.token_data) {
                    window.console.log(this.token_data);
                } else {
                    window.console.log('no tokenData!')
                    return;
                }
                let settingObj={};
                settingObj[setting]=value;
                if(setting!=='password') {
                    this.setsettings(settingObj);
                    this.$store.commit('settings/setsettings', settingObj)
                }

                fetch('users/' + this.token_data.id, {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(settingObj)
                })
                    .then((response) => {
                        this.isLoading=false;
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json()
                    })
                    .then((data) => {
                        window.console.log(data)

                    })
                    .catch((error) => {
                        window.console.log(error);
                        this.setError(error)
                    })


            },
            showChooseAvatar(){
                this.showChooseAvatarDialog=true;
            },
            saveAvatar(){
                window.console.log(this.pictureUrl)
                this.updatePersonField ('pictureUrl', (this.pictureUrl).toString())
                this.update()
                this.showChooseAvatarDialog=false;
            }



        }
    }
</script>

