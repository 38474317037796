<template>
    <div class="svg-item">
        <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
            <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="rgba(255,255,255,.5)"></circle>
            <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
            <circle class="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" :style="{'stroke-dasharray': Math.round(startValue/full*100) + ' ' + (100-Math.round(startValue/full*100))}"   stroke-dashoffset="25"></circle>
            <g class="donut-text donut-text-3">

                <text y="50%" transform="translate(0, 2)">
                    <tspan x="50%" text-anchor="middle" class="donut-percent">{{Math.round(value/full*100)}}%</tspan>
                </text>
                <text y="60%" transform="translate(0, 2)">
                    <tspan x="50%" text-anchor="middle" class="donut-data">{{imagesCount}} Bilder</tspan>
                </text>
            </g>
        </svg>
    </div>
</template>
<style scoped>
.svg-item {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    animation: donutfade 1s;
    cursor: pointer;
}

@keyframes donutfade {
    /* this applies to the whole svg item wrapper */
    0% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .svg-item {
        width: 80%;
    }
}

.donut-ring {
    stroke: #EBEBEB;
}

.donut-segment {
    transform-origin: center;
    stroke: #FF6200;
    transition: stroke-dasharray 2s ease-out;
}


.donut-text {
    font-family: Arial, Helvetica, sans-serif;
    fill: #FF6200;
}
.donut-text-1 {
    fill: aqua;
}
.donut-text-2 {
    fill: #d9e021;
}
.donut-text-3 {
    fill: #ed1e79;
}

.donut-label {
    font-size: 0.28em;
    font-weight: 700;
    line-height: 1;
    fill: #000;
    transform: translateY(0.25em);
}

.donut-percent {
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
}

.donut-data {
    font-size: 0.12em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color:#666;
    fill: #666;
    animation: donutfadelong 1s;
}

</style>

<script>
export default {
    name: 'DonutChart.vue',
    props: {
        value: {
            type: Number,
            required: true
        },
        full: {
            type: Number,
            required: true
        },
        imagesCount: {
            type: Number,
            required: true
        },

    },
    data() {
        return {
            startValue: 0
        }
    },
    created() {

        setTimeout(() => {
            this.startValue=this.value
        }, 500);
    }
}
</script>

<style scoped>

</style>
