<template>
    <v-dialog  v-model="item" content-class="imagegalleryDialog" fullscreen
               transition="fade-transition">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
    <v-card>
        <v-card-title>{{ $t('app.Gallery.New') }}</v-card-title>



        <ImageGalleryForm
            :handle-submit="onSendForm"
            :handle-update-field="updateField"
            :values="item"
            :initial-values="item"
            :errors="violations"/>

        <v-footer class="text-justify pa-4">
            <v-btn small dark v-if="item"
                   :to="{ name: 'ImageGalleryList' }">{{ $t('app.BackToList') }}
            </v-btn>
        </v-footer>
    </v-card>
    </v-dialog>
</template>

<script>
import ImageGalleryForm from './Form'
import { mapActions, mapGetters } from 'vuex'
import fetch from '@/utils/fetch'
import { ENTRYPOINT } from '@/config/entrypoint'
//const {mapGetters, mapActions} = createNamespacedHelpers('imagegallery/create')

export default {
    components: {
        ImageGalleryForm
    },

    data () {
        return {
            item: {
                'headline':'',
                'fromYear': 1850,
                'toYear': 1980,
                'categories': []
            },
            entrypoint: ENTRYPOINT,
        }
    },

    computed: mapGetters({
        error: 'imagegallery/create/error',
        isLoading: 'imagegallery/create/isLoading',
        created: 'imagegallery/create/created',
        violations: 'imagegallery/create/violations',
        searchfilters: 'searchfilters/items',
    }),

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }

            this.$router.push({name: 'ImageGalleryUpdate', params: {id: created['@id']}})
        }
    },
    created () {

        if (this.$route.name==='ImageGalleryCreateSearchparameters'){
            let searchParams=this.getParamsFromSearchfilters()
            window.console.log('searchParams:',searchParams);
            this.getImages(searchParams);
        }
        //this.retrieve(decodeURIComponent(this.$route.params.id))
    },
    methods: {
        ...mapActions({
            create: 'imagegallery/create/create',
        }),

        onSendForm () {
            this.create(this.item)
        },

        updateField (field, value) {
            window.console.log(field,value);
            Object.assign(this.item, {[field]: value})
        },
        getParamsFromSearchfilters () {
            let params = []
            window.console.log(this.searchfilters);
            let titleArray=[];
            this.searchfilters.forEach((item) => {
                params.push(item.queryParam)

                switch(item.type) {
                    case 'category':
                        titleArray.push(item.text);
                        this.item.categories=['/categories/'+item.data['id']]
                        this.updateField('categories',['/categories/'+item.data['id']]);
                        break;
                    case 'daterange':
                        this.item.fromYear=item.data['fromDate'];
                        this.item.toYear=item.data['toDate'];
                        this.updateField('fromYear',item.data['fromDate']);
                        this.updateField('toYear',item.data['toDate']);
                        titleArray.push(item.text);
                        break;
                    case 'place':
                        titleArray.push(item.text);
                        window.console.log('place', item.data);
                        this.loadPlaceDetail(item.data.osm_type.substr(0,1)+item.data.osm_id);
                        break;
                    case 'text':
                        titleArray.push(item.text);
                        break;
                    //case 'types':
                    //    titleArray.push(item.text);
                    //    break;
                }

            })

            if(this.searchfilters.length===0){
                params.push('showInFeed=true')
            }else{
                this.item.headline=titleArray.join(', ');
                this.updateField('headline', this.item.headline);

                this.item.searchParameters=params.join('&');
                this.updateField('searchParameters', this.item.searchParameters);
                window.console.log('headline', this.item);
            }
            window.console.log('this.item',this.item);
            return params.join('&')
        },
        getImages (searchParams) {
            return fetch(this.entrypoint + '/feed_items?documentType=Photograph&itemsPerPage=100&' + searchParams, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    window.console.log(data);
                    if(data['hydra:member']){
                        let images=[];
                        data['hydra:member'].forEach((item) => {
                            images.push(decodeURIComponent(item.id));
                        });
                        this.$set(this.item, 'photographs', images)
                        window.console.log(this.item);
                    }

                })
                .catch((e) => {
                    window.console.error('Loading Photographs was not successful for the following reason: ' + e)
                    return e
                })

        },
        loadPlaceDetail(placeKey){
            window.console.log('loadPlaceDetails');
            if (placeKey==null) return;
            return fetch(this.entrypoint + '/placeDetail/de/' + placeKey, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    window.console.log(data);
                    data.display_name=data.localname;
                    //this.placesList=[data];
                    //this.geoSearchValue=data;
                    this.geoDataLoading=false;

                    const placeObj=this.mapToPlace(data)
                    window.console.log('placeObj',placeObj)
                    this.updateField('place',placeObj);
                    this.place=null;

                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    this.geoDataLoading=false;
                    return e
                })
        },
        mapToPlace(place){
            //const parser = new DOMParser();
            //const address = parser.parseFromString('<root>'+place.adr_address+'</root>', 'text/xml');
            window.console.log(place);
            let placeObj= {
                placeId: place.place_id,
                name: place.display_name,
                //types: place.types,
                postalAddress: {
                    /*addressCountry: address.querySelector('span.country-name') ? address.querySelector('span.country-name').innerHTML : null,
                    addressLocality: address.querySelector('span.locality') ? address.querySelector('span.locality').innerHTML : null,
                    addressRegion: address.querySelector('span.region') ? address.querySelector('span.region').innerHTML : null,
                    postalCode: address.querySelector('span.postal-code') ? address.querySelector('span.postal-code').innerHTML : null,
                    streetAddress: address.querySelector('span.street-address') ? address.querySelector('span.street-address').innerHTML : null,
                */
                },
                geo: {
                    longitude: this.manualset ? this.item.geo.longitude : place.geometry.coordinates[0],
                    latitude: this.manualset ? this.item.geo.latitude : place.geometry.coordinates[1]
                },
                //viewport: place.boundingbox,
            };
            let placeKeys=[];
            let displayName=[];
            if (place.address){
                for(let i=0; i<place.address.length; i++){
                    let addr=place.address[i];
                    if (addr.osm_type!==null && addr.isaddress ) {
                        placeKeys.push(addr.osm_type.substr(0,1)+addr.osm_id);
                        if (addr.place_type==='state') placeObj.postalAddress.addressRegionKey=addr.osm_type+addr.osm_id;
                        if (addr.place_type==='city'||
                            addr.place_type==='town'||
                            addr.place_type==='village'
                        ) placeObj.postalAddress.addressLocalityKey=addr.osm_type+addr.osm_id;
                        if (addr.class==='highway') placeObj.postalAddress.streetAddressKey=addr.osm_type+addr.osm_id;
                    }
                    if (addr.isaddress) {
                        displayName.push(addr.localname);
                    }
                }
                placeObj.name=displayName.join(', ');
                window.console.log(this.searchFieldValue);
                if (this.searchFieldValue===null || this.searchFieldValue=='') this.searchFieldValue=place.display_name;
            }
            this.updateField('placeKeys',placeKeys);

            if (place.country_code) placeObj.postalAddress.addressCountry = place.country_code
            if (place.country) placeObj.postalAddress.addressCountryLong = place.country
            if (place.city) placeObj.postalAddress.addressLocality = place.city
            if (place.town) placeObj.postalAddress.addressLocality = place.town
            if (place.village) placeObj.postalAddress.addressLocality = place.village
            if (place.state) placeObj.postalAddress.addressRegion = place.state
            if (place.road) placeObj.postalAddress.streetAddress = place.road

            return placeObj;
        },
    }
}
</script>
