import OrderItemList from '../components/orderitem/List'
import OrderItemCreate from '../components/orderitem/Create'
import OrderItemUpdate from '../components/orderitem/Update'
import OrderItemShow from '../components/orderitem/Show'

export default [
  { name: 'OrderItemList', path: '/shopping_cart/', components: {default: OrderItemList}, meta: { requiresAuth: true } },
  { name: 'OrderItemCreate', path: '/shopping_cart/add', components: {default: OrderItemCreate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderItemCreateSearchparameters', path: '/shopping_cart/add/:document_id', components: {default: OrderItemCreate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderItemUpdate', path: '/shopping_cart/edit/:id', components: {default: OrderItemUpdate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderItemShow', path: '/shopping_cart/show/:id', components: {'MyImagesTab': OrderItemShow}, meta: { requiresAuth: false, navHidden: true } },

]
