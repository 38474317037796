<template>
    <div class='list-container'>
        <div class="background-image-intro">
            <div class="intro-content">
                <!-- Left Column -->
                <v-col cols="4" md="6" sm="5"  lg="6" class="left-column">
                    <!-- Content for the left column -->
                    <div class="content-left">
                        <div class="time-image"></div>
                        <div class="panel_mobile">
                            <div class="text-mobile">
                                <div class="darken-bg">
                                </div>
                                <span class="intro-text-one">{{ $t('app.IntroTextOne') }}</span>
                                <br>
                                <span class="intro-text-two">{{ $t('app.IntroTextTwo') }}</span>
                                <br>
                                <span class="intro-text-number">10.000</span>
                                <br>
                                <span class="intro-text-three">{{ $t('app.IntroTextThree') }}</span>
                                <br>
                                <span class="intro-text-four">{{ $t('app.IntroTextFour') }}</span>
                            </div>
                        </div>
                        <div class="join-image" @click="scrollTo('section1')"></div>
                    </div>
                </v-col>

                <!-- Right Column -->
                <v-col cols="8" md="6" sm="7"  lg="6" class="right-column">
                    <!-- Content for the right column -->
                    <div class="content-right">
                        <div class="panel_web">
                            <div class="border"></div>
                            <div class="text-web">
                                <p class="intro-text-one">{{ $t('app.IntroTextOne') }}</p>
                                <p class="intro-text-two">{{ $t('app.IntroTextTwo') }}</p>
                                <p class="intro-text-number">10.000</p>
                                <p class="intro-text-three">{{ $t('app.IntroTextThree') }}</p>
                                <p class="intro-text-four">{{ $t('app.IntroTextFour') }}</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </div>
        </div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-tooltip left >
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn @click="goToFeed" icon
                       class="closeWinButton"
                       right
                       fixed
                       v-on="onTooltip">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('app.Close') }}</span>
        </v-tooltip>
        <div v-if="!isLoading"
         id="section1"
         class="pt-8
        d-flex
        font-weight-light
        font-italic
        flex-column justify-center align-center custom-line-height"
        >
            <span class="title-collections">Kulturerhalt</span>
            <span class="subtitle-collections">durch Crowdfunding</span>
        </div>
        <div  v-if="!isLoading" class="container custom-border mt-8 mb-8 ">
            <div class="row justify-center align-center ">
                    <div
                        @click="navigateTo(item)"
                        v-for="item in sortedCollections"
                        class="col-auto custom-hover"
                        :key="item.id"
                    >
                        <v-img
                            class="collection-presentative-image"
                            :src="entrypoint+'/photograph_collections/'+item.thumbnail+'/display'"
                            min-height="300"
                            width="240"
                            max-height="calc(25vh - 24px)"
                            margin="auto"
                            cover
                        ></v-img>
                        <v-progress-linear
                            class="custom-progress-bar"
                            :value='(item.crowdfundingFunded / item.crowdfundingRequired) * 100'
                            height="20"
                            color="#79bdfa"
                        ></v-progress-linear>
                        <v-list-item-content class="headline-on-cover">
                            <v-list-item-title>{{ item.headline }}</v-list-item-title>
                        </v-list-item-content>
                    </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-progress-linear >>> .v-progress-linear__buffer{
    box-shadow:unset !important;
    background-image: unset !important;
}
.v-progress-linear>>>.v-progress-linear__background{
    background-color: unset!important;
    border-color: black!important;
}
.v-progress-linear>>>.v-progress-linear__determinate{
    background-color: black !important;
}
.title-collections{
    color:#4c4238;
    font-size: 45px;
    font-weight: normal;
    font-family: 'Courier New', monospace;

}
.custom-line-height{
    line-height: 1.1;
    box-shadow: 0px -40px 60px 30px #d1c2af
}
.subtitle-collections{
    color:#4c4238;
    font-size: 25px;
    font-weight: lighter;
}
@media screen and (max-width: 960px) {
    .custom-border{
        border-image-width: 16rem;
        padding: 60px;
        padding-left: 60px;
        border-image-outset: 70px;
    }
    .container{
        max-width: 90vw!important;
    }
}
@media screen and (max-width: 770px) {
    .container{
        max-width: 70vw!important;
    }
    .custom-border{
        border-image-width: 16rem;
        padding: 60px;
        padding-left: 60px;
        border-image-outset: 70px;
    }
}
@media screen and (max-width: 600px) {
    .container{
        max-width: 76vw!important;
    }
    .custom-border{
        border-image-width: 7rem;
        padding: 18px;
        padding-left: 20px;
        border-image-outset: 45px;;

    }
}
@media screen and (max-width: 400px) {
    .container{
        max-width: 70vw!important;
    }
    .custom-border{
        border-image-width: 5rem;
        padding: 20px;
        padding-left: 20px;
        border-image-outset: 40px;

    }
}
</style>
<style>
@import "../../styles/intro-styles.css";
.image-wrapper {
    min-height: 25vh;
}
.list-container{
    background-color: #d1c2af   ;
    position:fixed;
    top:0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.custom-hover {
    cursor: pointer;
}
.custom-border{
    border-image: url("~@/assets/img/Frame 1 (2).svg") 30% stretch round;
    border-image-width: 25rem;
    border-image-outset: 90px;
    padding: 120px;

}

.shadow-img{
    border-color: #0A7EbE;
}
.headline-on-cover{
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
}
.custom-hover:hover .headline-on-cover{
    opacity: 1;
}

.custom-progress-bar {
    max-width: 300px;
    margin: 20px auto auto;
    border: black 1px solid;
    border-radius: 6px;
}
.collection-presentative-image {
    margin: auto;
    border: 1px solid  #0A7EbE; /* Example border style */
    border-radius: 4px;
    box-shadow:2px 4px 10px rgba(0,0,0,.5),-2px 4px 10px rgba(0,0,0,.5)
}

</style>
<script >
import {mapActions, mapGetters} from "vuex";
import {ENTRYPOINT} from "@/config/entrypoint";

export default {
    computed: mapGetters({
        error: 'photographcollection/list/error',
        items: 'photographcollection/list/items',
        isLoading: 'photographcollection/list/isLoading',
    }),
    watch: {
        items: function () {
            this.sortedCollections = Object.assign([], this.items)
            this.sortedCollections = this.sortedCollections
                .filter(item => item.status === 1)
                .sort((a, b) => {
                    // Check if crowdfundingFunded is non-null and then compare its values
                    const aHasFunded = a.crowdfundingFunded != null;
                    const bHasFunded = b.crowdfundingFunded != null;

                    if (aHasFunded && !bHasFunded) {
                        return -1; // a should come before b
                    }
                    if (!aHasFunded && bHasFunded) {
                        return 1; // b should come before a
                    }

                    // Both are either non-null or null, compare their values
                    if (a.crowdfundingFunded != null && b.crowdfundingFunded != null) {
                        if (a.crowdfundingFunded/a.crowdfundingRequired * 100 > b.crowdfundingFunded/b.crowdfundingRequired * 100) {
                            return -1; // a should come before b
                        }
                        if (a.crowdfundingFunded/a.crowdfundingRequired * 100  < b.crowdfundingFunded/b.crowdfundingRequired * 100) {
                            return 1; // b should come before a
                        }
                    }

                    // Fall back to internalRating if crowdfundingFunded values are equal or both are null
                    if (a.internalRating > b.internalRating) {
                        return -1; // a should come before b
                    }
                    if (a.internalRating < b.internalRating) {
                        return 1; // b should come before a
                    }
                    return 0; // They are equal
                });
        }
    },
    data() {
        return {
            entrypoint: ENTRYPOINT,
            sortedCollections: [],
            crowdfundingValue: 0,
        }
    },
    created () {
        this.getCollections('photograph_collections?page=1&order[dateCreated]=DESC&status=1')
    },

    methods:{
        ...mapActions({
            getCollections: 'photographcollection/list/default'
        }),
        scrollTo(sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        goToFeed() {
            this.$router.push('/feed');
        },
        navigateTo(item) {
            this.$router.push({name: 'PhotographCollectionShow', params: {id: item['@id']}})
        }
    }
}
</script>
