<template>
    <div if="item">
        <form  @submit.prevent="handleSubmit(item)" style="overflow-y:auto">
            <v-card v-if="step===0">
                <v-card-title>{{$t('app.ReportBugDialogDescription1')}}</v-card-title>
                <v-card-text>
                    <v-textarea
                    label="Nachricht"
                    v-model="reportBugText"
                    outlined
                    auto-grow
                ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeWin">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="step = 1"
                    >
                        {{$t('app.continue')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
            <v-card v-if="step===1">
                <v-card-title>{{$t('app.ReportBugDialogDescription1')}}</v-card-title>
                <v-card-text>
                    <v-btn @click="createScreenshot" :loading="creatingScreenshot">Bildschirmfoto hinzufügen</v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="step=0">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="handleSubmit"
                    >
                        {{$t('app.ReportBugSubmit')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
    </form>
    </div>
</template>

<style>
.noBackground{
    background-color: transparent !important;
}
</style>

<script>

import html2canvas from 'html2canvas';

export default {
    components: {
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },
        handleUpdateField: {
            type: Function,
            required: true
        },
        handleClose: {
            type: Function,
            required: true
        },
        editRectangle: {
            type: Function,
            required: false
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => {}
        }
    },

    computed: {

        item () {
            return this.initialValues || this.values
        },

        violations () {
            return this.errors || {}
        }
    },
    data: () => ({
        step: 0,
        reportBugText: '',
        creatingScreenshot: false,
    }),
    created () {
        this.step=0;
    },
    beforeDestroy () {
        window.console.log('beforeDestroy')
        this.step=0;
    },
    beforeMount () {
        this.step=0;
    },
    watch: {
        item: {
            handler:function() {
                this.reportBugText = this.item.text;
                if(this.item.text==='') this.step=0;
            },
            deep: true
        },
        reportBugText: function () {
            this.handleUpdateField('text',this.reportBugText);
            this.handleUpdateField('additionalData',{...this.item.additionalData, console: JSON.parse(JSON.stringify(window.console.everything))});
        },
    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        closeWin(){
            this.step=0;
            this.handleClose();
        },
        async createScreenshot(){
            this.creatingScreenshot=true;
            html2canvas(window.document.body,{
                ignoreElements: (element) => element.getAttribute('aria-modal') === 'true',
                allowTaint: true,
                logging: false,
                letterRendering: 1,
                useCORS: true
            }).then((canvas) => {
                const frame = canvas.toDataURL("image/png");
                //window.location.href = frame;
                this.creatingScreenshot=false;

                this.handleUpdateField('additionalData',{...this.item.additionalData, screenshot: frame});
            });

            /*const canvas = window.document.createElement("canvas");
            const context = canvas.getContext("2d");
            const screenshot = window.document.createElement("screenshot");

            try {
                const captureStream = await navigator.mediaDevices.getDisplayMedia();
                screenshot.srcObject = captureStream;
                context.drawImage(screenshot, 0, 0, window.width, window.height);
                const frame = canvas.toDataURL("image/png");
                captureStream.getTracks().forEach(track => track.stop());
                window.location.href = frame;
            } catch (err) {
                console.error("Error: " + err);
            }

             */
        }

    }
}
</script>
