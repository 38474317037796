import list from './list'
import create from './create'
import update from './update'
import show from './show'
import del from './delete'
import order_collection from './order_collection'

export default {
  namespaced: true,
  modules: {
    list,
    create,
    update,
    show,
    del,
    order_collection
  }
}
