<template>

    <v-dialog
        v-model="logindialog"
        fullscreen
        content-class="justify-center"
        fill-height
        justify="center"
        class="justify-center"
        fluid
    >

        <div class="loginDialog justify-center text-center">


            <v-card-title
                class="text-h5"
                primary-title
            >
                <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
            </v-card-title>

            <v-alert type="error" v-if="error!==''">
                {{ error }}
            </v-alert>
            <v-form ref="form" lazy-validation class="mx-2">
                <v-card-title class="text-center justify-center text-h4">{{$t('app.Login.SignIn')}}</v-card-title>
                <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    :label="$t('app.Login.EmailAddress')"
                    required
                    autofocus
                    outlined
                    prepend-inner-icon="mdi-account"

                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    :label="$t('app.Login.Password')"
                    :hint="$t('app.Login.PasswordHint')"
                    outlined
                    prepend-inner-icon="mdi-lock"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>
            </v-form>


            <v-card-actions>
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pa-1"
                    >
                        <v-btn
                            color="primary"
                            @click="login"
                            raised
                            block

                        >
                            {{ $t('app.Login.SignIn') }}
                        </v-btn>

                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        class="pa-1"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn
                                    v-on="onTooltip"
                                    color="#5890FF"
                                    block
                                    style="letter-spacing: normal;"
                                    @click="openLink(entrypoint+'/connect/facebook')"
                                >
                                    <v-icon style="max-height:36px; padding-right:14px">mdi-facebook</v-icon> {{ $t('app.Login.Facebook') }}
                                </v-btn>
                            </template>
                            <span>{{ $t('app.Login.Facebook') }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        class="pa-1"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn
                                    v-on="onTooltip"
                                    color="#FFFFFF"
                                    block
                                    style="letter-spacing: normal; color: rgba(0,0,0,0.54)"
                                    @click="openLink(entrypoint+'/connect/google')"
                                >
                                    <img src="@/assets/img/Google_G_Logo.svg" style="max-height:24px; padding-right:14px"/> {{ $t('app.Login.Google') }}
                                </v-btn>
                            </template>
                            <span>{{ $t('app.Login.Google') }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-card-text class="text-center pa-0 mt-4">{{ $t('app.loginUserHasNoAccountQuestion') }}
                <v-btn
                    color="secondary"
                    small
                    :to="{ name: 'Register' }"
                >
                    {{ $t('app.Login.Register') }}
                </v-btn>
            </v-card-text>

            <v-card-actions class="text-center justify-center">
                <v-btn small text :to="{ name: 'ResetPassword' }">{{ $t('app.Login.PasswordLost') }}</v-btn>
            </v-card-actions>

            <nimmerso-footer class="text-center justify-center"></nimmerso-footer>

        </div>
    </v-dialog>

</template>
<style>
.loginDialog {
    /*background: transparent !important;
    margin-left: auto !important;
    margin-right: auto !important;

     */
    background: rgba(0,0,0,0.75);
    max-width: 600px;
    min-width: 300px;
    overflow: auto;
    height: 100vh;
    font-family: "Roboto Condensed", Arial, sans-serif;
}

.loginDialog:after {
    background-image: url('~@/assets/img/Login_background.jpg');
    content: "";
    background-size: cover;
    background-position: center;
    background-color: gray;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: -1;
}

.loginDialog .v-input fieldset {
    background-color: rgba(0, 0, 0, 0.5)
}

</style>

<script>
import fetch from '../../utils/fetch'
import { mapActions, mapGetters } from 'vuex'
import NimmersoFooter from '../NimmersoFooter'
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
    name: 'Login',
    components: {
        NimmersoFooter,
    },
    computed: {
        ...mapGetters({
            settings: 'settings/items',
        })
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            showPassword: false,
            logindialog: true,
            email: '',
            password: '',
            error: '',
            rules: {
                required: value => !!value || this.$t('app.Login.Required'),
                min: v => v.length >= 6 || this.$t('app.Login.PasswordHint'),
                email: [
                    v => !!v || this.$t('app.Login.EmailRequired'),
                    v => /.+@.+\..+/.test(v) || this.$t('app.Login.EmailMustBeValid'),
                ]
            },
        }
    },
    methods: {
        ...mapActions({
            settoken: 'user/token/settoken',
            setrefreshtoken: 'user/token/setrefreshtoken',
            gettoken: 'user/token/gettoken',
            gettokenexpiretime: 'user/token/gettokenexpiretime',
            gettokendata: 'user/token/gettokendata',
            removetoken: 'user/token/removetoken',
            removerefreshtoken: 'user/token/removerefreshtoken',
        }),
        setError (message) {
            this.error = message
        },
        register () {

        },
        login () {
            this.logindialog = false
            this.removetoken()
            return fetch('/authentication_token', {
                method: 'POST',
                body: JSON.stringify({email: this.email.toLowerCase().trim(), password: this.password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then((response) => {
                    window.console.log("Login response:  ", response);
                    if (response.status < 200 || response.status >= 300) {
                        this.setError(response.statusText)
                        throw new Error(response.statusText)
                    }

                    return response.json()
                })
                .then(({token, refresh_token}) => {
                    window.console.log(119, token, refresh_token)
                    this.settoken(token)
                    this.setrefreshtoken(refresh_token)

                    const redirectRoute = this.getRedirectRoute()
                    if(redirectRoute && redirectRoute !== '/login') {
                        this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: null}));
                        this.$router.push({name: 'HomeList'}).then(() => this.$router.push({path: redirectRoute}));
                    }else{
                        this.$router.push({name: 'HomeList'})
                    }


                })
                .catch((error) => {
                    window.console.log('error',error);
                    this.logindialog = true
                    this.setError(error)
                })

        },
        openLink (url) {
            window.location = url
        },
        carouselItemClick(item){
            const documentType = item['documentType'];
            this.$router.push({name: documentType + 'Show', params: {id: decodeURIComponent(item['id'])}})

        },
        getRedirectRoute(){
            window.console.log('getRedirectRoute',this.settings['redirectRoute'])
            return this.settings['redirectRoute']
        },
    }
}
</script>

<style lang="css">
body {
    background: #605B56;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
