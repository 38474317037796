<template>

    <v-container v-if="item">
        <div v-for="attribute in attributes" :key="attribute.code" class="form-group">

            <v-text-field v-if="attribute.type==='Text' || attribute.type==='Number'"
                          :label="attribute.name"
                          :id="'attribute_'+attribute.code"
                          :value="item[attribute.code]"
                          :class="['form-control', isInvalid('headline') ? 'is-invalid' : '']"
                          :type="attribute.type.toLocaleLowerCase()"
                          :placeholder="attribute.name"
                          @input="(val)=> {handleUpdateField(attribute.code, val)}"></v-text-field>


            <v-menu
                v-if="attribute.type==='Date' || attribute.type==='Time'"
                :close-on-content-click="false"
                :nudge-right="40"
                v-model="calendarsShow[attribute.code]"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="attribute.name"
                        :id="'attribute_'+attribute.code"
                        v-model="item[attribute.code]"
                        :class="['form-control', isInvalid('headline') ? 'is-invalid' : '']"
                        :prepend-icon="attribute.type==='Date' ? 'mdi-calendar' : 'mdi-clock'"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-if="attribute.type==='Date'"
                               min="1700-01-01"
                               v-model="item[attribute.code]"
                               @change="()=> {handleUpdateField(attribute.code, item[attribute.code])}"
                               @input="calendarsShow[attribute.code] = false"
                ></v-date-picker>
                <v-time-picker v-if="attribute.type==='Time'"
                               v-model="item[attribute.code]"
                               @change="()=> {handleUpdateField(attribute.code, item[attribute.code])}"
                               @input="calendarsShow[attribute.code] = false"
                ></v-time-picker>
            </v-menu>

            <v-checkbox v-if="attribute.type==='Boolean'"
                        v-model="item[attribute.code]"
                        :label="attribute.name"
                        @change="()=> {handleUpdateField(attribute.code, item[attribute.code])}"

            ></v-checkbox>

            <v-autocomplete v-if="attribute.type==='Location'"
                            v-model="item[attribute.code]"
                            :items="placesList[attribute.code] || []"
                            :loading="dataLoading[attribute.code]"
                            :ref="attribute.code"
                            :search-input.sync="geoSearch[attribute.code]"
                            hide-no-data
                            hide-selected
                            item-text="text"
                            clearable
                            @click:clear="item[attribute.code]=null"

                            :label="attribute.name"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-map"
                            return-object
                            @update:search-input="onSearchFieldChange(attribute.code)"
                            @change="()=> {handleUpdateField(attribute.code, item[attribute.code])}"
            ></v-autocomplete>

            <v-autocomplete v-if="attribute.type==='AlbumLink'"
                            v-model="item[attribute.code]"
                            :items="galleryList[attribute.code] || []"
                            :loading="dataLoading[attribute.code]"
                            :ref="attribute.code"
                            :search-input.sync="gallerySearch[attribute.code]"
                            hide-no-data
                            hide-selected
                            item-text="headline"
                            clearable
                            @click:clear="()=> {handleUpdateField(attribute.code, null)}"
                            :label="attribute.name"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-image-multiple"
                            return-object
                            @update:search-input="onSearchFieldChange(attribute.code)"
                            @change="()=> {handleUpdateField(attribute.code, item[attribute.code])}"
            ></v-autocomplete>

            <div
                v-if="isInvalid('headline')"
                class="invalid-feedback">{{ violations.headline }}
            </div>
        </div>
    </v-container>

</template>
<style>

</style>
<script>

import { mapActions } from 'vuex'
import fetch from '@/utils/fetch'
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
    components: {},
    props: {

        values: {
            type: Object,
            required: true
        },

        categories: {
            type: Array,
            required: true
        },
        allCategories: {
            type: Array,
            required: true
        },

        initialValues: {
            type: Object,
            default: () => {
                return {}
            }
        },
        handleUpdateField: {
            type: Function,
            required: true
        },
    },
    computed: {

        item () {
            //return Object.assign({}, this.initialValues || this.values);
            return this.values //JSON.parse(JSON.stringify( this.values))
        },

        violations () {
            return this.errors || {}
        }
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        attributes: {},
        calendarsShow: {},
        geoSearch: {},
        oldGeoSearch: {},
        placesList: [],
        gallerySearch: {},
        oldGallerySearch: {},
        galleryList: [],
        dataLoading: {}
    }),
    mounted () {

        this.$nextTick(() => {
        this.updateAttributes()
        });
    },
    watch: {
        categories: {
            handler: function () {
                this.updateAttributes()
            },
            deep: true
        },
        allCategories: {
            handler: function () {
                this.updateAttributes()
            },
            deep: true
        },
        item: {
            handler: function () {
                this.updateAttributes()
            },
            deep: true
        }

    },
    methods: {
        ...mapActions({
            getSvgTemplates: 'svgtemplate/list/default',
            getCategories: 'category/list/default'
        }),

        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        getTranslation (item) {
            let transList = item.translations.filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item.translations[0] : transList[0]
        },
        updateAttributes () {
            this.attributes = {}
            if (this.categories.length > 0) {
                this.categories.forEach((category) => {
                    let catObject = this.allCategories.filter(c => c['@id'] === category)[0]
                    if (typeof (catObject) !== 'undefined') {
                        const translation = this.getTranslation(catObject)
                        if (typeof translation.attributesDefinition === 'object' && translation.attributesDefinition !== null) {
                            translation.attributesDefinition.forEach((aD) => {
                                this.attributes[aD.code] = aD

                                if (this.attributes[aD.code].type === 'Location') {
                                    this.$set(this.dataLoading, aD.code, false)
                                    if (typeof this.geoSearch[aD.code] == 'undefined') {
                                        this.$set(this.geoSearch, aD.code, this.item[aD.code] ? this.item[aD.code].text : null)
                                        this.$set(this.oldGeoSearch, aD.code, this.item[aD.code] ? this.item[aD.code].text : null)
                                    }
                                    if (typeof this.placesList[aD.code] == 'undefined') {
                                        this.$set(this.placesList, aD.code, [])
                                    }
                                }
                                if (this.attributes[aD.code].type === 'AlbumLink') {
                                    this.$set(this.dataLoading, aD.code, false)
                                    if (typeof this.gallerySearch[aD.code] == 'undefined') {
                                        this.$set(this.gallerySearch, aD.code, this.item[aD.code] ? this.item[aD.code].headline : null)
                                        this.$set(this.oldGallerySearch, aD.code, this.item[aD.code] ? this.item[aD.code].headline : null)
                                    }
                                    if (typeof this.galleryList[aD.code] == 'undefined') {
                                        this.$set(this.galleryList, aD.code, [])
                                    }

                                }

                            })
                        }
                    }
                })
            }
        },
        onSearchFieldChange (attributeCode) {
            if (typeof this.item === 'undefined') return false
            if (typeof this.dataLoading[attributeCode] == 'undefined') {
                this.$set(this.dataLoading, attributeCode, false)
            }
            if (typeof this.geoSearch[attributeCode] == 'undefined' || this.geoSearch[attributeCode] == null) {
                this.$set(this.geoSearch, attributeCode, (typeof this.item !== 'undefined' && typeof this.item[attributeCode] !== 'undefined' && this.item[attributeCode] !== null) ? this.item[attributeCode].text : '')
            }
            if (typeof this.gallerySearch[attributeCode] == 'undefined' || this.gallerySearch[attributeCode] == null) {
                this.$set(this.gallerySearch, attributeCode, (typeof this.item !== 'undefined' && typeof this.item[attributeCode] !== 'undefined' && this.item[attributeCode] !== null) ? this.item[attributeCode].headline : '')
            }
            if (typeof this.placesList[attributeCode] == 'undefined' || this.placesList[attributeCode].length === 0) {
                this.$set(this.placesList, attributeCode, [this.item[attributeCode]])
            }
            if (typeof this.galleryList[attributeCode] == 'undefined' || this.galleryList[attributeCode].length === 0) {
                this.$set(this.galleryList, attributeCode, [this.item[attributeCode]])
            }
            if (this.oldGeoSearch[attributeCode] !== this.geoSearch[attributeCode] && this.geoSearch[attributeCode] !== '' && this.geoSearch[attributeCode] !== null) {
                this.$set(this.dataLoading, attributeCode, true)
                if (this.loadGeoDataTimeout !== null) clearTimeout(this.loadGeoDataTimeout)
                this.loadGeoDataTimeout = setTimeout(() => {this.loadGeoData(attributeCode)}, 1000)
            }
            if (this.oldGallerySearch[attributeCode] !== this.gallerySearch[attributeCode] && this.gallerySearch[attributeCode] !== '' && this.gallerySearch[attributeCode] !== null) {
                this.$set(this.dataLoading, attributeCode, true)
                if (this.loadGalleryDataTimeout !== null) clearTimeout(this.loadGalleryDataTimeout)
                this.loadGalleryDataTimeout = setTimeout(() => {this.loadGalleryData(attributeCode)}, 1000)
            }


        },
        loadGeoData (attributeCode) {
            this.oldGeoSearch[attributeCode] = this.geoSearch[attributeCode]
            return fetch(this.entrypoint + '/placesSearch?q=' + this.geoSearch[attributeCode], {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    this.$set(this.placesList, attributeCode, data.map((item) =>{
                        return {
                            text: item.properties.name+ ' ('+this.$t('app.PlaceTypes.'+item.properties.type)+')',
                            place_id: item.properties.osm_type+item.properties.osm_id,
                            data: item

                        }
                    }))
                    this.$set(this.dataLoading, attributeCode, false)
                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    this.dataLoading[attributeCode] = false
                    return e
                }).finally(() => (this.$set(this.dataLoading, attributeCode, false)))

        },

        loadGalleryData (attributeCode) {
            this.oldGallerySearch[attributeCode] = this.gallerySearch[attributeCode]
            return fetch(this.entrypoint + '/image_galleries?headline=' + this.gallerySearch[attributeCode], {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    if(data['hydra:member']){
                        let galleries=[];
                        data['hydra:member'].forEach((item) => {
                            galleries.push({
                                id:item.id,
                                '@id':item['@id'],
                                headline: item.headline
                            });
                        });
                        this.$set(this.galleryList, attributeCode, galleries)
                        //window.console.log(this.galleryList);
                    }
                    this.$set(this.dataLoading, attributeCode, false)
                })
                .catch((e) => {
                    window.console.error('Loading galleries was not successful for the following reason: ' + e)
                    this.dataLoading[attributeCode] = false
                    return e
                }).finally(() => (this.$set(this.dataLoading, attributeCode, false)))

        },
    }
}
</script>
