import ImageGalleryList from '../components/imagegallery/List'
import ImageGalleryCreate from '../components/imagegallery/Create'
import ImageGalleryUpdate from '../components/imagegallery/Update'
import ImageGalleryShow from '../components/imagegallery/Show'

export default [
  { name: 'ImageGalleryList', path: '/my_images/', components: {'MyImagesTab': ImageGalleryList}, meta: { requiresAuth: true } },
  { name: 'ImageGalleryCreate', path: '/image_galleries/create', components: {'MyImagesTab': ImageGalleryCreate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'ImageGalleryCreateSearchparameters', path: '/image_galleries/create/forSearchParameters', components: {default: ImageGalleryCreate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'ImageGalleryUpdate', path: '/image_galleries/edit/:id', components: {'MyImagesTab': ImageGalleryUpdate}, meta: { requiresAuth: true, navHidden: true } },

  { name: 'ImageGalleryShowCommentColor', path: '/image_galleries/show/:id/:comment_id/color', components: { 'MyImagesTab': ImageGalleryShow}, meta: { requiresAuth: false, navHidden: true } },
  { name: 'ImageGalleryShowColor', path: '/image_galleries/show/:id/color', components: {'MyImagesTab': ImageGalleryShow}, meta: { requiresAuth: false, navHidden: true } },
  { name: 'ImageGalleryShowComment', path: '/image_galleries/show/:id/:comment_id', components: { 'MyImagesTab': ImageGalleryShow}, meta: { requiresAuth: false, navHidden: true } },
  { name: 'ImageGalleryShow', path: '/image_galleries/show/:id', components: {'MyImagesTab': ImageGalleryShow}, meta: { requiresAuth: false, navHidden: true } },

]
