<template>
  <div>


    <v-snackbar
            v-if="created"
            v-model="created"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ created['@id'] }} created.
        </v-snackbar>
        <v-snackbar
            v-if="updated"
            v-model="updated"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ updated['@id'] }} updated.
        </v-snackbar>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="deleteLoading"
            v-model="deleteLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
      <v-icon>mdi-alert</v-icon> {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon> {{ deleteError }}
        </v-snackbar>
      <v-dialog v-if="item" v-model="item" content-class="photographcollectionDialog" fullscreen hide-overlay
                transition="fade-transition">
          <h1>Edit {{ item && item['@id'] }}</h1>
          <v-card-text>
    <PhotographCollectionForm
      v-if="item"
      :handle-submit="onSendForm"
      :handle-update-field="updateField"
      :values="item"
      :errors="violations"
      :initial-values="retrieved" />
          </v-card-text>

          <v-footer class="text-justify pa-4">
              <v-btn small dark v-if="item"
                     @click="$router.back()">{{ $t('app.BackToList') }}
              </v-btn>&nbsp;
              <v-spacer></v-spacer>
              <v-tooltip top
                         v-if="retrieved && retrieved['@type']==='PhotographCollection' && retrieved['status']===0">
                  <template v-slot:activator="{ on: onTooltip }">
                      <v-btn @click="del(retrieved)" v-on="onTooltip" color="error" small dark>
                          <v-icon>mdi-delete</v-icon>{{ $t('app.Delete') }}
                      </v-btn>
                  </template>
                  <span>{{ $t('app.Delete') }}</span>
              </v-tooltip>
          </v-footer>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PhotographCollectionForm from './Form.vue'

export default {
  components: {
    PhotographCollectionForm
  },

  data () {
    return {
      item: {}
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'photographcollection/update/isLoading',
      error: 'photographcollection/update/error',
      deleteError: 'photographcollection/del/error',
      deleteLoading: 'photographcollection/del/isLoading',
      created: 'photographcollection/create/created',
      deleted: 'photographcollection/del/deleted',
      retrieved: 'photographcollection/update/retrieved',
      updated: 'photographcollection/update/updated',
      violations: 'photographcollection/update/violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    deleted: function (deleted) {
      if (!deleted) {
        return
      }

      this.$router.push({ name: 'PhotographCollectionList' })
    }
  },

  beforeDestroy () {
    this.reset()
  },

  created () {
    this.retrieve(decodeURIComponent(this.$route.params.id))
  },

  methods: {
    ...mapActions({
      createReset: 'photographcollection/create/reset',
      deleteItem: 'photographcollection/del/del',
      delReset: 'photographcollection/del/reset',
      retrieve: 'photographcollection/update/retrieve',
      updateReset: 'photographcollection/update/reset',
      update: 'photographcollection/update/update',
      updateRetrieved: 'photographcollection/update/updateRetrieved'
    }),

    del () {
      if (window.confirm('Are you sure you want to delete this photographcollection ?')) {
        this.deleteItem(this.retrieved)
      }
    },

    reset () {
      this.updateReset()
      this.delReset()
      this.createReset()
    },

    onSendForm () {
      this.update()
    },

    updateField (field, value) {
      this.updateRetrieved({ [field]: value })
    }
  }
}
</script>
