<template>
  <v-card>
    <v-card-title>{{ $t('app.Order.Edit') }}</v-card-title>

    <v-snackbar
            v-if="created"
            v-model="created"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ created['@id'] }} created.
        </v-snackbar>
        <v-snackbar
            v-if="updated"
            v-model="updated"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ updated['@id'] }} updated.
        </v-snackbar>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="deleteLoading"
            v-model="deleteLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
      <v-icon>mdi-alert</v-icon> {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon> {{ deleteError }}
        </v-snackbar>
      <v-snackbar
          v-if="uploadError"
          v-model="uploadError"
          :timeout="5000"
          :top="true"
          color="error"
      >
          <v-icon>mdi-alert</v-icon> {{ uploadError }}
      </v-snackbar>
    <OrderForm
      v-if="item"
      :handle-submit="onSendForm"
      :handle-update-field="updateField"
      :values="item"
      :errors="violations"
      :initial-values="retrieved" />

      <v-footer class="text-justify pa-4">
          <v-btn small dark v-if="item"
                       :to="{ name: 'OrderList' }">{{ $t('app.BackToList') }}
          </v-btn>&nbsp;
          <v-spacer></v-spacer>
          <v-btn color="error" small dark
                 @click="del"><v-icon>mdi-delete</v-icon>{{ $t('app.Delete') }}
          </v-btn>
      </v-footer>
</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderForm from './Form.vue'

export default {
  components: {
    OrderForm
  },

  data () {
    return {
      item: {}
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'order/update/isLoading',
      error: 'order/update/error',
      deleteError: 'order/del/error',
      deleteLoading: 'order/del/isLoading',
      created: 'order/create/created',
      deleted: 'order/del/deleted',
      retrieved: 'order/update/retrieved',
      updated: 'order/update/updated',
      violations: 'order/update/violations',
        uploadError: 'order/upload/error',
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    deleted: function (deleted) {
      if (!deleted) {
        return
      }

      this.$router.push({ name: 'OrderList' })
    },
      updated: function() {
          this.$router.push({ name: 'OrderList' })
      }
  },

  beforeDestroy () {
    this.reset()
  },

  created () {
    this.retrieve(decodeURIComponent(this.$route.params.id))
  },

  methods: {
    ...mapActions({
      createReset: 'order/create/reset',
      deleteItem: 'order/del/del',
      delReset: 'order/del/reset',
      retrieve: 'order/update/retrieve',
      updateReset: 'order/update/reset',
      update: 'order/update/update',
      updateRetrieved: 'order/update/updateRetrieved'
    }),

    del () {
      if (window.confirm('Are you sure you want to delete this order ?')) {
        this.deleteItem(this.retrieved)
      }
    },

    reset () {
      this.updateReset()
      this.delReset()
      this.createReset()
    },

    onSendForm (fields) {
        this.updateRetrieved(fields)
        if (typeof fields['retrosnaps'] !== 'undefined') this.updateRetrieved({ ['retrosnaps']: this.arrayToIris(fields['retrosnaps']) })
        this.update()
    },

    updateField (field, value) {
      this.updateRetrieved({ [field]: value })
    },

    arrayToIris(datas){
        let iriArray=[];
        datas.forEach((data)=>{
            iriArray.push(data['@id']);
        });
        return iriArray;
    }
  }
}
</script>
