<template>
    <v-form @submit.prevent="" ref="form" v-model="valid">
        <v-container>

            <v-card class="">
                <v-card-title>{{ $t('app.BillingAddress') }}</v-card-title>
                <order-address-form :values="item.billingAddress" :handle-update-field="billingAddressUpdate"></order-address-form>
            </v-card>
            <v-card v-if="item.shippingAddressNeeded">
                <v-card-title>{{ $t('app.ShippingAddress') }}</v-card-title>
                <v-card-subtitle>
                    <v-checkbox
                        v-model="sameAsBillingAddress"
                        :label="$t('app.SameAsBillingAddress')"
                    ></v-checkbox></v-card-subtitle>
                <order-address-form v-show="!sameAsBillingAddress" :values="item.shippingAddress"  :handle-update-field="shippingAddressUpdate"></order-address-form>
            </v-card>

        </v-container>
    </v-form>
</template>

<script>
    import {ENTRYPOINT} from '@/config/entrypoint';
    import { mapActions } from 'vuex'
    import OrderAddressForm from '@/components/order/OrderAddressForm'
    export default {
        components: {
            OrderAddressForm
        },
        props: {
            handleUpdateField: {
                type: Function,
                required: true
            },
            handleValidChange: {
                type: Function,
                required: true
            },
            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                    return {
                        billingAddress: {},
                        shippingAddress: {},
                        shippingAddressNeeded: true,
                        status: 'new'
                    }
                }
            }
        },
        computed: {
            // eslint-disable-next-line
            item() {
                let item = JSON.parse( JSON.stringify(this.initialValues ? this.initialValues : this.values));
                item.billingAddress = {
                    ...item.billingAddress,
                    addressCountry: item.billingAddress.addressCountry || 'DE'
                };
                return item
                //return this.initialValues || this.values
            },

            violations() {
                return this.errors || {}
            },
        },
        data: () => ({
            entrypoint: ENTRYPOINT,
            webpSupported: false,
            sameAsBillingAddress: true,
            valid: false
        }),
        created () {
        },
        watch: {

            item: {
                handler:function() {

                },
                deep: true
            },

            valid(){
                window.console.log('valid changed', this.valid)
                this.handleValidChange(this.valid);
            },

            sameAsBillingAddress(){
                if(this.sameAsBillingAddress){
                    this.handleUpdateField('shippingAddress',this.item.billingAddress);
                }else{
                    this.handleUpdateField('shippingAddress',{});
                }
                this.$refs.form.validate()
            }
        },
        methods: {
            ...mapActions({
                getSvgTemplates: 'svgtemplate/list/default',
                getCategories: 'category/list/default'
            }),

            billingAddressUpdate(field, value){
                this.item.billingAddress[field]=value;
                this.handleUpdateField('billingAddress',this.item.billingAddress);
                if(this.sameAsBillingAddress){
                    this.handleUpdateField('shippingAddress',this.item.billingAddress);
                }
            },
            shippingAddressUpdate(field, value){
                this.item.shippingAddress[field]=value;
                this.handleUpdateField('shippingAddress',this.item.shippingAddress);
            }

        }
    }
</script>
