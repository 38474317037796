//import UserList from '../components/user/List'
import UserCreate from '../components/user/Create'
//import UserUpdate from '../components/user/Update'
//import UserShow from '../components/user/Show'
import Login from '../components/login/Login'
import Registered from '../components/user/Registered'
import UserActivate from '../components/user/Activate'
import ResetPassword from '../components/user/ResetPassword'
import ResetPasswordOk from '../components/user/ResetPasswordOk'
import UserResetPassword from '../components/user/UserResetPassword'
import UserResetPasswordOk from '../components/user/UserResetPasswordOk'
import OauthConnectCheck from '@/components/user/OauthConnectCheck'
import OauthConnectCheckRegister from '@/components/user/OauthConnectCheckRegister'
import DeleteAccount from '../components/user/DeleteAccount'

export default [
  //{ name: 'UserList', path: '/users/', component: UserList, meta: { requiresAuth: true } },
  //{ name: 'UserCreate', path: '/users/create', component: UserCreate, meta: { requiresAuth: true } },
  //{ name: 'UserUpdate', path: '/users/edit/:id', component: UserUpdate, meta: { requiresAuth: true } },
  //{ name: 'UserShow', path: '/users/show/:id', component: UserShow, meta: { requiresAuth: true } },
  { name: 'Login', path: '/login', component: Login, meta: { navHidden: true, requiresAuth: false } },
  { name: 'Register', path: '/register', component: UserCreate, meta: { navHidden: true, requiresAuth: false } },
  { name: 'Registered', path: '/registered', component: Registered, meta: { navHidden: true, requiresAuth: false } },
  { name: 'UserActivate', path: '/users/activate/:id/:invitationcode', component: UserActivate, meta: { navHidden: true, requiresAuth: false } },
  { name: 'ResetPassword', path: '/reset_password', component: ResetPassword, meta: { navHidden: true, requiresAuth: false } },
  { name: 'ResetPasswordOk', path: '/reset_password_ok', component: ResetPasswordOk, meta: { navHidden: true, requiresAuth: false } },
  { name: 'UserResetPassword', path: '/users/reset_password/:id/:passwordResetToken', component: UserResetPassword, meta: { navHidden: true, requiresAuth: false } },
  { name: 'UserResetPasswordOk', path: '/users/reset_password_ok', component: UserResetPasswordOk, meta: { navHidden: true, requiresAuth: false } },
  { name: 'OauthConnectCheck', path: '/connect/:provider/check', component: OauthConnectCheck, meta: { navHidden: true, requiresAuth: false } },
  { name: 'OauthConnectCheckRegister', path: '/connect/:provider/check/register', component: OauthConnectCheckRegister, meta: { navHidden: true, requiresAuth: false } },
  { name: 'DeleteAccount', path: '/delete_account', component: DeleteAccount, meta: { navHidden: true, requiresAuth: false } },
]
