/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    scope: '.',
    ready () {
      window.console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      window.console.log('Service worker has been registered.');

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    /*registered () {
      window.console.log('Service worker has been registered.')
    },*/
    cached () {
      window.console.log('Content has been cached for offline use.')
    },
    updatefound () {
      window.console.log('New content is downloading.')
    },
    updated (registration) {
      window.console.log('New content is available; please refresh.');

      // Add a custom event and dispatch it.
      // Used to display of a 'refresh' banner following a service worker update.
      // Set the event payload to the service worker registration object.
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    /*updated () {
      window.console.log('New content is available; please refresh.')
      caches.keys().then(function(names) {
        for (let name of names)
          caches.delete(name);
      });
    },*/
    offline () {
      window.console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
