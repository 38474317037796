
import PhotographUpdate from '../components/photograph/Update'
import PhotographShow from '../components/photograph/Show.vue'
import PhotographRetrosnaps from '../components/photograph/PhotographRetrosnaps'

export default [
  { name: 'PhotographUpdate', path: '/photographs/edit/:id', components: { default: PhotographUpdate}, meta: { requiresAuth: true, navHidden: true } },

  { name: 'PhotographShowBackCommentColor', path: '/photographs/show/:id/back/color/:comment_id', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowCommentColor', path: '/photographs/show/:id/color/:comment_id', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowBackColor', path: '/photographs/show/:id/back/color', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowColor', path: '/photographs/show/:id/color', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowBackComment', path: '/photographs/show/:id/back/:comment_id', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowComment', path: '/photographs/show/:id/:comment_id', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'PhotographShowBack', path: '/photographs/show/:id/back', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },


  { name: 'PhotographShow', path: '/photographs/show/:id', components: { default: PhotographShow}, meta: { navHidden: true, fullscreen: true } },

  { name: 'PhotographRetrosnaps', path: '/photographs/retrosnaps/:id', components: { default: PhotographRetrosnaps}, meta: { navHidden: true, fullscreen: true } },

]
