<template>
    <div class="collection-intro-container">
        <div class="container_for_buttons">
        <landing-buttons :displayColumn="true"/>
        </div>
    </div>
</template>

<script>
import LandingButtons from "@/components/landing/LandingButtons.vue";
export default {
    components: {LandingButtons},
};
</script>

<style scoped>
.collection-intro-container {
    position:fixed;
    top:0;
    width: 100vw;
    height: 100vh;
    overflow:auto
}


.container_for_buttons{
    background-color: #1F1F1F !important;
    width: 100% !important;
}
</style>
