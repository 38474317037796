<template>
    <div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <div id="collageImageviewer"
             style="position:fixed; top:0; width: 100vw; height: 100vh; background-color: black;"></div>
        <v-tooltip left v-if="itemId">
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn @click.stop="closeWin" icon
                       class="closeWinButton"
                       right
                       fixed
                       v-on="onTooltip">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('app.Close') }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import OpenSeadragon from 'openseadragon';
import {ENTRYPOINT} from "@/config/entrypoint";
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            deleteError: 'photograph/del/error',
            error: 'photograph/show/error',
            isLoading: 'photograph/show/isLoading',
        })
    },
    data() {
        return {
            itemId: null,
            entrypoint: ENTRYPOINT,
        };
    },
    created() {
        this.itemId = this.$route.params.id;
        this.collectionId = this.$route.params.collectionId;
    },
    mounted() {
        let options = {
            id: 'collageImageviewer',
            maxZoomLevel: 20,
            minZoomLevel: 0.3,
            showNavigator: false,
            visibilityRatio: 1.0,
            constrainDuringPan: true,
            showNavigationControl: false,
            homeFillsViewer: window.innerWidth < 600 && !this.showBackside,
            degrees: this.imageRotation,
            gestureSettingsTouch: {
                pinchRotate: false
            },
            debugMode: false,
            immediateRender: true,
            tileSources: {
                type: 'image',
                url: `${this.entrypoint}/media/photographs/photograph_collection/raw/${this.collectionId}/${this.itemId}.jpeg`
            },
            error: function(event) {
                window.console.error("Error loading image:", event);
            }
        }
        this.viewer = OpenSeadragon(options)
        this.viewer.addHandler('open-failed', function(event) {
            window.console.error('OpenSeadragon open-failed event:', event.message);
        });
    },
    methods: {
        closeWin () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/feed')
            //this.isActive=false;
        },
    },
    beforeDestroy() {
        if (this.viewer) {
            this.viewer.destroy();
        }
    }
};
</script>

<style scoped>
</style>
