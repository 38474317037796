<template>

        <v-dialog
            v-model="oauthconnectcheckdialog"
            fullscreen
            content-class="justify-center"
            fill-height
            justify="center"
            class="justify-center"
            fluid
        >

            <div class="oauthConnectCheckDialog justify-center text-center">

                <v-card-title
                    class="text-h5"
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>

                <v-card-title class="text-center justify-center" v-if="loading">
                    {{$t('app.OauthConnectCheck.Title')}}
                </v-card-title>
                <v-card-subtitle class="text-center justify-center" v-if="loading">
                    {{$t('app.OauthConnectCheck.Description')}}
                </v-card-subtitle>
                <v-card-text>

                    <v-alert type="error" v-if="error!==''">
                        {{error}}
                    </v-alert>
                </v-card-text>


                <v-card-actions>

                    <v-btn
                        color="primary"
                        raised
                        block
                        :to="{name:'Login'}"
                        v-if="!loading"
                    >
                        {{$t('app.OauthConnectCheck.BackToLogin')}}
                    </v-btn>
                </v-card-actions>
                <nimmerso-footer></nimmerso-footer>
            </div>
        </v-dialog>

</template>
<style>
    .oauthConnectCheckDialog {
        /*background: transparent !important;
        margin-left: auto !important;
        margin-right: auto !important;

         */
        background: rgba(0,0,0,0.75);
        max-width: 600px;
        min-width: 300px;
        overflow: auto;
        height: 100vh;
        font-family: "Roboto Condensed", Arial, sans-serif;
    }
    .oauthConnectCheckDialog:after {
        background-image: url('~@/assets/img/Login_background.jpg');
        content: "";
        background-size: cover;
        background-position: center;
        background-color: gray;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: -1;
    }
</style>

<script>
    import fetch from '../../utils/fetch'
    import { ENTRYPOINT } from '@/config/entrypoint';
    import {mapActions} from "vuex";
    import NimmersoFooter from "../NimmersoFooter"
    export default {
        name: 'OauthConnectCheck',
        components: {
            NimmersoFooter
        },
        data() {
            return {
                oauthconnectcheckdialog: true,
                error: '',
                loading:true
            }
        },
        created(){
            this.checkToken();
        },
        methods: {
            ...mapActions({
                settoken: 'user/token/settoken',
                setrefreshtoken: 'user/token/setrefreshtoken',
                gettoken: 'user/token/gettoken',
                gettokenexpiretime: 'user/token/gettokenexpiretime',
                gettokendata: 'user/token/gettokendata',
                removetoken: 'user/token/removetoken',
                removerefreshtoken: 'user/token/removerefreshtoken'
            }),
            setError(message) {
                this.error = message;
            },

            checkToken() {
                this.loading = true;
                const provider = (this.$route.params.provider)
                //window.console.log(this.$route.query);
                //const queryParams=new URLSearchParams(this.$route.query).toString();
                this.removetoken();
                const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/')
                var url = new URL('/connect/'+provider+'/check',entryPoint);
                let params=this.$route.query;
                params['register']=true;
                url.search = new URLSearchParams(params).toString();
                return fetch(url, {
                    method: 'GET',
                    headers: new Headers({'Accept': 'application/json,text/html'}),
                })
                .then((response) => {
                        this.loading=false;
                        if (response.status < 200 || response.status >= 300) {
                            this.setError(response.statusText);
                            throw new Error(response.statusText);
                        }

                        return response.json();
                    })
                    .then(({register}) => {
                        if (register){
                            this.$router.push({name: 'Registered'})
                        }


                    })
                    .catch((error) => {
                        this.loading=false;
                        this.oauthconnectcheckdialog = true;
                        this.setError(error);
                    });

            }
        }
    }
</script>

<style lang="css">
    body {
        background: #605B56;
    }


    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>
