<template>
  <v-container>
      <v-row>
          <v-col
              cols="12"
              md="4"
          >
              <v-text-field
                  v-model="item.givenName"
                  :rules="nameRules"
                  :label="$t('app.GivenName')"
                  @input="handleUpdateField('givenName', item.givenName)"
                  required
              ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
              <v-text-field
                  v-model="item.familyName"
                  :rules="nameRules"
                  :label="$t('app.FamilyName')"
                  @input="handleUpdateField('familyName', item.familyName)"
                  required
              ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col
              cols="12"
              md="4"
              v-if="$i18n"
          >
              <v-autocomplete
                  v-model="item.addressCountry"
                  @change="handleUpdateField('addressCountry', item.addressCountry)"
                  :items="countriesList"
                  item-text="name"
                  item-value="code"
                  :label="$t('app.Country')"
                  required
                  :rules="[ v => (typeof v !== 'undefined' && v!==null && v!=='') || 'Country is required']"
                  auto-select-first
              ></v-autocomplete>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
              <v-phone-input
                  v-model="item.phoneNumber"
                  :label="$t('app.Phone')"
                  :countryLabel="$t('app.Country')"
                  :preferredCountries="['de','it','at','ch']"
                  :defaultCountry="'DE'"
                  @input="handleUpdateField('phoneNumber', item.phoneNumber)"
              />
          </v-col>
      </v-row>
      <v-row>
          <v-col
              cols="12"
              md="8"
          >
              <v-text-field
                  v-model="item.streetAddress"
                  :label="$t('app.StreetAddress')"
                  :rules="addressRules"
                  @input="handleUpdateField('streetAddress', item.streetAddress)"
                  required
              ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col
              cols="12"
              md="4"
          >
              <v-text-field
                  v-model="item.addressLocality"
                  :label="$t('app.AddressLocality')"
                  :rules="placeRules"
                  @input="handleUpdateField('addressLocality', item.addressLocality)"
                  required
              ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
              <v-text-field
                  v-model="item.postalCode"
                  :label="$t('app.postalCode')"
                  :rules="postalcodeRules"
                  @input="handleUpdateField('postalCode', item.postalCode)"
                  required
              ></v-text-field>
          </v-col>

      </v-row>
  </v-container>
</template>

<script>
import countries from 'i18n-iso-countries';

export default {
  props: {
    handleUpdateField: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
    data: () => (
        {
            nameRules: [
                v => (typeof v !== 'undefined' && v!==null && v!=='') || 'Name is required',
                v => (typeof v !== 'undefined' && v!==null && v.length > 1) || 'Name must be more than 1 characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            addressRules: [
                v => (typeof v !== 'undefined' && v!==null && v!=='') || 'Address is required',
                v => (typeof v !== 'undefined' && v!==null && v.length > 5) || 'Address must be more than 5 characters',
            ],
            placeRules: [
                v => (typeof v !== 'undefined' && v!==null && v!=='') || 'Place is required',
                v => (typeof v !== 'undefined' && v!==null && v.length > 3) || 'Place must be more than 3 characters',
            ],
            postalcodeRules: [
                v => (typeof v !== 'undefined' && v!==null && v!=='') || 'Postal code is required',
                v => (typeof v !== 'undefined' && v!==null && v.length > 3) || 'Postal code must be more than 3 characters',
            ],
            countriesList: []
        }

    ),
  computed: {
    // eslint-disable-next-line
    item () {
        const order = this.initialValues
        return { ...order, addressCountry: order?.addressCountry || 'DE' } || this.values
    },

    violations () {
      return this.errors || {}
    }
  },
    created () {
        countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
        this.countriesList=[];
        const countriesObj=countries.getNames(this.$i18n.locale, {select: "official"});
        for (const [key, name] of Object.entries(countriesObj)) {
            this.countriesList.push({
                code: key,
                name: name
            })
        }
    },

    methods: {
    isInvalid (key) {
      return Object.keys(this.violations).length > 0 && this.violations[key]
    }
  }
}
</script>
