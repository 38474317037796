<template>
    <v-app id="nimmerso" toolbar-fixed toolbar>
        <v-navigation-drawer v-if='authenticated && !$router.currentRoute.meta.navHidden'
                             v-model="drawer"
                             app
                             clipped
                             right
                             touchless
                             temporary
                             style="z-index:9999;"
                             class="bgBlurNavbar"
                             width="280"
        >

            <v-row dense>
                <v-list-item two-line>

                    <div style="width:62px; height:90px">
                        <v-avatar
                            size="90"
                            style="left:-20px"
                            class="pa-0 ma-0"
                        ><svg width="720" height="720" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <metadata>
                                <rdf:RDF>
                                    <cc:Work rdf:about="">
                                        <dc:format>image/svg+xml</dc:format>
                                        <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                        <dc:title/>
                                    </cc:Work>
                                </rdf:RDF>
                            </metadata>
                            <g transform="translate(0 -106.5)">
                                <g transform="translate(2.5034 -7.6052)">
                                    <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                    <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                </g>
                            </g>
                            <g transform="translate(0 -106.5)">
                                <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((personItem && personItem.pictureUrl && personItem.pictureUrl.indexOf('https')===-1) ? personItem['pictureUrl'] : '0')+'.png'"/>
                            </g>
                        </svg>

                    </v-avatar>
                    </div>
                    <v-list-item-content >
                        <v-list-item-title>{{ username }}</v-list-item-title>
                        <v-list-item-subtitle>Logged In</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :to="{ name: 'Settings' }">
                    <v-list-item-action>
                        <v-icon >mdi-tune</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.Settings') }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-action>
                        <v-switch @change="updateSettingShowColorized"
                                  color="active"
                                  :input-value="settings['showColorized']"
                        ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.settings.showColorized') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-switch @change="updateSettingShowInfosOverlay"
                                  color="active"
                                  :input-value="settings['showInfosOverlay']"
                        ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.settings.showInfosOverlay') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-switch @change="updateSettingShow25DPhotos"
                                  color="active"
                                  :input-value="settings['show25DPhotos']"
                        ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.settings.show25DPhotos') }}
                    </v-list-item-title>
                </v-list-item>

                <!--<v-list-item>
                    <v-list-item-action>
                        <v-switch @change="updateSettingDarkTheme"
                                  :input-value="settings['darkTheme']"
                        ></v-switch>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.settings.darkTheme') }}
                    </v-list-item-title>
                </v-list-item>-->
                <v-divider></v-divider>

            </v-row>

            <v-list>
                <v-list-item
                    v-for="item in items"
                    :key="item.text"
                    :to="item.to"
                >
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('app.' + item.text) }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item href="https://youtu.be/-UUtv8xsius" target="_blank">
                    <v-list-item-action>
                        <v-icon>mdi-play-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.IntroMovie') }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="reportCollection">
                    <v-list-item-action>
                        <v-icon>mdi-tooltip-image-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.AddYourImages') }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if='deferredInstallPrompt' @click="showInAppInstallPromotion">
                    <v-list-item-action>
                        <v-icon>mdi-plus-box</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.installButton') }}</v-list-item-title>
                </v-list-item>
                <!--<v-list-item @click="openYbug" data-v-step="4">
                    <v-list-item-action>
                        <v-icon>mdi-chat-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.Feedback') }}</v-list-item-title>
                </v-list-item>-->
                <v-list-item @click="openFeedback" data-v-step="4">
                    <v-list-item-action>
                        <v-icon>mdi-chat-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.Feedback') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openReportBug" data-v-step="4">
                    <v-list-item-action>
                        <v-icon>mdi-bug</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.ReportBug') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!settings['showTour'] && $route.name==='HomeList'" @click="startTour">
                    <v-list-item-action>
                        <v-icon>mdi-help</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.StartTour') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if='authenticated' @click="logout">
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.Logout') }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-else @click="login">
                    <v-list-item-action>
                        <v-icon>login</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('app.Login') }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <nimmerso-footer class="text-center justify-center"></nimmerso-footer>
            </template>
        </v-navigation-drawer>


        <v-main style="margin-top: 44px;">
            <v-toolbar
                dense
                class="apptoolbar"
                v-if='!$router.currentRoute.meta.navHidden'
            >
                <img
                    src="@/assets/img/nimmerso_typeface.svg"

                    :alt="$t('app.Title')"
                    @click=topFunction()
                    class="logo_nav_bar"
                />
                <v-spacer></v-spacer>
                <v-btn icon @click="showSearchLayer=true"  data-v-step="1">
                    <v-icon>icon-Search</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-tooltip  bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn small :icon="isMobile" @click="showItemsNearMe" v-on="onTooltip" data-v-step="0">
                            <v-icon color="grey darken-1">mdi-near-me</v-icon>
                            <span v-if="!isMobile">{{ $t('app.nearMeButton') }}</span>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.nearMeButton') }}</span>
                </v-tooltip>
                <v-spacer v-if='deferredInstallPrompt'></v-spacer>
                <v-tooltip v-if='deferredInstallPrompt' bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn small :icon="isMobile" @click="showInAppInstallPromotion" v-on="onTooltip"  data-v-step="2">
                            <!--<v-icon color="grey darken-1">mdi-plus-box</v-icon>-->
                            <img src="/img/icons/Ebicon_512x512.png" style="width:32px; height:32px;" />
                            <span v-if="!isMobile">{{ $t('app.installButton') }}</span>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.installButton') }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn right icon @click.stop="drawer = !drawer" v-if='authenticated' data-v-step="3">
                    <v-icon top>mdi-dots-vertical</v-icon>
                </v-btn>
                <v-btn right :to="{path: '/'}" v-if='!authenticated'>
                    {{$t('app.Login.PleaseSignIn')}}
                </v-btn>

            </v-toolbar>
            <v-tabs-items :touchless="activeTab==='/map'" v-model="activeTab" @change="tabSwipe">
                <v-tab-item :id="$router.resolve({ name: 'HomeList' }).href" ref="HomeList">
                    <keep-alive>

                        <router-view name="HomeTab"
                                     :key="$router.resolve({ name: 'HomeList' }).href"
                        />
                    </keep-alive>
                </v-tab-item>
                <v-tab-item :id="$router.resolve({ name: 'MapShow' }).href" ref="MapShow">
                    <keep-alive>
                        <router-view name="MapTab"
                                     :key="$router.resolve({ name: 'MapShow' }).href"
                        />
                    </keep-alive>
                </v-tab-item>
                <v-tab-item :id="$router.resolve({ name: 'ImageGalleryList' }).href"
                            ref="ImageGalleryList">
                    <keep-alive max="5">
                        <router-view name="MyImagesTab"
                                     :key="$route.fullPath"
                        />
                    </keep-alive>
                </v-tab-item>
                <!--<v-tab-item :id="$router.resolve({ name: 'RankingShow' }).href" ref="RankingShow">
                    <keep-alive max="5">
                        <router-view name="RankingTab"
                                     :key="$route.fullPath"
                        />
                    </keep-alive>
                </v-tab-item>-->
            </v-tabs-items>

                <router-view></router-view>



            <v-snackbar
                v-model="snackWithButtons"
                :timeout="timeout"
                bottom
                left
                class="snack"
                style="z-index: 1002"
            >
                <span>{{ snackWithBtnText }}</span>
                <v-btn
                    dark
                    text
                    color="#00f500"
                    @click.native="refreshApp"
                >
                    {{ snackBtnText }}
                </v-btn>
                <v-btn
                    icon
                    @click="snackWithButtons = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-snackbar>

        </v-main>

        <v-bottom-navigation v-if="!$router.currentRoute.meta.navHidden && authenticated"
                             fixed
                             shift
                             light
                             :value="activeTab"
        >
            <v-btn to="/feed" value="/feed" exact>
                <span>Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>

            <v-btn to="/map" value="/map">
                <span>Karte</span>
                <v-icon>icon-Marker</v-icon>
            </v-btn>
            <v-btn to="/my_images" value="/my_images">
                <span>meine Bilder</span>
                <v-icon>icon-Album</v-icon>
            </v-btn>

            <!--<v-btn to="/ranking" value="/ranking">
                <span>Ranking</span>
                <v-icon>icon-Ranking</v-icon>
            </v-btn>-->
        </v-bottom-navigation>

        <!--<v-bottom-sheet
            v-model="loginBottomSheet"
            content-class="text-end loginBottomSheet"

            persistent
            hide-overlay
            max-width="600"
            max-height="300"
        >
            <v-btn style="position:relative; z-index:1" icon @click="loginBottomSheet = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-sheet
                style="background-color: unset"

            class="text-center pa-8 font-weight-bold"

        >

                <div>
                    {{ $t('app.Login.NotLoggedInMessage') }}
                </div>
                <v-btn
                    text
                    color="blue"
                    class="font-weight-bold"
                    :to="{path:'/login'}"
                >
                    {{ $t('app.Login.PleaseSignIn') }}
                </v-btn>
                <div>
                    oder
                </div>
                <v-btn
                    text
                    color="green"
                    class="font-weight-bold"
                    :to="{path:'/register'}"
                >
                    {{ $t('app.Login.Register') }}
                </v-btn>
                <div>
                    <a href="https://nimmerso.com/" target="_blank">{{ $t('app.Login.Informations') }}</a>
                </div>
            </v-sheet>
        </v-bottom-sheet>-->
        <SearchLayer
                     :show-search-layer="showSearchLayer"
                     :handle-show-search-layer="setShowSearchLayer"
                     :updatesearchfilters="updatesearchfilters"
                     :searchfilters-param="searchfilters"
                     style="z-index:9999;"
        ></SearchLayer>
        <v-dialog
            v-model="showWelcomeDialog"
            v-if="authenticated"
            max-width="490"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.welcomeTitle') }}</v-card-title>
                <v-card-subtitle class="text-justify font-weight-bold">
                    {{ $t('app.welcomeDescription1') }}
                </v-card-subtitle>
                <v-card-text class="text-justify">
                    {{ $t('app.welcomeDescription2') }}
                </v-card-text>
                <v-card-text class="text-justify">
                    {{ $t('app.welcomeDescription3') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        :to="{ name: 'Settings' }"
                    >
                        {{ $t('app.Settings') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="agreeCookies()"
                    >
                        {{ $t('app.welcomeAgree') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showThankYouDialog"
            max-width="490"
            v-if="authenticated"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.thankYou') }}</v-card-title>
                <v-card-subtitle class="text-justify font-weight-bold">
                    {{ $t('app.thankYouDescription1') }}
                </v-card-subtitle>
                <v-card-text class="text-center">
                    <v-btn icon @click="showIntroMovie">
                        <v-icon x-large>mdi-play-circle-outline</v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-text class="text-justify">
                    {{ $t('app.thankYouDescription2') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="showThankYouDialog=false"
                    >
                        {{ $t('app.thankYouNoThanks') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="showIntroMovie"
                    >
                        {{ $t('app.thankYouShowIntro') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showPushDialog"
            v-if="authenticated"
            max-width="490"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.pushTitle') }}</v-card-title>
                <v-card-subtitle class="text-justify font-weight-bold">
                    {{ $t('app.pushDescription1') }}
                </v-card-subtitle>
                <v-card-text class="text-justify">
                    <ul>
                        <li>{{ $t('app.settings.NotificationLi1') }}</li>
                        <li>{{ $t('app.settings.NotificationLi2') }}</li>
                        <li>{{ $t('app.settings.NotificationLi3') }}</li>
                        <li>{{ $t('app.settings.NotificationLi4') }}</li>

                    </ul>
                </v-card-text>
                <v-card-text class="text-justify">
                    {{ $t('app.pushDescription2') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="disagreePushNotifications"
                    >
                        {{ $t('app.thankYouNoThanks') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="agreePushNotifications"
                    >
                        {{ $t('app.pushActivate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showWebPushDeniedInfo"
            max-width="400"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5">{{ $t('app.settings.PushMessagingBlocked') }}</v-card-title>
                <v-card-text>
                    {{ $t('app.settings.PushMessagingBlockedDescription') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click.stop="showWebPushDeniedInfo = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showInAppInstallPromotionDialog"
            max-width="490"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.installTitle') }}</v-card-title>
                <v-card-text class="text-justify">
                    <img src="@/assets/img/screenshot_android_nimmerso.png" style="float: left; margin: 0px 15px 15px 0px;" />
                    {{ $t('app.installDescription') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="installDismiss()"
                    >
                        {{ $t('app.installDismiss') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="installAgree()"
                    >
                        {{ $t('app.installAgree') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showChangeUsernameNotice"
            max-width="490"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.ChangeUsernameNoticeTitle') }}</v-card-title>
                <v-card-text class="text-justify">
                    {{ $t('app.ChangeUsernameNoticeDescription') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="showChangeUsernameNotice=false"
                    >
                        {{ $t('app.ChangeUsernameNoticeLater') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        :to="{ name: 'Settings' }"
                        @click="showChangeUsernameNotice=false"
                    >
                        {{ $t('app.ChangeUsernameNoticeAgree') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showNearMePermissionDialog"
            max-width="490"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5 pb-10">{{ $t('app.ShowNearMePermissionDialogTitle') }}</v-card-title>
                <v-card-text class="text-justify">
                    {{ $t('app.ShowNearMePermissionDialogDescription') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="secondary"
                        text
                        @click="showNearMePermissionDialog=false"
                    >
                        {{ $t('app.ShowNearMePermissionDialogLater') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        text
                        @click="activateNearMePermission"
                    >
                        {{ $t('app.ShowNearMePermissionDialogAgree') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showGetLocationDeniedInfo"
            max-width="400"
            style="z-index: 1001"
        >
            <v-card>
                <v-card-title class="text-h5">{{$t('app.settings.LocationBlocked')}}</v-card-title>
                <v-card-text>
                    {{$t('app.settings.LocationBlockedDescription')}}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click.stop="showGetLocationDeniedInfo = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showFeedbackDialog"
            max-width="500"
            style="z-index: 10001"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-text>
                    <FeedbackForm
                        :handle-update-field="feedbackUpdateField"
                        :handle-submit="feedbackSubmit"
                        :handle-close="feedbackClose"
                        :handle-show-report-bug="showReportBug"
                        :values="feedbackItem"
                    ></FeedbackForm>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showFeedbackThxDialog"
            max-width="200"
            style="z-index: 10001"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-text class="text-center text-h6 white--text pt-2">{{$t('app.thankYou')}}</v-card-text>
                <v-card-text v-if="showFeedbackThxDialogShowLink" class="text-center">
                    Bewerte uns auf <br/><a href="https://de.trustpilot.com/review/nimmerso.com" target="_blank" rel="noopener">
                    <img src="@/assets/img/trustpilot-logo-white.svg" style="height:28px;" /></a>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click.stop="showFeedbackThxDialog = false"
                    >
                        {{$t('app.Close')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showReportBugDialog"
            max-width="500"
            style="z-index: 10001"
            transition="dialog-bottom-transition"
            data-html2canvas-ignore
        >
            <v-card>
                <v-card-text>
                    <ReportBugForm
                        :handle-update-field="reportBugUpdateField"
                        :handle-submit="reportBugSubmit"
                        :handle-close="reportBugClose"
                        :values="reportBugItem"
                    ></ReportBugForm>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showReportBugThxDialog"
            max-width="200"
            style="z-index: 10001"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-text class="text-center text-h6 white--text pt-2">{{$t('app.thankYou')}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click.stop="showReportBugThxDialog = false"
                    >
                        {{$t('app.Close')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-tour name="nimmersoTour" :steps="tourSteps" :callbacks="tourCallbacks" :options="tourOptions"></v-tour>
    </v-app>
</template>

<style>
html {
    /*scroll-behavior: smooth*/
    /*-webkit-touch-callout: none;*/ /* iOS Safari */
    /*-webkit-user-select: none;*/ /* Safari */
    /*-moz-user-select: none;*/ /* Old versions of Firefox */
    /*-ms-user-select: none;*/ /* Internet Explorer/Edge */
    /*user-select: none;*/
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */

}

.v-slide-group__prev {
    display: none !important;
}

.v-tabs-items {
    height: 100%;
}

.v-window-item {
    height: 100%;
}

.feedbackButton {
    z-index: 10001 !important;
    bottom: 1px;
    right: 1px !important;
}

.v-bottom-navigation .v-btn {
    height: inherit !important;
    min-width: 80px !important;
    background-color: transparent !important;

}
.v-bottom-navigation .v-btn .v-icon {
    text-shadow: none !important;
}
.logo_nav_bar{
    cursor:pointer;
    width: 206.48px;
    height: 40px;
    margin-left:10px;
}
.v-bottom-navigation{
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.5) !important;
    border: 1px solid rgba(255,255,255,0.3) !important;
}
/*.loginBottomSheet{
    background-image:url('/img/Seebad_bes_t.jpeg')
}*/

.noBackground{
    background-color: transparent !important;
}

.bgBlur{
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.3) !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border: 1px solid rgba(255,255,255,0.3) !important;
}

.bgBlurNavbar{
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.3) !important;
    border: 1px solid rgba(255,255,255,0.3) !important;
}
.commentItem{
    text-shadow: 1px 1px 1px #000000;
}
.v-card-title, h1 {
    color: #f8d19f !important
}

</style>
<script>
import fetch from './utils/fetch'
import SearchLayer from './components/search/Search'
import NimmersoFooter from './components/NimmersoFooter'
import { dragscroll } from 'vue-dragscroll'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import './assets/iconsstyle.css'
//import { bootstrap } from 'vue-gtag'
import { ENTRYPOINT } from './config/entrypoint'
import InApp from 'detect-inapp';
import FeedbackForm from '@/components/comment/FeedbackForm'
import ReportBugForm from '@/components/comment/ReportBugForm'
export default {
    directives: {
        'dragscroll': dragscroll
    },
    components: {
        FeedbackForm,
        ReportBugForm,
        SearchLayer,
        NimmersoFooter
    },
    props: {
        source: String,
    },
    metaInfo () {
        return {
            title: 'Nimmerso - am selben Ort, zu andrer Zeit',
            meta: [
                {
                    name: 'description',
                    content: 'Nimmerso ist dein multimedialer Reiseführer in die Vergangenheit'
                },
                {property: 'og:title', content: 'Nimmerso - am selben Ort, zu andrer Zeit'},
                {property: 'og:site_name', content: 'Nimmerso'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'index,follow'}
            ]
        }
    },
    computed: {
        ...mapState(['settings', 'user/token']),
        ...mapGetters({
            //personError: 'person/show/error',
            //personIsLoading: 'person/show/isLoading',
            personItem: 'person/show/retrieved',
            searchfilters: 'searchfilters/items',
            settings: 'settings/items',
            token: 'user/token/token',
            refresh_token: 'user/token/refresh_token',
            token_expires: 'user/token/token_expires',
            token_data: 'user/token/token_data'
        })
    },
    data: function () {
        return {
            isMobile: false,
            appBarVisible: true,
            deferredInstallPrompt: null,
            showInAppInstallPromotionDialog: false,
            entrypoint: ENTRYPOINT,
            webPushClient: null,
            scroll_positions: {},
            remember: ['HomeList', 'ImageGalleryList'],
            activeTab: '/feed',
            refreshing: false,
            registration: null,
            snackBtnText: '',
            snackWithBtnText: '',
            snackWithButtons: false,
            timeout: -1,
            authenticated: false,
            navHidden: (typeof (this.$router.currentRoute.meta.navHidden) !== 'undefined' && this.$router.currentRoute.meta.navHidden === true),
            drawer: null,
            items: [
                /*{icon: '-', text: 'Comments', to: {name: 'CommentList'}},
                {icon: '-', text: 'ImageGalleries', to: {name: 'ImageGalleryList'}},
                {icon: '-', text: 'Organizations', to: {name: 'OrganizationList'}},
                {icon: '-', text: 'People', to: {name: 'PersonList'}},
                {icon: '-', text: 'Photographs', to: {name: 'PhotographList'}},
                {icon: '-', text: 'Retrosnaps', to: {name: 'RetrosnapList'}},
                {icon: '-', text: 'Categories', to: {name: 'CategoryList'}},
                {icon: '-', text: 'PhotographCollections', to: {name: 'PhotographCollectionList'}},
                {icon: '-', text: 'Places', to: {name: 'PlaceList'}},
                {icon: '-', text: 'FeedItems', to: {name: 'FeedItemList'}},
                {icon: '-', text: 'Users', to: {name: 'UserList'}}*/
            ],
            showSearchLayer: false,
            //searchfilters: [],
            chipIcons: {
                'text': 'mdi-card-text',
                'category': 'mdi-view-list',
                'place': 'mdi-map-marker',
                'daterange': 'mdi-calendar-range'
            },
            chipColors: {
                'text': '#8C8189',
                'category': '#9E919A',
                'place': '#B0A0AA',
                'daterange': '#C2B0BC',
                'types': '#989181'
            },
            tokenTimeoutTimer: null,
            //showColorized: this.settings ? this.settings.showColorized : false,
            //showInfosOverlay: this.settings ? this.settings.showInfosOverlay : false,
            //searchfilterItems: null
            username: 'Anonymous',
            showWebPushDeniedInfo: false,
            settingShowNotifications: false,
            showTour: false,
            showWelcomeDialog: false,
            showThankYouDialog: false,
            showPushDialog: false,
            vMainStyle: this.activeTab === '/map' ? 'margin-top: 0' : 'margin-top: calc( 60px + env(safe-area-inset-top) )',
            sat: getComputedStyle(document.documentElement).getPropertyValue('--sat'),
            sar: getComputedStyle(document.documentElement).getPropertyValue('--sar'),
            sab: getComputedStyle(document.documentElement).getPropertyValue('--sab'),
            sal: getComputedStyle(document.documentElement).getPropertyValue('--sal'),
            //loginBottomSheet: false,
            refreshButtonClicked: false,
            tourSteps: [
                {
                    target: '[data-v-step="0"]',  // We're using document.querySelector() under the hood
                    content: `<strong>Lass dir Bilder in deiner Nähe anzeigen!</strong>`
                },
                {
                    target: '[data-v-step="1"]',
                    content: 'Hier kannst du nach verschiedenen Bildern suchen.'
                },
                {
                    target: '.masonry-item:first-of-type',  // We're using document.querySelector() under the hood
                    content: `<strong>Klicke auf die Vorschau um Details anzusehen</strong>`
                },
                {
                    target: '[data-v-step="3"]',
                    content: 'Ein Klick auf diesen Button öffnet das Menü',
                },
                {
                    target: '[data-v-step="4"]',
                    content: 'Du kannst uns deine Meinung zu Nimmerso sagen indem du hier klickst. Wir würden uns freuen!',
                    before: () => new Promise((resolve) => {
                        // Time-consuming UI/async operation here
                        this.drawer=true;
                        setTimeout(resolve,500);
                        //resolve('foo')
                    })
                },
            ],
            tourCallbacks: {
                onPreviousStep: ()=>{

                },
                onNextStep: ()=>{

                },
                onSkip: ()=>{
                    this.updateSettingShowTour(false);
                },
                onStop: ()=>{
                    this.updateSettingShowTour(false);
                },
                onFinish: ()=>{
                    this.updateSettingShowTour(false);
                }
            },
            tourOptions: {
                useKeyboardNavigation: true,
                labels: {
                    buttonSkip: 'Tour überspringen',
                    buttonPrevious: 'zurück',
                    buttonNext: 'weiter',
                    buttonStop: 'Tour beenden'
                }
            },
            showChangeUsernameNoticeTimeout: null,
            showChangeUsernameNotice: false,
            showNearMePermissionDialog: false,
            showGetLocationDeniedInfo: false,
            showFeedbackDialog: false,
            feedbackItem: {},
            showFeedbackThxDialog: false,
            showFeedbackThxDialogShowLink: false,
            showReportBugDialog: false,
            reportBugItem: {},
            showReportBugThxDialog: false
        }
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },

    beforeMount () {
        this.isAuthenticated()

    },
    mounted () {
        this.removeSearchfilters()
        this.$router.onReady(() => {
            this.activeTab = this.$route.path.replace('image_galleries','my_images').replace('photograph_collections','my_images').split('/').slice(0, 2).join('/')
            this.vMainStyle = this.activeTab === '/map' ? 'margin-top: 0' : 'margin-top: calc( 60px + env(safe-area-inset-top) )'
            //window.console.log(this.$router.currentRoute);
        })


        const observer = new MutationObserver((mutations) => {
            this.appBarVisible = mutations[0].target.style.transform === 'translateY(0px)'
            //window.console.log(mutations[0].target.style.height, this.appBarVisible)
            /*if (newIndex !== oldIndex) {
                console.log('new:', , 'old:', oldIndex);
            }*/
        })
        //window.console.log(document.querySelector('header'))
        observer.observe(document.querySelector('header'), {
            attributes: true,
            attributeFilter: ['style'],
        })
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })

        this.sat = getComputedStyle(document.documentElement).getPropertyValue('--sat')
        this.sar = getComputedStyle(document.documentElement).getPropertyValue('--sar')
        this.sab = getComputedStyle(document.documentElement).getPropertyValue('--sab')
        this.sal = getComputedStyle(document.documentElement).getPropertyValue('--sal')
        /*if (process.env.NODE_ENV === 'production')(function() {
            window.ybug_settings = {
                "id":"t6351zymzagdkwg26b7p",
                hide_launcher: true
            };
            var ybug = document.createElement('script'); ybug.type = 'text/javascript'; ybug.async = true;
            ybug.src = 'https://widget.ybug.io/button/'+window.ybug_settings.id+'.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);

        })();*/


    },
    created () {
        const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
        window.console.log(inapp.isInApp,inapp.browser);
        /*if(
            inapp.isMobile
            && inapp.isInApp
            && ['messenger', 'facebook', 'line', 'twitter', 'wechat', 'miui', 'instagram'].includes(inapp.browser)
            && window.location.href.indexOf('#rd')===-1
        ){
            //try to open in real browser:
            let newHref=window.location.href.split('#');
            newHref[1]='rd';
            // geht nicht: window.open(newHref.join('#'),'_system');
            window.open('googlechromes://'+newHref.join('#'));
            //window.open(window.location.href,'_system');
            window.console.log('redirect')
        }
        document.addEventListener('visibilitychange', () => {
            //window.console.log('visibilitychange')
            this.isAuthenticated()
        })*/
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI, {once: true})
        // Refresh all open app tabs when a new service worker is installed.
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            //window.console.log('controllerchange');
            if (this.refreshing) return
            this.refreshing = true
            if(this.refreshButtonClicked) window.location.reload()
        })
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            this.deferredInstallPrompt = e;
            if(this.deferredInstallPrompt){

                this.tourSteps.splice(2,0,{
                    target: '[data-v-step="2"]',
                    content: 'Installiere ein Nimmerso-Icon auf deinem Smartphone, damit du immer sofort Nimmerso nutzen kannst.',
                });
            }
            // Show your customized install prompt for your PWA
            // Your own UI doesn't have to be a single element, you
            // can have buttons in different locations, or wait to prompt
            // as part of a critical journey.
            //this.showInAppInstallPromotion();
        });
        this.isAuthenticated()
        this.$router.beforeEach((to, from, next) => {
            if (from && from.name) {
                this.scroll_positions[from.name] = document.scrollingElement.scrollTop

            }
            next()
        })



        this.watchSettings()
        setTimeout(() => {
            this.checkSettings()
        }, 5000)
        /*window.setTimeout(() => {
            this.loginBottomSheet=
                !this.authenticated
                && this.$router.currentRoute.name!==null
                && this.$router.currentRoute.name!=='Login'
                && this.$router.currentRoute.name!=='Register'
                && this.$router.currentRoute.name!=='Registered'
                && this.$router.currentRoute.name!=='UserActivate'
                && this.$router.currentRoute.name!=='ResetPassword'
                && this.$router.currentRoute.name!=='ResetPasswordOk'
                && this.$router.currentRoute.name!=='UserResetPassword'
                && this.$router.currentRoute.name!=='UserResetPasswordOk';
        }, 15000)*/
        //this.activeTab=this.$router.currentRoute.fullPath;

    },
    watch: {
        // Everytime the route changes, check for auth status
        '$route': function () {
            //window.console.log('route changed', this.$route);
            this.isAuthenticated()
            window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }


                const params =  {u: window.location.toString(), props: {logged_in: this.authenticated, appinstalled: window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches}};

                window.plausible('pageview', JSON.parse(JSON.stringify(params)));

            this.vMainStyle = this.activeTab === '/map' ? 'margin-top: 0' : 'margin-top: calc( 60px + env(safe-area-inset-top) )'
            this.activeTab = this.$router.currentRoute.fullPath.replace('image_galleries','my_images').replace('photograph_collections','my_images').split('/').slice(0, 2).join('/')
            let scroll = 0
            if (Object.prototype.hasOwnProperty.call(this.scroll_positions, this.$route.name) && this.remember.includes(this.$route.name)) {
                scroll = this.scroll_positions[this.$route.name]
            }

            this.navHidden= (typeof (this.$router.currentRoute.meta.navHidden) !== 'undefined' && this.$router.currentRoute.meta.navHidden === true)
            const fullscreen = (typeof (this.$router.currentRoute.meta.fullscreen) !== 'undefined' && this.$router.currentRoute.meta.fullscreen === true)

            if(window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches) {
                if (fullscreen) {
                    window.document.body.requestFullscreen({navigationUI: 'hide'}).catch((err) => {
                        window.console.log(
                            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                        );
                    });

                } else {

                    if (window.document.fullscreenElement) window.document.exitFullscreen();


                }
            }
            if(this.settings['showTour']) {
                this.startTour();
            }



            window.setTimeout(() => {
                document.scrollingElement.scrollTop = scroll
            }, 10)

            /*window.setTimeout(() => {
                if(window.webPushClient){
                    this.webPushClient = window.webPushClient;
                    if (this.webPushClient.getPermissionState() === 'granted') {
                        this.webPushClient.subscribe([]).then((subscriptions) => { window.console.log(subscriptions)}, (error) => {
                            window.console.log(error)
                        })
                    }
                }
            },100);*/


            //this.activeTab=this.$router.currentRoute.fullPath;
        },
        '$router.currentRoute.meta.navHidden': function (navHidden) {
            this.navHidden = navHidden
            window.console.log(navHidden);
            if(navHidden) {
                window.document.requestFullscreen({navigationUI: 'hide'}).catch((err) => {
                    alert(
                        `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                    );
                });

            }else{
                window.document.requestFullscreen({navigationUI: 'show'});
            }
        },
        settings: function () {
            this.watchSettings()
            setTimeout(() => {
                this.checkSettings()
            }, 5000)
        },
        settingShowNotifications: function () {

        },

        token: function () {
            //window.console.log('TOKEN CHANGED')
            this.getUsername()
            window.setTimeout(() => {
                if(window.webPushClient){
                    this.webPushClient = window.webPushClient;
                    if (this.webPushClient.getPermissionState() === 'granted') {
                        this.webPushClient.subscribe([]).then((subscriptions) => { window.console.log(subscriptions)}, (error) => {
                            window.console.log(error)
                        })
                    }
                }
            },100);
        },
        activeTab: function () {
            /*if (
                //this.$router.currentRoute.fullPath.indexOf(this.activeTab)!==0
                //|| (this.activeTab === '/' && this.$router.currentRoute.fullPath!=='/')
                this.$router.currentRoute.fullPath!==this.activeTab
            ) this.$router.push(this.activeTab);*/
        },
        personItem: function() {
            if(this.personItem.name.substr(0,4)==='usr_' && typeof(window.changeUsernameNoticeShowen)==='undefined') {
                this.showChangeUsernameNoticeTimeout=setTimeout(()=>{this.showChangeUsernameNotice=true},5000);
                window.changeUsernameNoticeShowen=true;
            }
        },
    },
    methods: {
        ...mapMutations({
            setsearchfilters: 'searchfilters/setsearchfilters',
            setsettings: 'settings/setsettings'

        }),
        ...mapActions({
            settoken: 'user/token/settoken',
            setrefreshtoken: 'user/token/setrefreshtoken',
            gettoken: 'user/token/gettoken',
            gettokenexpiretime: 'user/token/gettokenexpiretime',
            gettokendata: 'user/token/gettokendata',
            removetoken: 'user/token/removetoken',
            removerefreshtoken: 'user/token/removerefreshtoken',
            createComment: 'comment/create/create',
            removeSearchfilters: 'searchfilters/removeSearchfilters'
        }),
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        showRefreshUI (e) {
            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.registration = e.detail
            this.snackBtnText = this.$t('app.NewVersionRefresh')
            this.snackWithBtnText = this.$t('app.NewVersionAvailable')
            this.snackWithButtons = true
        },
           topFunction() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                this.removeSearchfilters()
                },
            refreshApp () {
            this.snackWithButtons = false
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) {
                return
            }
            this.refreshButtonClicked=true;
            this.registration.waiting.postMessage('skipWaiting')
        },
        isAuthenticated () {
            this.authenticated = this.token !== null
            //window.console.log('TOKEN:', this.token, this.authenticated, this.$router.currentRoute.meta.requiresAuth);
            if (!this.authenticated && this.refresh_token != null) {
                this.doRefreshToken()
            } else if (!this.authenticated && this.$router.currentRoute.meta.requiresAuth) {
                //window.console.log('gotologin 721')
                this.setRedirectRoute(this.$route.fullPath);
                this.$router.push({path: '/'})
            } else {
                if (this.tokenTimeoutTimer === null) {
                    const currentTime = Math.floor(new Date().getTime() / 1000)
                    let timeoutTime = this.token_expires - currentTime
                    if (timeoutTime < 0) timeoutTime = 0
                    this.tokenTimeoutTimer = setTimeout(() => {
                        this.doRefreshToken()
                    }, timeoutTime * 1000)
                    this.getUsername()
                }

            }


        },
        doRefreshToken () {

            const formData = new FormData()

            if (this.refresh_token == null && this.$router.currentRoute.meta.requiresAuth) {
                //window.console.log('gotologin 742')
                this.setRedirectRoute(this.$route.fullPath);
                this.$router.push({path: '/'})
            }
            if (this.refresh_token == null) return;
            formData.append('refresh_token', this.refresh_token)
            this.removetoken()
            return fetch('/refresh_token', {
                method: 'POST',
                body: formData,
            }).then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    this.setError(response.statusText)
                    //throw new Error(response.statusText);
                    if (this.$router.currentRoute.meta.requiresAuth) {
                        //window.console.log('gotologin 755')
                        this.setRedirectRoute(this.$route.fullPath);
                        this.$router.push({path: '/'})
                    }
                }

                return response.json()
            })
                .then(({token, refresh_token}) => {
                    this.settoken(token) // The JWT token is stored in the browser's local storage
                    this.setrefreshtoken(refresh_token)
                    const currentTime = Math.floor(new Date().getTime() / 1000)
                    //window.console.log(this.token_expires, this.token_expires - currentTime)
                    let timeoutTime = this.token_expires - currentTime - 60
                    if (timeoutTime < 1) timeoutTime = 1
                    if (this.tokenTimeoutTimer == null) {
                        this.tokenTimeoutTimer = setTimeout(() => {
                            clearTimeout(this.tokenTimeoutTimer)
                            this.tokenTimeoutTimer = null
                            this.doRefreshToken()
                        }, timeoutTime * 1000)
                    }
                    this.isAuthenticated()
                })
                .catch((error) => {
                    this.setError(error)
                    if (!this.authenticated && this.$router.currentRoute.meta.requiresAuth) {
                        //window.console.log('goologin 782')
                        this.setRedirectRoute(this.$route.fullPath);
                        this.$router.push({path: '/'})
                    }
                })
        },
        setError (message) {
            this.error = message
        },
        login () {

        },
        logout () {
            this.removetoken()
            this.removerefreshtoken()
            this.$forceUpdate();
            //window.location.reload()
            setTimeout(() => {
                this.setRedirectRoute(null);
                this.$router.push({path: '/'})
            }, 500)


        },
        toggleDarkTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            //window.console.log(this.$vuetify.theme.dark);
        },
        setShowSearchLayer (ssl) {
            this.showSearchLayer = ssl
        },
        removeChip (searchfilter) {
            let newsearchfilters = this.searchfilters.filter((item) => item !== searchfilter)
            this.updatesearchfilters(newsearchfilters)
        },
        updatesearchfilters (newsearchfilters) {
            //this.searchfilterItems=newsearchfilters;
            this.setsearchfilters(newsearchfilters)
            this.$store.commit('searchfilters/setsearchfilters', newsearchfilters)
        },
        getUsername () {
            if (this.token_data) this.username = this.token_data.username

        },
        updateSettingShowColorized (e) {
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {showColorized: e === true}))
        },
        updateSettingShowInfosOverlay (e) {
            //this.settings.showInfosOverlay=e===true;
            this.setsettings({showInfosOverlay: e === true})
            this.$store.commit('settings/setsettings', {showInfosOverlay: e === true})
            //this.$store.commit('settings/setsettings',this.settings)
        },
        updateSettingShow25DPhotos (e) {
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {show25DPhotos: e !== false}))
        },
        updateSettingDarkTheme (e) {
            //this.setsettings({darkTheme: e === true})
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {darkTheme: e !== false}))

        },
        updateSettingShowTour (e) {
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {showTour: e === true}))
        },
        setRedirectRoute(route){
            window.console.log('setRedirectRoute',route);
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
        },

        enablePlugin () {
            /*setOptions({
                config: { id: process.env.VUE_APP_GTAG_ID }
            })*/
            //bootstrap()//.then(() => {
            //window.console.log('bootstrap',gtag);
            //})
        },
        agreeCookies () {
            //this.setsettings({googleAnalytics: true})
            //this.$store.commit('settings/setsettings', {googleAnalytics: true})
            this.showWelcomeDialog = false
            //this.showThankYouDialog=true;
        },
        agreePushNotifications () {
            this.webPushClient = window.webPushClient
            this.$nextTick(() => {

                if (this.webPushClient.getPermissionState() === 'denied') {
                    this.showWebPushDeniedInfo = true
                    this.settingShowNotifications = false
                    this.setsettings({showNotifications: false})
                    this.$store.commit('settings/setsettings', {showNotifications: false})
                } else {
                    //window.console.log(this.webPushClient.getPermissionState());
                    this.setsettings({showNotifications: this.settingShowNotifications === true})
                    this.$store.commit('settings/setsettings', {showNotifications: true})

                    this.webPushClient.subscribe([]).then((subscriptions) => { window.console.log(subscriptions)}, (error) => {
                        window.console.log(error)
                        this.showWebPushDeniedInfo = true
                        this.settingShowNotifications = false
                        this.setsettings({showNotifications: false})
                        this.$store.commit('settings/setsettings', {showNotifications: false})
                    })

                }
            })

            this.showPushDialog = false
        },
        disagreePushNotifications () {
            this.settingShowNotifications = false
            this.setsettings({showNotifications: false})
            this.$store.commit('settings/setsettings', {showNotifications: false})
            this.showPushDialog = false
        },
        showIntroMovie () {
            this.showThankYouDialog = false
            this.$router.push({name: 'IntroMovie'})
        },
        checkSettings () {
            if (this.settings.length === 0) {
                this.showWelcomeDialog = true
            } else if (typeof this.settings['showNotifications'] == 'undefined') {
                if(navigator.serviceWorker.controller!==null && navigator.serviceWorker.controller.state==='activated') {
                    this.showPushDialog = true
                }
            }
        },
        watchSettings () {
            //window.console.log('wathSettings')
            //this.settingsGoogleAnalytics = this.settings['googleAnalytics']
            this.settingShowNotifications = this.settings['showNotifications']
            this.$vuetify.theme.dark = typeof this.settings['darkTheme'] !=='undefined' ? this.settings['darkTheme'] : true;

            if (typeof (this.settings['showColorized']) === 'undefined') {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {showColorized: false}))
            }
            if (typeof (this.settings['show25DPhotos']) === 'undefined') {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {show25DPhotos: true}))
            }
            if (typeof (this.settings['showInfosOverlay']) === 'undefined') {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {showInfosOverlay: false}))
            }
            if (typeof (this.settings['darkTheme']) === 'undefined') {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {darkTheme: true}))
            }
            if (typeof (this.settings['showTour']) === 'undefined') {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {showTour: true}))
            }

            //window.console.log(this.settingShowNotifications);
        },
        /*openYbug(){
            window.Ybug.open()
        },*/

        tabSwipe(tab){
            if (this.$route.fullPath!==tab) this.$router.push(tab);
        },
        reportCollection(){
            /*if(this.plausible !== null){
                window.console.log({props:{logged_in: this.authenticated, appinstalled: window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches}});

                this.plausible('app-report-collection-button-click',{props:{logged_in: this.authenticated, appinstalled: window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches}});
            }*/
            this.$router.push({name:'PhotographCollectionCreate'});
        },
        showInAppInstallPromotion(){
            this.showInAppInstallPromotionDialog=true;

        },
        installDismiss(){
            this.showInAppInstallPromotionDialog=false;
        },
        async installAgree(){
            this.showInAppInstallPromotionDialog=false;
            // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
            this.deferredInstallPrompt.prompt();
            // Find out whether the user confirmed the installation or not
            const { outcome } = await this.deferredInstallPrompt.userChoice;
            // The deferredPrompt can only be used once.
            this.deferredInstallPrompt = null;
            // Act on the user's choice
            if (outcome === 'accepted') {
                window.console.log('User accepted the install prompt.');
            } else if (outcome === 'dismissed') {
                window.console.log('User dismissed the install prompt');
            }
        },
        showItemsNearMe(){
            if (typeof (this.settings['getLocation']) === 'undefined' || this.settings['getLocation']===false) {
                this.showNearMePermissionDialog=true;
                return;
            }

            navigator.geolocation.getCurrentPosition(position => {

                    this.setFilterForPosition(position);
                    //this.getPage('/feed_items?' + this.getParamsFromSearchfilters())
                },
                () => {
                    this.showGetLocationDeniedInfo = true;
                    this.settingGetLocation = false;
                    this.setsettings({getLocation: false});
                    this.$store.commit('settings/setsettings', {getLocation: false})
                    /*const position = this.$offlineStorage.get('lastPosition')
                    if (position) {
                        this.center = position
                    } else {
                        this.center = {
                            lat: null,
                            lng: null
                        }
                    }*/

                })
        },
        setFilterForPosition(position){
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            this.$offlineStorage.set('lastPosition', this.center)

            this.updatesearchfilters([{
                text: this.$t('app.nearMeButton'),
                type: 'geo',
                data: null,
                queryParam: 'geo=' + this.center.lat + ',' + this.center.lng
            }]);
            if(this.$router.currentRoute.name!=='HomeList') this.$router.push({name:'HomeList'});
        },
        activateNearMePermission(){
            this.showNearMePermissionDialog=false;
            this.setsettings({getLocation: true});
            this.$store.commit('settings/setsettings', {getLocation: true})
            this.$nextTick(() => {
                    navigator.permissions.query({name: 'geolocation'}).then( (result)=> {
                        window.console.log(result.state);
                        if (result.state === 'granted') {
                            window.console.log(result.state);
                            navigator.geolocation.getCurrentPosition((cp)=>{
                                this.setFilterForPosition(cp);
                            }, (cp)=>{
                                window.console.log(cp);
                                this.showGetLocationDeniedInfo = true;
                                this.settingGetLocation = false;
                                this.setsettings({getLocation: false});
                                this.$store.commit('settings/setsettings', {getLocation: false})
                            },{timeout:10000});
                        } else if (result.state === 'prompt') {
                            window.console.log(result.state);
                            navigator.geolocation.getCurrentPosition((cp)=>{
                                this.setFilterForPosition(cp);
                            }, (cp)=>{
                                window.console.log(cp);
                                this.showGetLocationDeniedInfo = true;
                                this.settingGetLocation = false;
                                this.setsettings({getLocation: false});
                                this.$store.commit('settings/setsettings', {getLocation: false})
                            },{timeout:10000});
                        } else if (result.state === 'denied') {
                            window.console.log(result.state);
                            this.showGetLocationDeniedInfo = true;
                            this.settingGetLocation = false;
                            this.setsettings({getLocation: false});
                            this.$store.commit('settings/setsettings', {getLocation: false})
                        }
                        result.onchange = function () {
                            window.console.log(result.state);
                        }
                    });
            });
        },

        startTour(){
            window.console.log('startTour', this.$route.name);
            if(this.$route.name==='HomeList'){

                this.drawer=false;
                setTimeout(()=>{ this.$tours['nimmersoTour'].start() },500);
            }


        },
        getAvatar(){
            if(this.personItem && this.personItem.pictureUrl){
                if(this.personItem.pictureUrl.indexOf('https://')!==false){
                    return this.personItem['pictureUrl'];
                }else{
                    return this.entrypoint+'/avatars/'+((this.personItem && this.personItem.pictureUrl && this.personItem.pictureUrl.indexOf('https')===-1) ? this.personItem['pictureUrl'] : '0')+'.png';
                }
                }else{
                return this.entrypoint+'/avatars/0.png'
            }

        },
        openFeedback(){
            this.drawer=false;
            this.feedbackItem={
                text: "",
                additionalData:{
                    rating: null
                }
            };
            this.showFeedbackDialog=true;
        },
        feedbackUpdateField(field,value){
            Object.assign(this.feedbackItem, { [field]: value })
        },
        feedbackSubmit(){
            window.console.log(this.feedbackItem);
            this.createComment(this.feedbackItem);
            this.showFeedbackThxDialog=true;
            this.showFeedbackThxDialogShowLink = this.feedbackItem.additionalData.rating >= 4;

            this.showFeedbackDialog=false;

        },
        feedbackClose(){
            this.showFeedbackDialog=false;
        },
        showReportBug(){
            this.feedbackClose();
            this.openReportBug();
        },

        openReportBug(){
            this.drawer=false;
            this.reportBugItem={
                text: "",
                additionalData:{
                }
            };
            this.showReportBugDialog=true;
        },
        reportBugUpdateField(field,value){
            Object.assign(this.reportBugItem, { [field]: value })
        },
        reportBugSubmit(){
            window.console.log(this.reportBugItem);
            this.createComment(this.reportBugItem);
            this.showReportBugThxDialog=true;

            this.showReportBugDialog=false;

        },
        reportBugClose(){
            this.showReportBugDialog=false;
        }

    }
}

</script>

