import * as types from './mutation_types'

export default {
  [types.SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.SET_UPLOADD] (state, uploaded) {
    Object.assign(state, { uploaded })
  },

  [types.SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.RESET] (state) {
    Object.assign(state, {
      uploaded: null,
      error: '',
      isLoading: false,
      violations: null
    })
  }
}
