<template>

        <v-dialog
            v-model="agbDialog"
            fullscreen

        >
            <v-tooltip left >
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>
            <div class="agbDialog">

                <v-card-title
                    class="text-h5"
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>
                <v-card-text >
                    <div class="custom-scrollbar" v-html="markdownToHtml"></div>
                </v-card-text>
                <nimmerso-footer></nimmerso-footer>
            </div>
        </v-dialog>

</template>
<style scoped>
    @import "../../styles/custom-scrollbar.css";

    .agbDialog {

        background: rgba(0,0,0,0.75);
        max-width: 600px;
        min-width: 300px;
        overflow: auto;

        font-family: "Roboto Condensed", Arial, sans-serif;
    }
    .agbDialog:after {
        background-image: url('~@/assets/img/Login_background.jpg');
        content: "";
        background-size: cover;
        background-position: center;
        background-color: gray;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: -1;
    }
    .closeWinButton {
        z-index: 10001;
        top: max( 30px, env( safe-area-inset-top, 30px ) );
    }
</style>

<script>
    import NimmersoFooter from "../NimmersoFooter"
    import { marked } from 'marked';
    import markdown from 'raw-loader!../../locales/agb_de.md'
    export default {
        name: 'Login',
        components: {
            NimmersoFooter
        },
        data() {
            return {
                agbDialog: true,
                markdown: markdown

            }
        },
        computed: {
            markdownToHtml(){
               return marked(this.markdown);

            }
        },
        methods: {
            closeWin () {
                window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
            },
        }
    }
</script>

