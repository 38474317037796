<template>

    <v-form ref="form" lazy-validation @submit.prevent="checkValid(item)">
        <v-card-title class="text-center justify-center text-h4">{{$t('app.Register')}}</v-card-title>
        <v-card-text>

            <v-text-field
                v-model="item.email"
                :rules="rules.email"
                :label="$t('app.Email')"
                required
                autofocus
                outlined
                prepend-inner-icon="mdi-account"
                @input="handleUpdateField('email', item.email.toLowerCase().trim())">
            </v-text-field>
            <div
                v-if="isInvalid('email')"
                class="invalid-feedback">{{ violations.email }}
            </div>
            <v-text-field
                v-model="item.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.password"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                :label="$t('app.Password')"
                outlined
                prepend-inner-icon="mdi-lock"
                @input="handleUpdateField('password', item.password)"
                @click:append="() => (showPassword = !showPassword)"
            ></v-text-field>
            <div
                v-if="isInvalid('password')"
                class="invalid-feedback">{{ violations.password }}
            </div>
            <div>
            <!--<p>{{$t('app.ReceiveUpdates1')}}</p>-->
            </div>
        </v-card-text>
        <v-card-actions>
            <v-row no-gutters>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pa-1"
                >
                    <v-btn
                        type="submit"
                        :disabled="registering"
                        color="primary"
                        block
                        raised
                    >
                        {{$t('app.Register')}}
                    </v-btn>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-1"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                                v-on="onTooltip"
                                color="#5890FF"
                                block
                                style="letter-spacing: normal;"
                                @click="openLink(entrypoint+'/connect/facebook?register=true')"
                            >
                                <v-icon style="max-height:36px; padding-right:14px">mdi-facebook</v-icon> {{ $t('app.Login.RegisterFacebook') }}
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Login.RegisterFacebook') }}</span>
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-1"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn
                                v-on="onTooltip"
                                color="#FFFFFF"
                                block
                                style="letter-spacing: normal; color: rgba(0,0,0,0.54)"
                                @click="openLink(entrypoint+'/connect/google?register=true')"
                            >
                                <img src="@/assets/img/Google_G_Logo.svg" style="max-height:24px; padding-right:14px"/> {{ $t('app.Login.RegisterGoogle') }}
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Login.RegisterGoogle') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>



        </v-card-actions>
        <v-card-text class="text-center pa-0 mt-4">{{$t('app.registerUserHasAccountQuestion')}} <v-btn
            color="secondary"
            small
            :to="{ name: 'Login' }"
        >
            {{$t('app.Login.SignIn')}}
        </v-btn></v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text-caption font-weight-thin" style="color: lightgray">
            <div v-html="$t('app.AcceptPrivacy' ,{ privacylink:'/privacy', agblink: '/agb'})"></div>
        </v-card-text>

        <v-dialog
            v-model="showUpdatesSelectDialog"
            width="500"
            scrollable
        >
            <v-card>
                <v-card-title
                    primary-title
                >
                    {{$t('app.settings.EmailNotification')}}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <v-switch
                                      v-model="item.receiveEmailsLogins"
                                      @change="checkInderteminate"
                            ></v-switch>
                        </v-col>
                        <v-col cols="10">
                            <span class=".subtitle-1">{{$t('app.settings.Logins')}}</span>
                            <p>{{$t('app.settings.LoginsDescription')}}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-switch
                                v-model="item.receiveEmailsUpdates"
                                @change="checkInderteminate"
                            ></v-switch>
                        </v-col>
                        <v-col cols="10">
                            <span class=".subtitle-1">{{$t('app.settings.Updates')}}</span>
                            <p>{{$t('app.settings.UpdatesDescription')}}</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-switch
                                v-model="item.receiveEmailsSurveys"
                                @change="checkInderteminate"
                            ></v-switch>
                        </v-col>
                        <v-col cols="10">
                            <span class=".subtitle-1">{{$t('app.settings.Surveys')}}</span>
                            <p>{{$t('app.settings.SurveysDescription')}}</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-switch
                                v-model="item.receiveEmailsOffers"
                                @change="checkInderteminate"
                            ></v-switch>
                        </v-col>
                        <v-col cols="10">
                            <span class=".subtitle-1">{{$t('app.settings.Offers')}}</span>
                            <p>{{$t('app.settings.OffersDescription')}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="showUpdatesSelectDialog = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--<v-dialog
            v-model="registernoticedialog"
            width="500"
            style="z-index:100"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{$t('app.registernoticeThankyou')}}
                </v-card-title>

                <v-card-text>
                    <p>{{$t('app.registernotice')}}</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="registernoticedialog = false"
                    >
                        {{$t('app.continue')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>-->
    </v-form>


</template>

<script>

    import { ENTRYPOINT } from '@/config/entrypoint'

    export default {
        props: {
            handleSubmit: {
                type: Function,
                required: true
            },

            handleUpdateField: {
                type: Function,
                required: true
            },

            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                }
            }
        },
        data () {
            return {
                entrypoint: ENTRYPOINT,
                showPassword: false,
                registering: false,
                registerdialog: true,
                submitted: false,
                showUpdatesSelectDialog: false,
                receiveInfos: false,
                isIndeterminate: false,
                //registernoticedialog: false,
                rules: {
                    firstName: [
                        v => !!v || this.$t('app.FirstNameIsRequired')
                    ],
                    lastName: [
                        v => !!v || this.$t('app.LastNameIsRequired'),
                    ],
                    email: [
                        v => !!v || this.$t('app.EmailIsRequired'),
                        v => /.+@.+\..+/.test(v) || this.$t('app.EmailMustBeValid'),
                    ],
                    password: [
                        v => (v || '').length >= 8 || this.$t('app.PasswordLength'),
                    ],
                    privacy: [v => !!v || 'You must agree to continue!']
                }
            }
        },
        computed: {
            // eslint-disable-next-line
            item () {
                return this.initialValues || this.values
            },

            violations () {
                return this.errors || {}
            }
        },
        watch: {
            // eslint-disable-next-line object-shorthand,func-names
            receiveInfos: function () {
                //window.console.log('receiveInfo',this.receiveInfos)
                this.item.receiveEmailsLogins=this.receiveInfos;
                this.item.receiveEmailsUpdates=this.receiveInfos;
                this.item.receiveEmailsSurveys=this.receiveInfos;
                this.item.receiveEmailsOffers=this.receiveInfos;
            }

        },
        mounted () {
            /*setTimeout(()=> {
                this.registernoticedialog=true;
            },1000);
*/
        },
        methods: {
            isInvalid (key) {
                //window.console.log('isInvalid', key)
                return Object.keys(this.violations).length > 0 && this.violations[key]
            },
            checkValid (item) {
                //window.console.log('checkValid')
                if (this.$refs.form.validate()) {
                    this.handleSubmit(item)
                }
            },
            checkInderteminate(){
                this.isIndeterminate= false;
                if(this.item.receiveEmailsLogins && this.item.receiveEmailsUpdates && this.item.receiveEmailsSurveys && this.item.receiveEmailsOffers) {
                    this.receiveInfos=true;
                }else if(!this.item.receiveEmailsLogins && !this.item.receiveEmailsUpdates && !this.item.receiveEmailsSurveys && !this.item.receiveEmailsOffers) {
                    this.receiveInfos=false;
                }else{
                    this.isIndeterminate=true;
                }
            },
            openLink(url){
                window.location=url;
            }


        }
    }
</script>
