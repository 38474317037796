<template>
  <div>

    <v-snackbar
          v-if="isLoading"
          v-model="isLoading"
          :timeout="5000"
          :top="true"
          color="info"
      >Loading...
      </v-snackbar>

      <v-snackbar
          v-if="error"
          v-model="error"
          :timeout="5000"
          :top="true"
          color="error"
      >
      <v-icon>mdi-alert</v-icon> {{ error }}
      </v-snackbar>
      <v-snackbar
          v-if="deletedItem"
          v-model="deletedItem"
          :timeout="5000"
          :top="true"
          color="success"
      >
          <v-icon>mdi-delete</v-icon> {{ deletedItem['@id'] }} deleted.
      </v-snackbar>



    <div class="table-responsive">

    </div>
 Diese Funktion ist in dieser Version noch nicht verfügbar.
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    deletedItem: 'feeditem/del/deleted',
    error: 'feeditem/list/error',
    items: 'feeditem/list/items',
    isLoading: 'feeditem/list/isLoading',
    view: 'feeditem/list/view'
  }),

  created () {
    this.getPage()
  },

  methods: mapActions({
    getPage: 'feeditem/list/default'
  })
}
</script>
