<template>
    <form @submit.prevent="">
        {{value}}
        <div class="form-group">
            <v-file-input
                label="Upload Image"
                accept="image/jpeg, image/png, image/gif"
                id="upload_file_contentUrl"
                name="file"
                @change="doSubmit"
                :class="['form-control', isInvalid('file') ? 'is-invalid' : '']" />
            <div
                v-if="isInvalid('file')"
                class="invalid-feedback">{{ violations.file }}</div>
            {{thumbnail}}
            <!--<v-img
                :src="entrypoint+'/image_galleries/'+thumbnail+'/small'"
                :lazy-src="entrypoint+'/image_galleries/'+thumbnail+'/lazy'"
                class="white--text"
                :contain="true"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200"
                @click="zoomItem(item)"
            ></v-img>-->
        </div>
    </form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import fetch from '../../utils/fetch'
import {ENTRYPOINT} from "../../config/entrypoint";
export default {
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },

        handleUpdateField: {
            type: Function,
            required: true
        },

        value: {
            type: String,
        },
        thumbnail: {
            type: String,
        },
        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => { return {file:''}}
        }
    },

    computed: {
        // eslint-disable-next-line
        items () {
            return this.initialValues || {file:this.value}
        },

        violations () {
            return this.errors || {}
        }
    },
    data() {
        return {
            entrypoint: ENTRYPOINT,
        }
    },
    methods: {
        ...mapActions({
            upload: 'imagegallery/upload/upload',
        }),
        ...mapMutations({
            setError: 'imagegallery/upload/SET_ERROR'
        }),
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },

        doSubmit(file){
            /*this.upload(this.items).then((response) =>{
              console.log(response);
              this.handleSubmit(this.items.file)
            },(err) => {
              console.log('fehler');
            });
            */



            let headers =new Headers();
            headers.set("Content-Type",'multipart/form-data; boundary=----WebKitFormBoundarydXskqET69oNB63ct')
            let data = new FormData();
            data.append('thumbnail',this.thumbnail)
            data.append('file',file);

            fetch('image_galleries/upload', {
                method: 'POST',
                body: data,
                //headers: headers
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    this.handleUpdateField(data['hydra:member'][0])
                })
                .catch((e) => {

                    this.setError(e.toString());
                    return e
                })



        }
    }
}
</script>
