<template>
    <v-container pa-2 fluid id="photographretrosnapcontainer">
        <v-snackbar
                v-if="isLoading"
                v-model="isLoading"
                :timeout="5000"
                :top="true"
                color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
                v-if="error"
                v-model="error"
                :timeout="5000"
                :top="true"
                color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
                v-if="deletedItem"
                v-model="deletedItem"
                :timeout="5000"
                :top="true"
                color="success"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deletedItem['@id'] }} deleted.
        </v-snackbar>
        <v-dialog v-if="item" v-model="item" content-class="zoomImageDialog photographRetrosnapsDialog" fullscreen
                  hide-overlay transition="fade-transition" >
            <div class="pa-4" :style="'min-height: 100vh; position:absolute; background-image: url('+entrypoint+'/thumbnail/display/'+item['filePath'].replace(/\.jpeg/,'.webp')+'); background-size: cover; background-position: center; background-attachment:fixed;'"
            >

                <div class="masonry">
                    <div class="masonry-item"
                         v-for="retrosnap in item.retrosnaps" :key="retrosnap['@id']"
                         :style="'height:calc( width * '+item['ratio']+' - 4 ); background-size: cover; background-image: url('+entrypoint+'/thumbnail/display/'+item['filePath'].replace(/\.jpeg/,'.webp')+')'"
                    ><img @click.stop="carouselItemClick(retrosnap)"
                                   :src="entrypoint+'/thumbnail/display'+retrosnap['filePath'].replace(/\.jpeg/,'.webp')"
                                   style="width:100%; margin-bottom: -8px;"
                                   class="fadingEffect"
                            /></div>
                </div>
                <v-btn @click.stop="$router.go(-1)" icon style="position:fixed; top:10px;right:10px">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn :to="{name: 'RetrosnapCreate', params: { photograph: item['@id'] }}">
                    <v-icon>icon-RetroSnapPlus</v-icon>&nbsp; {{ $t('app.Retrosnap.Create') }}
                </v-btn>
            </div>
        </v-dialog>
    </v-container>
</template>
<style>

    .photographRetrosnapsDialog .homevcardactions i {
        text-shadow: 0 1px 2px black;
    }

    .photographRetrosnapsDialog .masonry-wrapper {
        margin-right: auto;
        margin-left: auto;
        background-color: rgba(0, 0, 0, .5);
    }

    .photographRetrosnapsDialog .masonry {
        columns: 1;
        column-gap: 20px;
    }

    .photographRetrosnapsDialog .masonry-item {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 20px;
        width: 100%;
        border: 2px solid white !important;
        border-radius: 0 !important;
        cursor: pointer;
    }

    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        .photographRetrosnapsDialog .masonry {
            columns: 2;
        }
    }

    @media only screen and (min-width: 1024px) {
        .photographRetrosnapsDialog .masonry {
            columns: 3;
        }
    }

    .photographRetrosnapsDialog .masonry-item {

        overflow: hidden;
    }

    .photographRetrosnapsDialog .masonry-item {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .3));
        transition: filter .25s ease-in-out;
    }

    .photographRetrosnapsDialog .masonry-item:hover {
        filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .3));
    }


    .photographRetrosnapsDialog .masonry-item {
        background-color: #010308;
    }


    .photographRetrosnapsDialog .masonry-footer a {
        color: currentColor;
    }


</style>
<script>
  import { ENTRYPOINT } from '../../config/entrypoint'
  import { mapActions, mapGetters } from 'vuex'
  import { Scroll } from 'vuetify/lib/directives'


  export default {
    directives: {
      Scroll
    },
    computed: mapGetters({
      item: 'photograph/show/retrieved',
      deletedItem: 'retrosnap/del/deleted',
      error: 'retrosnap/list/error',
      isLoading: 'retrosnap/list/isLoading',
      view: 'retrosnap/list/view',
      personError: 'person/show/error',
      personIsLoading: 'person/show/isLoading',
      personItem: 'person/show/retrieved',
      settings: 'settings/items',
      token_data: 'user/token/token_data'
    }),
    watch: {
      settings () {
        this.showColorized = this.settings.showColorized
        this.showInfosOverlay = this.settings.showInfosOverlay
      },
      item () {
        /*setTimeout(() => {
            this.rellax = new Rellax('.rellax', {
                center: true,
                wrapper: '.zoomImageDialog',
                round: true
            });
        },1000);*/

      }
    },
    data: () => ({
      entrypoint: ENTRYPOINT,
      items: [],
      showColorized: false,
      showInfosOverlay: false,
      //rellax: null
    }),
    created () {
      this.retrieve(decodeURIComponent(this.$route.params.id))
      if (this.token_data && this.token_data.person_id !== null) {
        this.personRetrieve('/people/' + this.token_data.person_id)
      } else {
        window.console.log('no tokenData!')
      }
      this.showColorized = this.settings.showColorized
      this.showInfosOverlay = this.settings.showInfosOverlay

    },

    methods: {
      ...mapActions({
        retrieve: 'photograph/show/retrieve',
        personRetrieve: 'person/show/retrieve'
      }),
      carouselItemClick (item) {
        this.$router.push({name: 'RetrosnapShow', params: {id: item['@id']}})
      },
    }
  }
</script>
