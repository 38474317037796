<template>
  <div>
    <v-snackbar
         v-if="isLoading"
         v-model="isLoading"
         :timeout="5000"
         :top="true"
         color="info"
     >Loading...
     </v-snackbar>

     <v-snackbar
         v-if="error"
         v-model="error"
         :timeout="5000"
         :top="true"
         color="error"
     >
      <v-icon>mdi-alert</v-icon> {{ error }}
     </v-snackbar>
     <v-snackbar
         v-if="deleteError"
         v-model="deleteError"
         :timeout="5000"
         :top="true"
         color="error"
     >
         <v-icon>mdi-delete</v-icon> {{ deleteError }}
     </v-snackbar>
      <v-dialog v-if="item" v-model="item" fullscreen hide-overlay
                transition="fade-transition" style="z-index:10000">
          <v-card>
          <v-tooltip left>
              <template v-slot:activator="{ on: onTooltip }">
                  <v-btn @click.stop="$router.go(-1)" icon
                         style="z-index:10001; position:fixed; top:10px;right:10px" v-on="onTooltip">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </template>
              <span>{{ $t('app.Close') }}</span>
          </v-tooltip>
              <LazyYoutube
                  src="https://youtu.be/-UUtv8xsius"
                  maxWidth="1920"
              ></LazyYoutube>
          </v-card>

      </v-dialog>
  </div>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
export default {
    components: {
        LazyYoutube
    },
    data: () => ({
         item: true
     }),

      methods: {

      }
}
</script>
