<template>
    <div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <div id="imageviewer"
             style="position:fixed; top:0; width: 100vw; height: 100vh; background-color: black;"></div>
        <div class="commentOverlayOrig commentEditable" style="display:none;">
            <div class="handle handletl"></div>
            <div class="handle handletr"></div>
            <div class="handle handlebl"></div>
            <div class="handle handlebr"></div>
            <v-btn class="commentOverlayOk" primary icon large>
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn class="commentOverlayAbort" primary icon large>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="tooltipBox" v-if="!pathEditMode"
             :style="'position:absolute; top:'+tooltipBoxPos.y+'px; left:'+tooltipBoxPos.x+'px'"
             v-html="this.tooltipText">
        </div>
        <!--<div class="pathEditModeTextBox" v-if="pathEditMode && textBoxPos"
             :style="'position:absolute; top:'+textBoxPos.y+'px; left:'+textBoxPos.x+'px'">
            <v-card>
            <tiptap-vuetify
                v-model="newComment.text"
                :extensions="tiptapExtensions"
                :toolbar-attributes="{ color: 'rgba(20,20,20,.5)' }"
                :placeholder="$t('app.Comment.CommentText')"
                label="Text"
                required="true"
                :card-props="{ flat: true, color: 'rgba(20,20,20,.5)' }"
                width="100%"
            ></tiptap-vuetify>
                <v-card-actions></v-card-actions>
            </v-card>
        </div>-->
        <v-card class="photographShow"
                :style="(hideGui ? ' opacity:0;transition:opacity 500ms;' : 'opacity:1;transition:opacity 500ms;')">

            <v-card-title v-if="item"
                          class="text-h7 photographTitle"
                          primary-title

            >
                {{ getTranslation(item).name }}
            </v-card-title>

            <div class="photographShowInfo" v-if="!pathEditMode">
                <v-card v-if="item" :style="hideGui ? 'pointer-events: none !important;' : ''">
                    <!--<v-btn top right absolute icon class="pa-0" @click="showInfo = false" style="z-index:1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>-->
                    <!--<v-card-title
                        class="text-h7 mr-5"
                        primary-title
                        style="hyphens: auto; hyphenate-limit-chars: auto 5; hyphenate-limit-lines: 2;"
                    >
                        {{ getTranslation(item).name }}
                    </v-card-title>-->
                    <v-card-text>
                        <v-card-subtitle>
                            <p v-if="getTranslation(item).description && (getTranslation(item).description.length<100 || descriptionShowAll)"
                               class="description-text" v-html="getTranslation(item).description"></p>
                            <span
                                v-if="getTranslation(item).description && getTranslation(item).description.length>100 && !descriptionShowAll">
                                <span class="description-text"
                                      v-html="getTranslation(item).description.substring(0, 100).replace('<p>','').replace('</p>','') + ' '">
                                </span>
                                <a href="#" @click.prevent="descriptionShowAll=true">[more...]</a>
                            </span>

                        </v-card-subtitle>
                        <v-card-subtitle>
                            <div v-if="item.fromYear && item.toYear">{{ $t('app.Photograph.Period') }}:
                                {{ item.fromYear }}<span
                                    v-if="item.fromYear!==item.toYear"> - {{ item.toYear }}</span></div>
                            <div v-if="item.place && item.place.name">{{ $t('app.Photograph.Place') }}:
                                {{ item.place.name }}
                            </div>
                            <div v-if="item.material">{{ $t('app.Photograph.Material') }}: {{ item.material }}</div>

                            <!--<a href="#" rel="noreferrer" v-if="item" v-on:click.stop.prevent="showCollectionInfoDialog=true" x-small  v-html="attribution"></a>-->

                        </v-card-subtitle>
                        <v-card-actions class="px-0" v-if="isEditor || isAdmin || isOwner">
                            <v-btn x-small :to="{name: 'PhotographUpdate', params: { id: item['@id'] }}"
                            >{{ $t('app.Edit') }}
                            </v-btn>
                        </v-card-actions>

                    </v-card-text>

                </v-card>
                <v-card-actions v-if="item">
                    <v-row>
                        <v-col xs="10" sm="10" md="8" lg="6" xl="4">
                            <v-btn
                                rounded
                                outlined
                                block
                                style="margin-bottom:7px; pointer-events: stroke;"
                                @click="addComment()"


                            >
                                <v-row>
                                    <v-col class="text-capitalize text-left">{{ $t('app.Comment.Comment') }}...</v-col>
                                    <v-col class="text-right">
                                        <v-icon>
                                            mdi-comment-plus
                                        </v-icon>
                                    </v-col>
                                </v-row>


                            </v-btn>
                        </v-col>
                        <v-col cols="2" xs="2" sm="2" md="4" lg="6" xl="8">
                            <v-tooltip top style="transition-delay: 0.15s;" v-if="item.comments.length>0">
                                <template v-if="item.comments.length>0" v-slot:activator="{ on: onTooltip }">
                                    <v-badge

                                        :content="item.comments.length"
                                        :value="item.comments.length"
                                        offset-x="16"
                                        offset-y="16"
                                        overlap
                                    >
                                        <v-btn @click.stop="showComments=true" v-on="onTooltip" icon
                                               style="pointer-events: stroke;">
                                            <v-icon>icon-SpeechBubble</v-icon>
                                        </v-btn>
                                    </v-badge>


                                </template>
                                <span>{{ $t('app.Photograph.ShowComments') }}</span>
                            </v-tooltip>
                            <v-tooltip v-else top style="transition-delay: 0.15s;">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn @click.stop="showComments=true" v-on="onTooltip" icon
                                           style="pointer-events: stroke;">
                                        <v-icon>icon-SpeechBubble</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Photograph.ShowComments') }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </div>
            <v-tooltip left v-if="item">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>
            <v-card-actions v-if="item && !pathEditMode"
                            style="z-index:7; position:fixed; bottom:40px; right:0px; flex-direction: column-reverse;">


                <v-speed-dial
                    v-if="item && !pathEditMode"
                    style="margin-bottom: 20px"
                    v-model="speedDialShow"
                    direction="top"
                    transition="slide-y-reverse-transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="speedDialShow"
                            icon large
                        >
                            <v-icon v-if="speedDialShow">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-dots-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip left>

                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="toggleVoice" v-on="onTooltip" icon>
                                <v-icon v-if="audioPlaying">mdi-voice-off</v-icon>
                                <v-icon v-if="!audioPlaying">mdi-account-voice</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="audioPlaying">{{ $t('app.VoiceOff') }}</span>
                        <span v-if="!audioPlaying">{{ $t('app.VoiceOn') }}</span>
                    </v-tooltip>

                    <v-tooltip left>

                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="toggleTextlayer" v-on="onTooltip" icon>
                                <v-icon v-if="showTextlayer">mdi-message-bulleted-off</v-icon>
                                <v-icon v-if="!showTextlayer">mdi-message-bulleted</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="!showTextlayer">{{ $t('app.Photograph.ShowTextlayer') }}</span>
                        <span v-if="showTextlayer">{{ $t('app.Photograph.HideTextlayer') }}</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn v-if="item['place']"
                                   :to="{name: 'MapShowGeo', params: { geo: item.place.geo.latitude+','+item.place.geo.longitude }}"
                                   icon large v-on="onTooltip">
                                <v-icon>icon-Marker</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Photograph.ShowOnMap') }}</span>
                    </v-tooltip>


                    <v-tooltip left>
                        <!--<v-btn @click.stop="rotateImage" icon>
                            <v-icon>mdi-rotate-right</v-icon>
                        </v-btn>-->
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="showContrast=true" v-on="onTooltip" icon>
                                <v-icon>mdi-contrast-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Photograph.BrightnessContrast') }}</span>
                    </v-tooltip>
                </v-speed-dial>

                <!--<v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="showInfo=true" v-on="onTooltip" icon large>
                            <v-icon>icon-info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.Info') }}</span>
                </v-tooltip>-->

                <v-speed-dial
                    v-if="!speedDialShow && item && !pathEditMode"

                    v-model="selectSpeedDialShow"
                    direction="left"
                    transition="slide-x-reverse-transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="selectSpeedDialShow"
                            icon large
                        >
                            <v-icon v-if="selectSpeedDialShow">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>icon-Select_empty</v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip left style="transition-delay: 0.15s;">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="selectPerson" v-on="onTooltip" icon class="mb-2">
                                <v-icon>icon-Select_Person</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Comment.SelectPerson') }}</span>
                    </v-tooltip>
                    <v-tooltip left style="transition-delay: 0.15s;">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="selectBuilding" v-on="onTooltip" icon class="mb-2">
                                <v-icon>icon-Select_Building</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Comment.SelectBuilding') }}</span>
                    </v-tooltip>
                    <v-tooltip left style="transition-delay: 0.15s;">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="selectText" v-on="onTooltip" icon class="mb-2">
                                <v-icon>icon-Select_X</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Comment.SelectText') }}</span>
                    </v-tooltip>

                    <v-tooltip left style="transition-delay: 0.15s;">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="selectSection" v-on="onTooltip" icon class="mb-2">
                                <v-icon>icon-Select_empty</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Comment.SelectEmpty') }}</span>
                    </v-tooltip>


                </v-speed-dial>


                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn :to="{name: 'RetrosnapCreate', params: { photograph: item['@id'] }}" v-on="onTooltip"
                               icon large class="mb-2">
                            <v-icon>icon-RetroSnapPlus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Retrosnap.Create') }}</span>
                </v-tooltip>
                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.native.stop.prevent="showGallerySelectDialog(item)" icon class="mb-2"
                               v-on="{...onTooltip}">
                            <v-icon>mdi-notebook-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Gallery.Add') }}...</span>
                </v-tooltip>

                <v-tooltip v-if="!speedDialShow && showBackside===false && item.isBlackAndWhite===true" left>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="toggleColorized" v-on="onTooltip" icon>
                            <v-icon v-if="colorized">mdi-invert-colors-off</v-icon>
                            <v-icon v-if="!colorized">mdi-invert-colors</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.Colorized') }}</span>
                </v-tooltip>

                <v-tooltip v-if="!speedDialShow && showBackside!==false" left>

                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="rotateImage" v-on="onTooltip" icon>
                            <v-icon>mdi-rotate-right</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.RotateImage') }}</span>
                </v-tooltip>

                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.native.stop.prevent="orderItemsDrawer=true" icon v-on="{...onTooltip}">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.Order') }}...</span>
                </v-tooltip>


                <ShareButton v-if="!speedDialShow && metaUrl" style="transition-delay: 0.15s;"
                             :url="metaUrl"
                             :title="metaTitle"
                             :description="metaDescription"
                             quote="Schau dir dieses RetroPic auf Nimmerso an"
                             :hashtags="'nimmerso,'+metaHashtags.join(',')"
                             :media="metaImage"
                             twitterUser="nimmerso"
                ></ShareButton>


            </v-card-actions>
            <!--<v-card-actions v-if="item && pathEditMode" class=""
                            style="position:fixed; bottom:20px; left:20px">
                <v-btn primary @click.stop="pathEditOk" icon large>
                    <v-icon>mdi-check-bold</v-icon>
                </v-btn>
            </v-card-actions>-->
            <v-tooltip v-if="item && item['backFilePath'] && toggleBacksideButtonIcon!==null" top>
                <template v-slot:activator="{ on: onTooltip }">
                    <button class="toggle-backside-button" type="button" v-on="onTooltip"
                            @click="toggleBackside">
                        <img class="toggle-backside-button-img"
                            :src="toggleBacksideButtonIcon"
                        />

                        <img class="toggle-backside-button-icon" src="/img/icons/FlipOver.svg" alt="backside"/>

                        <!--<v-icon v-if="!showBackside">mdi-arrange-send-backward</v-icon>
                        <v-icon v-if="showBackside">mdi-arrange-bring-forward</v-icon>-->
                    </button>
                </template>
                <span v-if="!showBackside">{{ $t('app.Photograph.ShowBackside') }}</span>
                <span v-if="showBackside">{{ $t('app.Photograph.ShowFrontside') }}</span>
            </v-tooltip>
            <div class="additionalPhotographsContainer"
                 v-if="item && (item['additionalPhotographs'].length>0 || item['retrosnaps'].length>0)"
                 :style="(additionalPhotographsOpen ? 'width: 100vw;' : 'width: 100px;')">
                <div class="additionalPhotographsContainerScroll" v-dragscroll.x="true" @dragscrollstart="onDragStart"
                     @click.capture="onDragClick">
                    <button class="additionalPhotographsButton" type="button"
                            v-if="item && (item['additionalPhotographs'].length>0 || item['retrosnaps'].length>0)"
                            v-click-outside="closeAdditionalPhotographs"
                    >


                        <img v-for="(additionalPhotograph, index) in item['additionalPhotographs']"
                             :key="additionalPhotograph['id']"
                             class="additionalPhotographsImage"
                             @click.stop="toggleAdditionalPhotographsOpen(additionalPhotograph)"
                             :style="(additionalPhotographsOpen ? 'left: '+(20 + 80*index)+'px; transform: rotate(0)' : 'left: 20px; transform: rotate('+(Math.random()*60-30)+'deg)')"

                             :src="entrypoint+'/thumbnail/'+'icon/'+additionalPhotograph['filePath'].replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                             alt=""/>

                        <div
                            :style="'transform-origin: center center; '+(additionalPhotographsOpen ? 'left: '+(20 + 80*(index + item['additionalPhotographs'].length))+'px; transform: rotate(0)' : 'left: 20px; transform: rotate('+(Math.random()*60-30)+'deg)')"
                            top v-for="(retrosnap, index) in item['retrosnaps']" :key="retrosnap['id']"
                            class="retroSnapThumbnailContainer"
                            @click.stop="toggleAdditionalPhotographsOpen(retrosnap)">
                            <img
                                class="retroSnapThumbnail"
                                :src="entrypoint+'/thumbnail/'+'icon/'+retrosnap['filePath'].replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                                alt=""/>
                        </div>
                    </button>
                </div>
            </div>
            <v-btn v-if="item" @click="showCollectionInfoDialog=true" x-small class="attributionLayer"
                   v-html="attribution"></v-btn>

        </v-card>
        <v-bottom-sheet v-if="item" v-model="showContrast" inset hide-overlay>
            <v-card class="pa-4">
                <v-btn style="position:absolute; top:0; right:0" icon @click="showContrast = !showContrast">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-row class="pt-8">
                    <v-col md="2" class="pa-0">{{ $t('app.Photograph.Brightness') }}</v-col>
                    <v-col md="10" class="pa-0">
                        <v-slider
                            v-model="brightness"
                            dense
                            append-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            thumb-label
                            :thumb-size="20"
                            :min="-100"
                            :max="100"
                            @click:append="brightness+=10"
                            @click:prepend="brightness-=10"
                        ></v-slider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2" class="pa-0">{{ $t('app.Photograph.Contrast') }}</v-col>
                    <v-col md="10" class="pa-0">
                        <v-slider
                            v-model="contrast"
                            dense
                            append-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            thumb-label
                            :thumb-size="20"
                            :min="-100"
                            :max="100"
                            @click:append="contrast+=10"
                            @click:prepend="contrast-=10"
                        ></v-slider>
                    </v-col>
                </v-row>

            </v-card>


        </v-bottom-sheet>

        <v-bottom-sheet content-class="photographShowComments" inset hide-overlay
                        v-model="showComments"
                        v-if="showComments"
                        scrollable
                        class="noBackground"
        >
            <v-card v-if="item" class="bgBlur">
                <v-btn top right absolute icon class="pa-0" @click="showComments = false" style="z-index:1">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title>{{ commentItems.length }} {{ $t('app.Comments') }}</v-card-title>
                <v-card-text class="py-4" v-if="commentItems.length==0">Keine Kommentare vorhanden</v-card-text>

                <v-card-text class="" ref="commentsContainer">

                    <comment-item
                        v-for="commentItem in commentItems"
                        hover
                        elevation="2"
                        class="my-2 py-1"
                        :color="(activeComment===commentItem.id ? 'grey lighten-4' : 'grey lighten-5')"
                        :id="'comment_'+commentItem.id"
                        :ref="'comment_'+commentItem.id"
                        :categories="categories"
                        :comment-item="commentItem"
                        :comment-click="commentClick"
                        :active-comment="activeComment"
                        :edit-comment="editComment"
                        :delete-comment="deleteComment"
                        :upvote-comment="upvoteComment"
                        :downvote-comment="downvoteComment"
                        :add-comment-to-gallery="addCommentToGallery"
                        :report-inappropriate-comment="reportInappropriateComment"
                        :imagegalleries="imagegalleries"
                        :key="commentItem.id">

                    </comment-item>

                </v-card-text>
                <v-card-actions v-if="item">
                    <v-btn
                        rounded
                        outlined
                        block
                        style="margin-bottom:7px; pointer-events: stroke;"
                        @click="addComment()"
                    >
                        <v-row>
                            <v-col class="text-capitalize text-left">{{ $t('app.Comment.Comment') }}...</v-col>
                            <v-col class="text-right">
                                <v-icon>
                                    mdi-comment-plus
                                </v-icon>
                            </v-col>
                        </v-row>


                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
            inset
            hide-overlay
            scrollable
            v-if="showCommentDialog" v-model="showCommentDialog"
        >
            <comment-dialog
                :do-comment-dialog-close="doCommentDialogClose"
                :edit-rectangle="editRectangle"
                :new-comment="newComment"
                :on-send-comment-form="onSendCommentForm"
                :update-comment-field="updateCommentField"
                :submit-comment="submitComment"
            >
            </comment-dialog>
        </v-bottom-sheet>

        <v-dialog
            v-model="showFaceDialog"
            max-width="390"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('app.Photograph.FaceKnown') }}
                </v-card-title>
                <v-card-text>{{ $t('app.Photograph.FaceKnownText') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="editFace"
                    >
                        {{ $t('app.Photograph.FaceInsertData') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showCollectionInfoDialog"
            v-if="item"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ item.collection.headline }}
                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCollectionInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="pt-6" v-if="item.collection.about">{{ item.collection.about }}</v-card-subtitle>
                <v-card-text v-if="item.collection.text" v-html="item.collection.text"></v-card-text>
                <v-card-text>
                    <v-btn small @click.stop.prevent="showPhotosFromCollection">{{ item.collection.photographsCount }}
                        RetroPics
                    </v-btn>
                </v-card-text>

                <v-card-text v-if="this.item.collection.collectionCopyrightUrl"><a
                    :href="this.item.collection.collectionCopyrightUrl"
                    target="_blank">{{ this.item.collection.collectionCopyrightUrl }}</a></v-card-text>
                <v-card-text v-if="this.item.collection.licenseName">
                    <a v-if="this.item.collection.licenseUrl" :href="this.item.collection.licenseUrl" rel="license"
                       target="_blank">{{ this.item.collection.licenseName }}</a>
                    <span v-else>{{ this.item.collection.licenseName }}</span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="gallerySelectDialog" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add') }}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGalery"
                    >
                        {{ $t('app.Gallery.AddShort') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog
            v-if="showCommentLoginDialog"
            v-model="showCommentLoginDialog"
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Comment.PleaseLogIn') }}
                </v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCommentLoginDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('app.Comment.LoginText') }}<br/>
                    {{ $t('app.Comment.LoginText2') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="goToStart">{{ $t('app.Comment.LoginRegister') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer
            v-model="orderItemsDrawer"
            fixed
            right
            clipped
            :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
            style="z-index:10002;"
            class="fill-height orderItemsDrawer"

        >
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="mt-3">Was möchtest du drucken?</v-list-item-title>
                        <v-btn top right absolute icon @click="orderItemsDrawer = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense style="overflow-y:auto; flex-grow: 5" fluid>
                <v-list-item v-for="(product, index) in products" :key="index">
                    <v-expand-transition>
                        <product :value="product" :handle-product-config="onProductSelect"></product>
                    </v-expand-transition>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>

        <v-dialog
            id="cropper-modal"
            ref="cropper-modal"
            v-model="cropperModal"
            style="z-index:10003;"
            :fullscreen="isMobile"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Photograph.CropperSelect') }}
                </v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="cropperModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>


                    <cropper v-if="item && item.filePath"
                             ref="cropper"
                             class="cropper"
                             :src="entrypoint+'/thumbnail/display/'+item.filePath"
                             :stencil-props="{
                      aspectRatio: cropperRatio
                    }"
                             style="width:100%; min-height:300px"
                             :resizeImage="false"
                             :default-size="defaultSize"
                             @change="calcDpi"
                             @

                    ></cropper>
                </v-card-text>
                <v-card-actions>
                    <div :style="'color:'+evaluation">DPI: {{Math.round(dpi)}}</div>
                    <v-spacer></v-spacer>
                    <v-btn @click="changeCropper">In den Warenkorb...</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style>
* button {
    outline: none;
}

.photographShowInfo {
    box-shadow: none;
    max-height: 340px;
    pointer-events: none;
    position: fixed;
    right: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3) 40%) !important;
    bottom: 0;

}

.photographShowInfo .btn, .photographShowInfo a {
    pointer-events: auto;
}

.photographTitle {
    text-shadow: 0px 2px 3px #000000;
    top: max(30px, env(safe-area-inset-top, 30px));
    hyphens: auto;
    hyphenate-limit-chars: auto;
    hyphenate-limit-lines: 2;
    position: fixed;
    padding-top: 0;
    font-weight: bold;
    max-width: calc(100vw - 60px);
}

.photographShowInfo .v-card__subtitle {
    padding: 0;
}

.photographShowInfo > .v-card {
    background-color: initial !important;
    max-height: 240px;
    overflow-y: auto;
    mask-image: linear-gradient(transparent, black 5%, black 95%, transparent);
    mask-mode: alpha;
    padding-top: 20px;
    padding-right: 25px;
    pointer-events: stroke;

}

.attributionLayer {
    bottom: 0;
    right: 0;
    position: fixed;
    height: 30px;
    padding: 5px 20px 5px 5px;
    text-shadow: 1px 1px 2px black;
    background-color: rgba(50, 50, 50, .2) !important;
    text-transform: none;
}

.zoomImageDialog i.v-icon {
    text-shadow: 0 1px 2px black;
    /*-webkit-text-fill-color: white;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;*/
}

.closeWinButton {
    z-index: 10001;
    top: max(30px, env(safe-area-inset-top, 30px));
}


.commentHidden {
    display: none !important;
}

.toggle-backside-button {
    position: fixed;
    top: 20%;
    left: 20px;
}
.toggle-backside-button-img {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
}


.toggle-backside-button-icon {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 25%;
    right: -15px;
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: white;
}

.additionalPhotographsButton {
    left: 10px;
    top: 30%;

}

.additionalPhotographsContainer {
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    width: 150px;
    height: 120px;
    display: block;
    /*direction: rtl;*/
    left: 0;
    position: fixed;
    top: 30%;
    right: 0;
    transition: all 0.5s ease-out;
    scroll-behavior: smooth;
}

.additionalPhotographsContainerScroll {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    height: 140px;
}


.additionalPhotographsImage {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
    position: absolute;
    transition: all 0.5s ease-out;
    display: inline-block;
    margin-left: 15px;
}

.additionalPhotographsContainerScroll div {
    position: absolute;
    transition: all 0.5s ease-out;
    display: inline-block;
}

.commentOverlay {
    outline: 12px auto #0A7EbE;
    background-color: rgba(0, 0, 0, .4);
    position: absolute;

}

.commentOverlay > .textDiv {
    /*color: rgba(255, 255, 255, .8);
    text-shadow: 0 2px 1px rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    overflow: hidden;*/
    display: none;
    justify-content: center;
    align-items: center;
}

.commentOverlay:hover, .commentOverlay:focus {
    background-color: rgba(0, 0, 0, .1);
}

.commentOverlay .handle {
    outline: 12px auto #0A7EbE;
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    display: none;
}

.commentOverlay .commentOverlayOk, .commentOverlay .commentOverlayAbort {
    display: none
}

.commentEditable .handle {
    display: block;
}

.commentEditable .commentOverlayOk, .commentEditable .commentOverlayAbort {
    display: block;
}


.commentOverlay .handletl {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
}

.commentOverlay .handletr {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
}

.commentOverlay .handlebl {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
}

.commentOverlay .handlebr {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
}

.commentTooltipDiv {
    display: block;
    max-width: 200px;
    text-wrap: normal;
    word-wrap: break-word;

}

.commentTooltipDiv p {
    max-width: 200px;
    word-wrap: break-word;
}

.zoomImageDialog .leaflet-tooltip p {
    white-space: normal;
    min-width: 250px;
    max-width: 300px;
}

.commentsContainerText p {
    margin-bottom: 0;
}

.leaflet-control-attribution {
    color: #daa081 !important;
}

.tooltipBox {
    background-color: rgb(100, 100, 100, .5);
    text-shadow: 0 2px 1px rgba(0, 0, 0, .5);
    padding: 0 10px 0 10px;
    max-width: 250px;
}

.tooltipBox p {
    margin: 8px 0 8px 0;
}

/*

.retroSnapThumbnail {

    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.retroSnapThumbnail:after {
    color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
    left: 0;
    opacity: 0;
    width:24px;
    height:24px;
    pointer-events: none;
    position: absolute;
    top: 0;
    content: "\e905";
}*/


.retroSnapThumbnailContainer {
    /*max-width: 64px;
    max-height: 64px;*/
    display: flex;
    scroll-behavior: smooth;
}

.retroSnapThumbnailContainer img {
   /* margin-left: 15px; */
}

.retroSnapThumbnailContainer::after {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 30px;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(255, 255, 255, 0.5);
    content: "\e906";
    position: absolute;
    top: 0;
    right: 0;
}

/*
.retroSnapThumbnail::before {
    color: rgba(255, 255, 255, 0.5);
    content: "\e905";
}

.retroSnapThumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
*/

.photographShowInfo .description-text p {
    margin-bottom: 0 !important;
}
.cropper{
    min-height:300px;
    min-width:300px;
}

</style>
<script>
require('openseadragon-filtering')
import OpenSeadragon from 'openseadragon'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ENTRYPOINT } from '@/config/entrypoint'
import fetch from '../../utils/fetch'
import isWebpSupported from '../../utils/isWebpSupported'
import CommentItem from '../comment/CommentItem'
import CommentDialog from '../comment/CommentDialog'
import ShareButton from '../share/ShareButton'
import { dragscroll } from 'vue-dragscroll'
import Product from './Product'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
/*import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    BulletList,
    OrderedList,
    ListItem,
    Blockquote,
    HardBreak
} from 'tiptap-vuetify'*/

export default {
    directives: {
        'dragscroll': dragscroll
    },
    components: {
        Product, CommentItem, CommentDialog, ShareButton, Cropper //, TiptapVuetify
    },
    computed: {
        ...mapGetters({
            deleteError: 'photograph/del/error',
            error: 'photograph/show/error',
            isLoading: 'photograph/show/isLoading',
            item: 'photograph/show/retrieved',
            personItem: 'person/show/retrieved',
            settings: 'settings/items',
            token_data: 'user/token/token_data',
            commentDeletedItem: 'comment/del/deleted',
            commentError: 'comment/list/error',
            commentItems: 'comment/list/items',
            commentIsLoading: 'comment/list/isLoading',
            commentView: 'comment/list/view',
            commentCreated: 'comment/create/created',
            commentRetrieved: 'comment/update/retrieved',
            commentUpdated: 'comment/update/updated',
            categories: 'category/list/items',
            products: 'product/list/items',
        }),
        attribution () {
            let attribution = typeof this.item !== 'undefined' && typeof this.item.collection !== 'undefined' ? this.item.collection.headline : ''
            if (typeof this.item !== 'undefined' && typeof this.item.collection !== 'undefined' && this.item.collection.licenseName) {
                attribution += ', '
                attribution += this.item.collection.licenseUrl ? '<a href="' + this.item.collection.licenseUrl + '" rel="license noreferrer" target="_blank">' + this.item.collection.licenseName + '</a>' : this.item.collection.licenseName
            }
            //attribution+= ' | '+'&copy; <a href="https://nimmerso.com/">nimmerso.com</a>';
            return attribution
        },
        isAdmin () {
            return (this.token_data && this.token_data.roles.indexOf('ROLE_ADMIN') !== -1)
        },
        isEditor () {
            return (this.token_data && this.token_data.roles.indexOf('ROLE_EDITOR') !== -1)
        },
        isOwner () {
            return this.personItem && this.item && this.item.collection && this.item.collection.owner && this.personItem['@id'] === this.item.collection.owner && this.item['@type'] === 'https://schema.org/Photograph'
            //return (this.token_data && this.token_data.roles.indexOf('ROLE_EDITOR')!==-1);
        }

    },
    data () {
        return {
            /*tiptapExtensions: [
                Blockquote,
                Underline,
                Italic,
                Bold,
                ListItem,
                BulletList,
                OrderedList,
                HardBreak
            ],*/
            entrypoint: ENTRYPOINT,
            tilesurl: process.env.VUE_APP_TILES_URL,

            colorized: false,
            showBackside: false,
            toggleBacksideButtonIcon: null,
            imagegalleries: [],
            showInfo: true,
            showComments: false,
            showCommentDialog: false,
            newComment: {box: null, isPrivate: false},
            box: null,
            pathEditMode: false,
            textBoxPos: {x: null, y: null},
            tooltipBoxPos: {x: null, y: null},
            tooltipText: '',
            activeComment: null,
            filteredCommentItems: [],
            webpSupported: true,
            additionalPhotographsOpen: false,
            imageRotation: 0,
            showContrast: false,
            brightness: 0,
            contrast: 0,
            showToggleBacksideTooltip: false,
            showTextlayer: false,
            metaItem: {},
            metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
            metaDescription: 'Nimmerso ist dein multimedialer Reiseführer in die Vergangenheit',
            metaUrl: null,
            metaImage: null,
            metaHashtags: [],
            viewer: null,
            showFaceDialog: false,
            selectedFace: null,
            dragged: false,
            dragTimeout: null,
            hideGui: false,
            hideGuiTimeout: null,
            showCollectionInfoDialog: false,
            speedDialShow: false,
            selectSpeedDialShow: false,
            audioPlaying: false,
            audioObj: null,
            selectedGallery: null,
            gallerySelectDialog: false,
            selectedSlide: null,
            descriptionShowAll: false,
            isActive: false,
            showCommentLoginDialog: false,
            orderItemsDrawer: false,
            cropperModal: false,
            cropperRatio: 1,
            defaultPosition: null,
            cropperImage: {},
            isMobile: false,
            selectedProduct:null,
            productConfig: null,
            dpi: 0,
            evaluation: 'red'
        }
    },
    watch: {
        personItem: function () {
            this.imagegalleries = this.personItem.imageGalleries
        },
        item: function () {
            if (this.item === [] || this.item === null) return
            if (this.viewer !== null) this.viewer.destroy()
            this.metaItem = this.item
            this.metaTitle = this.getTranslation(this.metaItem).name + ' - Nimmerso'
            this.metaDescription = this.htmlAsText(this.getTranslation(this.metaItem).description + ' © ' + this.attribution)
            if (this.showBackside) {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.backFilePath
            } else {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.filePath
            }
            if(this.item['backFilePath']) this.toggleBacksideButtonIcon =this.entrypoint+'/thumbnail/'+'icon/'+(this.showBackside ? this.item['filePath'] : this.item['backFilePath']).replace(/\.jpeg/,(this.webpSupported ? '.webp' : '.jpeg'))
            this.metaUrl = this.getCompleteUrl()
            this.metaHashtags = []
            if (this.categories) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            if (this.item.isBlackAndWhite === false) {
                this.colorized = false
            }
            this.$nextTick(() => {
                let options = {
                    id: 'imageviewer',
                    tileSources: [],
                    maxZoomLevel: 20,
                    crossOriginPolicy: 'Anonymous',
                    minZoomLevel: 0.3,
                    showNavigator: false,
                    visibilityRatio: 1.0,
                    constrainDuringPan: true,
                    //navigatorPosition: 'TOP_LEFT',
                    showNavigationControl: false,
                    homeFillsViewer: window.innerWidth < 600 && !this.showBackside,
                    degrees: this.imageRotation,
                    gestureSettingsTouch: {
                        pinchRotate: false
                    },
                    debugMode: false,
                    immediateRender: true
                }
                this.viewer = OpenSeadragon(options)
                this.viewer.addHandler('canvas-click', (evt) => {
                    if (evt.quick) {
                        //window.console.log('toggleGui',this.hideGui);
                        this.hideGui = !this.hideGui
                    }
                })

                this.viewer.addHandler('canvas-key-down', (evt) => {
                    //[ - / _, shift+W, shift+up arrow ] - zoom viewport out
                    //[ = / +, shift+S, shift+down arrow ] - zoom viewport in
                    evt.preventDefault = true // disable default keyboard controls
                    evt.preventFlip = true // disable vertical panning with arrows and W or S keys
                    evt.preventRotation = true // disable horizontal panning with arrows and A or D keys
                    evt.originalEvent.stopPropagation()
                    evt.originalEvent.preventDefault()
                    if (evt.originalEvent.key == 'f') return
                    if (evt.originalEvent.key == 'r') return
                })
                this.viewer.addHandler('canvas-key', (evt) => {
                    //[ - / _, shift+W, shift+up arrow ] - zoom viewport out
                    //[ = / +, shift+S, shift+down arrow ] - zoom viewport in
                    evt.preventDefault = true // disable default keyboard controls
                    evt.preventFlip = true // disable vertical panning with arrows and W or S keys
                    evt.preventRotation = true // disable horizontal panning with arrows and A or D keys
                    evt.originalEvent.stopPropagation()
                    evt.originalEvent.preventDefault()
                    if (evt.originalEvent.key == 'f') return
                    if (evt.originalEvent.key == 'r') return
                    if (
                        evt.originalEvent.type !== 'keydown' || (
                            evt.originalEvent.key !== '-' &&
                            evt.originalEvent.key !== '_' &&
                            !(evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) &&
                            !(evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true) &&

                            evt.originalEvent.key !== '+' &&
                            evt.originalEvent.key !== '=' &&
                            !(evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) &&
                            !(evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                        )
                    ) {
                        return
                    }
                    //Zoom-In:
                    if (
                        evt.originalEvent.key === '+' ||
                        evt.originalEvent.key === '=' ||
                        (evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) ||
                        (evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                    ) {
                        this.viewer.viewport.zoomBy(2)
                        this.viewer.viewport.applyConstraints()
                    }
                    //Zoom-Out:
                    if (
                        evt.originalEvent.key === '-' ||
                        evt.originalEvent.key === '_' ||
                        (evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) ||
                        (evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true)
                    ) {
                        this.viewer.viewport.zoomBy(0.5)
                        this.viewer.viewport.applyConstraints()
                    }

                    evt.preventDefault = true
                    //window.console.log(evt)

                })
                this.updateViewer()
                if (document.querySelector('.additionalPhotographsContainer')) {
                    document.querySelector('.additionalPhotographsContainer').addEventListener('transitionend', (evt) => {
                        if (document.querySelector('.additionalPhotographsContainer') !== evt.target) return
                        evt.target.scrollLeft = -1000
                        //window.console.log(evt)
                    }, false)
                }
            })

        },

        categories: function () {
            this.metaHashtags = []
            if (this.item) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
        },
        $route: function () {
            this.init()
        },
        showCommentDialog: function () {
            //window.console.log('showCommentDialog watch', this.showCommentDialog)
            if (this.showCommentDialog === false && this.pathEditMode === false) this.commentDialogClose()
        },
        commentCreated: function () {
            this.getComments('/comments?photograph=' + this.$route.params.id)
        },
        commentItems: function () {
            this.filterCommentItems()
            //window.console.log()
            if (this.viewer) this.addRects()
        },
        showBackside: function () {
            this.filterCommentItems()
            if (this.showBackside) {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.backFilePath
            } else {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.filePath
            }
            if(this.item['backFilePath']) this.toggleBacksideButtonIcon =this.entrypoint+'/thumbnail/'+'icon/'+(this.showBackside ? this.item['filePath'] : this.item['backFilePath']).replace(/\.jpeg/,(this.webpSupported ? '.webp' : '.jpeg'))
            this.updateViewer()
            if (this.filteredCommentItems) {
                this.filteredCommentItems.forEach((commentItem) => {
                    if (commentItem.box !== null) {
                        this.addCommentRect(commentItem.id, commentItem.box, commentItem.text, commentItem.faceId)
                    }
                })
            }
            this.zoomToActiveCommentRect()
        },
        showTextlayer: function () {
            let commentOverlays = document.getElementsByClassName('commentOverlay')
            if (this.showTextlayer) {
                commentOverlays.forEach((commentOverlay) => {
                    commentOverlay.classList.remove('commentHidden')
                })
            } else {
                commentOverlays.forEach((commentOverlay) => {
                    commentOverlay.classList.add('commentHidden')
                })
            }

        },
        contrast: function () {
            this.setTilesFilter()
        },
        brightness: function () {
            this.setTilesFilter()
        },
        imageRotation: function () {
            this.viewer.degrees = this.imageRotation
            this.viewer.viewport.setRotation(this.imageRotation)
        },
        colorized: function () {
            this.updateViewer()
        },
        hideGui: function () {
            let commentOverlays = document.getElementsByClassName('commentOverlay')
            commentOverlays.forEach((commentOverlay) => {
                commentOverlay.style.opacity = this.hideGui ? '0' : '1'
                commentOverlay.style.transition = this.hideGui ? 'opacity 500ms' : 'opacity 500ms'
            })
            const feedbackButton = document.querySelector('.feedbackButton')
            if (feedbackButton) {
                feedbackButton.style.opacity = this.hideGui ? '0' : '1'
                feedbackButton.style.transition = 'opacity 500ms'
            }
            let photographShowInfoOverlay = document.querySelector('.photographShowInfo')
            if (photographShowInfoOverlay) {
                photographShowInfoOverlay.style.opacity = this.hideGui ? '0' : '1'
                photographShowInfoOverlay.style.transition = 'opacity 500ms'
            }

        },
        /*showInfo: function(){
            this.showInfo=true;
        }*/

    },

    beforeDestroy () {
        this.reset()
        this.isActive = false
        if (typeof window !== 'undefined') window.removeEventListener('resize', this.onResize, {passive: true})
    },
    deactivated () {
        //window.console.log('deactivated');
        this.isActive = false
    },

    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                {name: 'description', content: this.metaDescription},

                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: this.metaTitle},
                {name: 'twitter:description', content: this.metaDescription},
                // image must be an absolute path
                {name: 'twitter:image', content: this.metaImage},

                {property: 'og:title', content: this.metaTitle},
                {property: 'og:site_name', content: 'Nimmerso'},
                {property: 'og:description', content: this.metaDescription},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: this.metaUrl},
                {property: 'og:image', content: this.metaImage}
            ]
        }
    },

    created () {

        this.isActive = true
        this.getCategories()
        const categoryIds = ['/product_categories/64e76ea0a2ec3600ce54b9e3']
        this.getProducts('products?productCategories[]=' + categoryIds.join('&productCategories[]='))
        this.webpSupported = isWebpSupported()
        this.onResize()
        window.addEventListener('resize', this.onResize, {passive: true})
        this.init()

    },
    methods: {
        ...mapMutations({
            setError: 'photograph/show/PHOTOGRAPH_SHOW_SET_ERROR',
            setsearchfilters: 'searchfilters/setsearchfilters',
            setsettings: 'settings/setsettings'
        }),
        ...mapActions({
            del: 'photograph/del/del',
            reset: 'photograph/show/reset',
            retrieve: 'photograph/show/retrieve',
            updateImageGallery: 'imagegallery/update/update',
            updateRetrievedImageGallery: 'imagegallery/update/updateRetrieved',
            retrieveImageGallery: 'imagegallery/update/retrieve',
            personRetrieve: 'person/show/retrieve',
            gettokendata: 'user/token/gettokendata',
            gettoken: 'user/token/gettoken',
            createComment: 'comment/create/create',
            getComments: 'comment/list/default',
            getCategories: 'category/list/default',
            getProducts: 'product/list/default',
        }),

        setRedirectRoute(route) {
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
        },
        goToStart(){
            this.showLoginDialog=false;
            this.setRedirectRoute(this.$route.fullPath);
            this.$router.push({path: '/login'})
        },
        init () {
            if (!this.isActive) return
            this.getComments('/comments?photograph=' + this.$route.params.id)
            this.retrieve(decodeURIComponent(this.$route.params.id))
            if (this.token_data && this.token_data.person_id !== null) {
                //window.console.log(this.token_data)
                this.personRetrieve('/people/' + this.token_data.person_id)
            } else {
                window.console.log('no tokenData!')
            }

            //this.colorized = this.settings.showColorized
            this.colorized = this.$route.path.indexOf('/color') !== -1
            if (
                this.$route.name.indexOf('PhotographShowBack') > -1
            ) {
                this.showBackside = true
            } else {
                this.showBackside = false
            }

            this.showTextlayer = typeof this.settings.showTextlayer !== 'undefined' ? this.settings.showTextlayer : false

        },

        updateViewer () {
            if (!this.isActive) return
            if (!this.item) return
            //window.console.log('showBackside', this.showBackside)
            let size = this.showBackside ? this.item.backSize : this.item.size
            this.imageWidth = size[0]
            this.imageHeight = size[1]
            this.maxLevel = size[0] / 256
            this.maxLevel = Math.ceil(Math.log(Math.max(this.imageWidth, this.imageHeight) / 256))
            this.viewer.clearOverlays()
            this.viewer.addHandler('open', () => {
                this.addRects()
            })
            this.viewer.open(
                {
                    tileSize: 256,
                    minLevel: 0,
                    maxLevel: this.maxLevel,
                    width: this.imageWidth, //6400 //6515
                    height: this.imageHeight, //4096 //4116
                    getTileUrl: (level, x, y) => {
                        return this.tilesurl + (!this.showBackside ? (this.colorized ? 'colorized/' : '') + this.item['filePath'] : this.item['backFilePath']) + '/' + level + '-' + x + '-' + y + (this.webpSupported ? '.webp' : '.jpeg')
                    },
                    /*overlays: [{
                        id: 'example-overlay',
                        px: 100,
                        py: 100,
                        width: 500,
                        height: 100,
                        className: 'commentOverlay'
                    }],*/
                }
            )

            //this.viewer.setMaxZoom(this.maxLevel);

        },
        zoomToActiveCommentRect () {
            //window.console.log('zoomToActiveCommentRect', this.activeComment)
            this.$nextTick(() => {
                if (this.activeComment !== null) {
                    //window.console.log('rect_' + this.activeComment)
                    let commentOverlay = this.viewer.getOverlayById('rect_' + this.activeComment)

                    if (commentOverlay) {
                        //window.console.log('zoomToActiveCommentRect, commentOverlay: ', commentOverlay.getBounds(this.viewer.viewport))
                        this.viewer.viewport.fitBoundsWithConstraints(commentOverlay.getBounds(this.viewer.viewport), true)
                    }

                    let commentItem = this.commentItems.filter((cI) => cI.id === this.activeComment)[0]

                    this.metaTitle = this.getTranslation(this.metaItem).name + ' - Nimmerso'
                    this.metaDescription = this.htmlAsText(commentItem.text) + this.htmlAsText(this.getTranslation(this.metaItem).description + ' © ' + this.attribution)
                    this.metaImage = this.entrypoint + commentItem['@id'] + '/showThumbnail/display.jpeg'
                    //this.metaUrl = this.getCompleteUrl()

                }
            })

        },
        addCommentRect (id, box, commentText, faceId) {
            if (faceId) {
                let faceElt = document.getElementById('facerect_' + faceId)
                if (faceElt) {
                    faceElt.parentNode.removeChild(faceElt)
                }
            }

            let elt = document.querySelector('div.commentOverlayOrig').cloneNode(false)
            elt.style.display = 'block'
            elt.id = 'rect_' + id
            elt.ref = id
            //this.$refs[id]=elt;

            //elt.innerText+=commentText;
            let rect = typeof box.x == 'undefined' ? new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], box[4]) : new OpenSeadragon.Rect(box.x, box.y, box.width, box.height, box.degrees)
            //window.console.log(rect)
            this.viewer.addOverlay({
                element: elt,
                location: rect,

            })
            elt.classList.remove('commentOverlayOrig')
            elt.classList.remove('commentEditable')
            elt.classList.add('commentOverlay')
            if (!this.showTextlayer) elt.classList.add('commentHidden')
            elt.innerHTML = '<div class="textDiv">' + commentText + '</div>'
            elt.onclick = (event) => {
                if (event.target.classList.contains('commentEditable')) return false
                if (event.target.id === 'rect_editboxe') return false
                this.showComments = true
                //let oldActiveComment=this.activeComment;
                this.activeComment = event.target.parentNode.id.split('_')[1]
                //if(oldActiveComment!==this.activeComment) {
                //window.console.log(event.target.parentNode, {id: this.item['@id'], comment_id: this.activeComment});
                this.$router.replace({
                    name: 'PhotographShowComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })

            }
            new OpenSeadragon.MouseTracker({
                element: elt,
                enterHandler: (event) => {
                    // Show tooltip
                    this.tooltipText = event.originalEvent.target.innerHTML
                    this.$nextTick(() => {
                        let tooltipBox = document.querySelector('.tooltipBox')
                        //window.console.log('tooltipBox', tooltipBox)
                        let mousex = event.originalEvent.pageX + 20, //Get X coodrinates
                            mousey = event.originalEvent.pageY + 20, //Get Y coordinates
                            tipWidth = tooltipBox.offsetWidth, //Find width of tooltip
                            tipHeight = tooltipBox.offsetHeight, //Find height of tooltip

                            //Distance of element from the right edge of viewport
                            tipVisX = window.innerWidth - (mousex + tipWidth),
                            //Distance of element from the bottom of viewport
                            tipVisY = window.innerHeight - (mousey + tipHeight)

                        if (tipVisX < 20) { //If tooltip exceeds the X coordinate of viewport
                            mousex = event.originalEvent.pageX - tipWidth - 20
                        }
                        if (tipVisY < 20) { //If tooltip exceeds the Y coordinate of viewport
                            mousey = event.originalEvent.pageY - tipHeight - 20
                        }

                        //window.console.log(tipWidth, tipHeight, tipVisX, tipVisY)

                        this.tooltipBoxPos = {x: mousex, y: mousey}
                        //window.console.log('show tooltip', event, this.tooltipBoxPos);
                    })
                },
                exitHandler: () => {
                    // Hide tooltip
                    this.tooltipText = ''
                    this.tooltipBoxPos = {x: null, y: null}
                    //window.console.log('hide tooltip',event);
                }
            }).setTracking(true)

        },
        addEditRect (id, box) {

            let elt = document.querySelector('div.commentOverlayOrig').cloneNode(true)
            elt.style.display = 'block'
            elt.id = 'rect_' + id
            elt.ref = id
            let rect = typeof box.x == 'undefined' ? new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], box[4]) : new OpenSeadragon.Rect(box.x, box.y, box.width, box.height, box.degrees)

            //elt.onclick=this.handlePathClick;
            this.viewer.addOverlay({
                element: elt,
                location: rect
            })
            elt.classList.add('commentEditable')
            elt.classList.remove('commentOverlayOrig')
            elt.classList.add('commentOverlay')
            this.calcTextBoxPos()

            new OpenSeadragon.MouseTracker({
                element: elt,
                dragHandler: (e) => {

                    let overlay = this.viewer.getOverlayById(e.eventSource.element.id)
                    window.overlay = overlay
                    if (!overlay.element.classList.contains('commentEditable')) return false
                    let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                    overlay.position.x += delta.x
                    overlay.position.y += delta.y
                    this.viewer.forceRedraw()
                    this.calcTextBoxPos()
                },
                //dragEndHandler: () => {window.console.log('dragEndHandler')},
            })
            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.handletl'),
                dragHandler: (e) => {
                    let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                    if (!overlay.element.classList.contains('commentEditable')) return false
                    let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                    overlay.position.x += delta.x
                    overlay.position.y += delta.y
                    overlay.width -= delta.x
                    overlay.height -= delta.y
                    this.viewer.forceRedraw()
                    this.calcTextBoxPos()
                },
                //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
            })
            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.handletr'),
                dragHandler: (e) => {
                    let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                    if (!overlay.element.classList.contains('commentEditable')) return false
                    let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                    overlay.position.y += delta.y
                    overlay.width += delta.x
                    overlay.height -= delta.y
                    this.viewer.forceRedraw()
                    this.calcTextBoxPos()
                },
                //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
            })

            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.handlebl'),
                dragHandler: (e) => {
                    let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                    if (!overlay.element.classList.contains('commentEditable')) return false
                    let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                    overlay.position.x += delta.x
                    overlay.width -= delta.x
                    overlay.height += delta.y
                    this.viewer.forceRedraw()
                    this.calcTextBoxPos()
                },
                //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
            })
            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.handlebr'),
                dragHandler: (e) => {
                    let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                    if (!overlay.element.classList.contains('commentEditable')) return false
                    let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                    overlay.width += delta.x
                    overlay.height += delta.y
                    this.viewer.forceRedraw()
                    this.calcTextBoxPos()
                },
                //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
            })
            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.commentOverlayOk'),
                clickHandler: (e) => {
                    e.originalEvent.preventDefault()
                    e.originalEvent.stopPropagation()
                    setTimeout(() => {
                        this.pathEditOk()
                    }, 500)
                }
            })
            new OpenSeadragon.MouseTracker({
                element: elt.querySelector('.commentOverlayAbort'),
                clickHandler: () => {
                    this.abortPathEdit()
                }
            })
        },

        addFacesRect (id, box) {

            let elt = document.querySelector('div.commentOverlayOrig').cloneNode(false)
            elt.style.display = 'block'
            elt.id = 'facerect_' + id
            elt.ref = id

            let rect = new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], 0)
            //window.console.log(rect)

            this.viewer.addOverlay({
                element: elt,
                location: rect
            })
            elt.classList.remove('commentOverlayOrig')
            elt.classList.remove('commentEditable')
            elt.classList.add('commentOverlay')
            if (!this.showTextlayer) elt.classList.add('commentHidden')
            elt.innerHTML = '<div class="textDiv">?</div>'

            //window.console.log(elt)

            elt.onclick = (event) => {
                if (event.target.classList.contains('commentEditable')) return false
                if (event.target.id === 'rect_editboxe') return false
                this.selectedFace = event.target.parentNode.id.split('_')[1]
                this.showFaceDialog = true
            }
        },
        editFace () {
            this.showFaceDialog = false
            //window.console.log('editFAce', this.selectedFace, event)
            const face = this.item.faces[this.selectedFace]
            const box = face.bounding_box
            const rect = new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], 0)
            this.showCommentDialog = true
            this.newComment = {
                faceId: this.selectedFace,
                box: rect,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
        },
        addRects () {
            //window.console.log('addRects')
            if (this.viewer !== null) this.viewer.clearOverlays()

            if (typeof this.$route.params.comment_id !== 'undefined') {
                this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                if (this.activeComment === false) this.activeComment = null
                this.showComments = true
                let commentItem = this.commentItems.filter((cI) => cI.id === this.activeComment)[0]
                if (commentItem) this.showBackside = commentItem.isBackside === true
                /*this.$nextTick(() => {
                    let top = 0
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        let vele = this.$refs['comment_' + this.activeComment][0]
                        top = vele.$el.offsetTop - 100
                        this.$refs['commentsContainer'].scrollTo(0, top)
                    }
                })*/

            }
            let commentedFaces = []
            this.filteredCommentItems.forEach((commentItem) => {
                if (commentItem.box !== null) {
                    if (commentItem.faceId !== null) commentedFaces.push(commentItem.faceId)
                    //window.console.log(commentItem)
                    this.addCommentRect(commentItem.id, commentItem.box, commentItem.text), commentItem.faceId
                }
            })

            if (!this.showBackside && this.item.faces !== null && this.item.faces.length > 0) {
                this.item.faces.forEach((face, id) => {
                    if (commentedFaces.indexOf(id) === -1) {
                        this.addFacesRect(id, face.bounding_box)
                    }

                })

            }

            this.zoomToActiveCommentRect()
        },

        calcTextBoxPos () {

            let overlay = this.viewer.getOverlayById('rect_editbox')
            //window.console.log('calcTextBoxPos', overlay.element)
            if (!overlay.element.classList.contains('commentEditable')) return false
            //let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true);
            let pathEditModeTextBox = document.querySelector('.pathEditModeTextBox')
            //window.console.log(pathEditModeTextBox)
            if (pathEditModeTextBox == null) return
            let y = overlay.element.offsetTop // + overlay.element.offsetHeight
            /*if (y < 0) {
                y = 0
            } else if (y + pathEditModeTextBox.offsetHeight > document.body.offsetHeight) {
                y = document.body.offsetHeight - pathEditModeTextBox.offsetHeight
            }*/
            let x = overlay.element.offsetLeft
            /*if (x < 0) {
                x = 0
            } else if (x + pathEditModeTextBox.offsetWidth > document.body.offsetWidth) {
                x = document.body.offsetWidth - pathEditModeTextBox.offsetWidth
            }*/

            this.textBoxPos = {
                x: x,
                y: y
            }
        },
        closeWin () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/feed')
            //this.isActive=false;
        },
        rotateImage () {
            this.imageRotation += 90
            if (this.imageRotation >= 360) this.imageRotation -= 360
        },

        onSendCommentForm (commentData) {
            this.newComment = commentData
            if (this.newComment.id == null) {
                this.create(this.newComment)
            } else {
                this.updateComment(this.newComment)
            }

        },

        updateCommentField (field, value) {
            //window.console.log(field, value)
            Object.assign(this.newComment, {[field]: value})
        },

        deleteItem (item) {
            if (window.confirm('Are you sure you want to delete this item?')) {
                this.del(item).then(() => this.$router.push({name: 'PhotographList'}).catch(err => {
                    window.console.log(err)
                }))
            }
        },
        toggleBackside () {
            //this.showBackside = !this.showBackside
            if (!this.showBackside) {
                this.$router.replace({
                    name: 'PhotographShowBack' + (this.colorized ? 'Color' : ''),
                    params: {id: this.$route.params.id}
                })
            } else {
                this.$router.replace({
                    name: 'PhotographShow' + (this.colorized ? 'Color' : ''),
                    params: {id: this.$route.params.id}
                })
            }
            //this.updateViewer()

            //this.calcMap()
        },

        selectGalery () {
            if (this.selectedGallery === null) return
            let imagegallery = null
            this.imagegalleries.forEach((item) => {
                if (item['@id'] === this.selectedGallery) {
                    imagegallery = item
                    return true
                }
            })

            this.addToGallery(imagegallery, this.item)

        },

        addToGallery (imagegallery, item) {
            imagegallery.photographs.push('/photographs/' + item['id'])
            imagegallery.photographs = [...new Set(imagegallery.photographs)]
            //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
            this.updateGallery(imagegallery)
        },

        getTranslation (item) {
            if (typeof item['translations'] === 'undefined') return {
                name: '-',
                description: '-'
            }
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            let translation = typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
            return (typeof translation === 'undefined') ? {name: '-', description: '-'} : translation
        },

        showGallerySelectDialog (slide) {
            this.selectedSlide = slide
            this.gallerySelectDialog = true
        },

        updateGallery (gallery) {
            let ig = JSON.parse(JSON.stringify(gallery))
            if (typeof ig.retrosnaps !== 'undefined') ig.retrosnaps = this.arrayToIris(ig.retrosnaps)
            if (typeof ig.subGalleries !== 'undefined') ig.subGalleries = this.arrayToIris(ig.subGalleries)
            if (ig.parentGalleries) delete ig.parentGalleries
            return fetch(ig['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(ig)
            })
                .then(response => response.json())
                .then(() => {
                    this.gallerySelectDialog = false
                    //window.console.log(data)
                })
                .catch((e) => {
                    this.$store.error = e.message
                    return e

                })
        },

        addComment () {
            if (this.token_data == null) {
                //not logged in:
                this.showCommentLoginDialog = true
                return
            }
            this.showComments = false
            this.showCommentDialog = true

            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
        },
        commentDialogClose () {
            //window.console.log('commentDialogClose')
            this.pathEditMode = false
            this.box = null
        },
        doCommentDialogClose () {
            this.showCommentDialog = false
            this.commentDialogClose()
        },
        editComment (commentItem) {
            //window.console.log('commentItem', commentItem)
            this.showComments = false
            this.newComment = commentItem
            this.showCommentDialog = true
        },
        deleteComment (commentItem) {
            if (window.confirm(this.$t('app.Comment.DeleteConfirm'))) {
                return fetch(commentItem['@id'], {
                    method: 'DELETE',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(commentItem)
                })
                    .then(() => {
                        this.getComments('/comments?photograph=' + this.$route.params.id)
                        //window.console.log('deleted')
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            }
        },
        reportInappropriateComment (commentItem) {
            window.console.log('reportInappropriateComment', commentItem)
        },
        addCommentToGallery (imagegallery, commentItem) {
            //commentItem.galleries.push(imagegallery['@id']);
            const galleries = [...commentItem.galleries, imagegallery['@id']].filter(function (item, pos, self) {
                return self.indexOf(item) === pos
            })
            //window.console.log(galleries)
            //this.updateCommentField('galleries',galleries)
            Object.assign(commentItem, {'galleries': galleries})
            this.updateComment(commentItem)
            /*imagegallery.photographComments.push('/comments/' + commentItem['id'])
            imagegallery.photographComments = [...new Set(imagegallery.photographComments)]
            this.updateGallery(imagegallery)*/
        },
        editRectangle () {
            this.viewer.clearOverlays()
            this.showCommentDialog = false
            this.pathEditMode = true
            this.box = this.newComment.box
            if (this.box == null) {

                let bounds = this.viewer.viewport.getBounds()
                window.bounds = bounds
                let center = this.viewer.viewport.getCenter()
                let w = bounds.width / 2
                let h = bounds.height / 2
                this.box = {
                    x: center.x - w / 2,
                    y: center.y - h / 2,
                    width: w,
                    height: h,
                    degrees: 0
                }
            }
            //window.console.log(this.box)

            this.addEditRect('editbox', this.box)
        },
        pathEditOk () {
            window.vvv = this.viewer
            this.box = this.viewer.getOverlayById('rect_editbox').getBounds(this.viewer.viewport)
            //window.console.log(this.box)

            this.showCommentDialog = true
            this.pathEditMode = false
            this.newComment.box = this.box
            this.viewer.getOverlayById('rect_editbox').element.classList.remove('commentEditable')
            //this.viewer.getOverlayById('rect_editbox').destroy();
            this.box = null

        },
        abortPathEdit () {
            this.showCommentDialog = false
            this.pathEditMode = false
            this.viewer.getOverlayById('rect_editbox').element.classList.remove('commentEditable')
            this.viewer.getOverlayById('rect_editbox').destroy()
            this.box = null
            this.viewer.clearOverlays()
            this.addRects()
            //window.console.log('abortPathEdit')
        },
        submitComment () {
            this.viewer.clearOverlays()
            this.showCommentDialog = false
            this.commentDialogClose()
            //window.console.log(this.newComment)
            if (this.newComment['@id']) {
                this.updateComment(this.newComment)
            } else {
                this.createComment(this.newComment)
            }

        },
        updateComment (comment) {

            return fetch(comment['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(comment)
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    window.console.error(e)
                    this.setError(e)
                    return e

                })
        },
        commentClick (commentItem) {
            //window.console.log(commentItem)
            //this.zoomToActiveCommentRect()
            if (this.activeComment === commentItem.id) return
            this.activeComment = commentItem.id
            //this.showBackside = commentItem.isBackside === true
            this.showTextlayer = true
            if (commentItem.isBackside) {
                this.$router.replace({
                    name: 'PhotographShowBackComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })
            } else {
                this.$router.replace({
                    name: 'PhotographShowComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })
            }

        },
        filterCommentItems () {

            this.filteredCommentItems = this.commentItems.filter((item) => (item.box && ((item.isBackside === true) === this.showBackside)))
            //window.console.log(this.showBackside, this.filteredCommentItems)

        },
        upvoteComment (commentItem) {
            //window.console.log('upvote')
            return fetch(commentItem['@id'] + '/vote/up', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        downvoteComment (commentItem) {
            //window.console.log('downvote')
            return fetch(commentItem['@id'] + '/vote/down', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        toggleAdditionalPhotographsOpen (target) {
            if (this.item['additionalPhotographs'].length + this.item['retrosnaps'].length === 1) {
                this.additionalPhotographsOpen = true
            }
            if (this.additionalPhotographsOpen) {
                let targetId = target['@id'] //encodeURIComponent(target['@id'])
                if (target['@id'].indexOf('/retrosnaps/') > -1) {
                    this.$router.push({name: 'RetrosnapShow' + (this.colorized ? 'Color' : ''), params: {id: targetId}})
                } else {
                    this.$router.push({
                        name: 'PhotographShow' + (this.colorized ? 'Color' : ''),
                        params: {id: targetId}
                    })
                }

            } else {
                this.additionalPhotographsOpen = true
            }
        },
        toggleTextlayer () {
            this.showTextlayer = !this.showTextlayer
            this.setsettings({showTextlayer: this.showTextlayer})
            this.$store.commit('settings/setsettings', {showTextlayer: this.showTextlayer})
        },
        closeAdditionalPhotographs () {
            if (!this.dragged) {
                this.additionalPhotographsOpen = false
            }
        },
        setTilesFilter: function () {
            //window.console.log(this.brightness, ((this.contrast + 100) / 200) * 2)
            this.viewer.setFilterOptions({
                filters: {
                    processors: [
                        OpenSeadragon.Filters.BRIGHTNESS((this.brightness * 2)),
                        OpenSeadragon.Filters.CONTRAST(((this.contrast + 100) / 200) * 2)
                    ]
                }
            })
            //document.querySelectorAll('.leaflet-tile').forEach((item) => { item.style.filter = 'contrast(' + (this.contrast / 100 + 1) + ') brightness(' + (this.brightness / 100 + 1) + ')' })
        },
        getCompleteUrl: function () {
            return window.location.origin + this.$route.fullPath
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },
        onDragStart () {
            clearTimeout(this.dragTimeout)

            this.dragged = false
            this.dragTimeout = setTimeout(() => { this.dragged = true }, 100) // Minimal delay to be regarded as drag instead of click
        },
        onDragClick (e) {
            if (this.dragged) {
                e.preventDefault()
                e.stopPropagation()
            }

            this.dragged = false
        },

        showPhotosFromCollection () {
            this.$router.push({name: 'PhotographCollectionShow', params: {id: this.item.collection['@id']}})
            return false
        },
        toggleVoice () {

            if (!this.audioPlaying) {
                //audio starten:
                if (this.audioObj === null) {
                    this.audioObj = new Audio(this.entrypoint + '/getAudio/' + this.$root.$i18n.locale + '/' + this.item['@id'])
                    this.audioObj.addEventListener('canplaythrough', event => {
                        event.target.play()
                        this.audioPlaying = true
                    })
                    this.audioObj.addEventListener('ended', () => {
                        this.audioPlaying = false
                    })
                } else {
                    this.audioObj.play()
                    this.audioPlaying = true
                }
            } else {
                //audio stoppen:
                this.audioObj.pause()
                this.audioPlaying = false
            }

        },
        toggleColorized () {
            let routeName = 'PhotographShow'
            let routeParams = this.$route.params
            if (this.showBackside) routeName += 'Back'
            if (this.activeComment) {
                routeName += 'Comment'
            }
            if (this.colorized) {
                this.$router.replace({name: routeName, params: routeParams})
            } else {
                this.$router.replace({name: routeName + 'Color', params: routeParams})
            }

        },
        selectSection () {
            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        selectPerson () {

            let category = this.categories.filter((category) => category.name == 'Person')
            //window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                category: category[0]['@id'],
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        selectBuilding () {
            let category = this.categories.filter((category) => category.name == 'Gebäude')
            //window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                category: category[0]['@id'],
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        selectText () {
            let category = this.categories.filter((category) => category.name == 'Text')
            //window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                category: category[0]['@id'],
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        toggleOrderItems () {
            this.orderItemsDrawer = true
        },
        /*getStencilCoordinates () {
            const factor = this.item.ratio < 1 ? this.item.size[1] / 1024 : this.item.size[0] / 1024
            if (!this.item.thumbnailCoordinates || !this.item.thumbnailCoordinates[this.editThumbnailType]) return null
            return {
                left: this.item.thumbnailCoordinates[this.editThumbnailType].left / factor * this.item.size[0],
                top: this.item.thumbnailCoordinates[this.editThumbnailType].top / factor * this.item.size[0],
                width: this.item.thumbnailCoordinates[this.editThumbnailType].width / factor * this.item.size[0],
                height: this.item.thumbnailCoordinates[this.editThumbnailType].height / factor * this.item.size[0]
            }
        },*/
        onProductSelect (product, productConfig) {
            //window.console.log(product, productConfig)
            this.cropperModal = true
            this.cropperRatio = 1
            //window.console.log(product.additionalAttributes.files[0])
            if (product.additionalAttributes.files[0]) {
                this.cropperRatio = product.additionalAttributes.files[0].width / product.additionalAttributes.files[0].height
            }
            this.selectedProduct=product;
            this.productConfig=productConfig;
            this.$nextTick(() => {
                this.$refs.cropper.refresh();
            });
            /*
                        const coords = this.getStencilCoordinates()
                        if (coords != null) {
                            this.defaultPosition = {
                                left: coords.left,
                                top: coords.top,
                            }
                            this.defaultSize = {
                                width: coords.width,
                                height: coords.height,
                            }
                        }
                        */

        },
        onCropperChange () {

        },
        calcDpi({ coordinates }) {
            //console.log('Coordinates was changed', coordinates, canvas, this.item.size,this.selectedProduct.additionalAttributes.files[0]);
            //let imageWidth=this.$refs.cropper.imageAttributes.width;
            let factor =  this.item.size[0] /this.$refs.cropper.imageAttributes.width;
            //console.log(imageWidth,factor);
            this.dpi= (coordinates.width*factor)/(this.selectedProduct.additionalAttributes.files[0].width*0.0393701);
            //window.console.log('dpi',this.dpi);
            let mmcount=this.selectedProduct.additionalAttributes.files[0].width*this.selectedProduct.additionalAttributes.files[0].height;
            //window.console.log('mmcount', mmcount);
            this.evaluation='red';
            if (mmcount< 210*297) { // < A4
                this.evaluation = this.dpi < 300 ? 'red' : 'green';
            } else if (mmcount< 420 * 594) { // < A2
                this.evaluation = this.dpi < 100 ? 'red' : 'green';
            } else if (mmcount< 594 * 841) { // < A1
                this.evaluation = this.dpi < 50 ? 'red' : 'green';
            } else if (mmcount< 841 * 1189) { // < A0
                this.evaluation = this.dpi < 20 ? 'red' : 'green';
            } else{ // < A0
                this.evaluation = this.dpi < 10 ? 'red' : 'green';
            }
            //window.console.log('evaluation', this.evaluation)


            /**
             * < A4 300 dpi   A4 = 210 x 297 mm = 62.370        sqrt=
             * < A2 100 dpi   A2 = 420 x 594 mm = 249.480       sqrt=499
             * < A1 50 dpi    A1 = 594 x 841 mm = 499.554       sqrt=706
             * > A0 20 dpi    A0 = 841 x 1189 mm = 999.949       sqrt=999
             */


        },
        changeCropper () {
            const {coordinates, canvas} = this.$refs.cropper.getResult()

            window.console.log('changeCropper', coordinates, canvas.width, canvas.height)
            this.calcDpi();

            this.cropperImage = canvas.toDataURL()
            this.cropperModal = false
        },
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        defaultSize ({imageSize, visibleArea}) {
            return {
                width: (visibleArea || imageSize).width,
                height: (visibleArea || imageSize).height,
            }
        }
    }
}
</script>
