<template>
    <v-dialog v-if="order" v-model="order" content-class="orderDialog" fullscreen
              transition="fade-transition">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"

            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>

        <v-card
            class="mx-auto"
            v-if="order"

        >
            <v-tooltip left v-if="order">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>
                <template
                    v-if="order && $route.name==='OrderCreated' && order.paymentStatus!=='paid'"
                >       <div class="fill-cover">
                            <img
                                class="align-end text-white payment-cover-img"
                                :src="require('@/assets/img/Auf_dem_Schulweg_1931.jpg')"
                            />
                        </div>
                        <div
                            class="text-center py-3 thanks-msg"
                            v-if="order && orderPaymentMethod && $route.name==='OrderCreated' && order.paymentStatus!=='paid'  && orderPaymentMethod.method!=='banktransfer'"
                        >
                            Danke, dass du dich für den Erhalt dieser alten Schätze einsetzt!
                        </div>
                        <div
                            v-if="order && orderPaymentMethod && order.paymentStatus!=='paid' && orderPaymentMethod.method!=='banktransfer'"
                            class="text-center py-2 warning-msg"
                        >
                            Die Zahlung ist noch nicht abgeschlossen!
                        </div>
                        <div
                            v-if="order && orderPaymentMethod && order.paymentStatus!=='paid' && orderPaymentMethod.method==='banktransfer'"
                            class="text-left  mb-1 open-payment-text"
                        >
                            Du hast für diese Sammlung am
                            <span class="font-weight-bold">{{ $d(Date.parse(order.dateCreated),'long') }}</span> eine Unterstützung
                            <br>
                            für
                            <span class="font-weight-bold"> € {{ Number(order.priceWithVat).toLocaleString() }} </span>
                            in Auftrag gegeben. Falls du die Bezahlung ausgeführt hast,
                            <br>
                            kann es noch ein paar Tage dauern, bis das Geld ankommt.
                            <br>
                            Du solltest dann eine Benachrichtigung an {{ orderPaymentMethod.paymentData.details?.billingEmail || order.billingAddress?.email }} erhalten.
                            <div class="text-center">
                                <v-btn
                                    class="text-capitalize order-show-button-bg mt-3"
                                    elevation="8"
                                    @click.stop="dialog = true"
                                >
                                    <span class="order-button-first-word"> {{ $t('app.Order.Order') }}</span>
                                    <span class="order-button-second-word text-lowercase"> {{ $t('app.Order.Show') }}</span>
                                </v-btn>

                                <!-- Dialog -->
                                <v-dialog v-model="dialog" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">Order Details</v-card-title>
                                        <v-list>

                                            <v-list-item
                                                v-for="(item, i) in orderPaymentMethod.paymentData.details"
                                                :key="i"
                                                :value="item"
                                                color="deep-purple-lighten-5"
                                                rounded="xl"
                                            >

                                                <v-list-item-title v-text="i"></v-list-item-title>

                                                <v-list-item-title v-text="item"></v-list-item-title>
                                                <v-list-item-action v-if="i=='transferReference'">
                                                    <v-btn @click="copyToClipboard(item)" icon>
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                                        </v-card-actions>
                                        <v-footer class="justify-center">{{ $t('app.Order.ContactUs') }}
                                            <address class="pl-2">
                                                <a :href="mailtoLink"> support@nimmerso.com</a>
                                                <v-list-item-action>
                                                    <v-btn @click="copyToClipboard('support@nimmerso.com')" icon>
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </address>
                                        </v-footer>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </div>

<!--                    Currently, this route is not ready.
                       Add collection list UI here

-->
<!--                        <div v-if="collections" class="collection-choose">-->
<!--                            <p>-->
<!--                                Falls du Nimmerso jetzt nochmal unterstützen willst, darfst du eine-->
<!--                                <br>der-->
<!--                                <a  class="other-collections">-->
<!--                                    anderen Sammlungen-->
<!--                                </a>-->
<!--                                aussuchen.-->
<!--                            </p>-->
<!--                        </div>-->
                        <div class="mt- d-flex align-items-center justify-center" v-if="order && orderPaymentMethod && order.paymentStatus!=='paid'  && orderPaymentMethod.method!=='banktransfer'">
                                <v-btn
                                    class="text-capitalize cancel-button-bg mt-3"
                                    large
                                    elevation="4"
                                    @click.stop="canceldialog = true"
                                >
                                    {{ $t('app.Order.CancelPayment') }}
                                </v-btn>

                            <v-dialog v-model="canceldialog" max-width="500">
                                <v-card>
                                    <v-card-title class="text-h6">Stornierung bestätigen</v-card-title>
                                    <v-card-text>Deine Unterstützung wird hiermit storniert und abgebrochen.</v-card-text>
                                    <v-card-actions>
                                        <v-btn class="order-cancel" text @click="canceldialog = false">Zurück</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn color="darken-1" text @click="confirmCancelPayment">Ja, stornieren!</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                                <img
                                    class="payment-hat"
                                    :src="require('@/assets/img/Hat_fancy_sm.svg')"
                                    alt=""
                                />
                                <v-btn
                                    class="pl-6 mt-3 text-capitalize pay-button-bg"
                                    density="comfortable"
                                    elevation="4"
                                    large
                                    @click="onDoPayment"
                                >
                                    {{ $t('app.Order.DoPayment') }}
                                </v-btn>
                        </div>
                        <div class="d-flex justify-center row no-gutters">
                            <v-col class="px-2 "  sm="8" md="4" >
                                <div class="d-flex justify-space-between my-3">
                                    <div class="font-weight-bold">
                                        {{ $t('app.Order.ContributionSummary') }}
                                    </div>

                                </div>
                                <div class="d-flex justify-space-between align-center">
                                    <div>
                                        <v-avatar >
                                            <v-img
                                                :src="'/favicon.ico'"
                                                aspect-ratio="1"
                                            ></v-img>
                                        </v-avatar>
                                        {{ getTranslation(order.items[0].product).name }}
                                    </div>

                                </div>

                                <v-list class="modal-order-list">
                                    <v-list-item>
                                        <v-list-item-title>
                                            {{ $t('app.Order.Subtotal') }}
                                        </v-list-item-title>
                                        <div>
                                            <div class="text-right text-no-wrap">€ {{ Number(order.priceWithVat).toLocaleString() }}

                                            </div>
                                        </div>


                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>{{ $t('app.Order.Shipping') }}
                                        </v-list-item-title>
                                        <div>
                                            <div class="text-right text-no-wrap">
                                                {{'€ '+ order.shippingPrice === 0 ? $t('app.Order.Free') : Number(order.shippingPrice).toLocaleString() }}
                                            </div>
                                        </div>

                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>{{ $t('app.Order.Total') }}
                                        </v-list-item-title>
                                        <div>
                                            <div class="text-right text-no-wrap">€ {{ Number(order.priceTotal).toLocaleString() }}
                                            </div>
                                        </div>


                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </div>
                </template>
            <v-row class="no-gutters" >
                <v-col
                    cols="12"
                    sm="8"

                >
                    <v-card-title class="text-justify"
                               v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'"
                >
                    Vielen Dank!

                </v-card-title>
                    <v-card-title class="text-justify"
                                  v-if="order && $route.name==='OrderShow'"
                    >
                        Zusammenfassung deiner Bestellung
                    </v-card-title>
                    <v-card-subtitle v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'">Du erhältst in kürze eine E-Mail auf {{ order.billingAddress.email }} mit einer
                        Zusammenfassung deiner Bestellung.
                    </v-card-subtitle>

                    <v-list-item two-line v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'">
                        <v-list-item-content v-if="photographcollection">
                            <v-list-item-title>{{ $t('app.Order.ContributedTo') }}</v-list-item-title>
                            <v-list-item-subtitle>{{ photographcollection.headline }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-row v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'">
                        <v-col cols="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('app.Order.OrderNumber') }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ order.orderNumber }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="6">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('app.Order.OrderStatus') }}</v-list-item-title>
                                <v-list-item-subtitle>{{ order.status }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-col>
                    </v-row>
                    <v-row v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'">
                        <v-col cols="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('app.BillingAddress') }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="order.billingAddress.organizationName">
                                        {{ order.billingAddress.organizationName }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ order.billingAddress.givenName }}
                                        {{ order.billingAddress.familyName }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{
                                            order.billingAddress.streetAddress
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ order.billingAddress.addressCountry }}
                                        {{ order.billingAddress.postalCode }} {{ order.billingAddress.addressLocality }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>P: {{
                                            order.billingAddress.phoneNumber
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>E: {{ order.billingAddress.email }}</v-list-item-subtitle>


                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="6">
                            <v-list-item two-line v-if="order.shippingAddressNeeded">
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('app.ShippingAddress') }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ order.shippingAddress }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    v-if="order && $route.name==='OrderCreated' && order.paymentStatus==='paid'"
                    cols="12"
                    sm="4"
                >
                    <v-card-title>{{ $t('app.Order.ContributionSummary') }}</v-card-title>
                    <v-list-item :key="i" v-for="(item,i) in order.items">
                        <v-list-item-avatar v-if="item.product.images">
                            <v-img
                                :src="entrypoint+'/media/photographs/'+item.product.images[0]"
                            ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ getTranslation(item.product).name }}
                            <div class="text-right text-nowrap">€ {{ Number(item.priceWithVat).toLocaleString() }} </div>
                        </v-list-item-title>

                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>{{ $t('app.Order.Subtotal') }}
                            </v-list-item-title>
                            <div>
                                <div class="text-right text-no-wrap">€ {{ Number(order.priceWithVat).toLocaleString() }}

                                </div>
                            </div>

                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title>{{ $t('app.Order.Shipping') }}
                            </v-list-item-title>
                            <div>
                                <div class="text-right text-no-wrap">
                                    € {{ order.shippingPrice === 0 ? $t('app.Order.Free') : Number(order.shippingPrice).toLocaleString()  }}
                                </div>
                            </div>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('app.Order.Total') }}
                            </v-list-item-title>
                            <div>
                                <div class="text-right text-no-wrap">€ {{ Number(order.priceTotal).toLocaleString() }}
                                </div>
                            </div>


                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

        </v-card>
    </v-dialog>
</template>
<style>
    @import "../../styles/custom-scrollbar.css";
    .fill-cover{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .open-payment-text{
        max-width:600px;
        margin:auto;
        background-color:#282c38;
        padding:20px;
    }

    .collection-choose{
        max-width:600px;
        margin:auto;
        padding:20px;
        color:#e0d9d3;
    }
    .payment-cover-img{
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: 350px;
        background-size: cover;
        object-fit: contain;
        object-position: top;

    }
    .other-collections{
        color:#79bdfa !important;
        text-decoration: underline;
    }
    .payment-hat{
        position: relative;
        left: 1%;
        width: 70px;
        z-index: 1;
    }
    .order-show-button-bg {
        background-color: #ded8fe !important;
        color: #262838 !important;
        width:100px !important;
        height:50px !important;


    }
    .order-show-button-bg  .v-btn__content{

        white-space: normal; /* Allow text to wrap */
        text-align: center; /* Optional: center the text */
        width: 100%;
        display: block;
    }
    .order-button-first-word{
        font-family: "Lucida Console", "Courier New", monospace;
        font-size: 18px;
        font-weight: bold;
    }
    .order-button-second-word{
        font-size: 16px;
    }
    .cancel-button-bg{
        background-color: #a5a09c !important;
        color: #282c39 !important;
    }
    .pay-button-bg{
        background-color: #ded8fe !important;
        color: #282c39 !important;
    }

    .closeWinButton {
        padding: 0 8px !important;
        min-width: unset !important;
    }

    .thanks-msg {
        text-align: center;
        font-size: 22px;
        color: #DDD7FC;
        background-color: #282C39FF;
    }

    .warning-msg {
        background-color: #ff3f40;
        color: #ffb646 !important;
        font-size: 16px;
    }
    .modal-order-list {
        background-color: transparent !important;
    }
    .order-cancel{
        background-color: #5b4f99!important;
        padding: 10px!important;
    }
    @media screen and (max-width: 959px) {
        .payment-cover-img{
            object-fit: cover;
        }
        .payment-hat {
            left: 3% !important;
        }
    }
</style>

<script>

import { ENTRYPOINT } from '@/config/entrypoint'
import isWebpSupported from '../../utils/isWebpSupported'
import { mapActions, mapGetters } from 'vuex'

import { Scroll } from 'vuetify/lib/directives'

export default {
    components: {},
    directives: {
        Scroll
    },
    computed: {
    ...mapGetters({
        error: 'order/show/error',
        isLoading: 'order/show/isLoading',
        order: 'order/show/retrieved',
        orderPaymentMethod: 'order/show/paymentMethod',
        canceled: 'order/del/canceled',
        settings: 'settings/items',
        personItem: 'person/show/retrieved',
        token: 'user/token/token',
        refresh_token: 'user/token/refresh_token',
        token_data: 'user/token/token_data',
        photographcollection: 'photographcollection/show/retrieved',
        collections: 'photographcollection/list/items',
    }),
        // Computed property to generate the mailto link
        mailtoLink() {
            // Encode subject and body to ensure proper URL formatting
            const encodedSubject = encodeURIComponent(this.orderPaymentMethod.paymentData.details.transferReference);
            // Construct the mailto link
            return `mailto:support@nimmerso.com?subject=${encodedSubject}&body.`;
        }
    },
    watch: {
        '$route.params.id': function (id) {

            if (this.oldId === id) return
            if (this.$route.name !== 'OrderShow') return

            this.oldId = id
            if (id) this.retrieve(decodeURIComponent(id))
            if (id) this.paymentMethod(decodeURIComponent(id)+'/paymentMethod')

        },
        personItem: function () {
            this.orders = this.personItem.orders
        },

        canceled: function () {
            if(this.canceled.status==200){
                this.isActive = false
                this.$router.push('/feed')
                }
            },
        order: function () {

            if (this.order === [] || this.order === null) return
            this.isActive = true
            if (this.offsetTop) {
                //window.console.log('offsetTop', this.offsetTop);
                window.scrollTo(0, this.offsetTop)
            }
            this.retrievePhotographCollection(this.order.photographCollection)

        },

        offsetTop: function () {

        }
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        dialog: false,
        canceldialog:false,
        showColorized: false,
        showOrderInfosOverlay: false,
        showInfo: false,
        show25DPhotos: true,
        webpSupported: true,
        orders: [],
        oldScrollPos: 0,
        metaItem: {},
        metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
        metaDescription: 'Nimmerso ist dein multimedialer Reiseführer in die Vergangenheit',
        metaUrl: null,
        metaImage: null,
        metaHashtags: [],
        attributes: [],
        activeTouchEle: null,
        albumThumbnailHeight: window.innerHeight,
        albumThumbnailTop: 20,
        albumThumbnailOpacity: 1,
        offsetTop: 0,
        isActive: false,
        oldId: null,
        showAll: false,
        isConfirmationModalOpen: false,
    }),

    beforeDestroy () {
        //window.console.log('beforeDestroy',this.isActive);
        this.isActive = false
        this.reset()
    },
    deactivated () {
        this.isActive = false
    },
    activated () {
        //window.console.log('activated',this.isActive);
        this.isActive = true
        if (this.offsetTop) {
            //window.console.log('offsetTop', this.offsetTop);

            setTimeout(() => {
                window.scrollTo(0, this.offsetTop)
            }, 500)
        }

    },

   async created () {
        //window.console.log('created',this.isActive);
        this.isActive = true

        this.webpSupported = isWebpSupported()
       this.getCollections('photograph_collections?itemsPerPage=1000')
       await this.retrieve(decodeURIComponent(this.$route.params.id))

        if(this.order?.paymentId){
            this.paymentMethod(decodeURIComponent(this.$route.params.id)+'/paymentMethod')
            //update order after paymentMethod call
            await this.retrieve(decodeURIComponent(this.$route.params.id))
        }

        if (this.token_data) {
            this.personRetrieve('/people/' + this.token_data.person_id)
        } else {
            window.console.log('no tokenData!')
        }

    },
    mounted () {
        this.isActive = true
    },
    methods: {
        ...mapActions({
            reset: 'order/show/reset',
            retrieve: 'order/show/retrieve',
            paymentMethod: 'order/show/paymentMethod',
            cancelOrder: 'order/del/cancel',
            personRetrieve: 'person/show/retrieve',
            gettokendata: 'user/token/gettokendata',
            retrievePhotographCollection: 'photographcollection/show/retrieve',
            getCollections: 'photographcollection/list/default',
        }),
        getTranslation (item) {
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        confirmCancelPayment() {
            this.canceldialog = false;
            this.cancelPayment();
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.success('Copied to clipboard!'); // Optional: notify the user
            }).catch(err => {
                window.console.error('Failed to copy: ', err);
            });
        },
        cancelPayment(){
            this.cancelOrder(decodeURIComponent(this.$route.params.id)+'/cancel')
        },
        showOrder() {
            this.dialog = true;
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },

        closeWin () {
            this.isActive = false
            this.$router.push('/feed')
            /*if(this.$route.name==='OrderCreated' && this.order && this.order.photographCollection){
                this.$router.push({name:"PhotographCollectionShow", params: {id:  this.order.photographCollection}})
            }else{
                this.$router.push('/feed')
            }
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')*/
        },
        onDoPayment () {
            window.console.log('paymentCheckoutUrl', this.order.paymentCheckoutUrl);
            window.console.log('order', this.order);
            window.location.href=this.order.paymentCheckoutUrl;
        }
    }
}
</script>
