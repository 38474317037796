import { NimmersoOfflineStorage} from '@/utils/nimmerso-offline-storage'

export default {
  namespaced: true,
  state: {
    items:  NimmersoOfflineStorage.get('order_collection') || [],
  },
  mutations: {
    set: (state,orders) => {
      state.items=Object.assign({},state.items,orders)
      NimmersoOfflineStorage.set('order_collection',Object.assign({},state.items,orders),365*24*60*60);
      //window.console.log('orders',Object.assign({},state.items,orders));
    }
  },
  actions: {
    set({ commit },orders) {
      commit('set',orders)
    },
    get(state){
      //window.console.log('getorders',this.state.items)
      return state.items
    },
  },
  getters: {
    items (state) {
      return state.items
    }
  }
}
