import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const upload = ({ commit }, values) => {
  commit(types.PRODUCT_UPLOAD_SET_ERROR, '')
  commit(types.PRODUCT_UPLOAD_TOGGLE_LOADING)
  let headers =new Headers();
  headers.set("Content-Type",'multipart/form-data; boundary=----WebKitFormBoundarydXskqET69oNB63ct')
  let data = new FormData();
  data.append('file',values.file,values.file.name);
  return fetch('products/imageUpload', {
    method: 'POST',
    body: data,
    //headers: headers
  })
    .then((response) => {
      commit(types.PRODUCT_UPLOAD_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.PRODUCT_UPLOAD_SET_UPLOADED, data)
      return data;
    })
    .catch((e) => {
      commit(types.PRODUCT_UPLOAD_TOGGLE_LOADING)
      if (e instanceof SubmissionError) {
        commit(types.PRODUCT_UPLOAD_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.PRODUCT_UPLOAD_SET_ERROR, e.errors._error)
        return
      }

      commit(types.PRODUCT_UPLOAD_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.PRODUCT_UPLOAD_RESET)
}
