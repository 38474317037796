<template>
    <v-container class="imagegallerycontainer" pa-1 fluid id="imagegallerycontainer">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deletedItem"
            v-model="deletedItem"
            :timeout="5000"
            :top="true"
            color="success"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deletedItem['@id'] }} deleted.
        </v-snackbar>
        <!-- <v-app-bar
             hide-on-scroll
             class="stickyFilter mb-2"

         >
             <v-btn-toggle
                 multiple
                 mandatory
                 rounded
                 big
                 v-model="toggleShow"

             >
                 <v-btn value="showCollections" >
                     Sammlungen
                     <v-icon>mdi-image-multiple</v-icon>
                 </v-btn>
                 <v-btn value="showAlben">
                     Alben
                     <v-icon>icon-Album</v-icon>
                 </v-btn>
             </v-btn-toggle>
         </v-app-bar>
         <div style="height:50px"></div>-->
        <div class="masonry-wrapper mt-2">
            <v-card v-if="showCollections">
                <v-card-title>Deine Sammlungen</v-card-title>
                <v-card-subtitle>Sammlungen enthalten deine Bilder die du uns zur Verfügung gestellt hast
                </v-card-subtitle>
                <v-card-text v-if="personItem && photographcollectionItems.length===0">
                    <p>Du hast noch keine Sammlungen erstellt.</p>
                    <p>Klicke auf den Button um eine Sammlung zu erstellen:</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="reportCollection">{{ $t('app.AddYourImages') }}</v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-row dense v-scroll="onScroll">
                        <v-col cols="12" md="6" lg="4" xl="2" v-for="item in photographcollectionItems" :key="item.id">


                            <v-card
                                @click.stop="carouselCollectionClick(item)"
                                color="#434156"
                                min-height="150"
                                lang="de-DE"
                            >
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-card-title
                                            v-text="item.headline"
                                        ></v-card-title>
                                        <v-card-subtitle
                                            v-text="item.about"></v-card-subtitle>
                                    </div>
                                    <v-avatar v-if="item.photographsCount>0"
                                              class="ma-3"
                                              size="125"
                                              tile
                                    >
                                        <v-img
                                            :src="entrypoint+'/photograph_collections/'+item['thumbnail']+'/small'"
                                            :lazy-src="entrypoint+'/photograph_collections/'+item['thumbnail']+'/lazy'"
                                            aspect-ratio="1"
                                        >
                                            <v-card-subtitle v-if="item.photographsCount===0">Diese Sammlung enthält
                                                keine Bilder
                                            </v-card-subtitle>
                                        </v-img>
                                    </v-avatar>

                                </div>
                                <v-card-actions>{{item.photographsCount}} RetroPics</v-card-actions>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card v-if="showAlben">
                <v-card-title>Deine Alben</v-card-title>
                <v-card-subtitle>In Alben kannst du deine Lieblings-RetroPics sammeln. Du kannst auch Alben zu
                    interessanten Themen zusammenstellen und anderen Nutzern zeigen.
                </v-card-subtitle>
                <v-card-text v-if="personItem && items.length===0">
                    <p>Du hast noch kein Album erstellt.</p>
                    <p>Klicke auf den Button um ein Album zu erstellen:</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn :to="{ name: 'ImageGalleryCreate' }">{{ $t('app.Gallery.Create') }}</v-btn>
                </v-card-actions>
                <v-card-text>
                    <div class="mt-2"></div>

                    <div class="masonry" v-scroll="onScroll">
                        <v-card class="masonry-item mi-small" v-for="item in items" :key="item.id"
                                @click.stop="carouselItemClick(item)"
                        >
                            <v-img v-if="item['@type']==='https://schema.org/ImageGallery'"
                                   :src="entrypoint+'/image_galleries/'+item['thumbnail']+'/display'"
                                   aspect-ratio="1"
                            ><!--<v-card-title class="text-h6">
                        {{ item.headline }}
                    </v-card-title>-->
                                <v-card-subtitle v-if="item.photographs && item.photographs.length===0 && item.photographComments && item.photographComments.length===0 && item.retrosnaps && item.retrosnaps.length===0">Diese Galerie
                                    enthält keine Bilder
                                </v-card-subtitle>
                            </v-img>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <!-- <div id="homecontainerbottom">
             <div v-if="isLoading || view['hydra:next']" class="text-center">
                 <v-progress-circular
                     indeterminate
                     color="brown"
                     class="center bottom"
                 />
             </div>
         </div>-->

    </v-container>
</template>
<style>

.homevcardactions i {
    text-shadow: 0 1px 2px black;
}


.imagegallerycontainer .masonry {
    grid-auto-rows: 1fr;
}

.stickyFilter {
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: unset !important;
    padding-top: 80px;
}

.stickyFilter.v-app-bar--hide-shadow {

    opacity: 0;
}

</style>
<script>
import { ENTRYPOINT } from '../../config/entrypoint'
import { mapActions, mapGetters } from 'vuex'
import { Scroll } from 'vuetify/lib/directives'

export default {
    directives: {
        Scroll
    },
    computed: mapGetters({
        deletedItem: 'imagegallery/del/deleted',
        error: 'imagegallery/list/error',
        isLoading: 'imagegallery/list/isLoading',
        view: 'imagegallery/list/view',

        photographcollectionDeletedItem: 'photographcollection/del/deleted',
        photographcollectionError: 'photographcollection/list/error',
        photographcollectionItems: 'photographcollection/list/items',
        photographcollectionIsLoading: 'photographcollection/list/isLoading',
        photographcollectionView: 'photographcollection/list/view',

        personError: 'person/show/error',
        personIsLoading: 'person/show/isLoading',
        personItem: 'person/show/retrieved',
        settings: 'settings/items',
        token_data: 'user/token/token_data',
    }),
    watch: {
        personItem: function () {
            this.items = this.personItem.imageGalleries
        },
        settings () {
            this.showColorized = this.settings.showColorized
            this.showInfosOverlay = this.settings.showInfosOverlay
        },
        deletedItem () {
            this.personRetrieve('/people/' + this.token_data.person_id)
        },
        toggleShow () {
            this.showCollections = this.toggleShow.findIndex(v => v === 'showCollections') > -1
            this.showAlben = this.toggleShow.findIndex(v => v === 'showAlben') > -1
        }
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        items: [],

        showColorized: false,
        showInfosOverlay: false,
        showAlben: true,
        showCollections: true,
        toggleShow: ['showCollections', 'showAlben'],
    }),
    created () {
        //window.console.log(this.token_data)
        /*if (this.token_data && this.token_data.person_id !== null) {
            this.personRetrieve('/people/' + this.token_data.person_id)
            this.getPage('photograph_collections?owner=' + this.token_data.person_id)

        } else {
            window.console.error('no tokenData!')
        }*/
        this.showColorized = this.settings.showColorized
        this.showInfosOverlay = this.settings.showInfosOverlay
    },
    activated () {
        if (this.token_data && this.token_data.person_id !== null) {
            this.personRetrieve('/people/' + this.token_data.person_id)
            this.getPage('photograph_collections?owner=' + this.token_data.person_id)

        } else {
            window.console.error('no tokenData!')
        }
    },

    methods: {
        ...mapActions({
            personRetrieve: 'person/show/retrieve',
            getPage: 'photographcollection/list/default'
        }),
        carouselItemClick (item) {

            this.$router.push({name: 'ImageGalleryShow', params: {id: item['@id']}})
        },
        carouselCollectionClick(item){
            this.$router.push({name: 'PhotographCollectionShow', params: {id: item['@id']}})
        },
        onScroll () {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 40

            if (bottomOfWindow) {
                if (this.view && this.view['hydra:next'] && !this.isLoading) this.getPage(this.view['hydra:next'])
            }
        },
        reportCollection(){
            /*if(this.plausible !== null){
                this.plausible('report-collection-button-click',{props:{logged_in: this.token !== null, appinstalled: window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches}});
            }*/
            this.$router.push({name:'PhotographCollectionCreate'});
        }
    }
}
</script>
