<template>
    <v-form @submit.prevent="handleSubmit(item)">
        <v-container>

            <v-switch v-model="item.isPrivate" :label="$t('app.Gallery.IsPrivate')" hint="indicates whether the gallery is private."
                      @input="handleUpdateField('isPrivate', item.isPrivate)"></v-switch>


            <div class="form-group">
                <v-text-field
                    :label="$t('app.Gallery.Headline')"
                    id="imagegallery_headline"
                    v-model="item.headline"
                    :class="['form-control', isInvalid('headline') ? 'is-invalid' : '']"
                    type="text"
                    :placeholder="$t('app.Gallery.HeadlinePlaceholder')"
                    @input="handleUpdateField('headline', item.headline)"></v-text-field>
                <div
                    v-if="isInvalid('headline')"
                    class="invalid-feedback">{{ violations.headline }}
                </div>
            </div>
            <div class="form-group">

                <v-textarea
                    id="imagegallery_description"
                    :label="$t('app.Gallery.Description')"
                    v-model="item.description"
                    :class="['form-control', isInvalid('description') ? 'is-invalid' : '']"
                    type="text"
                    :placeholder="$t('app.Gallery.DescriptionPlaceholder')"
                    @input="handleUpdateField('description', item.description)"></v-textarea>
                <div
                    v-if="isInvalid('description')"
                    class="invalid-feedback">{{ violations.description }}
                </div>
            </div>
            <div class="form-group">
                <tiptap-vuetify
                    v-model="item.text"
                    :extensions="tiptapExtensions"
                    :toolbar-attributes="{ color: '#1E1E1E' }"
                    :placeholder="$t('app.Gallery.TextPlaceholder')"
                    label="Text"
                    :class="['form-control', isInvalid('text') ? 'is-invalid' : '']"
                    required="true"
                    @keydown="handleUpdateField('text', item.text)"
                ></tiptap-vuetify>
                <div
                    v-if="isInvalid('text')"
                    class="invalid-feedback">{{ violations.text }}
                </div>
            </div>
            <!--<div class="form-group">
                <v-range-slider
                    :value="yearRange"
                    min="1850"
                    max="1980"
                    :label="$t('app.Photograph.Period')"
                    @input="(value) => { handleUpdateField('fromYear', value[0]); handleUpdateField('toYear', value[1]);}"
                >
                </v-range-slider>
                <v-row no-gutters>
                    <v-col cols="3"
                    md="4"><v-text-field
                        :full-width="false"
                        id="imagegallery_fromYear"
                        v-model="item.fromYear"
                        :class="['form-control', isInvalid('fromYear') ? 'is-invalid' : '']"
                        type="number"
                        @input="handleUpdateField('fromYear', item.fromYear)"></v-text-field></v-col>
                    <v-col cols="1">-</v-col>
                    <v-col cols="3"
                    md="4"><v-text-field
                        :full-width="false"
                        id="imagegallery_toYear"
                        v-model="item.toYear"
                        :class="['form-control', isInvalid('toYear') ? 'is-invalid' : '']"
                        type="number"
                        @input="handleUpdateField('toYear', item.toYear)"></v-text-field>
                </v-col>
                </v-row>

            </div>-->

            <div class="form-group">
                <MapWidget

                    :handle-update-field="handleGeoUpdate"
                    :value="item.place"
                    :place-keys="item.placeKeys"
                    :class="[isInvalid('place') ? 'is-invalid' : '']"
                />
                <div
                    v-if="isInvalid('geo')"
                    class="invalid-feedback">{{ violations.geo }}
                </div>
            </div>

            <div class="form-group">
                <v-autocomplete
                    v-model="item.categories"
                    @change="(newval)=>{itemcategories=newval}"
                    multiple
                    :items="categories"
                    item-text="label"
                    item-value="id"
                    chips
                    deletable-chips
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('app.Categories')"
                ></v-autocomplete>
                <div
                    v-if="isInvalid('keywords')"
                    class="invalid-feedback">{{ violations.keywords }}
                </div>
            </div>
            <div class="form-group" v-if="item.categories">
                <category-attributes
                    :label="$t('app.Gallery.Attributes')"
                    id="imagegallery_categoryAttributes"
                    :values="item.categoryAttributes ? item.categoryAttributes : {}"
                    :class="['form-control', isInvalid('categoryAttributes') ? 'is-invalid' : '']"
                    :categories="itemcategories"
                    :allCategories="categoriesraw"
                    :handle-update-field="updateCategoryAttributeField"
                ></category-attributes>
                <div
                    v-if="isInvalid('categoryAttributes')"
                    class="invalid-feedback">{{ violations.categoryAttributes }}
                </div>
            </div>

            <div class="form-group" v-if="item && item.photographs && item.id && item.photographs.length>=1">
                <label class="form-control-label">{{$t('app.Gallery.AlbumCover')}}</label>
                <v-radio-group
                    v-model="useTemplate"
                    row
                    mandatory
                >
                    <v-radio
                        :label="$t('app.Gallery.ThumbnailTemplate')"
                        value="true"
                    ></v-radio>
                    <v-radio
                        :label="$t('app.Gallery.UploadImage')"
                        value="false"
                    ></v-radio>
                </v-radio-group>
                <div
                    v-if="isInvalid('thumbnailSettings')"
                    class="invalid-feedback">{{ violations.thumbnailSettings }}
                </div>
                <v-dialog v-model="editSvgImage" max-width="512" height="auto">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Select Image</span>
                        </v-card-title>
                        <v-card-text>
                            <v-carousel
                                hide-delimiter-background
                                show-arrows-on-hover
                                height="256"
                            >
                                <v-carousel-item
                                    v-for="(photograph, i) in item.photographs"
                                    :key="i"
                                    @click="setSvgImage(photograph['id'])"
                                >
                                    <v-img contain max-height="256" :src="entrypoint+'/thumbnail/small/'+(webpSupported ? photograph['filePath'].replace(/\.jpeg/,'.webp') : photograph['filePath'])" ></v-img>
                                </v-carousel-item>
                                <v-carousel-item
                                    v-for="(photograph) in item.photographComments"
                                    :key="photograph"
                                    @click="setSvgImage(photograph)"

                                >
                                    <v-img contain max-height="256" :src="entrypoint+photograph+'/showThumbnail/small'+(webpSupported ?'.webp' : '.jpeg')"></v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </v-card-text>

                    </v-card>
                </v-dialog>
            </div>
            <v-btn color="primary" class="mt-5" small dark type="submit"
                   ><v-icon>mdi-content-save</v-icon>{{ $t('app.Submit') }}
            </v-btn>
        </v-container>
    </v-form>
</template>
<style>
    .svgimage {
        cursor: pointer;
    }
    .svgimage:hover {
        opacity: 0.5;
        filter: brightness(0.25);
    }
</style>
<script>
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Underline,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        History
    } from 'tiptap-vuetify';
    import {ENTRYPOINT} from '@/config/entrypoint';
    import { mapActions, mapGetters } from 'vuex'
    import CategoryAttributes from '@/components/CategoryAttributes'
    import MapWidget from '@/components/map/Widget'
    export default {
        components: {
            CategoryAttributes,
            TiptapVuetify,
            MapWidget,
        },
        props: {
            handleSubmit: {
                type: Function,
                required: true
            },

            handleUpdateField: {
                type: Function,
                required: true
            },

            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                    return {
                        'thumbnailSettings': {
                            useTemplate: true
                        }
                    }
                }
            }
        },
        computed: {
            // eslint-disable-next-line
            ...mapGetters({
                svgtemplates: 'svgtemplate/list/items',
                categoriesraw: 'category/list/items'
            }),
            item() {

                //return JSON.parse(JSON.stringify(this.initialValues || this.values))

                return this.initialValues || this.values
            },
            /*yearRange(){
                return [this.item.fromYear,this.item.toYear];
            },*/

            violations() {
                return this.errors || {}
            },
        },
        data: () => ({
            tiptapExtensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Italic,
                Bold,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Paragraph,
                HardBreak
            ],
            entrypoint: ENTRYPOINT,
            selectedImages: [],
            selectedTemplate: null,
            editSvgImageId: null,
            editSvgImage: false,
            categories: [],
            categoriesLoading: false,
            itemcategories: [],
            webpSupported: false,
            //yearRange: [1850,1980]
            useTemplate: true
        }),
        created () {
            this.getSvgTemplates('svg_templates?itemsPerPage=1000');
            this.getCategories('categories');
        },
        watch: {
            selectedTemplate: function(){
                if(typeof this.item.thumbnailSettings=='undefined' || this.item.thumbnailSettings==null) this.item.thumbnailSettings={};
                this.item.thumbnailSettings.images=this.selectedImages;
                this.item.thumbnailSettings.template=typeof this.svgtemplates[this.selectedTemplate]!=='undefined' ? this.svgtemplates[this.selectedTemplate].id : null;
                this.handleUpdateField('thumbnailSettings', this.item.thumbnailSettings);
            },
            item: {
                handler:function() {
                    this.itemcategories=this.item.categories;
                    window.console.log(this.itemcategories);
                    //this.yearRange = [this.item.fromYear, this.item.toYear];
                    window.console.log(this.item);
                    //this.item.thumbnailSettings={};
                    if (typeof(this.item.thumbnailSettings)=='undefined' || this.item.thumbnailSettings==null){
                        this.item.thumbnailSettings = {}
                    }
                    if (typeof (this.item.thumbnailSettings) !== 'undefined' && typeof (this.item.thumbnailSettings.images) !== 'undefined' && this.item.thumbnailSettings.images.length >0 ){
                        this.selectedImages=this.item.thumbnailSettings.images;
                        this.selectedTemplate=this.svgtemplates.findIndex((element) => element.id===this.item.thumbnailSettings.template);
                    }else{
                        if (typeof(this.item.photographs)==='object' && this.item.photographs.length>0) {
                            let z=0;
                            let templateImagesCount=6;
                            if (this.selectedTemplate && this.selectedTemplate.settings && this.selectedTemplate.settings.imagesCount){
                                templateImagesCount=this.selectedTemplate.settings.imagesCount;
                            }
                            while(z<templateImagesCount) {
                                this.item.photographs.forEach((photograph) => {
                                    this.selectedImages.push(photograph.id);
                                    z++;
                                });
                            }


                        }
                    }
                    if (typeof(this.item.thumbnailSettings.useTemplate) == 'undefined') this.item.thumbnailSettings.useTemplate=true;
                    this.useTemplate=this.item.thumbnailSettings.useTemplate;
                },
                deep: true
            },

            categoriesraw: function () {
                this.categories = this.categoriesraw.filter((category)=>{
                    return category['isForGalleries']!==false
                }).map(category => {
                    return {
                    'id': category['@id'],
                    'label': category['name'],
                    'children': this.getCategoryChildren(category['@id'])
                }
                }).sort((a, b) => {
                    let nameA = a.label.toLowerCase()
                    let nameB = b.label.toLowerCase()
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                }); //this.getCategoryChildren(null)
                window.console.log(this.categories);
            },
            categoriesSearch (val) {
                val && val !== this.select && this.querySelections(val)
            },
            useTemplate(val){

                this.item.thumbnailSettings.useTemplate=val;
                this.handleUpdateField('thumbnailSettings', this.item.thumbnailSettings);
            }
        },
        methods: {
            ...mapActions({
                getSvgTemplates: 'svgtemplate/list/default',
                getCategories: 'category/list/default'
            }),
            getCategoryChildren (parentid) {
                if (!this.categoriesraw) return []
                let children = this.categoriesraw.filter(category => category.parentcategory === parentid)
                return children.map(category => {
                    return {
                        'id': category['@id'],
                        'label': category['name'],
                        'isDisabled': this.item.id === category['id'],
                        'children': this.getCategoryChildren(category['@id'])
                    }
                }).sort((a, b) => {
                    let nameA = a.label.toLowerCase()
                    let nameB = b.label.toLowerCase()
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                })
            },
            svgLoaded(svg){
                let svgimages= document.querySelectorAll('#'+svg.id+' .svgimage');
                for (const svgimage of svgimages){
                    svgimage.addEventListener('click', (event)=>{
                        window.console.log(event.target.classList.item(1));
                        this.editSvgImageId=parseInt(event.target.classList.item(1).replace('image',''),10);
                        this.editSvgImage=true;
                    });
                }
            },
            setSvgImage(photograph){
                window.console.log(photograph);

                this.selectedImages[this.editSvgImageId]=photograph;
                this.editSvgImageId=null;
                this.editSvgImage=false;
                if(typeof this.item.thumbnailSettings=='undefined' || this.item.thumbnailSettings==null) this.item.thumbnailSettings={};
                this.item.thumbnailSettings.images=this.selectedImages;
                this.item.thumbnailSettings.template=this.svgtemplates[this.selectedTemplate].id;
                window.console.log('setSvgImage',this.item);
                this.handleUpdateField('thumbnailSettings', this.item.thumbnailSettings);
            },
            isInvalid(key) {
                return Object.keys(this.violations).length > 0 && this.violations[key]
            },
            updateCategoryAttributeField (field, value) {
                window.console.log(field,value);
                if (typeof this.item.categoryAttributes === 'undefined' || this.item.categoryAttributes===null)  this.item.categoryAttributes={};
                this.item.categoryAttributes[field]=value;
                this.handleUpdateField('categoryAttributes',this.item.categoryAttributes);
                window.console.log('categoryAttributes',this.item.categoryAttributes);
            },
            handleGeoUpdate (field, value) {
                //this.$set(this.item, field, value)
                //this.$set(this.item.geo, 'latitude', value.latitude);
                //this.$set(this.item.geo, 'longitude', value.longitude);
                window.console.log(field,value);
                this.handleUpdateField(field, value)
            },
            onFileUpload(response) {
                window.console.log('onFileUpload', response);
            },
            updateFileUploadField(value) {
                //this.item.filePath=value.filePath;
                window.console.log(value);
                this.$set(this.item, 'thumbnail', value.filePath);
                this.item.thumbnailSettings.originalName= value.originalName;
                this.item.thumbnail= value.thumbnail;
                this.handleUpdateField('thumbnailSettings', this.item.thumbnailSettings);
                this.handleUpdateField('thumbnail', value.filePath);
                /*Object.assign(this.item, { 'filePath': value.filePath })
                Object.assign(this.item, { 'originalName': value.originalName })*/

            },

        }
    }
</script>
