// Add routes to VueRouter

//import commentRoutes from './comment';
//import organizationRoutes from './organization';
//import personRoutes from './person';
//import placeRoutes from './place';
//import postaladdressRoutes from './postaladdress';
//import sponsorRoutes from './sponsor';

import homeRoutes from './home'
import mapRoutes from './map'
import imagegalleryRoutes from './imagegallery'
import photographRoutes from './photograph'
import retrosnapRoutes from './retrosnap'
import photographcollectionRoutes from './photographcollection'
import userRoutes from './user'
import rankingRoutes from './ranking'
import settingsRoutes from './settings'
import arimageRoutes from './imagear'
import pageRoutes from './page'
import orderitemRoutes from './orderitem'
import orderRoutes from './order'
import NotFound from '../components/home/NotFound'


export const routes = [
  ...homeRoutes,
  ...mapRoutes,
  //...commentRoutes,
  ...imagegalleryRoutes,
  //...organizationRoutes,
  //...personRoutes,
  ...photographRoutes,
  ...retrosnapRoutes,
  ...photographcollectionRoutes,
  //...placeRoutes,
  //...postaladdressRoutes,
  //...sponsorRoutes,
  ...userRoutes,
  ...pageRoutes,
  ...rankingRoutes,
  ...settingsRoutes,
  ...arimageRoutes,
  ...orderitemRoutes,
  ...orderRoutes,
  { path: '*', components: { default: NotFound}, meta: { navHidden: true, requiresAuth: false } }
]
