<template><v-img :src="imageOriginal" lazy-src="">
    <canvas :ref="'viewer'" style="width:100%; height:100%; margin-bottom: -8px"></canvas>
</v-img>
</template>
<script>
    import fragment from './shaders/fragment.glsl';
    import vertex from './shaders/vertex.glsl';


    export default {
        props: {
            imageOriginal: {
                type: String,
                required: true
            },
            imageDepth: {
                type: String,
                required: true
            },
            imageLazy: {
                type: String,
                required: false
            },
            aspectRatio: {
                type: Number,
                required: false
            },
            horizontalThreshold: {
                type: Number,
                default: () => {
                    return 20
                }
            },
            verticalThreshold: {
                type: Number,
                default: () => {
                    return 20
                }
            },
        },
        data: () => ({
            canvas: null,
            gl: null,
            windowWidth: null,
            windowHeight: null,
            mouseX: null,
            mouseY: null,
            mouseTargetX: null,
            mouseTargetY: null,
            imageURLs: [],
            textures: [],
            startTime: null,
            gn: null,
            oldAlpha: 0,
            oldBeta: 0,
            oldGamma: 0,
            orientation: null,
            isIntersecting: false
        }),
        watch: {
            imageOriginal: function () {
                this.init();

            }
        },
        mounted () {
            this.observer = new IntersectionObserver((entries) => {
                // isIntersecting is true when element and viewport are overlapping
                // isIntersecting is false when element and viewport don't overlap
                this.isIntersecting=entries[0].isIntersecting === true;

                if(this.isIntersecting){
                    //requestAnimationFrame( this.render.bind(this) );
                    this.init();
                }else{
                    this.destroy();
                }

            }, { threshold: [0] });

            this.observer.observe(this.$refs['viewer']);


        },
        methods: {
            destroy(){
                if (this.program){
                    this.gl.deleteProgram(this.program);
                    this.program=null;
                }
                if (this.gl){
                    this.gl.getExtension('WEBGL_lose_context').loseContext();
                    this.gl=null;


                    let oldCanvas=this.$refs['viewer'];
                    const canvasParent=oldCanvas.parentNode;
                    const oldAttributes=oldCanvas.attributes;
                    this.observer.unobserve(this.$refs['viewer']);

                    let newCanvas=document.createElement('canvas');
                    canvasParent.removeChild(oldCanvas);
                    oldCanvas.remove();
                    oldAttributes.forEach((a)=>newCanvas.setAttribute(a.name,a.value));
                    canvasParent.appendChild(newCanvas);

                    this.$refs['viewer']=newCanvas;
                    this.observer.observe(newCanvas);


                }

            },
            init(){
                //this.gn=new GyroNorm();
                this.canvas = this.$refs['viewer'];
                this.gl = this.canvas.getContext('webgl');
                this.gl.clearColor(0.0, 0, 0, 0.0);
                this.gl.clear(this.gl.COLOR_BUFFER_BIT);
                this.ratio = window.devicePixelRatio;
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
                this.mouseX = 0;
                this.mouseY = 0;

                this.mouseTargetX = 0;
                this.mouseTargetY = 0;

                this.vth = this.verticalThreshold;
                this.hth = this.horizontalThreshold;

                this.imageURLs = [
                    this.imageOriginal,
                    this.imageDepth
                ];
                this.textures = [];

                this.startTime = new Date().getTime(); // Get start time for animating

                this.createScene();
                this.addTexture();
                this.mouseMove();
                //this.gyro();
                /*if (window.DeviceMotionEvent){
                    window.addEventListener('devicemotion', this.handleOrientation,false);
                }else{
                    window.console.error('devicemotion not supported')
                }*/
                if(window.DeviceOrientationEvent){
                    window.addEventListener("deviceorientation", this.handleOrientation,true);
                }else{
                    window.console.error('devicemotion not supported')
                }
                /*
                const observer = new IntersectionObserver((entries) => {
                    // isIntersecting is true when element and viewport are overlapping
                    // isIntersecting is false when element and viewport don't overlap
                    this.isIntersecting=entries[0].isIntersecting === true;

                    if(this.isIntersecting){
                        requestAnimationFrame( this.render.bind(this) );
                    }

                }, { threshold: [0.5] });

                observer.observe(this.$refs['viewer']);
*/
                requestAnimationFrame( this.render.bind(this) );
            },
            addShader( source, type ) {
                let shader = this.gl.createShader( type );
                this.gl.shaderSource( shader, source );
                this.gl.compileShader( shader );
                let isCompiled = this.gl.getShaderParameter( shader, this.gl.COMPILE_STATUS );
                if ( !isCompiled ) {
                    throw new Error( 'Shader compile error: ' + this.gl.getShaderInfoLog( shader ) );
                }
                this.gl.attachShader( this.program, shader );
            },

            resizeHandler() {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
                this.width = this.canvas.offsetWidth;
                this.height = this.canvas.offsetHeight;
                if (this.aspectRatio){
                    this.height=this.width / this.aspectRatio;
                }

                this.canvas.width = this.width*this.ratio;
                this.canvas.height = this.height*this.ratio;
                /*this.canvas.style.width = this.width + 'px';
                this.canvas.style.height = this.height + 'px';*/
                let a1,a2;
                if(this.height/this.width<this.imageAspect) {
                    a1 = 1;
                    a2 = (this.height/this.width) / this.imageAspect;
                } else{
                    a1 = (this.width/this.height) * this.imageAspect ;
                    a2 = 1;
                }
                this.uResolution.set( this.width, this.height, a1, a2 );
                this.uRatio.set( 1/this.ratio );
                this.uThreshold.set( this.hth, this.vth );
                if (this.gl!==null) this.gl.viewport( 0, 0, this.width*this.ratio, this.height*this.ratio );
            },

            resize() {
                this.resizeHandler();
                window.addEventListener( 'resize', this.resizeHandler.bind(this) );
            },

            createScene() {

                this.program = this.gl.createProgram();

                this.addShader( vertex, this.gl.VERTEX_SHADER );
                this.addShader( fragment, this.gl.FRAGMENT_SHADER );

                this.gl.linkProgram( this.program );
                this.gl.useProgram( this.program );


                this.uResolution = new Uniform( 'resolution', '4f' , this.program, this.gl );
                this.uMouse = new Uniform( 'mouse', '2f' , this.program, this.gl );
                this.uTime = new Uniform( 'time', '1f' , this.program, this.gl );
                this.uRatio = new Uniform( 'pixelRatio', '1f' , this.program, this.gl );
                this.uThreshold = new Uniform( 'threshold', '2f' , this.program, this.gl );
                // create position attrib
                this.billboard = new Rect( this.gl );
                this.positionLocation = this.gl.getAttribLocation( this.program, 'a_position' );
                this.gl.enableVertexAttribArray( this.positionLocation );
                this.gl.vertexAttribPointer( this.positionLocation, 2, this.gl.FLOAT, false, 0, 0 );
            },

            addTexture() {
                loadImages(this.imageURLs, this.start.bind(this));
            },

            start(images) {
                let that = this;
                let gl = that.gl;


                this.imageAspect = images[0].naturalHeight/images[0].naturalWidth;
                for (var i = 0; i < images.length; i++) {


                    let texture = gl.createTexture();
                    gl.bindTexture(gl.TEXTURE_2D, texture);

                    // Set the parameters so we can render any size image.
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

                    // Upload the image into the texture.
                    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, images[i]);
                    this.textures.push(texture);
                }

                // lookup the sampler locations.
                let u_image0Location = this.gl.getUniformLocation(this.program, 'image0');
                let u_image1Location = this.gl.getUniformLocation(this.program, 'image1');

                // set which texture units to render with.
                this.gl.uniform1i(u_image0Location, 0); // texture unit 0
                this.gl.uniform1i(u_image1Location, 1); // texture unit 1

                this.gl.activeTexture(this.gl.TEXTURE0);
                this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[0]);
                this.gl.activeTexture(this.gl.TEXTURE1);
                this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[1]);


                // start application
                this.resize();
                this.render();
            },

            /*initsensors: function () {
                window.console.log('initsensors');
                if (window.DeviceOrientationEvent) {
                    window.addEventListener("deviceorientation", (event) => {
                        const beta = event.beta;
                        const gamma = event.gamma;
                        this.maxTilt = 15;
                        let y = gamma;
                        let x = beta;
                        this.mouseTargetY = clamp(x,-this.maxTilt, this.maxTilt)/this.maxTilt;
                        this.mouseTargetX = -clamp(y,-this.maxTilt, this.maxTilt)/this.maxTilt;


                    }, true);
                } else {
                    window.console.error("DeviceOrientationEvent is not supported by your browser.");
                }

            },*/
            handleOrientation(event) {


                //this.accelerationX += event.acceleration.x   // In degree in the range [-180,180]
                //this.accelerationY += event.acceleration.y  // In degree in the range [-90,90]

                this.orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
                //window.console.log(this.orientation);
                const deltaAlpha=this.oldAlpha-event.alpha*5;
                const deltaBeta=this.oldBeta-event.beta*5;
                const deltaGamma=this.oldGamma-event.gamma*5;
                //window.console.log(deltaAlpha,deltaBeta,deltaGamma);
                if (this.orientation === "landscape-primary") {
                    this.mouseTargetX-=deltaAlpha/this.horizontalThreshold;
                    this.mouseTargetY+=deltaGamma/this.verticalThreshold;
                } else if (this.orientation === "landscape-secondary") {
                    this.mouseTargetX-=deltaAlpha/this.horizontalThreshold;
                    this.mouseTargetY-=deltaGamma/this.verticalThreshold;
                } else if (this.orientation === "portrait-primary") {
                    this.mouseTargetX+=deltaGamma/this.horizontalThreshold;
                    this.mouseTargetY-=deltaBeta/this.verticalThreshold;
                    //this.mouseTargetY-=deltaAlpha/this.verticalThreshold;
                } else if (this.orientation === "portrait-secondary") {
                    this.mouseTargetX+=deltaGamma/this.horizontalThreshold;
                    this.mouseTargetY-=deltaBeta/this.verticalThreshold;
                }
                this.oldAlpha=event.alpha*5;
                this.oldBeta=event.beta*5;
                this.oldGamma=event.gamma*5;
                //this.mouseTargetX+=rotationRate.alpha/10;
                //this.mouseTargetY+=rotationRate.beta/10;

                if (this.mouseTargetX<-1)this.mouseTargetX=-1;
                if (this.mouseTargetX>1)this.mouseTargetX=1;
                if (this.mouseTargetY<-1)this.mouseTargetY=-1;
                if (this.mouseTargetY>1)this.mouseTargetY=1;

/*
                if (deltaX<-this.horizontalThreshold/10) deltaX=-this.horizontalThreshold/10;
                if (deltaX>this.horizontalThreshold/10) deltaX=this.horizontalThreshold/10;
                if (deltaY<-this.verticalThreshold/10) deltaY=-this.verticalThreshold/10;
                if (deltaY>this.verticalThreshold/10) deltaY=this.verticalThreshold/10;
                this.mouseTargetY = -deltaX; //clamp(x,-this.maxTilt, this.maxTilt)/this.maxTilt;
                this.mouseTargetX = -deltaY; //-clamp(y,-this.maxTilt, this.maxTilt)/this.maxTilt;
                */
            },

            mouseMove() {
                let that = this;
                document.addEventListener('mousemove', function(e) {
                    let halfX = that.windowWidth/2;
                    let halfY = that.windowHeight/2;

                    that.mouseTargetX = (halfX - e.clientX)/halfX;
                    that.mouseTargetY = (halfY - e.clientY)/halfY;


                });
            },

            render() {
                let now = new Date().getTime();
                let currentTime = ( now - this.startTime ) / 1000;
                this.uTime.set( currentTime );
                // inertia
                this.mouseX += (this.mouseTargetX - this.mouseX)*0.08;
                this.mouseY += (this.mouseTargetY - this.mouseY)*0.08;


                this.uMouse.set( this.mouseX, this.mouseY );

                // render
                if (this.gl!==null) {
                    this.billboard.render(this.gl);
                    if (this.isIntersecting) requestAnimationFrame(this.render.bind(this));
                }
            }
        }
    }
    function loadImage(url, callback) {
        var image = new Image();
        image.crossOrigin = "anonymous";
        image.src = url;
        image.onload = callback;
        return image;
    }
    function loadImages(urls, callback) {
        var images = [];
        var imagesToLoad = urls.length;

        // Called each time an image finished loading.
        var onImageLoad = function() {
            --imagesToLoad;
            // If all the images are loaded call the callback.
            if (imagesToLoad === 0) {
                callback(images);
            }
        };

        for (var ii = 0; ii < imagesToLoad; ++ii) {
            var image = loadImage(urls[ii], onImageLoad);
            images.push(image);
        }
    }
    function Uniform( name, suffix, program,gl ) {
        this.name = name;
        this.suffix = suffix;
        this.gl = gl;
        this.program = program;
        this.location = gl.getUniformLocation( program, name );
    }

    Uniform.prototype.set = function( ...values ) {
        let method = 'uniform' + this.suffix;
        let args = [ this.location ].concat( values );
        this.gl[ method ].apply( this.gl, args );
    };

    function Rect( gl ) {
        var buffer = gl.createBuffer();
        gl.bindBuffer( gl.ARRAY_BUFFER, buffer );
        gl.bufferData( gl.ARRAY_BUFFER, Rect.verts, gl.STATIC_DRAW );
    }

    Rect.verts = new Float32Array([
        -1, -1,
        1, -1,
        -1, 1,
        1, 1,
    ]);

    Rect.prototype.render = function( gl ) {
        gl.drawArrays( gl.TRIANGLE_STRIP, 0, 4 );
    };
    /*function clamp(number, lower, upper) {
        if (number === number) {
            if (upper !== undefined) {
                number = number <= upper ? number : upper;
            }
            if (lower !== undefined) {
                number = number >= lower ? number : lower;
            }
        }
        return number;
    }*/
</script>

<style scoped>

</style>
