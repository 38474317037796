import OrderList from '../components/order/List'
import OrderCreateForCollection from '../components/order/CreateForCollection'
import OrderUpdate from '../components/order/Update'
import OrderShow from '../components/order/Show'

export default [
  { name: 'OrderList', path: '/orders', components: {default: OrderList}, meta: { requiresAuth: true } },
  { name: 'OrderCollectionCreate', path: '/photograph_collections/:id/order/create', components: {default: OrderCreateForCollection}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderUpdate', path: '/orders/edit/:id', components: {default: OrderUpdate}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderShow', path: '/orders/show/:id', components: {default: OrderShow}, meta: { requiresAuth: true, navHidden: true } },
  { name: 'OrderCreated', path: '/orders/created/:id', components: {default: OrderShow}, meta: { requiresAuth: true, navHidden: true } },

]
