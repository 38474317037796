<template>
            <v-card
                :loading="loading"
                class="my-4"
                style="width: 100%"
                @click="productActive=true"


            >
                <v-carousel
                    hide-delimiters
                    v-if="value.images"
                    height="240"
                    show-arrows-on-hover
                    :show-arrows="value.images.length>1"
                >

                    <v-carousel-item
                        v-for="(item,i) in value.images"
                        :key="i"
                        fill
                        :src="entrypoint+'/media/photographs/'+item"
                    ></v-carousel-item>
                </v-carousel>


                <v-card-title>{{getTranslation(value).name}}</v-card-title>

                <v-expand-transition>
                <v-card-text v-show="productActive">
                    <v-row
                        align="center"
                        class="mx-0"
                    >
                        <div v-if="value.numberOfPucrchases" class="grey--text">
                            {{value.numberOfPucrchases}} Unterstützer haben dies gewählt.
                        </div>
                    </v-row>

                    <div class="mt-4" v-html="getTranslation(value).description"></div>
                </v-card-text>
                </v-expand-transition>

                <v-divider class="mx-4"></v-divider>
                <div v-if="value.configOptions && value.configOptions.length>0">
                    <v-expand-transition
                        v-for="(config) in value.configOptions"
                        v-bind:key="value.id+'-'+config.id"
                    >
                        <div>
                        <v-card-title v-show="productActive">{{config.label[$root.$i18n.locale]}}</v-card-title>
                            <v-card-text v-show="productActive" v-if="config.choices">
                                <v-chip-group
                                    v-model="productConfig[config.id]"
                                    active-class="deep-purple accent-4 white--text"
                                    column
                                >
                                    <v-chip  v-for="(choice,index) in config.choices"
                                             @change="checkProductConfig"
                                             :key="index" :value="choice.value">{{choice.name[$root.$i18n.locale]}}</v-chip>

                                </v-chip-group>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </div>
                <v-expand-transition>
                <v-card-actions v-show="productActive">
                    <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="handleProductConfig(value,productConfig)"
                        :disabled="!isProductConfigured"
                    >
                        In den Warenkorb...
                    </v-btn>
                </v-card-actions>
                </v-expand-transition>
            </v-card>
</template>

<script>
import { ENTRYPOINT } from '@/config/entrypoint'

export default {

    props: {

        handleProductConfig: {
            type: Function,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
    },

    data: () => ({
        entrypoint: ENTRYPOINT,
        productActive: false,
        loading: false,
        productConfig: {},
        isProductConfigured: true,
    }),
    watch: {
        value: {
            handler: function () {
                this.productConfig= {}
                this.value.configOptions.forEach(config => {
                    this.productConfig[config.id]=config.default ?? null;
                });
                //window.console.log('watch value',this.productConfig)
            },
            deep: true
        },
        productConfig: {
            handler: function () {
                this.isProductConfigured=this.checkProductConfig();
                //window.console.log('watch productConfig',this.isProductConfigured)
            },
            deep: true
        },
    },
    created () {
        this.productConfig= {}
        this.value.configOptions.forEach(config => {
            this.productConfig[config.id]=config.default ?? null;
        });
        //window.console.log('created value',this.productConfig)
    },
    methods: {
        getTranslation (item) {
            if(!item || !item['translations']) return {};
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        checkProductConfig(){
            this.$nextTick(() => {
                this.isProductConfigured = true;
                this.value.configOptions.forEach(config => {
                    //window.console.log(this.productConfig[config.id])
                    if (typeof (this.productConfig[config.id]) == 'undefined' || this.productConfig[config.id] == null) this.isProductConfigured = false
                });
            });
        },
    }

}
</script>
