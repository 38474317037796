import list from './list'
import list_imagegallery from './list_imagegallery'
import list_photographcollection from './list_photographcollection'
import create from './create'
import update from './update'
import show from './show'
import del from './delete'

export default {
  namespaced: true,
  modules: {
    list,
    list_imagegallery,
    list_photographcollection,
    create,
    update,
    show,
    del
  }
}
