<template>
    <v-card
        :loading="loading"
        :ripple="false"
        class="mx-auto perk-aside"
        @click="perkActive=true"
    >
        <v-carousel
            hide-delimiters
            v-if="images"
            show-arrows-on-hover
            :show-arrows="images.length>1"
            height="auto"
        >
            <v-carousel-item
                class="square"
                v-for="(item,i) in images"
                :key="i"
                :style="'background-image: url('+item+')'"
            >
            </v-carousel-item>
        </v-carousel>
        <v-expand-transition>
            <v-card-text>
                <v-row
                    align="center"
                    class="no-gutters"
                    v-show="perkActive"
                >
                    <div v-if="value.numberOfPucrchases" class="grey--text">
                        {{value.numberOfPucrchases}} Unterstützer haben dies gewählt.
                    </div>
                </v-row>
                <div class="perk-description" v-html="getTranslation(value.product).description"></div>
            </v-card-text>
        </v-expand-transition>
        <div v-if="value.product.configOptions && value.product.configOptions.length>0">
            <v-expand-transition
                v-for="(config) in value.product.configOptions"
                v-bind:key="config.id"
            >
                <v-card-text class="py-0" v-if="config.choices">
                    <v-chip-group
                        v-model="perkConfig[config.id]"
                        active-class="active white--text"
                        column
                    >
                        <div class="chips-container">
                            <v-chip
                                class="perk-chip"
                                v-for="(choice) in config.choices"
                                @change="checkPerkConfig"
                                :key="choice.value"
                                :value="choice.value"
                            >
                                {{choice.name[$root.$i18n.locale]}}
                            </v-chip>
                        </div>
                    </v-chip-group>
                </v-card-text>
            </v-expand-transition>
        </div>
        <v-expand-transition>
        <v-card-actions>
            <div class="row no-gutters d-flex align-center form-control">
                <div class="col-3 my-1 mr-2">
                    <div class="custom-amount-label pl-2">oder ein-trippen:</div>
                </div>
                <div class="col">
                    <input @input="handleCustomAmountChange" :value="customAmount" class="px-2 custom-amount" id="custom-amount" type="number">
                </div>
                <div class="col-5 offset-1">
                    <div class="d-flex justify-end">
                        <img
                            :style="'width:108.25px; margin-top: 1px; margin-right: 5px; '+ (isPerkConfigured || (customAmount && customAmount!= 0)? 'cursor:pointer' : 'filter: grayscale(1);')"
                            src="@/assets/img/weiter_button.png"
                            alt="weiter"
                            @click="handleSubmit"
                        />
                    </div>
                </div>
            </div>
        </v-card-actions>
        </v-expand-transition>
    </v-card>
</template>
<style scoped>
.perk-aside {
    color: #ece5de;
    background-color: #111016;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.square {
    width: 100%; /* Set your desired width */
    padding-top: 100%; /* This ensures the height is always equal to the width */
    position: relative;
    background-size: 100% auto;
}
.perk-description {
    color: #ded8fe;
    font-size: 18px;
    margin-top: 0;
}
.perk-description>p {
    margin-bottom: 0 !important;
}
.chips-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.chips-container span{
    height: unset;
    border-radius: 22px ;
    padding: 12px 19px 11px 19px;
}
.perk-chip {
    background: #282c39 !important;
    font-size: 16px;
    font-weight: 800;
    margin: 0 auto;
    padding: 4px 16px;
}
.custom-amount-label {
    width: 100%;
    color: #ded8fe;
    font-size: 16px;
    line-height: 16px;
    font-family: none,serif;
    font-weight: bold;
}
.custom-amount {
    width: 100%;
    height: 100%;
    padding-top: 3px;
    padding-bottom: 1px;
    font-weight: 100;
    font-size: 16px;
    white-space: nowrap;
    border: 1px solid #ded8fe;
    color: #ded8fe;
    background: #282c39;
    text-align: center;
}
/* For Chrome, Safari, Edge, and Opera */
.custom-amount::-webkit-inner-spin-button,
.custom-amount::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
.custom-amount {
    -moz-appearance: textfield;
}
@media screen and (max-width: 959px) {
    ․v-list-item.theme--dark {
        padding: 0;
    }
}
</style>
<script>
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
    props: {
        handlePerkConfig: {
            type: Function,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        perkActive: false,
        loading: false,
        perkConfig: {},
        customAmount: null,
        isPerkConfigured: true,
        images: ['/img/Betrag_waehlen_square.jpg']
    }),
    watch: {
        value: {
            handler: function () {
                this.perkConfig= {}
                this.value.product.configOptions.forEach(config => {
                    this.perkConfig[config.id]=config.default ?? null;
                });
                window.console.log('watch value',this.perkConfig)
            },
            deep: true
        },
        perkConfig: {
            handler: function () {
                this.isPerkConfigured=this.checkPerkConfig();
                window.console.log('watch perkConfig',this.isPerkConfigured)
            },
            deep: true
        },
    },
    created () {
        this.perkConfig= {}
        this.value.product.configOptions.forEach(config => {
            this.perkConfig[config.id] = config.default ?? null;
        });
        window.console.log('created value',this.perkConfig)
    },
    methods: {
        getTranslation (item) {
            if(!item || !item['translations']) return {};
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        checkPerkConfig(){
            this.$nextTick(() => {
                this.customAmount = null;
                this.isPerkConfigured = true;
                this.value.product.configOptions.forEach(config => {
                    window.console.log(this.perkConfig[config.id])
                    if (typeof (this.perkConfig[config.id]) == 'undefined' || this.perkConfig[config.id] == null) {
                        this.isPerkConfigured = false
                    }
                });
            });
        },
        handleCustomAmountChange($event){
            this.customAmount = $event.target.value;
            let parts = this.customAmount.split('.');
            if (parts[1]) {
                parts[1] = parts[1].substring(0, 2);
            }
            this.customAmount = parts.join('.');
            this.perkConfig.amount = this.customAmount;
        },
        handleSubmit(){
            if (this.customAmount == 0){
                return
            }
            if(this.isPerkConfigured || this.customAmount){
                if(this.customAmount) {
                    this.perkConfig.amount = this.customAmount;
                }
                this.handlePerkConfig(this.value,this.perkConfig)
            }
        }
    }
}
</script>
