<template>
    <div style="position:fixed; top:0; width: 100vw; height: 100vh; overflow:auto">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <div class="reportDialog">
            <v-tooltip left >
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click="goToLanding" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>
            <v-card-title
                class="text-h5"
                primary-title
            >
                <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                <v-card-subtitle>
                    {{$t('app.ContactEmailAndText')}}
                    <address>
                        <a class="custom-font" href='mailto:info@nimmerso.com'> info@nimmerso.com</a>
                        <v-list-item-action>
                            <v-btn @click="copyToClipboard('info@nimmerso.com')" icon>
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </address>
                </v-card-subtitle>
            </v-card-title>
            <v-list-item v-if="this.token_data" @click="openFeedback" data-v-step="4" class="ml-2">
                <v-list-item-action>
                    <v-icon>mdi-chat-plus</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('app.Feedback') }}</v-list-item-title>
            </v-list-item>
                <v-dialog
                    v-model="showFeedbackDialog"
                    max-width="500"
                    style="z-index: 10001"
                    transition="dialog-bottom-transition"
                >
                    <v-card>
                        <v-card-text>
                            <FeedbackForm
                                :handle-update-field="feedbackUpdateField"
                                :handle-submit="feedbackSubmit"
                                :handle-close="feedbackClose"
                                :handle-show-report-bug="showReportBug"
                                :values="feedbackItem"
                            ></FeedbackForm>
                        </v-card-text>
                    </v-card>
                </v-dialog>

            <PhotographCollectionForm
                :handle-submit="onSendForm"
                :handle-update-field="updateField"
                :values="item"
                :errors="violations"/>
            <nimmerso-footer></nimmerso-footer>
        </div>

    </div>
</template>
<style>
.reportDialog {

    background: rgba(0,0,0,0.75);
    max-width: 600px;
    min-width: 300px;
    overflow: auto;
    min-height: 100vh;

    font-family: "Roboto Condensed", Arial, sans-serif;
}
.custom-font{
    font-style: normal!important;
}
.reportDialog:after {
    background-image: url('/img/614afaf9d2cd3.jpg');
    content: "";
    background-size: cover;
    background-position: center;
    background-color: gray;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: -1;
}
.closeWinButton {
    z-index: 10001;
    top: max( 30px, env( safe-area-inset-top, 30px ) );
}
</style>
<script>

import{mapActions} from 'vuex'
import PhotographCollectionForm from './ReportForm'
import NimmersoFooter from "../NimmersoFooter"

// const {mapActions} = createNamespacedHelpers('photographcollection/create')
import { mapGetters } from 'vuex'
import FeedbackForm from "@/components/comment/FeedbackForm.vue";

export default {
    components: {
        FeedbackForm,
        PhotographCollectionForm,
        NimmersoFooter
    },

    data () {
        return {
            item: {
                licenseName: 'not defined yet',
                copyrightHoldingPerson: this.token_data ? '/people/'+this.token_data.person_id : null,
            },
            showFeedbackDialog: false,
            feedbackItem: {},
        }
    },

    computed: mapGetters({
        error: 'photographcollection/create/error',
        isLoading: 'photographcollection/create/isLoading',
        created: 'photographcollection/create/created',
        violations: 'photographcollection/create/violations',
        personItem: 'person/show/retrieved',
        token_data: 'user/token/token_data'
    }),
    created() {

        if (this.token_data && this.token_data.person_id!==null) {
            this.item.copyrightHoldingPerson= '/people/'+this.token_data.person_id;
        }else{
            window.console.log('no tokenData!')
        }
    },

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }

            this.$router.push({name: 'PhotographCollectionCreated'})
        },
        token_data: function () {
            this.item.copyrightHoldingPerson= '/people/'+this.token_data.person_id;
        },
    },

    methods: {
        ...mapActions({
            create:'photographcollection/create/create',
            createComment: 'comment/create/create'
        }),
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                window.console.log('Copied to clipboard!');
            }).catch(err => {
                window.console.error('Failed to copy: ', err);
            });
        },
        openFeedback(){
            this.drawer=false;
            this.feedbackItem={
                text: "",
                additionalData:{
                    rating: null
                }
            };
            this.showFeedbackDialog=true;
        },
        feedbackUpdateField(field,value){
            Object.assign(this.feedbackItem, { [field]: value })
        },
        feedbackSubmit(){
            window.console.log(this.feedbackItem);
            this.createComment(this.feedbackItem);
            this.showFeedbackThxDialog=true;
            this.showFeedbackThxDialogShowLink = this.feedbackItem.additionalData.rating >= 4;

            this.showFeedbackDialog=false;

        },
        showReportBug(){
            this.feedbackClose();
            this.openReportBug();
        },
        feedbackClose(){
            this.showFeedbackDialog=false;
        },
        goToLanding() {
            this.$router.push('/retro');
        },
        onSendForm () {
            this.create(this.item)
        },

        updateField (field, value) {
            Object.assign(this.item, {[field]: value})
        },
        closeWin () {
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
        },
    }
}
</script>
