<template>

        <v-dialog
            v-model="userResetPasswordOkDialog"
            fullscreen
            content-class="justify-center"
            fill-height
            justify="center"
            class="justify-center"
            fluid
        >

            <div class="userResetPasswordOkDialog">

                <v-card-title
                    class="text-h5"
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>

                <v-card-title class="text-center justify-center">
                    {{$t('app.UserResetPassword.OkTitle')}}
                </v-card-title>
                <v-card-subtitle class="text-center justify-center">
                    {{$t('app.UserResetPassword.OkDescription')}}
                </v-card-subtitle>
                <v-card-actions class="text-center justify-center"><v-btn text small :to="{name:'Login'}">{{$t('app.activatedloginbutton')}}</v-btn>
                </v-card-actions>
                <nimmerso-footer></nimmerso-footer>
            </div>
        </v-dialog>

</template>
<style>
    .userResetPasswordOkDialog {
        /*background: transparent !important;
        margin-left: auto !important;
        margin-right: auto !important;
         */
        max-width: 600px;
        min-width: 300px;
        overflow: auto;

        font-family: "Roboto Condensed", Arial, sans-serif;
    }
    .userResetPasswordOkDialog:after {
        background-image: url('~@/assets/img/Login_background.jpg');
        content: "";
        background-size: cover;
        background-position: center;
        background-color: gray;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: -1;
    }
</style>

<script>
    import NimmersoFooter from "../NimmersoFooter"
    export default {
        name: 'Login',
        components: {
            NimmersoFooter
        },
        data() {
            return {
                userResetPasswordOkDialog: true,
            }
        },
        methods: {

        }
    }
</script>

