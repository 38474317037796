<template>

        <v-dialog
            v-model="resetPasswordOkDialog"
            fullscreen
            content-class="justify-center text-center"
            justify="center"
            class="justify-center text-center"
        >

            <div class="resetPasswordOkDialog">

                <v-card-title
                    class="text-h5"
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>

                <v-card-title class="text-center justify-center">
                    {{$t('app.ResetPassword.Sended1')}}
                </v-card-title>
                <v-card-subtitle class="text-center justify-center">
                    {{$t('app.ResetPassword.Sended2')}}
                </v-card-subtitle>
                <v-card-text class="text-center justify-center" v-html="$t('app.ResetPassword.Sended3')">
                </v-card-text>
                <nimmerso-footer></nimmerso-footer>
            </div>
        </v-dialog>

</template>
<style>
    .resetPasswordOkDialog {
        /*background: transparent !important;
        margin-left: auto !important;
        margin-right: auto !important;

         */
        background: rgba(0,0,0,0.75);
        max-width: 600px;
        min-width: 300px;
        overflow: auto;

        font-family: "Roboto Condensed", Arial, sans-serif;
    }
    .resetPasswordOkDialog:after {
        background-image: url('~@/assets/img/Login_background.jpg');
        content: "";
        background-size: cover;
        background-position: center;
        background-color: gray;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: -1;
    }
</style>

<script>
    import NimmersoFooter from "../NimmersoFooter"
    export default {
        name: 'Login',
        components: {
            NimmersoFooter
        },
        data() {
            return {
                resetPasswordOkDialog: true,
            }
        },
        methods: {

        }
    }
</script>

