<template>
    <form @submit.prevent="handleSubmit(item)">

        <div class="form-group">
            <v-textarea
                counter
                id="retrosnap_text"
                v-model="item.text"
                :label="$t('app.Description')+':'"
                :class="['form-control', isInvalid('text') ? 'is-invalid' : '']"
                :placeholder="$t('app.RetroSnapTextPlaceholder')"
                @input="handleTextUpdate"
            ></v-textarea>
            <div
                v-if="isInvalid('text')"
                class="invalid-feedback">{{ violations.text }}
            </div>
        </div>
        <div style="padding-bottom:8px">
        <v-btn small color="primary"
               type="submit"
        ><span>{{ $t('app.Submit') }}</span><v-icon>mdi-content-save</v-icon>
        </v-btn>
        </div>
    </form>
</template>

<script>
    export default {
        props: {
            handleSubmit: {
                type: Function,
                required: true
            },

            handleUpdateField: {
                type: Function,
                required: true
            },

            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                }
            }
        },

        computed: {
            // eslint-disable-next-line
            item() {
                return this.initialValues || this.values
            },

            violations() {
                return this.errors || {}
            }
        },

        methods: {
            isInvalid(key) {
                return Object.keys(this.violations).length > 0 && this.violations[key]
            },
            handleTextUpdate() {
                this.handleUpdateField('text',this.item.text);
            }
        }
    }
</script>
