<template>

    <div>
        <v-dialog
            v-model="registerdialog"
            fullscreen
            content-class="registerDialogParent"
            scrollable
        >
            <v-snackbar
                v-if="isLoading"
                v-model="isLoading"
                :timeout="5000"
                :top="true"
                color="info"
            >Loading...
            </v-snackbar>
            <v-snackbar
                v-if="error"
                v-model="error"
                :timeout="5000"
                :top="true"
                color="error"
            >
                <v-icon>mdi-alert</v-icon>
                {{ error }}
            </v-snackbar>
            <div class="registerDialog justify-center text-center">
                <v-card-title
                    class="text-h5"
                    primary-title
                >
                    <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                    src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
                </v-card-title>
                <UserForm
                    :handle-submit="onSendForm"
                    :handle-update-field="updateField"
                    :values="item"
                    :errors="violations"></UserForm>
                <nimmerso-footer></nimmerso-footer>


            </div>

        </v-dialog>

    </div>

</template>
<style>

.registerDialog {
    /*background: transparent !important;
    margin-left: auto !important;
    margin-right: auto !important;*/
    background: rgba(0, 0, 0, 0.75);
    max-width: 600px;
    min-width: 300px;
    height: fit-content;
    min-height: 100vh;
    font-family: "Roboto Condensed", Arial, sans-serif;
}

.registerDialogParent {
    background-image: url('~@/assets/img/Login_background.jpg');
    background-size: cover;
    background-position: center;
    background-color: gray;
}

.registerDialog .v-input fieldset {
    background-color: rgba(0, 0, 0, 0.5)
}

</style>
<script>

import { ENTRYPOINT } from '@/config/entrypoint'
import UserForm from './Form'
import NimmersoFooter from '../NimmersoFooter'
import { mapActions, mapGetters } from 'vuex'
import isWebpSupported from '../../utils/isWebpSupported'

export default {
    components: {
        NimmersoFooter,
        UserForm,
    },
    mounted () {
        this.webpSupported=isWebpSupported();
    },

    data () {
        return {
            entrypoint: ENTRYPOINT,
            webpSupported: true,
            item: {},
            registerdialog: true,
            plausible: null
        }
    },

    computed: {
        ...mapGetters({
            error: 'user/create/error',
            isLoading: 'user/create/isLoading',
            created: 'user/create/created',
            violations: 'user/create/violations'
        }),
    },

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }
            this.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
            this.$router.push({name: 'Registered', params: {id: created['@id']}})
        }
    },

    methods: {
        ...mapActions({
            create: 'user/create/create',
        }),

        onSendForm () {
            this.updateField('email', this.item.email.toLowerCase())
            this.updateField('receiveEmailsLogins', true)
            this.updateField('receiveEmailsUpdates', true)
            this.updateField('receiveEmailsSurveys', true)
            this.updateField('receiveEmailsOffers', true)
            if (this.plausible !== null) {
                this.plausible('register-button-click', {props: {logged_in: false, appinstalled: window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches}})
            }

            this.create(this.item)
        },

        updateField (field, value) {
            if (field === 'email') {
                Object.assign(this.item, {[field]: value.trim()})
            } else {
                Object.assign(this.item, {[field]: value})
            }

        },
        carouselItemClick(item){
            const documentType = item['documentType'];
            this.$router.push({name: documentType + 'Show', params: {id: decodeURIComponent(item['id'])}})

        }

    }
}
</script>
