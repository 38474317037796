<template>
    <div style="position:fixed; top:0; width: 100vw; height: 100vh; overflow:auto">

        <v-snackbar
            v-if="created"
            v-model="created"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ created['@id'] }} created.
        </v-snackbar>
        <v-snackbar
            v-if="updated"
            v-model="updated"
            :timeout="5000"
            :top="true"
            color="success"
        >{{ updated['@id'] }} updated.
        </v-snackbar>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="deleteLoading"
            v-model="deleteLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <v-card>
            <v-card-title>Edit {{ item && item['@id'] }}</v-card-title>
            <v-card-text>
                <v-container>
                    <PhotographForm
                        v-if="item"
                        :handle-submit="onSendForm"
                        :handle-update-field="updateField"
                        :values="item"
                        :errors="violations"
                        :initial-values="retrieved"/>
                </v-container>
            </v-card-text>

            <v-footer class="text-justify pa-4">
                <v-btn small dark v-if="item"
                       @click="$router.back()">{{ $t('app.BackToList') }}
                </v-btn>&nbsp;
                <v-spacer></v-spacer>

            </v-footer>
            <v-card-actions>

            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PhotographForm from './Form.vue'

export default {
    components: {
        PhotographForm
    },

    data () {
        return {
            item: {}
        }
    },

    computed: {
        ...mapGetters({
            isLoading: 'photograph/update/isLoading',
            error: 'photograph/update/error',
            deleteError: 'photograph/del/error',
            deleteLoading: 'photograph/del/isLoading',
            created: 'photograph/create/created',
            deleted: 'photograph/del/deleted',
            retrieved: 'photograph/update/retrieved',
            updated: 'photograph/update/updated',
            violations: 'photograph/update/violations'
        })
    },

    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        deleted: function (deleted) {
            if (!deleted) {
                return
            }

            this.$router.push({name: 'PhotographList'})
        }
    },

    beforeDestroy () {
        this.reset()
    },

    created () {
        this.retrieve(decodeURIComponent(this.$route.params.id))
    },

    methods: {
        ...mapActions({
            createReset: 'photograph/create/reset',
            deleteItem: 'photograph/del/del',
            delReset: 'photograph/del/reset',
            retrieve: 'photograph/update/retrieve',
            updateReset: 'photograph/update/reset',
            update: 'photograph/update/update',
            updateRetrieved: 'photograph/update/updateRetrieved'
        }),

        del () {
            if (window.confirm('Are you sure you want to delete this photograph ?')) {
                this.deleteItem(this.retrieved)
            }
        },

        reset () {
            this.updateReset()
            this.delReset()
            this.createReset()
        },

        onSendForm () {
            if(this.retrieved.collection['@id']) this.updateRetrieved({['collection']:  this.retrieved.collection['@id']})
            this.update()
        },

        updateField (field, value) {
            this.updateRetrieved({[field]: value})
        }
    }
}
</script>
