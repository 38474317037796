<template>
    <v-dialog
        v-model="deleteAccountDialog"
        fullscreen
        content-class="justify-center"
        fill-height
        justify="center"
        class="justify-center"
        fluid
    >
        <div class="loginDialog justify-center text-center">
            <v-card-title
                class="text-h5"
                primary-title
            >
                <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
            </v-card-title>
            <v-card-text class="text-left">
                <div v-html="markdownToHtml"></div>
            </v-card-text>
            <nimmerso-footer class="text-center justify-center"></nimmerso-footer>
        </div>
    </v-dialog>

</template>

<script>
import { marked } from 'marked';
import markdown from 'raw-loader!../../locales/delete_account_de.md'
import NimmersoFooter from "../NimmersoFooter"
export default {
    components: {
        NimmersoFooter
    },
    computed: {
        markdownToHtml(){
            return marked(this.markdown);

        }
    },
    data () {
        return {
            markdown: markdown,
            deleteAccountDialog: true,
        }
    },

    created () {

    },

    methods: {}
}
</script>
