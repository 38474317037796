<template id="compare-template">
    <div id="slider-container" class="slider-container" v-resize="updateViewer" @mouseup="isDragging=false"
         @mousemove="trackDrag"
         v-touch="{  move: trackDrag}"
    >
        <span class="slider-handle"
              :style="'left:' + this.widthPercent"
              @mousedown="sliderHandleMousedown"
              @mouseup="isDragging=false"
              v-touch="{  start: sliderHandleTouchStart, end: () => {this.isDragging=false;}}"
        ></span>
    </div>
    <!--<img class="comparify-image"
         :src="entrypoint+'/thumbnail/big/'+item['filePath'].replace(/\.jpeg/,'.webp')"
    />
    <img class="comparify-image"
         :src="entrypoint+'/thumbnail/'+(colorized ? 'colorized.' : '')+'big/'+item['photograph']['filePath'].replace(/\.jpeg/,'.webp')"
    />-->
</template>

<style>
#slider-container {
    position: relative;
    height: 100vh;
}

.slider-handle {

    position: absolute;

    height: 44px;
    width: 44px;

    /* center the element */
    left: 50%;
    top: 50%;

    margin-left: -22px;
    margin-top: -22px;

    border-radius: 50%;

    background: #000000 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="%23FFF" d="M13 21l-5-5 5-5m6 0l5 5-5 5"/></svg>') no-repeat center center;
    cursor: move;

    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.6), inset 0 1px 0 rgba(255, 255, 255, 0.3);
    z-index: 2;

}

.slider-handle.draggable {
    background-color: #445b7c;
}

</style>

<script>
import OpenSeadragon from 'openseadragon'
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        colorized: {
            type: Boolean,
            required: true
        },
        onViewerClick: {
            type: Function,
            required: true
        },
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            tilesurl: process.env.VUE_APP_TILES_URL,
            viewer: null,
            isLoading: true,
            middle: null,
            leftImage: false,
            rightImage: false,
            leftRect: null,
            rightRect: null,
            oldSpringX: 0.5,
            containerWidth: null,
            containerOffest: null,
            minLeft: null,
            maxLeft: null,
            dragWidth: null,
            xPosition: null,
            isDragging: false,
            widthPercent: '50%'
        }
    },
    watch: {
        colorized: function () {
            this.updateViewer()
        },
    },
    mounted () {

    },
    created () {
        this.$nextTick(() => {
            let options = {
                id: 'slider-container',
                tileSources: [],
                maxZoomLevel: 20,
                crossOriginPolicy: 'Anonymous',
                //minZoomLevel: 0.2,
                showNavigator: false,
                visibilityRatio: 1,
                //navigatorPosition: 'TOP_LEFT',
                showNavigationControl: false,
                homeFillsViewer: false,
                gestureSettingsTouch: {
                    pinchRotate: false
                },
                debugMode: false,
                immediateRender: true
            }
            this.viewer = OpenSeadragon(options)
            this.viewer.addHandler('canvas-click', (evt)=>{
                if(evt.quick){
                    this.onViewerClick();
                }
            });
            this.handle = document.getElementsByClassName('slider-handle')[0]
            this.container = document.getElementsByClassName('slider-container')[0]

            this.middle = new OpenSeadragon.Point(this.container.getBoundingClientRect().width / 2, this.container.getBoundingClientRect().height / 2)
            //window.console.log('this.middle',this.middle.x,this.middle.y);
            this.leftRect = new OpenSeadragon.Rect(0, 0, 0, 0)
            this.rightRect = new OpenSeadragon.Rect(0, 0, 0, 0)

            // Handle pan and zoom events
            this.viewer.addHandler('animation', this.imagesClipAggressive)
            this.viewer.addHandler('animation-start', this.imagesClip)

            this.viewer.addHandler('canvas-key', (evt) => {
                //[ - / _, shift+W, shift+up arrow ] - zoom viewport out
                //[ = / +, shift+S, shift+down arrow ] - zoom viewport in

                if (
                    evt.originalEvent.type !== 'keydown' || (
                        evt.originalEvent.key !== '-' &&
                        evt.originalEvent.key !== '_' &&
                        !(evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) &&
                        !(evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true) &&

                        evt.originalEvent.key !== '+' &&
                        evt.originalEvent.key !== '=' &&
                        !(evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) &&
                        !(evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                    )
                ) {
                    return
                }
                //Zoom-In:
                if (
                    evt.originalEvent.key === '+' ||
                    evt.originalEvent.key === '=' ||
                    (evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) ||
                    (evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                ) {
                    this.viewer.viewport.zoomBy(3)
                }
                //Zoom-Out:
                if (
                    evt.originalEvent.key === '-' ||
                    evt.originalEvent.key === '_' ||
                    (evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) ||
                    (evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true)
                ) {
                    this.viewer.viewport.zoomBy(-3)
                }

                evt.preventDefault = true
                //window.console.log(evt)

            })
            this.updateViewer()

        })
    },
    methods: {

        updateViewer () {
            if (!this.item || this.viewer===null) return

            this.middle = new OpenSeadragon.Point(this.container.getBoundingClientRect().width / 2, this.container.getBoundingClientRect().height / 2)
            //window.console.log('this.middle',this.middle.x,this.middle.y);
            this.leftRect = new OpenSeadragon.Rect(0, 0, 0, 0)
            this.rightRect = new OpenSeadragon.Rect(0, 0, 0, 0)
            this.oldSpringX= 0.5;
            this.leftImage=false;
            this.rightImage=false;
            this.widthPercent='50%'
            let size = this.item.photograph.size
            this.imageWidth = size[0]
            this.imageHeight = size[1]
            this.maxLevel = size[0] / 256
            this.maxLevel = Math.ceil(Math.log(Math.max(this.imageWidth, this.imageHeight) / 256))
            this.viewer.clearOverlays()
            //window.vvv=this.viewer;
            this.viewer.addTiledImage({
                tileSource: {
                    tileSize: 256,
                    minLevel: 0,
                    maxLevel: this.maxLevel,
                    width: this.imageWidth, //6400 //6515
                    height: this.imageHeight, //4096 //4116
                    getTileUrl: (level, x, y) => {
                        return this.tilesurl + (this.colorized ? 'colorized/' : '') + this.item.photograph.filePath + '/' + level + '-' + x + '-' + y + (this.webpSupported ? '.webp' : '.jpeg')
                    }
                },
                success: (event) => {
                    //window.console.log('ready left',event)
                    this.leftImage = event.item
                    //window.lll=event.item;
                    this.imagesLoaded()
                }
            })
            this.viewer.addTiledImage({
                tileSource: {
                    type: 'image',
                    url: this.entrypoint + '/thumbnail/big/' + this.item['filePath'].replace(/\.jpeg/, '.webp'),
                    buildPyramid: false
                },
                success: (event) => {
                    //window.console.log('ready right', event)
                    this.rightImage = event.item
                    //window.rrr = event.item;
                    this.imagesLoaded()
                }
            })

        },
        updateMiddle (offset) {
            this.middle.x = offset
        },
        imagesLoaded () {
            if (this.leftImage && this.rightImage) {
                //window.console.log('imagesLoaded');

                this.leftRect.width = this.leftImage.getContentSize().x
                this.rightRect.width = this.rightImage.getContentSize().x

                this.leftRect.height = this.leftImage.getContentSize().y
                this.rightRect.height = this.rightImage.getContentSize().y
                //window.console.log( this.leftImage.getContentSize().x,this.rightImage.getContentSize().x);

                this.imagesClip()

                this.initClip()

            }
        },
        imagesClipAggressive () {
            if (this.leftImage && this.rightImage) {
                const newSpringX = this.viewer.viewport.centerSpringX.current.value
                const deltaSpringX = newSpringX - this.oldSpringX
                this.oldSpringX = newSpringX

                const fixedMiddle = this.viewer.viewport.viewerElementToViewportCoordinates(this.middle)
                fixedMiddle.x += deltaSpringX

                const rox = this.rightImage.viewportToImageCoordinates(fixedMiddle).x
                const lox = this.leftImage.viewportToImageCoordinates(fixedMiddle).x

                this.imagesClipShared(rox, lox)
            }
        },
        imagesClip () {
            if (this.leftImage && this.rightImage) {
                const lox = this.leftImage.viewerElementToImageCoordinates(this.middle).x
                const rox = this.rightImage.viewerElementToImageCoordinates(this.middle).x
                //window.console.log('imagesClip',this.middle,rox,lox);
                this.imagesClipShared(rox, lox)
            }
        },

        imagesClipShared (rox, lox) {
            //window.console.log('imagesClipShared',rox,lox);
            this.rightRect.x = rox
            this.rightRect.width = this.rightImage.getContentSize().x - rox

            this.leftRect.width = lox

            this.leftImage.setClip(this.leftRect)
            this.rightImage.setClip(this.rightRect)
        },
        initClip () {

            //console.log( leftImage, rightImage );
            //console.log( rightImage.getContentSize() );

            // We will assume that the width of the handle element does not change
            this.dragWidth = this.handle.clientWidth

            // However, we will track when the container resizes

            // Retrieve initial container dimention
            this.updateContainerDimensions()
            //window.addEventListener( 'resize', this.updateContainerDimensions.bind(this) );

        },
        sliderHandleMousedown (e) {
            this.isDragging = true
            this.xPosition = e.target.offsetLeft + this.dragWidth - e.pageX
            e.preventDefault()
        },
        sliderHandleTouchStart (e) {
            this.isDragging = true
            this.xPosition = e.target.offsetLeft + this.dragWidth - e.touchstartX

        },
        trackDrag (e) {
            if (!this.isDragging) return
            let leftValue = (e.pageX ? e.pageX + this.xPosition - this.dragWidth : e.touchmoveX + this.xPosition - this.dragWidth)
            //constrain the draggable element to move inside its container
            leftValue = Math.max(leftValue, this.minLeft)
            leftValue = Math.min(leftValue, this.maxLeft)

            //
            var widthPixel = (leftValue + this.dragWidth / 2 - this.containerOffset)
            var widthFraction = widthPixel / this.containerWidth
            this.widthPercent = widthFraction * 100 + '%'

            this.updateMiddle(widthPixel)
            this.imagesClip()

        },
        updateContainerDimensions () {
            window.console.log('updateContainerDimensions')
            if (typeof this.container === 'undefined') return
            window.vvv = this.viewer

            this.containerWidth = this.container.clientWidth

            const offset = {
                top: this.container.getBoundingClientRect().top + window.scrollY,
                left: this.container.getBoundingClientRect().left + window.scrollX,
            }

            this.containerOffset = offset.left

            window.console.log({
                containerOffset: this.containerOffset,
                containerWidth: this.containerWidth,
                dragWidth: this.dragWidth
            })

            this.minLeft = this.containerOffset + 10
            this.minLeft = this.containerOffset + 10
            this.maxLeft = this.containerOffset + this.containerWidth - this.dragWidth - 10

            this.widthPercent = '50%'
            var widthPixel = (this.dragWidth / 2 - this.containerOffset)
            this.updateMiddle(widthPixel)
            this.imagesClip()

            //window.console.log(offset, this.containerWidth, this.containerOffset)

            // Spoof the mouse events

            let event = {
                pageX: this.handle.offsetLeft + this.dragWidth / 2,
                target: this.handle,
                preventDefault: () => {}
            }
            //window.console.log(this.handle.offsetLeft, this.dragWidth)
            this.sliderHandleMousedown(event)

            event.target = this.container
            this.trackDrag(event)
            this.isDragging = false

        }

    }
}
</script>
