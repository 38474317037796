<template>
    <v-form @submit.prevent="handleSubmit(item)" v-if="item">
        <v-card
            class="mx-auto"
        >
            <v-row no-gutters>
                <v-col cols="12" sm="12">
                    <v-img v-if="item.filePath" contain
                               :src="entrypoint+'/thumbnail/display/'+item.filePath.replace(/\.jpeg/,'.webp')"/>

                    <v-list-item>
                        <v-list-item-subtitle>{{item.originalName}}</v-list-item-subtitle>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>

        <div class="form-group mb-4">
            <v-autocomplete
                v-model="item.categories"
                @change="handleUpdateCategories()"
                multiple
                :items="categories"
                item-text="label"
                item-value="id"
                chips
                deletable-chips
                flat
                outlined
                hide-no-data
                hide-details
                :label="$t('app.Categories')"
            ></v-autocomplete>
            <!--<treeselect
                class="py-5"
                v-model="item.categories"
                :multiple="true"
                :flat="true"
                :options="categories"
                :clear-on-select="true"
                :label="$t('app.Categories')"
                placeholder="Select categories..."
                @input="handleUpdateField('categories', item.categories)"
            >
            </treeselect>-->
            <div
                v-if="isInvalid('keywords')"
                class="invalid-feedback">{{ violations.keywords }}
            </div>
        </div>

        <div class="form-group">
            <v-row no-gutters>
                <v-col
                    cols="12"
                >
                    <v-text-field
                        v-model="item.dateText"
                        :class="['form-control', isInvalid('dateText') ? 'is-invalid' : '']"
                        type="text"
                        label="Datum"
                        required="true"
                        placeholder="Datum als Text"
                        hint="z.B. 11.3.1925 oder 25-29 (wird zu 1925-1929)"
                        outlined
                        @input="handleDateText"
                        @change="fixShortYear"
                    />
                    <div
                        v-if="isInvalid('dateText')"
                        class="invalid-feedback">{{ violations.dateText }}
                    </div>
                </v-col>
                <v-col cols="3"
                       md="4"><v-text-field
                    :full-width="false"
                    id="photograph_fromYear"
                    v-model="item.fromYear"
                    :class="['form-control', isInvalid('fromYear') ? 'is-invalid' : '']"
                    label="Jahr (min.)"
                    required="true"
                    placeholder="Jahr (min.)"
                    hint="z.B. 1925"
                    :rules="[rules.fromYear]"
                    maxlength="4"
                    outlined
                    @input="handleUpdateField('fromYear', item.fromYear)"></v-text-field></v-col>
                <v-col cols="1">-</v-col>
                <v-col cols="3"
                       md="4"><v-text-field
                    :full-width="false"
                    id="photograph_toYear"
                    v-model="item.toYear"
                    :class="['form-control', isInvalid('toYear') ? 'is-invalid' : '']"
                    label="Jahr (max.)"
                    :rules="[rules.toYear]"
                    required="true"
                    placeholder="Jahr (max.)"
                    hint="z.B. 1925"
                    maxlength="4"
                    outlined
                    @input="handleUpdateField('toYear', item.toYear)"></v-text-field>
                </v-col>
            </v-row>

        </div>

        <v-tabs v-model="translationTab" v-if="item.translations.filter">
            <div id="tabBar">
                <v-tab v-for="(translation, key) in item.translations.filter((t)=>userLocales.indexOf(t.locale) > -1)" :key="'tab-'+key" :href="'#tab-'+key">
                    {{locales[translation.locale]}}
                </v-tab>
            </div>
            <v-tabs-items v-model="translationTab">
                <v-tab-item
                    v-for="(translation, key) in item.translations.filter((t)=>userLocales.indexOf(t.locale) > -1)" :key="'tabItem-'+key" :value="'tab-' + key"
                >
                    <v-card flat>
                        <div class="form-group">

                            <v-text-field
                                v-model="item.translations[key].name"
                                :class="['font-weight-bold','form-control', isInvalid('name') ? 'is-invalid' : '']"
                                type="text"
                                label="Title"
                                required="true"
                                placeholder="the Title of the item"
                                outlined
                                @input="handleUpdateField('translations', item.translations)"
                            />
                            <div
                                v-if="isInvalid('name')"
                                class="invalid-feedback">{{ violations.name }}
                            </div>
                        </div>
                        <div class="form-group">
                            <label
                                class="form-control-label">Text zum Bild</label>
                            <tiptap-vuetify v-if="item"
                                v-model="item.translations[key].description"
                                :extensions="tiptapExtensions"
                                :toolbar-attributes="{ color: '#1E1E1E' }"
                                :card-props="{ outlined: true }"
                                placeholder="Beschreibenden Text einfügen..."
                                label="Info"
                                @input="handleUpdateField('translations', item.translations)"
                            />
                            <div
                                v-if="isInvalid('description')"
                                class="invalid-feedback">{{ violations.description }}
                            </div>
                        </div>
                        <!--<div class="form-group">
                            <v-text-field
                                v-model="item.translations[key].material"
                                type="text"
                                outlined
                                label="Material"
                                placeholder="A material that something is made from, e.g. leather, wool, cotton, paper."
                                @input="handleUpdateField('translations', item.translations)"/>
                            <div
                                v-if="isInvalid('material')"
                                class="invalid-feedback">{{ violations.material }}
                            </div>
                        </div>-->
                        <v-text-field
                            v-model="item.translations[key].locale"
                            type="hidden"
                            :id="'_translation_'+ key +'_locale'"
                            class="form-control"

                        />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <div class="form-group">
            <MapWidget

                :handle-update-field="handleGeoUpdate"
                :value="item.place ? item.place : {}"
                :place-keys="item.placeKeys"
                :class="[isInvalid('place') ? 'is-invalid' : '']"
            />
            <div
                v-if="isInvalid('geo')"
                class="invalid-feedback">{{ violations.geo }}
            </div>
        </div>

        <div class="form-group" v-if="isEditor || isAdmin">
            <label
                class="form-control-label">Internal Rating</label>
            <span class="mr-2">
                ({{ item.internalRating }})
            </span>
            <v-rating
                id="photograph_internalRating"
                v-model="item.internalRating"
                :class="['mr-2','form-control', isInvalid('internalRating') ? 'is-invalid' : '']"
                length="10"
                @input="handleUpdateField('internalRating', item.internalRating)"/>
            <div
                v-if="isInvalid('internalRating')"
                class="invalid-feedback">{{ violations.internalRating }}
            </div>
        </div>
<!--
        <div class="form-group" v-if="isEditor() || isAdmin()">
            <v-checkbox
                id="photograph_isFamilyFriendly"
                v-model="item.isFamilyFriendly"
                :class="['form-control', isInvalid('isFamilyFriendly') ? 'is-invalid' : '']"
                type="checkbox"
                label="Is the RetroPic Family Friendly?"
                placeholder="indicates whether this content is family friendly"
                @change="handleUpdateField('isFamilyFriendly', item.isFamilyFriendly)"/>
            <div
                v-if="isInvalid('isFamilyFriendly')"
                class="invalid-feedback">{{ violations.isFamilyFriendly }}
            </div>
        </div>
        -->
        <div class="form-group">
            <v-checkbox
                id="photograph_isHidden"
                v-model="item.isHidden"
                :class="['form-control', isInvalid('isHidden') ? 'is-invalid' : '']"
                type="checkbox"
                label="Is the RetroPic hidden?"
                placeholder="indicates whether the RetroPic should appear in feed."
                @change="handleUpdateField('isHidden', item.isHidden)"/>
            <div
                v-if="isInvalid('isHidden')"
                class="invalid-feedback">{{ violations.isHidden }}
            </div>
        </div>

        <!--<div class="form-group">
            <label
                for="depth_map"
                class="form-control-label">Depth-Map</label>
            <depth-map-draw v-if="item.filePath"
                            id="depth_map"
                            :src="item.filePath"
                            :depthMapPath="item.depthMapPath"
                            :onDepthMapDataUpdate="onDepthMapDataUpdate"
            ></depth-map-draw>
        </div>-->



        <v-btn color="primary" class="mt-5" small dark type="submit"
        ><v-icon>mdi-content-save</v-icon>{{ $t('app.Submit') }}
        </v-btn>
    </v-form>

</template>


<script>
import { LOCALES } from '@/config/locales'
import { mapActions, mapGetters } from 'vuex'
//import UploadForm from './UploadForm'
import { ENTRYPOINT } from '@/config/entrypoint'
import MapWidget from '../map/Widget'

//import DepthMapDraw from './DepthMapDraw'
import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    ListItem,
    Link,
    Blockquote,
} from 'tiptap-vuetify'

export default {
    components: {
        TiptapVuetify,
        //UploadForm,
        MapWidget,
        //Treeselect,
        //DepthMapDraw
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },

        handleUpdateField: {
            type: Function,
            required: true
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {
            }
        },

        initialValues: {
            type: Object,
            default: () => {

            }
        }
    },
    data () {
        return {
            // default options, see more options at: http://codemirror.net/doc/manual.html#config
            rules: {
                fromYear: value => {
                    const pattern = /^(\d{4,4})$/
                    if(value==='') return true;
                    if(!pattern.test(value)) return 'Invalid Year.';
                    if(value < 1850){
                        return 'Jahr (min.) muss größer oder gleich 1850 sein'
                    }
                    return true;
                },
                toYear: value => {
                    window.console.log(value);
                    const pattern = /^(\d{4,4})$/
                    if(value==='') return true;
                    if(!pattern.test(value)) return 'Invalid Year.';
                    if(value < this.item.fromYear){
                        return 'Jahr (max.) muss größer older gleich sein als Jahr (min.)'
                    }
                    return true;
                },
            },
            translationTab: null,
            tiptapExtensions: [
                Blockquote,
                Link,
                Underline,
                Italic,
                Bold,
                ListItem
            ],
            locales: LOCALES,
            userLocales: ['de'],
            entrypoint: ENTRYPOINT,
            categories: [],
            retrosnapOfs: [],
            /*listFilters: {
                collection: null
            },*/
            parentPhotographLoading: false,
            parentPhotographSearch: null,
        }

    },

    computed: {
        // eslint-disable-next-line
        item () {
            let values = JSON.parse( JSON.stringify(this.initialValues ? this.initialValues : this.values));
            let newtranslations = []
            let translations = values.translations
            Object.keys(LOCALES).map(function (objectKey, index) {
                let translation = {
                    name: '',
                    description: '',
                    locale: objectKey
                }
                if (values.translations) {

                    for (let iindex in translations) {
                        let val = translations[iindex]
                        if (val.locale === objectKey) {
                            translation = val
                        }
                    }
                }
                //newtranslations[index] = translation
                newtranslations.push(translation)
                window.console.log(newtranslations[index]);

            })
            values.translations = newtranslations

            return values
        },
        isAdmin(){
            return (this.token_data && this.token_data.roles.indexOf('ROLE_ADMIN')!==-1);
        },
        isEditor(){
            return (this.token_data && this.token_data.roles.indexOf('ROLE_EDITOR')!==-1);
        },

        ...mapGetters({
            people: 'person/list/items',
            organizations: 'organization/list/items',
            //collections: 'photographcollection/list/items',
            categoriesraw: 'category/list/items',
            photographs: 'photograph/list/items',
            token_data: 'user/token/token_data',
            //imagegalleries: 'imagegallery/list/items',
        }),
        /*selectedCollection () {
            return this.item.collection
        },*/
        violations () {
            return this.errors || {}
        }
    },
    created () {
        this.getPeople()
        this.getOrganizations()
        //this.getCollections('photograph_collections?itemsPerPage=1000')
        this.getCategories('categories')
        //this.getPhotographs('photographs?frontside[exists]=false&retrosnapOf[exists]=false&collection=' + this.item.collection)
        /*this.getImageGalleries('image_galleries?owner=' + this.item.collection)
        if (localStorage.getItem('listFilters')) {
            this.listFilters = JSON.parse(localStorage.getItem('listFilters'))
            if ((typeof this.item.collection == 'undefined' || this.item.collection === null) && this.listFilters.collection) this.item.collection = this.listFilters.collection
        }*/
    },
    watch: {
        categoriesraw: function () {
            this.categories = this.categoriesraw.filter((category) => {
                return category['isForPhotographs'] !== false
            }).map(category => {
                return {
                    'id': category['@id'],
                    'label': this.getParentNames(category),
                    //'children': this.getCategoryChildren(category['@id'])
                }
            }).sort((a, b) => {
                let nameA = a.label.toLowerCase()
                let nameB = b.label.toLowerCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
        },
        /*selectedCollection () {
            window.console.log('collection changed')
            //this.getPhotographs('photographs?frontside[exists]=false&retrosnapOf[exists]=false&collection=' + this.item.collection)
        },*/
        /*listFilters: {
            handler () {
                localStorage.setItem('listFilters', JSON.stringify(this.listFilters))
            },
            deep: true
        },*/
        photographs: function(){
            this.parentPhotographLoading = false
        },
        parentPhotographSearch (val) {
            val && val !== this.select && this.queryParentPhotographs(val)
        },
        item: function(){
            if(this.userLocales.length===1){
                document.getElementById('tabBar').parentElement.parentElement.parentElement.style='display:none';
            }
        }
    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        ...mapActions({
            getPeople: 'person/list/default',
            getOrganizations: 'organization/list/default',
            //getCollections: 'photographcollection/list/default',
            getCategories: 'category/list/default',
            getPhotographs: 'photograph/list/default',
            //getImageGalleries: 'imagegallery/list/default'
        }),
        getParentNames (category) {
            let name = category.name
            if (typeof category.parentcategory !== 'undefined' && category.parentcategory !== null) {
                const parent = this.categoriesraw.filter(c => c['@id'] === category.parentcategory)
                if (parent.length === 1) {
                    const parentNames = this.getParentNames(parent[0])
                    name = parentNames + ' / ' + name
                }
            }
            return name
        },
        handleUpdateCategories () {
            this.item.categories = this.item.categories.filter((category) =>
                this.categoriesraw.find(element => element['@id'] === category)
            )
            this.handleUpdateField('categories', this.item.categories)
        },
        queryParentPhotographs (v) {
            /****************************/
            this.parentPhotographLoading = true;
            this.getPhotographs('photographs?frontside[exists]=false&parentPhotograph[exists]=false&originalName=' + (v || '').toLowerCase());
        },
        onFileUpload (response) {
            window.console.log('onFileUpload', response)
        },
        updateFileUploadField (values) {
            //this.item.filePath=value.filePath;
            let filePaths = []
            let originalNames = []
            let ratios = []
            let sizes = []
            let fromYears = []
            let toYears = []
            values.forEach((value, index) => {
                if (index === 0) {
                    this.$set(this.item, 'filePath', value.filePath)
                    this.$set(this.item, 'originalName', value.originalName)
                    if (value.ratio) {
                        this.$set(this.item, 'ratio', value.ratio)
                        this.handleUpdateField('ratio', value.ratio)
                    }
                    if (value.size) {
                        this.$set(this.item, 'size', value.size)
                        this.handleUpdateField('size', value.size)
                    }
                    this.handleUpdateField('filePath', value.filePath)
                    this.handleUpdateField('originalName', value.originalName)
                    let yearFromTo=this.parseDateFromText(value.originalName)

                    this.handleUpdateField('dateText', this.item.dateText);
                    this.handleUpdateField('fromYear', yearFromTo.fromYear);
                    this.handleUpdateField('toYear', yearFromTo.toYear);
                } else {
                    filePaths.push(value.filePath)
                    originalNames.push(value.originalName)
                    ratios.push(value.ratio ? value.ratio : null)
                    sizes.push(value.size ? value.size : null)

                    let yearFromTo=this.parseDateFromText(value.originalName)
                    fromYears.push(yearFromTo.fromYear);
                    toYears.push(yearFromTo.toYear);
                }

            })
            this.$set(this.item, 'filePaths', filePaths)
            this.$set(this.item, 'originalNames', originalNames)
            this.$set(this.item, 'ratios', ratios)
            this.$set(this.item, 'sizes', sizes)
            this.$set(this.item, 'fromYears', fromYears)
            this.$set(this.item, 'toYears', toYears)
            this.handleUpdateField('filePaths', filePaths)
            this.handleUpdateField('originalNames', originalNames)
            this.handleUpdateField('ratios', ratios)
            this.handleUpdateField('sizes', sizes)
            this.handleUpdateField('fromYears', fromYears)
            this.handleUpdateField('toYears', toYears)

            /*Object.assign(this.item, { 'filePath': value.filePath })
            Object.assign(this.item, { 'originalName': value.originalName })*/

        },
        updateBackFileUploadField (values) {
            //this.item.filePath=value.filePath;
            this.$set(this.item, 'backFilePath', values[0].filePath)
            this.$set(this.item, 'backOriginalName', values[0].originalName)
            if (values[0].ratio) this.$set(this.item, 'backRatio', values[0].ratio)
            if (values[0].size) this.$set(this.item, 'backSize', values[0].size)
            /*Object.assign(this.item, { 'filePath': value.filePath })
            Object.assign(this.item, { 'originalName': value.originalName })*/
            this.handleUpdateField('backFilePath', values[0].filePath)
            this.handleUpdateField('backOriginalName', values[0].originalName)

        },
        onBackFileUpload (response) {
            window.console.log('onBackFileUpload', response)
        },

        removeBackFile(){
            this.$set(this.item, 'backFilePath', null)
            this.$set(this.item, 'backOriginalName', null)
            this.$set(this.item, 'backRatio', null)
            this.$set(this.item, 'backSize', null)

            this.handleUpdateField('backFilePath', null)
            this.handleUpdateField('backOriginalName', null)
        },

        handleGeoUpdate (field, value) {
            //this.$set(this.item, field, value)
            //this.$set(this.item.geo, 'latitude', value.latitude);
            //this.$set(this.item.geo, 'longitude', value.longitude);
            window.console.log(field,value);
            this.handleUpdateField(field, value)
        },
        handleDateText () {
            this.parseDateFromText(this.item.dateText)
        },
        fixShortYear(){
            let dateText=this.item.dateText;
            let fromYear=this.item.fromYear;
            let toYear=this.item.toYear;
            //Jahr verlängern:
            let regex = RegExp(/\d{1,2}[/|.]\d{1,2}[/|.](\d{2})$/);
            if(regex.test(dateText)){
                let match=dateText.match(regex)[1];
                if (match>='00' && match<='99') {
                    match='19'+match;
                    fromYear = match;
                    toYear = match;
                    dateText=dateText.replace(/(\d{1,2}[/|.]\d{1,2}[/|.])(\d{2})/g,"$119$2");
                }
            }

            //Jahr Range verlängern:
            regex = RegExp(/^(\d{2})\s?(-)\s?(\d{2})$/);
            if(regex.test(dateText)){


                let matchFrom=parseInt(dateText.match(regex)[1]);
                let matchTo=parseInt(dateText.match(regex)[3]);
                fromYear=1900+matchFrom;
                toYear=1900+matchTo;
                dateText=dateText.replace(/^(\d{2})\s?(-)\s?(\d{2})$/g,"19$1 $2 19$3");

            }
            //Jahr Range verlängern:
            regex = RegExp(/^(\d{4})\s?(-)\s?(\d{2})$/);
            if(regex.test(dateText)){


                let matchFrom=parseInt(dateText.match(regex)[1]);
                let matchTo=parseInt(dateText.match(regex)[3]);
                fromYear=matchFrom;
                toYear=1900+matchTo;
                dateText=dateText.replace(/^(\d{4})\s?(-)\s?(\d{2})$/g,"$1 $2 19$3");

            }



            //einfaches Jahr parsen
            regex = RegExp(/^(\d{2})$/);
            if(regex.test(dateText)){
                let match=dateText.match(regex)[1];
                if (match>='00' && match<='99') {
                    match='19'+match;
                    fromYear = match;
                    toYear = match;
                    dateText=dateText.replace(/(\d{2})/g,"19$1");
                    dateText=this.item.dateText;
                }
            }

            //Jahrzehnt (z.B. 1950er oder 1950s)
            regex = RegExp(/^(\d)(0[er|s])/);
            if(regex.test(dateText)){
                let match=dateText.match(regex)[1];
                if (match>='0' && match<='9') {
                    match='19'+match;
                    fromYear=match+'0';
                    toYear=match+'9';
                    dateText=dateText.replace(/(\d)(0[er|s])/,"19$1$2");
                }
            }

            this.handleUpdateField('dateText',dateText);
            this.handleUpdateField('fromYear', fromYear);
            this.handleUpdateField('toYear', toYear);
        },
        parseDateFromText(dateText){
            let fromYear=null;
            let toYear=null
            //einfaches Jahr parsen
            let regex = RegExp(/(\d{4})/);
            if(regex.test(dateText)){
                let match=dateText.match(regex)[1];
                if (match>=1850 && match<=1990) {
                    fromYear = match;
                    toYear = match;
                }
            }
            //Jahrzehnt (z.B. 1950er oder 1950s)
            regex = RegExp(/(\d{3})0[er|s]/);
            if(regex.test(dateText)){
                let match=dateText.match(regex)[1];
                fromYear=match+'0';
                toYear=match+'9';
            }

            // "ca1950", "ca.1950", "ca 1950", "ca. 1950"
            regex = RegExp(/ca.?\s?(\d{4})/);
            if(regex.test(dateText)){
                let match=parseInt(dateText.match(regex)[1]);
                fromYear=match-5;
                toYear=match+5;
            }
            // "1950ca", "1950 ca"
            regex = RegExp(/(\d{4})\s?ca/);
            if(regex.test(dateText)){
                let match=parseInt(dateText.match(regex)[1]);
                fromYear=match-5;
                toYear=match+5;
            }
            // Genaues Datum "24/10/1975", "24.10.1975"
            regex = RegExp(/\d{2}[/|.]\d{2}[/|.](\d{4})/);
            if(regex.test(dateText)){
                let match=parseInt(dateText.match(regex)[1]);
                fromYear=match;
                toYear=match;
            }
            // Amerikanisches Datum "1975/10/24", "1975-10-24"
            regex = RegExp(/(\d{4})[/|-]\d{2}[/|.]\d{2}/);
            if(regex.test(dateText)){
                let match=parseInt(dateText.match(regex)[1]);
                fromYear=match;
                toYear=match;
            }

            // Zeitspanne short "75-79", "75 - 79"
            regex = RegExp(/(\d{2})\s?-\s?(\d{2})/);
            if(regex.test(dateText)){
                let matchFrom=1900+parseInt(dateText.match(regex)[1]);
                let matchTo=1900+parseInt(dateText.match(regex)[2]);
                fromYear=matchFrom;
                toYear=matchTo;
            }

            // Zeitspanne short "1975-79", "1975 - 79"
            regex = RegExp(/(\d{4})\s?-\s?(\d{2})/);
            if(regex.test(dateText)){
                let matchFrom=parseInt(dateText.match(regex)[1]);
                let matchTo=1900+parseInt(dateText.match(regex)[2]);
                fromYear=matchFrom;
                toYear=matchTo;
            }

            // Zeitspanne "1975-1979", "1975 - 1979", "1975 1979"
            regex = RegExp(/(\d{4})\s?-?\s?(\d{4})/);
            if(regex.test(dateText)){
                let matchFrom=parseInt(dateText.match(regex)[1]);
                let matchTo=parseInt(dateText.match(regex)[2]);
                fromYear=matchFrom;
                toYear=matchTo;
            }




            return {fromYear: fromYear, toYear: toYear}

        },
        onDepthMapDataUpdate(depthMapData){
            this.item.depthMapData=depthMapData;
            this.handleUpdateField('depthMapData', this.item.depthMapData);
        },


    }
}
</script>
