import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const upload = ({ commit }, values) => {
  commit(types.SET_ERROR, '')
  commit(types.TOGGLE_LOADING)
  let headers =new Headers();
  headers.set("Content-Type",'multipart/form-data; boundary=----WebKitFormBoundarydXskqET69oNB63ct')
  let data = new FormData();
  data.append('file',values.file,values.file.name);
  return fetch('imagegallery/upload', {
    method: 'POST',
    body: data,
    //headers: headers
  })
    .then((response) => {
      commit(types.TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.SET_UPLOADED, data)
      return data;
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      window.console.log(e);
      if (e instanceof SubmissionError) {
        commit(types.SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.SET_ERROR, e.errors._error)
        return
      }

      commit(types.SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.RESET)
}
