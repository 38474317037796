<template>
    <div if="item">
        <form  @submit.prevent="handleSubmit(item)" style="overflow-y:auto">

        <div class="form-group">
            <tiptap-vuetify
                v-model="tiptapText"
                :extensions="tiptapExtensions"

                :placeholder="$t('app.Comment.CommentText')"
                label="Text"
                :class="['form-control', 'commentItem', 'noBackground', isInvalid('text') ? 'is-invalid' : '']"
                :card-props="{ flat: true, class: 'noBackground' }"
                :toolbar-attributes="{ color: 'none', class: 'noBackground' }"
                :editor-props="{ class: 'noBackground' }"
                required="true"


            ></tiptap-vuetify>
            <div
                v-if="isInvalid('text')"
                class="invalid-feedback">{{ violations.text }}
            </div>
        </div>
        <!--<div class="form-group">
            <v-switch small v-model="item.isPrivate" :label="$t('app.Comment.IsPrivate') "
                      @input="handleUpdateField('isPrivate', $event.target.value)"></v-switch>
        </div>-->
        <v-row class="ma-2">
            <v-col><div class="form-group px-2" v-if="typeof item['imageGallery']==='undefined' || item['imageGallery']==null">
                <v-autocomplete
                    v-model="item.category"
                    @change="(newval)=>{itemcategories=[newval]}"
                    :items="categories"
                    item-text="label"
                    item-value="id"
                    deletable-chips
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('app.Category')"
                    max-width="120"
                ></v-autocomplete>
                <div
                    v-if="isInvalid('keywords')"
                    class="invalid-feedback">{{ violations.keywords }}
                </div>
            </div>
            </v-col>
            <v-col></v-col>
        </v-row>
        <div class="form-group" v-if="item.category">
            <category-attributes
                :label="$t('app.Gallery.Attributes')"
                id="imagegallery_categoryAttributes"
                :values="item.categoryAttributes ? item.categoryAttributes : {}"
                :class="['form-control', isInvalid('categoryAttributes') ? 'is-invalid' : '']"
                :categories="itemcategories"
                :allCategories="categoriesraw"
                :handle-update-field="updateCategoryAttributeField"
            ></category-attributes>
            <div
                v-if="isInvalid('categoryAttributes')"
                class="invalid-feedback">{{ violations.categoryAttributes }}
            </div>
        </div>
    </form>
    </div>
</template>

<style>
.noBackground{
    background-color: transparent !important;
}
</style>

<script>

import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Blockquote,
    Image,
    Link
} from 'tiptap-vuetify'
//import Link from '@tiptap/extension-link'
import CategoryAttributes from '@/components/CategoryAttributes'
import { mapActions, mapGetters } from 'vuex'



export default {
    components: {
        TiptapVuetify,
        CategoryAttributes
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },

        handleUpdateField: {
            type: Function,
            required: true
        },
        editRectangle: {
            type: Function,
            required: false
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => {}
        }
    },

    computed: {
        // eslint-disable-next-line
        ...mapGetters({
            categoriesraw: 'category/list/items'
        }),
        item () {
            return this.initialValues || this.values
        },

        violations () {
            return this.errors || {}
        }
    },
    data: () => ({
        tiptapExtensions: [
            Blockquote,
            Underline,
            Italic,
            Bold,
            ListItem,
            BulletList,
            OrderedList,
            Paragraph,
            Image,
            [Link, {
                options:{
                    //HTMLAttributes: { target: '_blank' },
                    //autolink: true,
                    //linkOnPaste: true,
                    //openOnClick: true,
                }
            }]
        ],
        categories: [],
        categoriesLoading: false,
        itemcategories: [],
        tiptapText: ''
    }),
    created () {
        this.getCategories('categories');
        this.itemcategories=[this.item.category];
        this.tiptapText=this.item.text;
    },
    watch: {
        item: {
            handler:function() {
                this.itemcategories=[this.item.category];
                //window.console.log(this.itemcategories);
                //this.handleUpdateField('text',this.item.text);
                this.tiptapText=this.item.text;
            },
            deep: true
        },
        tiptapText: function () {

            this.handleUpdateField('text',this.tiptapText);
        },

        categoriesraw: function () {
            this.categories = this.categoriesraw.filter((category)=>{
                return category['isForComments']!==false
            }).map(category => {
                return {
                    'id': category['@id'],
                    'label': category['name'],
                    'children': this.getCategoryChildren(category['@id'])
                }
            }).sort((a, b) => {
                let nameA = a.label.toLowerCase()
                let nameB = b.label.toLowerCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            }); //this.getCategoryChildren(null)
        },
        categoriesSearch (val) {
            val && val !== this.select && this.querySelections(val)
        },
    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        ...mapActions({
            getCategories: 'category/list/default'
        }),
        getCategoryChildren (parentid) {
            if (!this.categoriesraw) return []
            let children = this.categoriesraw.filter(category => category.parentcategory === parentid)
            return children.map(category => {
                return {
                    'id': category['@id'],
                    'label': category['name'],
                    'isDisabled': this.item.id === category['id'],
                    'children': this.getCategoryChildren(category['@id'])
                }
            }).sort((a, b) => {
                let nameA = a.label.toLowerCase()
                let nameB = b.label.toLowerCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
        },
        updateCategoryAttributeField (field, value) {
            window.console.log(field,value);
            if (typeof this.item.categoryAttributes === 'undefined' || this.item.categoryAttributes===null)  this.item.categoryAttributes={};
            this.item.categoryAttributes[field]=value;
            this.handleUpdateField('categoryAttributes',this.item.categoryAttributes);
            window.console.log('categoryAttributes',this.item.categoryAttributes);
        },

    }
}
</script>
