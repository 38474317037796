import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const del = ({ commit }, item) => {
  commit(types.ORDER_DELETE_TOGGLE_LOADING)

  return fetch(item['@id'], { method: 'DELETE' })
    .then(() => {
      commit(types.ORDER_DELETE_TOGGLE_LOADING)
      commit(types.ORDER_DELETE_SET_DELETED, item)
    })
    .catch((e) => {
      commit(types.ORDER_DELETE_TOGGLE_LOADING)
      commit(types.ORDER_DELETE_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.ORDER_DELETE_RESET)
}

export const cancel = ({ commit }, id) => {
  commit(types.ORDER_CANCEL_TOGGLE_LOADING)

  return fetch(id, { method: 'POST' })
    .then((data) => {
      commit(types.ORDER_CANCEL_TOGGLE_LOADING)
      commit(types.ORDER_CANCEL_SET_CANCELED, data)
    })
    .catch((e) => {
      commit(types.ORDER_CANCEL_TOGGLE_LOADING)
      commit(types.ORDER_CANCEL_SET_ERROR, e.message)
    })
}
