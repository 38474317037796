<template>
    <v-dialog
        v-model="registereddialog"
        fullscreen
        content-class="justify-center"
        fill-height
        justify="center"
        class="justify-center"
        fluid
    >
        <div class="loginDialog justify-center text-center">
            <v-card-title
                class="text-h5"
                primary-title
            >
                <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                src="@/assets/img/nimmerso_typeface.svg" style="width:240px;" alt="Nimmerso"/>
            </v-card-title>

            <v-snackbar
                v-if="isLoading"
                v-model="isLoading"
                :timeout="5000"
                :top="true"
                color="info"
            >Loading...
            </v-snackbar>

            <v-snackbar
                v-if="error"
                v-model="error"
                :timeout="5000"
                :top="true"
                color="error"
            >
                <v-icon>mdi-alert</v-icon>
                {{ error }}
            </v-snackbar>
            <v-snackbar
                v-if="deleteError"
                v-model="deleteError"
                :timeout="5000"
                :top="true"
                color="error"
            >
                <v-icon>mdi-delete</v-icon>
                {{ deleteError }}
            </v-snackbar>
            <v-card-title
                primary-title
                class="text-center justify-center"
            >{{ $t('app.registeredtitle') }}
            </v-card-title>
            <v-card-text><p>{{ $t('app.registerednotice') }}</p>
                <p>{{ $t('app.registerednoticeThankyou') }}</p></v-card-text>
            <nimmerso-footer class="text-center justify-center"></nimmerso-footer>
        </div>
    </v-dialog>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: mapGetters({
        deleteError: 'user/del/error',
        error: 'user/show/error',
        isLoading: 'user/show/isLoading',
        item: 'user/show/retrieved'
    }),
    data () {
        return {

            registereddialog: true,
        }
    },

    created () {

    },

    methods: {}
}
</script>
