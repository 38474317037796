<template>
    <div v-if="arMode" class="arContent" style="width:100%; height:100%">
        <iframe src="./ar/geo" scrolling="no"></iframe>
        <v-tooltip left>
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn @click.stop="$router.go(-1)" icon
                       style="z-index:10001;" top
                       right
                       fixed
                       v-on="onTooltip">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('app.Close') }}</span>
        </v-tooltip>

    </div>
</template>

<style>
    .arContent {
        width:100%;
        height:100%;
        z-index: 1000;
        position: fixed;
        top:0;
        left:0;
    }
    .arContent iframe{
        width:100%;
        height:100%;
        border: 0 none;

    }

</style>

<script>

    export default {


        data () {
            return {
                arMode: false,
                center: []
            }
        },
        mounted () {
            this.arMode=true;

            navigator.geolocation.getCurrentPosition(position => {

                this.center = [position.coords.latitude, position.coords.longitude]

            })

        },
        beforeDestroy () {
            window.console.log('beforeDestroy')
            this.arMode=false;
        },
    }
</script>
