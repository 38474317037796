<template>
    <div class="SearchFilter">
        <div class="" >
              <span
                  v-for="(searchfilter, i) in searchfilters"
                  :key="i"
                  class="shrink"

              >
                  <v-chip
                      close
                      :color="chipColors[searchfilter.type]"

                      label
                      class="ma-1"
                      close-icon="mdi-close"
                      @click:close="removeChip(searchfilter)"
                  >
                      <v-icon
                          left
                      >{{ chipIcons[searchfilter.type]}}</v-icon>
                      <span class="chip-text">{{ searchfilter.text }}</span>
                  </v-chip>
              </span>
        </div>
    </div>
</template>

<style>

    .SearchFilter .chip-text {
        /*max-width: 110px; */
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .SearchFilter {
        margin-bottom: 10px;
    }

</style>
<script>
    import {mapGetters, mapMutations} from "vuex";

    export default {
        props: {
        },
        computed: {
            ...mapGetters({
                searchfilters: 'searchfilters/items'
            })
        },
        data: function () {
            return {
                showSearchLayer: false,
                //searchfilters: [],
                chipIcons: {
                    'text': 'mdi-card-text',
                    'category': 'mdi-view-list',
                    'place': 'mdi-map-marker',
                    'daterange': 'mdi-calendar-range'
                },
                chipColors: {
                    'text': '#8C8189',
                    'category': '#9E919A',
                    'place': '#B0A0AA',
                    'daterange': '#C2B0BC',
                    'types': '#989181'
                }
            }
        },
        methods: {
            ...mapMutations({
                setsearchfilters: 'searchfilters/setsearchfilters',
                setsettings: 'settings/setsettings'

            }),
            removeChip(searchfilter) {
                let newsearchfilters = this.searchfilters.filter((item) => item !== searchfilter)
                this.updatesearchfilters(newsearchfilters)
            },
            updatesearchfilters(newsearchfilters) {
                //this.searchfilterItems=newsearchfilters;
                this.setsearchfilters(newsearchfilters);
                this.$store.commit('searchfilters/setsearchfilters', newsearchfilters)
            }
        }
    };
</script>
