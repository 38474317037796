<template>
    <v-container class="mapcontainer" ma-0 pa-0 fill-height fluid v-if="isActive" style="background-color:#FCFCFC">
        <div
            v-if="isLoading"
            class="alert alert-info">Loading...
            <DonutChart :value="2000" :full="4000" :imagesCount="1000" :handle-click="()=>{}"></DonutChart>

        </div>
        <div
            v-if="error"
            class="alert alert-danger">{{ error }}
        </div>
        <MglMap
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="center"
            :zoom="zoom"

            style="top:0; height: 100vh; position: fixed"
            @load="onMapLoaded"
            @zoomend="zoomUpdated"
            @moveend="updateCenter"
            @touchstart="onTouchStart"
            @touchend="onTouchEnd"

        >


            <MglMarker
                :key="'i'+index"
                v-for="(item, index) in feedItems.filter((item)=>{ return item.documentType!=='PhotographCollection'})"
                :coordinates="[item.geometry.coordinates[1],item.geometry.coordinates[0]]"
                :anchor="(item.properties.customThumbnail || item.properties.cluster || item.properties.computedRating <= 4) ? 'center' : 'bottom'"
                >
                <div slot="marker" class="markerIcon" :style="'opacity: 0.1; '+((item.properties.cluster || item.properties.computedRating <= 4) ? 'width:18px; height:18px' : 'width:64px; height:68px')">
                    <div v-if="item.properties.cluster" slot="marker"
                         class="clusterIcon" style="width:18px; height:18px">
                        <span>{{ item.properties.point_count_abbreviated }}</span>
                    </div>
                    <div v-else-if="item.properties.customThumbnail"
                         class="mapboxgl-popup-anchor-center">
                        <div class="customThumbnailDiv">
                            <img :src="entrypoint+'/media/photographs/'+item.properties.customThumbnail"/>
                        </div>
                    </div>
                    <div v-else-if="item.properties.computedRating <= 4" slot="marker"
                         class="clusterIcon" style="width:18px; height:18px">
                        <span></span>
                    </div>
                    <div v-else-if="item.properties.documentType==='ImageGallery'"
                         class="albumMarkerIcon mapboxgl-popup mapboxgl-popup-anchor-bottom">
                        <div class="mapboxgl-popup-tip"></div>
                        <div class="imgborder">
                            <img style="width:64px; height:64px"
                                 :src="entrypoint+item.properties.images[0]+'/icon'+(webpSupported ? '.webp' : '.jpeg')"/>
                        </div>
                    </div>


                    <div v-else style="width:64px; height:68px">
                        <svg width="64" height="68" version="1.1" viewBox="0 0 432.72 488.01"
                         xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
                         xmlns:dc="http://purl.org/dc/elements/1.1/"
                         xmlns:osb="http://www.openswatchbook.org/uri/2009/osb"
                         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                         xmlns:xlink="http://www.w3.org/1999/xlink">

                        <g v-if="item.properties.images.length===1" transform="translate(7.5006 7.5002)">
                            <g transform="matrix(.98416 0 0 .98416 -37.179 -16.239)" fill="black" stroke="#000"
                               stroke-width="15.241" style="paint-order:stroke fill markers">
                                <path
                                    d="m125 17.299c-47.145 2.9823-87.883 45.573-87.133 93.107-1.1208 23.488 8.4147 45.499 21.767 64.253 63.455 105.11 126.91 210.23 190.37 315.34 63.455-105.11 126.91-210.23 190.37-315.34 13.352-18.755 22.888-40.765 21.767-64.253 0.75046-47.534-39.988-90.125-87.133-93.107-41.667-0.12407-83.334-0.4951-125-0.79932-41.666 0.30422-83.333 0.67525-125 0.79932z"
                                    fill="none" stroke="#000" stroke-linecap="round" stroke-width="15.241"
                                    style="paint-order:stroke fill markers"/>
                            </g>
                            <g transform="matrix(.98416 0 0 .98416 -37.179 -16.239)" fill="none" stroke="#000"
                               stroke-width="15.241" style="paint-order:stroke fill markers">
                                <image v-if="item.properties.documentType==='Photograph'" x=".75999" y=".74" width="500"
                                       height="500" preserveAspectRatio="xMaxYMax"
                                       :xlink:href="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.webp'+
                               (item.properties.thumbnailCoordinates && item.properties.thumbnailCoordinates.marker ? '?c='+item.properties.thumbnailCoordinates.marker.left+','+item.properties.thumbnailCoordinates.marker.top+','+item.properties.thumbnailCoordinates.marker.width+','+item.properties.thumbnailCoordinates.marker.height+',' : '')"
                                       clip-path="url(#markerClipPath)"/>
                                <image v-if="item.properties.documentType==='ImageGallery'" x=".75999" y=".74"
                                       width="500"
                                       height="500" preserveAspectRatio="xMaxYMax"
                                       :xlink:href="entrypoint+item.properties.images[0]+'/marker'+(webpSupported ? '.webp' : '.jpeg')"
                                       clip-path="url(#markerClipPath)"/>

                                <path
                                    d="m125 17.299c-47.145 2.9823-87.883 45.573-87.133 93.107-1.1208 23.488 8.4147 45.499 21.767 64.253 63.455 105.11 126.91 210.23 190.37 315.34 63.455-105.11 126.91-210.23 190.37-315.34 13.352-18.755 22.888-40.765 21.767-64.253 0.75046-47.534-39.988-90.125-87.133-93.107-41.667-0.12407-83.334-0.4951-125-0.79932-41.666 0.30422-83.333 0.67525-125 0.79932z"
                                    fill="none" stroke="#000" stroke-linecap="round" stroke-width="15.241"
                                    style="paint-order:stroke fill markers"/>

                            </g>
                        </g>
                        <g v-if="item.properties.images.length>1" transform="translate(7.5006 7.5002)">


                            <g transform="translate(-33.639 -16.5)" fill="#ded8ff" stroke="#000" stroke-width="15.241"
                               style="paint-order:stroke fill markers">
                                <image x=".75999" y=".75" width="500" height="500" preserveAspectRatio="xMaxYMax"
                                       :xlink:href="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.webp'"
                                       clip-path="url(#markerClipPath)"/>
                                <image x=".75999" y=".75" width="500" height="500" preserveAspectRatio="xMaxYMax"
                                       :xlink:href="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[item.properties.images.length-1]+'.webp'"
                                       class="fadingEffect" clip-path="url(#markerClipPath)"/>
                            </g>


                            <g transform="translate(7.5006 7.5002)">
                                <g transform="matrix(.98416 0 0 .98416 -37.179 -16.239)" fill="none" stroke="#000"
                                   stroke-width="15.241">
                                    <path
                                        d="m125 17.299c-47.145 2.9823-87.883 45.573-87.133 93.107-1.1208 23.488 8.4147 45.499 21.767 64.253 63.455 105.11 126.91 210.23 190.37 315.34 63.455-105.11 126.91-210.23 190.37-315.34 13.352-18.755 22.888-40.765 21.767-64.253 0.75046-47.534-39.988-90.125-87.133-93.107-41.667-0.12407-83.334-0.4951-125-0.79932-41.666 0.30422-83.333 0.67525-125 0.79932z"
                                        fill="none" stroke="#000" stroke-linecap="round" stroke-width="15.241"
                                        style="paint-order:stroke fill markers"/>
                                </g>
                            </g>
                            <g transform="translate(-33.639 -16.5)">
                                <path
                                    d="m391.93 254.89c-58.645 34.09-161.85 94.01-210.36 121.75 22.811 37.786 45.621 75.572 68.432 113.36 47.311-78.37 94.621-156.74 141.93-235.11z"/>
                            </g>
                            <g transform="translate(-33.639 -16.5)" display="none">
                                <path
                                    d="m102.03 249.89c50.753 84.072 102.52 169.82 147.97 245.11 49.323-81.703 98.646-163.41 147.97-245.11z"
                                    display="inline" style="paint-order:normal"/>
                            </g>
                            <g transform="translate(-33.639 -16.5)">
                                <g transform="matrix(.47764 0 0 .47764 146.7 107.03)">
                                    <path
                                        d="m239.37 349.96c-39.379 8.2157-91.945 24.637-91.945 24.637s-13.964 1.7603-13.964 13.823c0 12.063 25.16 6.9048 25.16 17.929v213.33c0 6.3991-10.747 7.0871-15.337 11.546-7.764 7.5422-1.264 19.407 5.294 17.65 23.546-6.3091 39.914-10.695 58.797-15.755 12.56-3.3656 12.511-11.872 5.5173-14.737-2.927-1.1989-7.3978-0.77294-7.3978-5.9428 0-9.0692 13.408-20.137 24.815-33.776 7.903 15.495 22.761 42.549 27.931 40.826 5.1698-1.7233 26.759-7.17 43.474-11.649 7.4842-2.0054 3.9516-12.72-3.3405-13.395-4.251-0.39321-9.3421-0.5444-12.206-3.8799-19.182-22.343-27.941-43.956-27.941-43.956s66.863-82.9 66.863-148.55c0-40.615-46.34-56.326-85.719-48.11zm-2.1547 25.135c18.018-4.8279 28.533 4.8233 29.627 19.184 5.0396 66.145-61.349 178.88-61.349 178.88v-189.56c12.02-3.1719 20.29-5.4367 31.722-8.4998z"
                                        fill="#fff"/>
                                </g>
                            </g>


                        </g>

                    </svg>
                    </div>

                </div>

                <MglPopup @open="setActivePopup">
                    <v-card v-if="typeof item.properties.cluster=='undefined'" slot="default">
                        <v-img v-if="item.properties.documentType==='PhotographCollection'"
                               width="255"
                               :aspect-ratio="1"
                               :src="entrypoint+item.properties.id+'/display'"
                               :lazy-src="entrypoint+item.properties.id+'/marker'"
                               @click="carouselItemClick(item.properties)"
                        ></v-img>
                        <v-img v-if="item.properties.documentType==='Photograph'"
                               width="255"
                               :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[0]+'.webp'"
                               :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.webp'"
                               :aspect-ratio="item.properties.imageRatio"
                               @click="carouselItemClick(item.properties)"
                        >
                            <v-img style="padding:2px" v-if="item.properties.images.length>1"
                                   :class="item.properties.images.length>1 ? 'retrosnapImg fadingEffect' : ''"
                                   width="255"
                                   :src="item.properties.images.length > 1 ? entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[item.properties.images.length-1]+'.webp' : entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[0]+(webpSupported ? '.webp' : '.jpeg')"
                                   :lazy-src="item.properties.images.length>1 ? entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[item.properties.images.length-1]+'.jpeg' : entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.jpeg'"
                            ></v-img>
                        </v-img>
                        <v-img v-if="item.properties.documentType==='ImageGallery'"
                               width="255"
                               :aspect-ratio="1"
                               :src="entrypoint+item.properties.images[0]+'/display'+(webpSupported ? '.webp' : '.jpeg')"
                               :lazy-src="entrypoint+item.properties.images[0]+'/lazy'+(webpSupported ? '.webp' : '.jpeg')"
                               @click="carouselItemClick(item.properties)"
                        ></v-img>
                    </v-card>
                    <v-card v-else-if="item.properties.cluster" slot="default">
                        <v-carousel hide-delimiters height="auto">
                            <v-carousel-item
                                v-for="(item,i) in superCluster.getLeaves(item.id, 100, 0)"
                                :key="i"
                            >
                                <v-img v-if="item.properties.documentType==='PhotographCollection'"
                                       width="255"
                                       :aspect-ratio="1"
                                       :src="entrypoint+item.properties.id+'/display'"
                                       :lazy-src="entrypoint+item.properties.id+'/marker'"
                                       @click="carouselItemClick(item.properties)"
                                ></v-img>
                                <v-img v-if="item.properties.documentType==='Photograph'"
                                       width="255"
                                       :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[0]+'.webp'"
                                       :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.webp'"
                                       :aspect-ratio="item.properties.imageRatio"
                                       @click="carouselItemClick(item.properties)"
                                >
                                    <v-img style="padding:2px" v-if="item.properties.images.length>1"
                                           :class="item.properties.images.length>1 ? 'retrosnapImg fadingEffect' : ''"
                                           width="255"
                                           :src="item.properties.images.length > 1 ? entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[item.properties.images.length-1]+'.webp' : entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'small/'+item.properties.images[0]+(webpSupported ? '.webp' : '.jpeg')"
                                           :lazy-src="item.properties.images.length>1 ? entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[item.properties.images.length-1]+'.jpeg' : entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'marker/'+item.properties.images[0]+'.jpeg'"
                                    ></v-img>
                                </v-img>
                                <v-img v-if="item.properties.documentType==='ImageGallery'"
                                       width="255"
                                       :aspect-ratio="1"
                                       :src="entrypoint+item.properties.images[0]+'/display'+(webpSupported ? '.webp' : '.jpeg')"
                                       :lazy-src="entrypoint+item.properties.images[0]+'/lazy'+(webpSupported ? '.webp' : '.jpeg')"
                                       @click="carouselItemClick(item.properties)"
                                ></v-img>
                            </v-carousel-item>
                        </v-carousel>
                    </v-card>
                </MglPopup>
            </MglMarker>

            <MglMarker :key="'g'+index"
                       v-for="(item, index) in geoAggregationMarkers" :coordinates="item.geo" anchor="bottom-left"
                       :offset="[0,0]">


                <div slot="marker">

                    <div class="citystats_marker" :style="'opacity: 0.1;'">
                        <div :style="'height:20px; transform-origin: top left;'">
                            <div class="citystatsbox" v-if="zoom>5" @click="setPlaceFilter(item)">
                                <span v-resize-text style="white-space: nowrap">&bull; {{ item.name }} ({{
                                        item.count
                                    }})</span>
                            </div>
                            <div class="citystatsbox" v-else>
                                {{ item.count }}
                            </div>
                            <!--<div class="citystatsbox_logo"></div>-->
                        </div>
                    </div>
                </div>
            </MglMarker>
            <MglMarker  :key="'c'+index" v-for="(item, index) in photographCollections" :coordinates="[item.geometry.coordinates[1],item.geometry.coordinates[0]]">
                <div slot="marker" @click="photographCollectionClick(item)" >
                    <DonutChart v-if="item.properties.crowdfundingRequired!==null" :style="'height:'+(100+item.properties.computedRating*10)+'px;'" :value="item.properties.crowdfundingFunded" :full="item.properties.crowdfundingRequired" :imagesCount="item.properties.crowdfundingEstimatedNumberImages" ></DonutChart>
                </div>
            </MglMarker>
        </MglMap>
        <transition name="fade-transition">
            <div v-if="cityStatsPlace!==null" class="citystats" style="z-index: 1000">
                <div class="citystatsplacebox"><div>{{ cityStatsPlace }}</div><div class="citystascount">{{ cityStatsCount }}</div>
                </div>
                <div class="citystatsplacebox_logo"></div>
                <div>{{ zoom }}</div>

            </div>
        </transition>
        <svg width="60" height="68" viewBox="0 0 60 68">
            <defs>
                <clipPath id="markerClipPath">

                    <path
                        d="m125 17.299c-47.145 2.9823-87.883 45.573-87.133 93.107-1.1208 23.488 8.4147 45.499 21.767 64.253 63.455 105.11 126.91 210.23 190.37 315.34 63.455-105.11 126.91-210.23 190.37-315.34 13.352-18.755 22.888-40.765 21.767-64.253 0.75046-47.534-39.988-90.125-87.133-93.107-41.667-0.12407-83.334-0.4951-125-0.79932-41.666 0.30422-83.333 0.67525-125 0.79932z"
                        fill="#ded8ff" stroke="#000" stroke-linecap="round" stroke-width="15.241"
                        style="paint-order:stroke fill markers"/>


                </clipPath>
            </defs>
        </svg>
        <div class="streetBoxContainer" v-if="zoom>=16">
            <div v-for="(item, index) in geoAggregations" class="streetBox" :key="index" @click="setPlaceFilter(item)">
                <div class="streetBoxBorder">
                    <div class="corner top-left"></div>
                    <div class="corner top-right"></div>
                    <div class="corner bottom-left"></div>
                    <div class="corner bottom-right"></div>
                    <div class="content">
                        {{ item.name }}
                    </div>
                    <div class="counter">
                        <div>{{ item.count }}</div>

                    </div>
                </div>
            </div>
        </div>
        <v-dialog
            v-model="showAskForLocationDialog"
            max-width="490"
            style="z-index: 40011"
        >
            <v-card>
                <v-card-title class="text-h5">{{ $t('app.settings.getLocation') }}</v-card-title>

                <v-card-text>
                    Nimmerso benötigt diese Berechtigung, um die Position des Benutzers auf der Karte darzustellen,
                    sowie Elemente in der Nähe anzuzeigen. Außerdem wird beim Erstellen eines RetroSnaps die genaue
                    Position der Aufnahme sowie der Aufnahmewinkel mit abgespeichert.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="red darken-1"
                        text
                        @click="updateSettingGetLocation(false)"
                    >
                        Disagree
                    </v-btn>

                    <v-btn
                        color="green "
                        text
                        @click="updateSettingGetLocation(true)"
                    >
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showGetLocationDeniedInfo"
            max-width="400"
            style="z-index: 40011"
        >
            <v-card>
                <v-card-title class="text-h5">Location blocked</v-card-title>
                <v-card-text>
                    Es kann nicht auf den Standort zugegriffen werden. Bitte checke deine Browsereinstellungen.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click.stop="showGetLocationDeniedInfo = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-actions style="z-index: 3011; position:fixed; bottom:80px; left: -8px;">
            <v-tooltip top>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn :to="{name: 'MapAr'}" v-on="onTooltip" icon>
                        <v-icon>mdi-augmented-reality</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.AugmentedRealityMode') }}</span>
            </v-tooltip>
            {{this.center}}
        </v-card-actions>
    </v-container>
</template>

<style>
.mapboxgl-popup-content {
    padding: 0 !important;
    background: transparent;
}

.mapboxgl-popup-close-button {
    z-index: 1;
    font-size: 40px;
    text-shadow: 0 1px 2px black;
}

.mapcontainer .mapboxgl-ctrl-bottom-left {
    bottom: 60px;
}

.mapcontainer .mapboxgl-ctrl-bottom-right {
    bottom: 60px;
}

.mapboxgl-popup-tip {
    border-top-color: #535353 !important;
    /*box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
*/
}

.mapboxgl-ctrl-icon, .mapboxgl-ctrl-icon > .mapboxgl-ctrl-compass-arrow {

}

.citystats {
    position: absolute;
    /*top: max( 60px, calc(30px + env(safe-area-inset-top)) ); */
    top: 10px;
}

.citystats_marker {
    transition: opacity 0.5s ease-in-out;
}

.markerIcon {
    transition: opacity 0.5s ease-in-out;
}

.albumMarkerIcon {

}

.albumMarkerIcon img {
    border: 2px solid black;
}

.albumMarkerIcon .imgborder {
    border: 2px solid white;
    box-shadow: 0 1px 2px black;
    width: 68px;
    height: 68px;
}

.citystatsbox {
    /*background-image: url('~@/assets/img/CityStats_box.png');
    background-repeat: repeat-x;
    background-size: contain;
*/
    font-family: "Fjalla One", Arial, sans-serif;
    font-weight: bold;
    color: white;

    /*margin-left: 40px;
    line-height: 16px;*/
    /*margin-top: 8px;*/
    /*padding: 5px 5px 5px 28px;*/
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
    cursor: pointer;
}

.citystatsplacebox {
    background-image: url('~@/assets/img/CityStats_box.png');
    background-repeat: repeat-x;
    background-size: contain;
    height: 53px;
    margin-top: 5px;

    font-family: "Fjalla One", Arial, sans-serif;

    color: black;

    margin-left: 40px;
    line-height: 16px;
    /*margin-top: 8px;*/
    padding: 5px 5px 5px 28px;
    box-shadow: 2px 2px 2px 2px rgb(0, 0, 0, 0.2);
    cursor: pointer;
}

.citystascount {
    margin-top: 8px;
    text-align: center;
}

.citystatsplacebox_logo {
    background-image: url('~@/assets/img/Ebicon_6-tone_dark.svg');
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 67px;
    position: absolute;
    top: 0;
    left: 5px;

}

.leaflet-div-icon {
    background-color: transparent !important;
    border: none !important;
}

.markerIcon {
    /*background-image: url('~@/assets/img/MarkerMulti.svg');*/
    background-color: transparent;
    background-size: cover;
    background-position: center;
    width: 67px;
    height: 67px;
    color: black;
    text-align: center;
    vertical-align: center;
    font-family: "Roboto Condensed", Arial, sans-serif;
    font-size: x-large;
}

.clusterIcon {
    /*background-image: url('~@/assets/img/MarkerMulti.svg');*/
    background-color: #1792FC;
    width: 18px;
    height: 18px;
    -moz-border-radius: 9px;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    line-height: 18px;
    color: white;
}

.clusterIcon span {
    white-space: nowrap;
    display: block;
    font-size: x-small;
}

.markerClip {
    clip-path: url(#markerClipPath);
}

.streetBoxContainer {
    position: absolute;
    top: 5px;
    right: 0px;

    overflow-y: auto;
    max-height: 100%;
    mask-image: linear-gradient(black, black 90%, transparent);
    mask-mode: alpha;
}

@media screen and (orientation: portrait) {
    .streetBoxContainer {
        max-height: 220px;
    }
}


.streetBox {
    background-color: #144089;
    padding: 5px;
    margin: 10px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    cursor: pointer;
    width: max-content;
    max-width: 75%;
    margin-left: auto;
}


.streetBox > div {
    /*border: 1px solid white;*/
    padding: 0 12px 4px 12px;
}

.streetBoxBorder {
    position: relative;
    margin: 0px;
    background: #144089;
    border: 1px solid white;
}

.streetBoxBorder .corner {
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #144089;
}

.streetBoxBorder .top-left {
    top: -1px;
    left: -1px;
    border-radius: 0 0 100% 0;
    border: 1px solid white;
    border-left: 0 none #144089;
    border-top: 0 none #144089;
}

.streetBoxBorder .top-right {
    top: -1px;
    right: -1px;
    border-radius: 0 0 0 100%;
    border: 1px solid white;
    border-right: 0 none #144089;
    border-top: 0 none #144089;
}

.streetBoxBorder .bottom-left {
    bottom: -1px;
    left: -1px;
    border-radius: 0 100% 0 0;
    border: 1px solid white;
    border-left: 0 none #144089;
    border-bottom: 0 none #144089;
}

.streetBoxBorder .bottom-right {
    bottom: -1px;
    right: -1px;
    border-radius: 100% 0 0 0;
    border: 1px solid white;
    border-right: 0 none #144089;
    border-bottom: 0 none #144089;
}

.streetBox .counter {
    position: absolute;
    left: -35px;
    top: 10%;
    border-radius: 50%;
    border: 1px solid white;
    background-color: white;
}

.streetBox .counter div {
    font-size: 12px;
    color: black;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
}

.streetBox .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-family: "Fjalla One", Arial, sans-serif;
}
.mapboxgl-ctrl-attrib-inner a {
    color: #000000 !important;
}

.customThumbnailDiv {
    width: 200px;
    height: 200px;
}

.customThumbnailDiv img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    -webkit-filter: drop-shadow(2px 2px 2px #222);
    filter: drop-shadow(2px 2px 2px #222);
}

</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ENTRYPOINT } from '@/config/entrypoint'
import fetch from '../../utils/fetch'
import ResizeText from 'vue-resize-text'
import isWebpSupported from '../../utils/isWebpSupported'
import DonutChart from './DonutChart'

import Mapbox from 'mapbox-gl'
import { MglMap, MglPopup } from 'v-mapbox'
import Supercluster from 'supercluster'
import MglMarker from '@/components/map/Marker'

export default {
    components: {
        MglMap,
        MglPopup,
        MglMarker,
        DonutChart
    },
    directives: {
        ResizeText
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            isActive: false,
            accessToken: process.env.VUE_APP_MAPBOX_TOKEN, // your access token. Needed if you using Mapbox maps
            mapStyle: 'https://maps.nimmerso.app/api/maps/nimmerso/style.json', //mapbox://styles/julianstrickernimmerso/ckgrug7b60la419n850139jcu', //'mapbox://styles/julianstrickernimmerso/ck8t44v7z01d91imd1v403a49', //'mapbox://styles/mapbox/streets-v11', // your map style
            center: {'lat': 46.609860, 'lng': 11.192860},
            boundsParam: null,
            zoom: 8,
            cityStatsPlace: null,
            cityStatsCount: null,
            cityStats: null,
            geoAggregations: [],
            geoAggregationMarkers: [],
            feedItems: [],
            settingGetLocation: false,
            showAskForLocationDialog: false,
            showGetLocationDeniedInfo: false,
            webpSupported: true,
            showColorized: true,
            initialRoute: null,
            superCluster: null,
            touchStartData: null,
            feedLoadTimeOut: null,
            geoAggregationLoadTimeOut: null,
            activePopup: null,
            feedAbortController: null,
            geoAggregationAbortController: null,
            photographCollections: []
        }
    },
    computed: {
        ...mapState(['settings', 'user/token']),
        ...mapGetters({
            settings: 'settings/items',
            isLoading: 'map/show/isLoading',
            error: 'map/show/error',
            searchfilters: 'searchfilters/items',
        }),
        searchfilters () {
            return this.$store.state.searchfilters.items || []
        }
    },
    created () {
        this.webpSupported = isWebpSupported()
        this.showColorized = this.settings.showColorized
        this.showInfosOverlay = this.settings.showInfosOverlay
        this.settingGetLocation = this.settings['getLocation']
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = Mapbox
        this.map = null
        this.superCluster = new Supercluster({
            radius: 1,
            maxZoom: 30
        })

    },
    watch: {
        searchfilters () {
            this.geoAggregationLoadTimeOut = setTimeout(this.loadGeoAggregation, 10)
            if (this.zoom >= 16) {
                this.feedLoadTimeOut = setTimeout(this.loadFeed, 10)
            }
        },
        settings () {
            this.showColorized = this.settings.showColorized
            this.showInfosOverlay = this.settings.showInfosOverlay
            this.settingGetLocation = this.settings['getLocation']
        },
        settingGetLocation: function () {
            //window.console.log('settingGetLocation', this.settingGetLocation)
            this.$nextTick(() => {
                if (this.settingGetLocation === true) {
                    (async () => {
                        const permissionStatus = await navigator.permissions.query({
                            name: 'geolocation'
                        })
                        permissionStatus.onchange = (result) => {
                            //window.console.log(result.state, 'resultOnchange')
                            //window.console.log(result.state)
                            if (result.state === 'granted') {
                                //window.console.log(result.state)
                                this.showGetLocationDeniedInfo = false
                                this.setsettings({getLocation: true})
                                this.$store.commit('settings/setsettings', {getLocation: true})
                                this.setGeoPos()
                            } else if (result.state === 'prompt') {
                                //window.console.log(result.state)
                                // this.showAskForLocationDialog=true;
                                /*navigator.geolocation.getCurrentPosition((cp)=>{
                                    window.console.log(cp);
                                }, (cp)=>{
                                    window.console.log(cp);
                                    this.showGetLocationDeniedInfo = true;
                                    this.settingGetLocation = false;
                                    this.setsettings({getLocation: false});
                                    this.$store.commit('settings/setsettings', {getLocation: false})
                                },{timeout:10000});*/
                            } else if (result.state === 'denied') {
                                //window.console.log(result.state)
                                this.showGetLocationDeniedInfo = true
                                this.settingGetLocation = false
                                this.setsettings({getLocation: false})
                                this.$store.commit('settings/setsettings', {getLocation: false})
                            }
                        }
                    })()

                } else {
                    this.showAskForLocationDialog = true
                }
            })
        },
        $route (to) {
            window.console.log('route', to)
            if (to.path.indexOf('/map') === 0) this.init()
        },

    },
    mounted () {
        this.isActive = true
        //this.init();
    },
    methods: {
        ...mapMutations({
            setsearchfilters: 'searchfilters/setsearchfilters',
            setsettings: 'settings/setsettings'
        }),
        ...mapActions({
            getPage: 'feeditem/list/default',
        }),
        init: function () {
            window.console.log('init', this.settingGetLocation)
            //this.items.map(item => {
            //item.open = true
            //})
            this.cityStatsPlace=null;
            this.cityStatsCount= null;
            this.cityStats= null;
            if (this.settingGetLocation === true) {

                this.$nextTick(() => {
                    this.setGeoPos()
                })
            } else {
                this.showAskForLocationDialog = true
            }
        },
        geolocate: function () {
            if (this.settingGetLocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    this.userpos = {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude)
                    }
                    setTimeout(this.geolocate, 10000)
                })
            }
        },
        onMapLoaded (event) {
            window.console.log('onMapLoaded')
            this.map = event.map
            /*if (this.$route.params.geo) {
                let geo = this.$route.params.geo.split(',');
                if(geo.length===4){
                    //this.center = {lat: parseFloat(geo[1])+(parseFloat(geo[3])-parseFloat(geo[1]))/2, lng: parseFloat(geo[0])+(parseFloat(geo[2])-parseFloat(geo[0]))/2}
                    //this.center=null;
                    //this.zoom=null;
                    window.console.log(598,this.center);
                    this.boundsParam=[[parseFloat(geo[0]),parseFloat(geo[1])],[parseFloat(geo[2]),parseFloat(geo[3])]];
                    this.map.fitBounds(this.boundsParam);
                }else if(geo.length===2){
                    this.zoom = 21
                    this.center = {lat: parseFloat(geo[0]), lng: parseFloat(geo[1])}
                }

            }*/
            // in component

            // or just to store if you want have access from other components
            this.$store.map = event.map
            this.map.addControl(new this.mapbox.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true,
                showAccuracyCircle: false
            }), 'bottom-right')

            this.init()
        },
        zoomUpdated (event) {
            //window.console.log(event)
            //window.console.log(event.map.getZoom())
            /*document.getElementsByClassName('markerIcon').forEach((element)=>{
                element.style.opacity = 0;
            });*/
            this.zoom = Math.round(event.map.getZoom())

            clearTimeout(this.feedLoadTimeOut)
            clearTimeout(this.geoAggregationLoadTimeOut)
            this.geoAggregationLoadTimeOut = setTimeout(this.loadGeoAggregation, 1000)
            if (this.zoom >= 16) {
                this.feedLoadTimeOut = setTimeout(this.loadFeed, 1000)
            } else {
                this.feedItems = []
            }
        },
        loadFeed () {
            if (!this.isActive) return
            this.closeAllPopups()
            if (this.feedAbortController !== null) this.feedAbortController.abort()
            this.feedAbortController = new AbortController()
            //this.getPage('/feed_items?' + this.getParamsFromSearchfilters());
            return fetch('/feed_items?groups[]=map&' + this.getParamsFromSearchfilters(), {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                signal: this.feedAbortController.signal
            })
                .then(response => response.json())
                .then((data) => {

                    let geoPoints = data['hydra:member'].filter((item) => item.geo).map((item) => {
                        return {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [item.geo.lat, item.geo.lon]
                            },
                            'properties': item
                        }
                    })
                    geoPoints.sort((a, b) => {
                        //return a.count - b.count;
                        //return a.geometry.coordinates[1] - b.geometry.coordinates[1]
                        return a.properties.computedRating - b.properties.computedRating;
                    })
                    //window.console.log(geoPoints);
                    this.photographCollections=geoPoints.filter((item)=>{
                        return (
                            item.properties.documentType==='PhotographCollection' &&
                            item.crowdfundingRequired!==null &&
                            item.crowdfundingFunded!==null &&
                            item.crowdfundingEstimatedNumberImages!==null
                        )
                    });
                    window.console.log(this.photographCollections);
                    this.superCluster.load(geoPoints.filter((item)=>{
                        return !(
                            item.properties.documentType==='PhotographCollection' &&
                            item.crowdfundingRequired!==null &&
                            item.crowdfundingFunded!==null &&
                            item.crowdfundingEstimatedNumberImages!==null
                        )
                    }))
                    //window.console.log([this.bounds.getSouth(), this.bounds.getWest(), this.bounds.getNorth(), this.bounds.getEast()],this.zoom);
                    const deltaSN = this.bounds.getNorth() - this.bounds.getSouth()
                    const deltaWE = this.bounds.getEast() - this.bounds.getWest()

                    this.feedItems = this.superCluster.getClusters([
                        (this.bounds.getSouth() - deltaSN / 2),
                        (this.bounds.getWest() - deltaWE / 2),
                        (this.bounds.getNorth() + deltaSN / 2),
                        (this.bounds.getEast() + deltaWE / 2)
                    ], this.zoom)
                    window.console.log(this.feedItems)

                    this.$nextTick(() => {
                        setTimeout(() => {
                            document.getElementsByClassName('markerIcon').forEach((element) => {
                                element.style.opacity = 1
                            })
                        }, 100)

                    })

                })
                .catch((e) => {
                    window.console.log(e)
                })

            //this.geoAggregations = []
            //this.getPage('/feed_items?itemsPerPage=100&page=1&geoBox='+this.bounds.oa.g+','+this.bounds.ka.g+','+this.bounds.oa.h+','+this.bounds.ka.h);
        },
        loadGeoAggregation () {
            if (!this.isActive) return
            this.bounds = this.map.getBounds()
            const deltaSN = this.bounds.getNorth() - this.bounds.getSouth()
            const deltaWE = this.bounds.getEast() - this.bounds.getWest()
            /*document.getElementsByClassName('citystats_marker').forEach((element)=>{
                element.style.opacity = 0.1;
            });*/
            this.closeAllPopups()
            if (this.geoAggregationAbortController !== null) {
                try{
                    this.geoAggregationAbortController.abort()
                }catch (e){
                    window.console.log(e);

                }
            }
            this.geoAggregationAbortController = new AbortController()
            return fetch('/geo_aggregations/' + this.$root.$i18n.locale + '/' + this.zoom + '/' +
                (this.bounds.getSouth() - deltaSN / 2) + ',' +
                (this.bounds.getWest() - deltaWE / 2) + ',' +
                (this.bounds.getNorth() + deltaSN / 2) + ',' +
                (this.bounds.getEast() + deltaWE / 2) + '?' + this.getParamsFromSearchfilters(false), {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                signal: this.geoAggregationAbortController.signal
            })
                .then(response => response.json())
                .then((data) => {

                    this.geoAggregations = data['hydra:member']
                    //doppelte zusammenfassen:
                    let geoAggregationsByName = []
                    this.geoAggregations.forEach((ga) => {
                        if (typeof geoAggregationsByName[ga.name] !== 'undefined') {
                            if (ga.count > geoAggregationsByName[ga.name].count) {
                                ga.count += geoAggregationsByName[ga.name].count
                            } else {
                                let newGa = geoAggregationsByName[ga.name]
                                newGa.count += ga.count
                                geoAggregationsByName[ga.name] = newGa
                            }
                        } else {
                            geoAggregationsByName[ga.name] = ga
                        }
                    })
                    this.geoAggregations = Object.values(geoAggregationsByName)
                    /*this.geoAggregations.forEach((ga) => {
                        let scale = Math.log10(ga.count) < 0.5 ? 0.5 : Math.log10(ga.count)
                        if (scale > 2) scale = 2
                        ga.scale = scale
                        //ga.scale=1;
                        ga.offset = [0, 0] //[-30*ga.scale,-67*ga.scale]

                    })*/
                    if (this.zoom >= 16) {
                        this.geoAggregationMarkers = []
                        this.geoAggregations.sort((a, b) => { return b.count - a.count })
                    } else {
                        this.geoAggregations.sort((a, b) => {
                            //return a.count - b.count;
                            return a.geo.lon - b.geo.lon
                        })
                        this.geoAggregationMarkers = this.geoAggregations
                    }

                    //window.console.log(this.geoAggregations);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            document.getElementsByClassName('citystats_marker').forEach((element) => {
                                element.style.opacity = 1
                            })
                        }, 100)

                    })
                })
                .catch((e) => {
                    window.console.log(e)
                })
        },
        /*getTranslation (item) {
            let transList = item.translations.filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item.translations[0] : transList[0]
        },*/
        getPosition (item) {

            return {
                lat: parseFloat(item.geo.lat),
                lng: parseFloat(item.geo.lon)
            }
        },
        getParamsFromSearchfilters (withGeoBox) {
            let params = ['itemsPerPage=500', 'page=1']

            this.$store.state.searchfilters.items.forEach((item) => {
                if (item.type !== 'place') params.push(item.queryParam)
            })

            if (this.$store.state.searchfilters.items.length === 0) {
                params.push('showInFeed=true')
            }
            if (withGeoBox !== false) {
                this.bounds = this.map.getBounds()
                const deltaSN = this.bounds.getNorth() - this.bounds.getSouth()
                const deltaWE = this.bounds.getEast() - this.bounds.getWest()

                if (this.bounds) params.push('geoBox=' +
                    (this.bounds.getSouth() - deltaSN / 2) + ',' +
                    (this.bounds.getWest() - deltaWE / 2) + ',' +
                    (this.bounds.getNorth() + deltaSN / 2) + ',' +
                    (this.bounds.getEast() + deltaWE / 2))
                params.push('cache=false')
                //window.console.log(this.bounds, params)
            }
            return params.join('&')
        },
        getBoundsZoomLevel (bounds, mapDim) {
            const WORLD_DIM = {height: 256, width: 256}
            const ZOOM_MAX = 21

            function latRad (lat) {
                const sin = Math.sin(lat * Math.PI / 180)
                const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
                return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
            }

            function zoom (mapPx, worldPx, fraction) {
                return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
            }

            const ne = bounds[1]
            const sw = bounds[0]

            const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI

            const lngDiff = ne.lng - sw.lng
            const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

            const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
            const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

            return Math.min(latZoom, lngZoom, ZOOM_MAX)
        },
        carouselItemClick (item) {
            let documentType = item['documentType']
            //if (documentType === 'PhotographCollection') documentType = 'ImageGallery'
            this.$router.push({name: documentType + 'Show', params: {id: item['id']}})
        },
        updateCenter () {
            clearTimeout(this.feedLoadTimeOut)
            clearTimeout(this.geoAggregationLoadTimeOut)
            clearTimeout(this.placeStatsLoadTimeOut)
            /*document.getElementsByClassName('markerIcon').forEach((element)=>{
                element.style.opacity = 0;
            });*/
            this.geoAggregationLoadTimeOut = setTimeout(this.loadGeoAggregation, 50)
            if (this.zoom >= 16) {
                this.feedLoadTimeOut = setTimeout(this.loadFeed, 50)
            }

            if (this.zoom >= 16) {
                clearTimeout(this.placeStatsLoadTimeOut)
                const latlng = this.map.getCenter()
                window.console.log('center: ',latlng);
                this.placeStatsLoadTimeOut = setTimeout(() => {
                    return fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latlng.lat + '&lon=' + latlng.lng + '&zoom=16&addressdetails=1', {
                        method: 'GET',
                        headers: new Headers({'Content-Type': 'application/json'}),
                    })
                        .then(response => response.json())
                        .then((data) => {
                            //window.console.log(data)
                            this.placeObj = this.mapToPlace(data.address)
                            clearTimeout(this.placeStatsLoadTimeOut)
                            this.placeStatsLoadTimeOut = setTimeout(this.loadPlaceStats, 1000)

                        })
                        .catch((e) => {
                            window.console.error('Geocode was not successful for the following reason: ' + e)
                            return e

                        })
                },100);
            }else{
                this.cityStatsPlace = null
                this.cityStatsCount = null
            }

        },
        mapToPlace (place) {
            let placeObj = {}
            if (place.country_code) placeObj.addressCountry = place.country_code
            if (place.country) placeObj.addressCountryLong = place.country
            if (place.city) placeObj.addressLocality = place.city
            if (place.town) placeObj.addressLocality = place.town
            if (place.village) placeObj.addressLocality = place.village
            if (place.state) placeObj.addressRegion = place.state
            //if (place.road) placeObj.streetAddress = place.road
            return placeObj
        },
        loadPlaceStats () {
            const queryString = Object.keys(this.placeObj).map(key => key + '=' + this.placeObj[key]).join('&')
            return fetch('/place_stats?' + queryString, {
                method: 'POST',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: '{}'
            })
                .then(response => response.json())
                .then((data) => {
                    //window.console.log(data)
                    /*if (data.streetAddressCount > 0) {
                        this.cityStatsPlace = this.placeObj.streetAddress
                        this.cityStatsCount = data.streetAddressCount
                    } else */if (data.addressLocalityCount > 0) {
                        this.cityStatsPlace = this.placeObj.addressLocality
                        this.cityStatsCount = data.addressLocalityCount
                    } else if (data.addressRegionCount > 0) {
                        this.cityStatsPlace = this.placeObj.addressRegion
                        this.cityStatsCount = data.addressRegionCount
                    } else if (data.addressCountryCount > 0) {
                        this.cityStatsPlace = this.placeObj.addressCountryLong
                        this.cityStatsCount = data.addressCountryCount
                    } else {
                        this.cityStatsPlace = null
                        this.cityStatsCount = null
                    }

                })
                .catch((e) => {

                    return e

                })
        },
        showClusterChildren (item) {

            window.console.log(item)
            let markers = this.superCluster.getLeaves(item.id, 100, 0) /*.map((marker)=>{
                return marker.properties;
            });*/
            window.console.log(markers)
            window.console.log(item.geometry.coordinates)

        },
        updateSettingGetLocation (e) {

            this.showAskForLocationDialog = false
            if (e === true) {
                navigator.geolocation.getCurrentPosition((cp) => {
                    window.console.log(cp)
                    this.setsettings({getLocation: true})
                    this.$store.commit('settings/setsettings', {getLocation: true})
                }, (cp) => {
                    window.console.log(cp)
                    this.showGetLocationDeniedInfo = true
                    this.settingGetLocation = false
                    this.setsettings({getLocation: false})
                    this.$store.commit('settings/setsettings', {getLocation: false})
                }, {timeout: 10000})
            }
        },
        setGeoPos () {
            window.console.log('setGeoPos', this.settingGetLocation, this.initialRoute, this.$route.fullPath)
            //only set new pos if route changed:
            if (this.$route.fullPath === this.initialRoute) {
                return
            }

            if (this.$route.params.geo) {
                let geo = this.$route.params.geo.split(',')
                if (geo.length === 4) {
                    //this.center = {lat: parseFloat(geo[1])+(parseFloat(geo[3])-parseFloat(geo[1]))/2, lng: parseFloat(geo[0])+(parseFloat(geo[2])-parseFloat(geo[0]))/2}
                    //this.center=null;
                    //this.zoom=null;
                    window.console.log(949, this.center)
                    this.boundsParam = [[parseFloat(geo[0]), parseFloat(geo[1])], [parseFloat(geo[2]), parseFloat(geo[3])]]
                    this.map.fitBounds(this.boundsParam, {padding: {top: 0, bottom: 0, left: 0, right: 0}})
                } else if (geo.length === 2) {
                    this.zoom = 17
                    this.center = {lat: parseFloat(geo[0]), lng: parseFloat(geo[1])}
                }

            } else if (this.initialRoute == null) {
                //window.console.log('settingGetLocation522', this.settingGetLocation)
                if (this.settingGetLocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.center = {lat: position.coords.latitude, lng: position.coords.longitude}
                        this.geolocate()
                    })
                } else {
                    //window.console.log('setGeoPos', 'setting showAskForLocationDialog to true')
                    this.showAskForLocationDialog = true
                }
            }
            this.initialRoute = this.$route.fullPath
        },
        onTouchStart (evt) {
            this.touchStartData = {
                point: evt.mapboxEvent.point,
                timestamp: Date.now()
            }
        },
        onTouchEnd (evt) {
            const now = Date.now()
            if (now - this.touchStartData.timestamp > 200) {
                return
            }
            const deltaX = evt.mapboxEvent.point.x - this.touchStartData.point.x
            const deltaY = evt.mapboxEvent.point.y - this.touchStartData.point.y
            if (Math.abs(deltaY) > Math.abs(deltaX)) {
                return
            }
            if (Math.abs(deltaX) < 250) {
                return
            }
            window.console.log(deltaX, deltaY)
            if (deltaX > 0) this.$router.push({name: 'HomeList'})
            if (deltaX < 0) this.$router.push({name: 'ImageGalleryList'})
        },
        closeAllPopups () {
            if (this.activePopup !== null) this.activePopup.popup.remove()
        },
        setActivePopup (who) {
            window.console.log(who)
            this.activePopup = who
        },
        setPlaceFilter (item) {
            let newsearchfilters = this.searchfilters.filter((item) => item.type !== 'place')

            let queryParam = []
            queryParam.push('placeKeys=' + item.id.toUpperCase())

            newsearchfilters.push({
                text: item.name,
                type: 'place',
                data: item,
                queryParam: queryParam.join('&')
            })
            this.setsearchfilters(newsearchfilters)
            this.$store.commit('searchfilters/setsearchfilters', newsearchfilters)
            this.$router.push({name: 'HomeList'})

        },
        photographCollectionClick (item){
            this.$router.push({name:  'PhotographCollectionShow', params: {id: item.properties.id}})
        }
    }
}
</script>
