//import PhotographCollectionList from '../components/photographcollection/List'
import PhotographCollectionCreate from '../components/photographcollection/Create'
import PhotographCollectionCreated from '../components/photographcollection/Created'
import PhotographCollectionUpdate from '../components/photographcollection/Update'
import PhotographCollectionShow from '../components/photographcollection/Show'
import PhotographCollectionCoverShow from '../components/photographcollection/CoverShow'
import PhotographCollectionCollageShow from '../components/photographcollection/CollageShow'
import LandingPage from "@/components/landing/LandingPage.vue";
import CollectionsList from "@/components/photographcollection/CollectionsList.vue"


export default [
  //{ name: 'PhotographCollectionList', path: '/photograph_collections/', components: {'MyImagesTab': PhotographCollectionList }, meta: { requiresAuth: true } },
  { name: 'PhotographCollectionCreate', path: '/report_collection', components: {default:  PhotographCollectionCreate }, meta: { requiresAuth: false, navHidden: true  } },
  { name: 'PhotographCollectionCreated', path: '/report_collection_thankyou', components: {default:  PhotographCollectionCreated }, meta: { requiresAuth: false, navHidden: true  } },
  { name: 'PhotographCollectionUpdate', path: '/photograph_collections/edit/:id', components: {default: PhotographCollectionUpdate }, meta: { requiresAuth: true, navHidden: true } },
  { name: 'PhotographCollectionShowColor', path: '/photograph_collections/show/:id/color', components: {'MyImagesTab':  PhotographCollectionShow}, meta: { requiresAuth: false, navHidden: true } },

  { name: 'PhotographCollectionShow', path: '/photograph_collections/show/:id', components: {'MyImagesTab':  PhotographCollectionShow }, meta: { requiresAuth: false, navHidden: true } },
  { name: 'CoverShow', path: '/photograph_collections/cover/show/:id', components: { default:PhotographCollectionCoverShow }, meta: { requiresAuth: false, navHidden: true } },
  { name: 'CollageShow', path: '/photograph_collections/collage/show/:collectionId/:id', components: { default:PhotographCollectionCollageShow }, meta: { requiresAuth: false, navHidden: true } },
  { name: 'LandingPage', path: '/retro', components: { default: LandingPage}, meta: { navHidden: true, requiresAuth: false } },
  { name: 'CollectionsList', path: '/collections', components: { default: CollectionsList}, meta: { navHidden: true, requiresAuth: false } },
]
