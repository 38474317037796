<template>
    <form @submit.prevent="handleSubmit(item)">
        <v-row>
            <v-col cols="12" md="6" lg="4" xl="2">
                <v-text-field
                    id="display_name"
                    :label="$t('app.settings.displayName')"
                    v-model="item.name"
                    :class="['form-control', isInvalid('name') ? 'is-invalid' : '']"
                    type="text"
                    :placeholder="$t('app.settings.displayNamePlaceholder')"
                    @input="handleUpdateField('name', $event.target.value)"></v-text-field>
                <div
                    v-if="isInvalid('name')"
                    class="invalid-feedback">{{ violations.name }}
                </div>
            </v-col>
            <!--<v-col cols="12" md="6" lg="4" xl="2">
                <v-text-field
                    id="person_familyName"
                    :label="t('app.settings.familyName')"
                    v-model="item.familyName"
                    :class="['form-control', isInvalid('familyName') ? 'is-invalid' : '']"
                    type="text"
                    placeholder="t('app.settings.familyNamePlaceholder')"
                    @input="handleUpdateField('familyName', $event.target.value)"></v-text-field>
                <div
                    v-if="isInvalid('familyName')"
                    class="invalid-feedback">{{ violations.familyName }}
                </div>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="2">

                <v-text-field
                    id="person_givenName"
                    label="Given name"
                    v-model="item.givenName"
                    :class="['form-control', isInvalid('givenName') ? 'is-invalid' : '']"
                    type="text"
                    placeholder="Given name. In the U.S., the first name of a Person. This can be used along with familyName instead of the name property."
                    @input="handleUpdateField('givenName', $event.target.value)"></v-text-field>
                <div
                    v-if="isInvalid('givenName')"
                    class="invalid-feedback">{{ violations.givenName }}
                </div>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="2">
                <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            id="person_birthDate"
                            :value="formatDate()"
                            label="Birth Date"
                            placeholder="Birth Date"
                            :class="['form-control', isInvalid('birthDate') ? 'is-invalid' : '']"
                            readonly
                            @blur="parseDate"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="item.birthDate" :locale="item.locale || 'de'"
                                   @input="datePickerMenu = false"></v-date-picker>
                </v-menu>
                <div
                    v-if="isInvalid('birthDate')"
                    class="invalid-feedback">{{ violations.birthDate }}
                </div>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="2">
                <v-radio-group v-model="item.gender" :mandatory="false" row>
                    <v-radio label="Male" value="Male"></v-radio>
                    <v-radio label="Female" value="Female"></v-radio>
                </v-radio-group>
                <div
                    v-if="isInvalid('gender')"
                    class="invalid-feedback">{{ violations.gender }}
                </div>
            </v-col>-->
            <v-col cols="12" md="6" lg="4" xl="2">
                <v-btn class="my-5" color="primary" small dark type="submit">
                    <v-icon>mdi-content-save</v-icon>{{ $t('app.Save') }}
                </v-btn>
            </v-col>
        </v-row>
    </form>
</template>

<script>
    import moment from 'moment'
    export default {
        props: {
            handleSubmit: {
                type: Function,
                required: true
            },

            handleUpdateField: {
                type: Function,
                required: true
            },

            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                datePickerMenu: false
            }
        },
        watch: {
            // eslint-disable-next-line object-shorthand,func-names
            item: function () {
                //this.dateFormatted=this.item.birthDate ? moment( this.item.birthDate).format('DD.MM.YYYY') : ''
            }
        },
        computed: {
            // eslint-disable-next-line
            item() {
                return JSON.parse(JSON.stringify(this.initialValues || this.values));
            },

            violations() {
                return this.errors || {}
            }
        },
        created () {
            //this.dateFormatted=this.item.birthDate ? moment( this.item.birthDate).format('DD.MM.YYYY') : ''
            //window.console.log(this.dateFormatted);
        },
        methods: {
            isInvalid(key) {
                return Object.keys(this.violations).length > 0 && this.violations[key]
            },
            parseDate(event){
                return moment(event.target.value,'DD.MM.YYYY').format('YYYY-MM-DD')
            },
            formatDate(){
                return this.item.birthDate ? moment( this.item.birthDate).format('DD.MM.YYYY') : '';
            }
        }
    }
</script>
