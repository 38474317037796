<template>

    <div class="background">
        <Viewer25D
            :imageOriginal="entrypoint+'/image_galleries/5ee3257caa59aa006d6631e3.2/display.jpeg'"
            imageDepth="@/assets/img/grayscale.jpg"
            :horizontalThreshold="25"
            :verticalThreshold="25"></Viewer25D>

    </div>
</template>

<script>
    import Viewer25D from './Viewer25DTest';
    import { ENTRYPOINT } from '../../config/entrypoint'
    export default {
        components: {
            Viewer25D
        },
        data: () => ({
            entrypoint: ENTRYPOINT,
        }),
        created () {

        },
    }
</script>

<style scoped>
 .background{
     position: absolute;
     top:0;
     left:0;
     width: 1024px;
     height: 1024px;
 }
</style>
