import RetrosnapList from '../components/retrosnap/List'
import RetrosnapCreate from '../components/retrosnap/Create'
import RetrosnapUpdate from '../components/retrosnap/Update'
import RetrosnapShow from '../components/retrosnap/Show'

export default [
  { name: 'RetrosnapList', path: '/retrosnaps/', component: RetrosnapList, meta: { requiresAuth: true } },
  { name: 'RetrosnapCreate', path: '/retrosnaps/create/:photograph', component: RetrosnapCreate, meta: { navHidden: true, requiresAuth: true, fullscreen: true } },
  { name: 'RetrosnapUpdate', path: '/retrosnaps/edit/:id', components: { default: RetrosnapUpdate}, meta: { navHidden: false, requiresAuth: true } },

  { name: 'RetrosnapShowCommentColor', path: '/retrosnaps/show/:id/color/:comment_id', components: { default: RetrosnapShow}, meta: { navHidden: true, fullscreen: true } },
  { name: 'RetrosnapShowColor', path: '/retrosnaps/show/:id/color', component: RetrosnapShow, meta: { navHidden: true, fullscreen: true } },
  { name: 'RetrosnapShowComment', path: '/retrosnaps/show/:id/:comment_id', components: { default: RetrosnapShow}, meta: { navHidden: true, fullscreen: true } },

  { name: 'RetrosnapShow', path: '/retrosnaps/show/:id', component: RetrosnapShow, meta: { navHidden: true, fullscreen: true } },
  ]
