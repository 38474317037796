<template>
    <div>
        <form @submit.prevent="handleSubmit(item)">
            <v-stepper
                style="background:none"
                v-model="step"
                vertical
            >
                <v-stepper-step
                    :complete="step > 0"
                    step="0"
                    editable
                >{{ $t('app.ReportCollection') }}

                </v-stepper-step>
                <v-stepper-content step="0">
                    <v-card-subtitle>{{ $t('app.PhotographCollection.step1Title') }}</v-card-subtitle>
                    <v-card-text v-html="$t('app.PhotographCollection.step1Text')"></v-card-text>
                    <v-btn
                        color="primary"
                        @click="step = 1"
                    >{{ $t('app.continue') }}
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step
                    :complete="step > 1"
                    step="1"
                    editable
                >{{ $t('app.PhotographCollection.step2Title') }}

                </v-stepper-step>

                <v-stepper-content step="1">
                    <div class="form-group">
                        <label
                            for="photographcollection_headline"
                            class="form-control-label">{{ $t('app.PhotographCollection.headlineLabel') }}</label>
                        <v-text-field
                            id="photographcollection_headline"
                            v-model="item.headline"
                            :class="['form-control', isInvalid('headline') ? 'is-invalid' : '']"
                            type="text"
                            :placeholder="$t('app.PhotographCollection.headlinePlaceholder')"
                            @input="handleUpdateField('headline', item.headline)"></v-text-field>
                        <div
                            v-if="isInvalid('headline')"
                            class="invalid-feedback">{{ violations.headline }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="photographcollection_howManyPhotographs"
                            class="form-control-label">{{ $t('app.PhotographCollection.howManyPhotographsLabel') }}</label>
                        <v-select
                            :items="howManySelectboxItems"
                            v-model="item.howManyPhotographs"
                            @change="updateTextField"
                        ></v-select>
                        <div
                            v-if="isInvalid('headline')"
                            class="invalid-feedback">{{ violations.headline }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="photographcollection_digitalized"
                            class="form-control-label">{{ $t('app.PhotographCollection.digitalizedLabel') }}</label>
                        <v-checkbox
                            v-model="item.isDigitalized"
                        >
                            <template slot="label">
                                <div v-html="$t('app.PhotographCollection.DigitalizedText')"></div>
                            </template>
                        </v-checkbox>
                        <p v-html="$t('app.PhotographCollection.DigitalizedInfo')"></p>
                    </div>

                    <v-btn
                        color="primary"
                        @click="step = 2"
                    >{{ $t('app.continue') }}
                    </v-btn>
                    <v-btn @click="step--" text>
                        {{ $t('app.back') }}
                    </v-btn>
                </v-stepper-content>


                <v-stepper-step
                    :complete="step > 2"
                    step="2"
                    editable
                >{{ $t('app.PhotographCollection.step3Title') }}
                </v-stepper-step>

                <v-stepper-content step="2">

                    <div class="form-group">
                        <label
                            for="photographcollection_about"
                            class="form-control-label">{{ $t('app.PhotographCollection.aboutLabel') }}</label>
                        <v-textarea
                            id="photographcollection_about"
                            v-model="item.about"
                            :class="['form-control', isInvalid('about') ? 'is-invalid' : '']"
                            type="text"
                            :placeholder="$t('app.PhotographCollection.aboutPlaceholder')"
                            @input="handleUpdateField('about', item.about)"></v-textarea>
                        <div
                            v-if="isInvalid('about')"
                            class="invalid-feedback">{{ violations.about }}
                        </div>
                    </div>
                    <v-btn
                        color="primary"
                        @click="step = 3"
                    >{{ $t('app.continue') }}
                    </v-btn>
                    <v-btn @click="step--" text>
                        {{ $t('app.back') }}
                    </v-btn>
                </v-stepper-content>


                <v-stepper-step
                    :complete="step > 3"
                    step="3"
                    editable
                >{{ $t('app.PhotographCollection.step4Title') }}
                    <small>{{ $t('app.PhotographCollection.step4Subtitle') }}</small>
                </v-stepper-step>

                <v-stepper-content step="3">
                    <div class="form-group">
                        <label
                            for="photographcollection_personName"
                            class="form-control-label">{{ $t('app.PhotographCollection.personNameLabel') }}</label>
                        <v-text-field
                            id="photographcollection_personName"
                            v-model="item.personName"
                            :class="['form-control', isInvalid('text') ? 'is-invalid' : '']"
                            type="text"
                            :placeholder="$t('app.PhotographCollection.personNamePlaceholder')"
                            @input="updateTextField"></v-text-field>
                        <div
                            v-if="isInvalid('personName')"
                            class="invalid-feedback">{{ violations.personName }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="photographcollection_personEmail"
                            class="form-control-label">{{ $t('app.PhotographCollection.personEmailLabel') }}</label>
                        <v-text-field
                            ref="emailField"
                            id="photographcollection_personEmail"
                            v-model="item.personEmail"
                            :class="['form-control', isInvalid('text') ? 'is-invalid' : '']"
                            type="text"
                            required="true"
                            :rules="rules.email"
                            :placeholder="$t('app.PhotographCollection.personEmailPlaceholder')"
                            @input="updateTextField"></v-text-field>
                        <div
                            v-if="isInvalid('personEmail')"
                            class="invalid-feedback">{{ violations.personEmail }}
                        </div>
                        <v-alert
                            v-if="emailError"
                            type="error"
                            dismissible
                        >
                            {{ emailError }}
                        </v-alert>
                    </div>

                    <v-btn
                        color="primary"
                        @click="handleContinue"
                    >{{ $t('app.continue') }}
                    </v-btn>
                    <v-btn @click="step--" text>
                        {{ $t('app.back') }}
                    </v-btn>
                </v-stepper-content>


                <v-stepper-step
                    step="4"
                    editable
                >{{ $t('app.PhotographCollection.step5Title') }}
                </v-stepper-step>
                <v-stepper-content step="4">
                    <p>{{ $t('app.PhotographCollection.AgreeContact') }}</p>
                    <p></p>
                    <v-checkbox
                        v-model="item.privacy"
                        :rules="rules.privacy"
                        required
                    >
                        <template slot="label">
                            <div v-html="$t('app.PhotographCollection.AcceptPrivacy' ,{ privacylink:'/privacy',agblink:'/agb'})"></div>
                        </template>
                    </v-checkbox>
                    <p v-html="$t('app.MoreInfos' ,{ privacylink:'/privacy'})">

                    </p>
                    <v-btn
                        color="primary"
                        type="submit"
                    >{{ $t('app.PhotographCollection.step5Button') }}
                    </v-btn>
                    <v-btn @click="step--" text>
                        {{ $t('app.back') }}
                    </v-btn>
                </v-stepper-content>
            </v-stepper>
        </form>
    </div>
</template>

<script>
import { ENTRYPOINT } from '@/config/entrypoint';
export default {
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },

        handleUpdateField: {
            type: Function,
            required: true
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => {
                return {
                    licenseName: 'not defined yet',
                }
            }
        }
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            step: 0,
            emailError:'',
            rules: {
                email: [
                    v => !!v || this.$t('app.EmailIsRequired'),
                    v => /.+@.+\..+/.test(v) || this.$t('app.EmailMustBeValid'),
                ],
                privacy: [v => !!v || 'You must agree to continue!']
            },
            howManySelectboxItems: [
                '1 - 10',
                '11 - 50',
                '51 - 100',
                '101 - 200',
                '201 - 500',
                '> 500'
            ],
            materialTypes: [
                this.$t('app.MaterialTypes.PrintsOnPaperOrCardboard'),
                this.$t('app.MaterialTypes.AlbumenPaper'),
                this.$t('app.MaterialTypes.SilverGelatinPaper'),
                this.$t('app.MaterialTypes.GlassPlateNegatives'),
                this.$t('app.MaterialTypes.CollodionPaper'),
                this.$t('app.MaterialTypes.Postcards'),
                this.$t('app.MaterialTypes.NegativeFilms'),
                this.$t('app.MaterialTypes.Slides')
            ],
            searchFieldValue: '',

        }
    },
    computed: {
        // eslint-disable-next-line
        item () {
            window.console.log('item changed')
            return this.initialValues || this.values
            //return JSON.parse(JSON.stringify(this.initialValues || this.values))
        },

        violations () {
            return this.errors || {}
        }
    },

    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        handleContinue(){
            if (this.$refs['emailField'].validate()){
                this.step=4
            }else{
                this.emailError=this.$t('app.EmailIsRequired')
            }
        },
        updateTextField(){
            let itemText='';
            if (typeof this.item.howManyPhotographs !== 'undefined'){
                itemText+=this.$t('app.PhotographCollection.howManyPhotographsLabel')+"<br/>\n";
                itemText+=this.item.howManyPhotographs+"<br/><br/>\n\n";
            }
            if (typeof this.item.isDigitalized !== 'undefined'){
                itemText+=this.$t('app.PhotographCollection.digitalizedLabel')+"<br/>\n";
                itemText+=(this.item.isDigitalized ? this.$t('app.PhotographCollection.DigitalizedText') : 'Nein')+"<br/>\n\n";
            }
            if (typeof this.item.personName !== 'undefined') {
                itemText += this.$t('app.PhotographCollection.personNamePlaceholder') + "<br/>\n";
                itemText += this.item.personName + "<br/><br/>\n\n";
            }
            if (typeof this.item.personEmail !== 'undefined') {
                itemText += this.$t('app.PhotographCollection.personEmailPlaceholder') + "<br/>\n";
                itemText += this.item.personEmail + "<br/><br/>\n\n";
            }
            /*if (typeof this.item.personPhone !== 'undefined') {
                itemText += this.$t('app.PhotographCollection.personPhonePlaceholder') + "<br/>\n";
                itemText += this.item.personPhone + "<br/><br/>\n\n";
            }*/
            this.handleUpdateField('text',itemText);
        },




    }
}
</script>
