<template>
    <div v-if="value">
        <v-autocomplete
            dark
            :search-input="searchFieldValue"
            v-model="geoSearchValue"
            :items="placesList"
            item-text="text"
            item-value="place_id"
            label="Search on Map"
            @update:search-input="onSearchFieldChange"
            @click:clear="onSearchFieldClear"
            @update:list-index="onSearchListClick"
            @change="onSearchChange"
            :filter="(item, queryText, itemText) => {return true}"
            clearable
            flat
            outlined
            return-object
        ></v-autocomplete>
        <v-row>
            <v-col>
                <div class="map-breadcrumb pb-5" v-if="value.postalAddress">
            <span v-if="value.postalAddress.addressCountryLong">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip close @click:close="removeAddressCountry()" v-bind="attrs" v-on="on">{{value.postalAddress.addressCountryLong}}</v-chip>
                    </template>
                    <span>Land</span>
                </v-tooltip>
            </span>
                    <span v-if="value.postalAddress.addressRegion"> >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip close @click:close="removeAddressRegion()" v-bind="attrs" v-on="on">{{value.postalAddress.addressRegion}}</v-chip>
                    </template>
                    <span>Region</span>
                </v-tooltip>
            </span>
                    <span v-if="value.postalAddress.addressLocality"> >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip close @click:close="removeAddressLocality()" v-bind="attrs" v-on="on">{{value.postalAddress.addressLocality}}</v-chip>
                    </template>
                    <span>Ort</span>
                </v-tooltip>
            </span>
                    <span v-if="value.postalAddress.streetAddress"> >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip close @click:close="removeStreetAddress()" v-bind="attrs" v-on="on">{{value.postalAddress.streetAddress}}</v-chip>
                    </template>
                    <span>Straße</span>
                </v-tooltip>
            </span>




                </div>
            </v-col>
            <v-col>Koordinaten: <span v-if="value.geo">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip close @click:close="removeGeo()" v-bind="attrs" v-on="on">LAT: {{value.geo.latitude}}, LNG: {{value.geo.longitude}}</v-chip>
                    </template>
                    <span>Koordinaten</span>
                </v-tooltip>
            </span></v-col>
        </v-row>

        <div style="height:350px;" id="map">
            <MglMap
                :accessToken="accessToken"
                :mapStyle="mapStyle"
                :center="center"
                :zoom="zoom"
                container="#map"

                @click="onMapClick"
                @load="onMapLoaded"
                :repaint="true"
            >
                <MglMarker v-if="position" :coordinates="position" :draggable="true"
                           @dragend="posDrag">
                </MglMarker>
            </MglMap>
        </div>

        <!--<l-map ref="lmap"
               :center="center"
               :zoom="zoom"
               :options="mapOptions"
               style="width:100%; height: 500px; z-index: 1"
               @click="onMapClick"
               >
            <l-tile-layer
                :url="url"
                :attribution="attribution"
                :options="tilesOptions"
                detectRetina

            />
            <l-marker
                :lat-lng="position"
                :draggable="true"
                @update:lat-lng="posDrag"
            >

            </l-marker>
        </l-map>-->
    </div>
</template>
<style>
.map-breadcrumb{

}
</style>

<script>
import fetch from '../../../../client/src/utils/fetch'
    import { ENTRYPOINT } from '@/config/entrypoint';
import { MglMap, MglMarker } from 'v-mapbox'

export default {
    components: {
        MglMap,
        MglMarker
    },
    props: {
        value: {
            type: Object,
        },
        placeKeys: {
            type: Array,
            required: false
        },
        errors: {
            type: Object,
            default: () => {
            }
        },

        handleUpdateField: {
            type: Function,
            required: true
        },

    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            accessToken: process.env.VUE_APP_MAPBOX_TOKEN, // your access token. Needed if you using Mapbox maps
            mapStyle: 'https://maps.nimmerso.app/api/maps/basic/style.json', //'mapbox://styles/julianstrickernimmerso/ckgrug7b60la419n850139jcu', //'mapbox://styles/julianstrickernimmerso/ck8t44v7z01d91imd1v403a49', //'mapbox://styles/mapbox/streets-v11', // your map style
                center: {'lat': 46.609860, 'lng': 11.192860},
                position: {'lat': 46.609860, 'lng': 11.192860},
            zoom: 8,
            //map: null,
            manualset: false,
            mapOptions: {
                //zoomSnap: 0.5,
                zoomControl: true
            },
                attribution:
                    '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
            placesList: [],
            geoDataLoading: false,
            loadGeoDataTimeout: null,
            searchFieldValue: '',
            geoSearchValue: null,

        }
    },
    watch: {
        value: function () {
            window.console.log('watch value', this.value)
            if (this.value !== null && typeof this.value.geo !== 'undefined' && this.value.geo!==null) {
                this.position=[this.value.geo.longitude, this.value.geo.latitude];
                //this.position = [this.value.geo.longitude,this.value.geo.latitude]
                //this.center=[this.value.geo.latitude,this.value.geo.longitude];
                this.center = [this.value.geo.longitude, this.value.geo.latitude]
                window.console.log(this.center)
                this.searchFieldValue = this.value.name
            }else{
                this.position=null;
                this.searchFieldValue= '';
                this.geoSearchValue= null;
            }

        },
        placeKeys: function () {
            //let placeKey=typeof(this.placeKeys)!=='undefined' && this.placeKeys!==null && typeof(this.placeKeys[0])!=='undefined' ? this.placeKeys[0] : null;
            //this.loadPlaceDetail(placeKey);
        },
        /*searchFieldValue: function(){
            window.console.log('watch searchFieldValue');
            this.onSearchFieldChange();
        },*/
        geoSearchValue: function (place) {
            window.console.log('watch geoSearchValue', place)
            if(place) this.placeChanged(place.data)

        }
    },
    computed: {
        // eslint-disable-next-line
        item () {
            return this.value || {geo: null}
        },
        violations () {
            return this.errors || {}
        },
        //google: gmapApi,
        placeName () {
            return typeof this.value !== 'undefined' && this.value !== null ? this.value.name : ''
        }
        /*position () {
          return {lat: this.item.latitude || 0, lng: this.item.longitude || 0}
        }*/
    },
    created () {
        /*this.sync()
        setTimeout(() => {
            window.console.log('timeout')
            this.sync()
        }, 1500)*/
        //this.placeName=this.place ? this.place.name : '';
        //let placeKey=typeof(this.placeKeys)!=='undefined' && this.placeKeys!==null && typeof(this.placeKeys[0])!=='undefined' ? this.placeKeys[0] : null;
        //this.loadPlaceDetail(placeKey);

    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        onMapClick: function (evt) {
            this.setPos(evt)
        },

        getPlaceForLngLat (lngLat) {
            /*const geocoder = new this.google.maps.Geocoder();

https://nominatim.openstreetmap.org/reverse?format=json&lat=46.794028&lon=11.943111&zoom=25&addressdetails=1&accept-language=de
geocoder.geocode({'location': latlng }, (results, status)=>{
if (status === 'OK') {
    if (results[0]) {
        const placeObj=this.mapToPlace(results[0])
        this.$refs.autocompletefield.$el.value=placeObj.name;
        this.handleUpdateField('place', placeObj);


        return results[0].formatted_address;
    } else {
        window.console.error('Geocode was not successful for the following reason: ' + status);
    }
}
})
*/

            fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + lngLat.lat + '&lon=' + lngLat.lon + '&zoom=25&addressdetails=1&accept-language=de', {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    window.console.log(data)
                    const placeObj = this.mapToPlace(data)
                    this.handleUpdateField('place', placeObj)

                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    return e

                })
            return lngLat
        },
        posDrag: function (evt) {
            window.console.log(evt)
            this.setPos({mapboxEvent: {lngLat: {lat: evt.mapboxEvent.target._lngLat.lat, lng: evt.mapboxEvent.target._lngLat.lng}}})

        },
        setPos: function (evt) {
            window.console.log(evt);
            let itemc = JSON.parse(JSON.stringify(this.item))
            this.item.geo = {latitude: evt.mapboxEvent.lngLat.lat, longitude: evt.mapboxEvent.lngLat.lng}
            this.handleUpdateField('place', itemc)
            //this.item.geo.latitude = evt.latlng.lat;
            //this.item.geo.longitude = evt.latlng.lng;
            this.position = [evt.mapboxEvent.lngLat.lng, evt.mapboxEvent.lngLat.lat]

            this.manualset = true
            if (typeof this.value === 'undefined' || this.value === null) {
                this.getPlaceForLngLat(evt.lngLat)
            }
            this.handleUpdateField('place', this.item)

        },
        mapToPlace (place) {
            //const parser = new DOMParser();
            //const address = parser.parseFromString('<root>'+place.adr_address+'</root>', 'text/xml');

            let placeObj = {
                placeId: place.place_id.toString(),
                name: place.name,
                //types: place.types,
                postalAddress: {
                    /*addressCountry: address.querySelector('span.country-name') ? address.querySelector('span.country-name').innerHTML : null,
                    addressLocality: address.querySelector('span.locality') ? address.querySelector('span.locality').innerHTML : null,
                    addressRegion: address.querySelector('span.region') ? address.querySelector('span.region').innerHTML : null,
                    postalCode: address.querySelector('span.postal-code') ? address.querySelector('span.postal-code').innerHTML : null,
                    streetAddress: address.querySelector('span.street-address') ? address.querySelector('span.street-address').innerHTML : null,
                */
                },
                geo: {
                    longitude: this.manualset ? this.item.geo.longitude : place.geometry.coordinates[0],
                    latitude: this.manualset ? this.item.geo.latitude : place.geometry.coordinates[1]
                },
                //viewport: place.boundingbox,
            }
            let placeKeys = []
            let displayName = []
            window.console.log(place);
            let actualRankAddress=40;
            if (place.address) {
                for (let i = 0; i < place.address.length; i++) {
                    let addr = place.address[i]
                    if (addr.type === 'country') {
                        placeObj.postalAddress.addressCountryLong = addr.localname
                    }
                    if (addr.type === 'country_code') {
                        placeObj.postalAddress.addressCountry = addr.localname
                    }
                    if (typeof(addr.osm_type) !== 'undefined' && addr.osm_type !== null && addr.isaddress) {


                        if (addr.place_type === 'state' || addr.admin_level===4) {
                            placeObj.postalAddress.addressRegionKey = addr.osm_type + addr.osm_id
                            placeObj.postalAddress.addressRegion = addr.localname
                            placeKeys.push(addr.osm_type.substr(0, 1) + addr.osm_id)
                            displayName.push(addr.localname)
                        }
                        if (/*addr.place_type==='city'||
                            addr.place_type==='town'||
                                addr.place_type==='village'*/
                            addr.rank_address >= 15 && addr.rank_address <= 16 && actualRankAddress > addr.rank_address
                        ) {
                            placeObj.postalAddress.addressLocalityKey = addr.osm_type + addr.osm_id
                            placeObj.postalAddress.addressLocality = addr.localname
                            actualRankAddress=addr.rank_address;
                            displayName.push(addr.localname)

                        }
                        if (addr.class === 'highway') {
                            placeObj.postalAddress.streetAddressKey = addr.osm_type + addr.osm_id
                            placeObj.postalAddress.streetAddress = addr.localname
                            placeKeys.push(addr.osm_type.substr(0, 1) + addr.osm_id)
                            displayName.push(addr.localname)
                        }
                    }

                }
                if(placeObj.postalAddress.addressLocalityKey) placeKeys.push(placeObj.postalAddress.addressLocalityKey);
                displayName=[...new Set(displayName)];
                placeObj.name = displayName.join(', ')
                window.console.log(placeObj.name)
                if (this.searchFieldValue === null || this.searchFieldValue === '') this.searchFieldValue = placeObj.name
            }
            this.handleUpdateField('placeKeys', placeKeys)

            /*if (place.country_code) placeObj.postalAddress.addressCountry = place.country_code
            if (place.country) placeObj.postalAddress.addressCountryLong = place.country
            if (place.city) placeObj.postalAddress.addressLocality = place.city
            if (place.town) placeObj.postalAddress.addressLocality = place.town
            if (place.village) placeObj.postalAddress.addressLocality = place.village
            if (place.state) placeObj.postalAddress.addressRegion = place.state
            if (place.road) placeObj.postalAddress.streetAddress = place.road*/

            return placeObj
        },
        placeChanged (place) {
            this.zoom = 40
            this.setCenter(place)

        },
        setCenter (place) {
            this.place = place
            if (this.place) {
                if(this.item.geo==null) this.item.geo={};
                this.item.geo.latitude = this.place.geometry.coordinates[1]
                this.item.geo.longitude = this.place.geometry.coordinates[0]
                this.center = [this.place.geometry.coordinates[0],this.place.geometry.coordinates[1]];
                //this.center = {'lat': this.place.geometry.coordinates[0], 'lon': this.place.geometry.coordinates[1]}
                this.position = [this.place.geometry.coordinates[0],this.place.geometry.coordinates[1]];
                //this.position = {'lat': this.place.geometry.coordinates[0], 'lon': this.place.geometry.coordinates[1]}

                if(typeof this.place.properties.extent !=='undefined') {
                    let extent = this.place.properties.extent
                    window.coords = [[extent[0], extent[1]], [extent[2], extent[3]]]
                    this.$nextTick(() => {
                        this.map.fitBounds([[extent[0], extent[1]], [extent[2], extent[3]]], {
                            padding: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0
                            }
                        })
                    })
                }
                //const placeObj=this.mapToPlace(this.place)
                this.loadPlaceDetail(place.properties.osm_type.substr(0, 1) + place.properties.osm_id)
                //this.handleUpdateField('place', placeObj);
                //this.place = null;
            }

        },
        sync () {
            if (
                typeof this.item !== 'undefined'
                && typeof this.item.geo !== 'undefined'
                && typeof this.item.geo.latitude !== 'undefined'
                && typeof this.item.geo.longitude !== 'undefined'
                && this.item.geo.latitude !== 0
                && this.item.geo.longitude !== 0
            ) {
                //this.center = [this.item.geo.latitude, this.item.geo.longitude]
                //this.center={'lat': this.item.geo.latitude, 'lon': this.item.geo.longitude};
                this.center = [ this.item.geo.longitude,this.item.geo.latitude ]
                //this.position = [this.item.geo.latitude, this.item.geo.longitude]
                //this.position={'lat': this.item.geo.latitude, 'lon': this.item.geo.longitude};
                this.position = [ this.item.geo.longitude,this.item.geo.latitude ]
            }
        },
        onSearchFieldChange (searchFieldValue) {
            window.console.log('onSearchFieldChange',searchFieldValue)
            if (this.searchFieldValue === searchFieldValue) return
            this.searchFieldValue = searchFieldValue
            if (this.searchFieldValue === '' || this.searchFieldValue === null) {
                window.console.log(this.searchFieldValue)
            } else {
                window.console.log(this.searchFieldValue)
                this.geoDataLoading = true
                if (this.loadGeoDataTimeout !== null) clearTimeout(this.loadGeoDataTimeout)
                this.loadGeoDataTimeout = setTimeout(() => {this.loadGeoData()}, 1000)
            }
        },
        onSearchFieldClear(){
            this.handleUpdateField('place', null)
            this.handleUpdateField('placeKeys', [])
            this.place = null
        },
        onSearchListClick(data){
            window.console.log('onSearchListClick',data)
        },
        onSearchChange(data){
            window.console.log('onSearchChange',data)
        },
        loadGeoData () {

            return fetch(this.entrypoint + '/placesSearch?q=' + this.searchFieldValue, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    window.console.log(data);
                    this.placesList = data.map((item) => {
                        if(item.properties.name) {
                            return {
                                text: item.properties.name + ((item.properties.city && item.properties.city!==item.properties.name) ? ', ' + item.properties.city : '') + ' (' + this.$t('app.PlaceTypes.' + item.properties.type) + ')' + (item.properties.district ? ', ' + item.properties.district : ''),
                                place_id: item.properties.osm_type + item.properties.osm_id,
                                data: item

                            }
                        }else{
                            let nameArr=[];
                            if(item.properties.street){
                                nameArr.push(item.properties.street);
                                if(item.properties.housenumber){
                                    nameArr[0]+=(item.properties.housenumber);
                                }
                            }
                            if(item.properties.city) nameArr.push(item.properties.city);
                            if(item.properties.state) nameArr.push(item.properties.state);
                            if(item.properties.country) nameArr.push(item.properties.country);
                            return {
                                text: nameArr.join(', ') + '(' + this.$t('app.PlaceTypes.' + item.properties.type) + ')',
                                place_id: item.properties.osm_type + item.properties.osm_id,
                                data: item

                            }
                        }
                    })
                    this.geoDataLoading = false
                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    this.geoDataLoading = false
                    return e
                })
        },
        loadPlaceDetail (placeKey) {
            window.console.log('loadPlaceDetails')
            if (placeKey == null) return
            return fetch(this.entrypoint + '/placeDetail/de/' + placeKey, {
                method: 'GET',
                headers: new Headers({'Content-Type': 'application/json'}),
            })
                .then(response => response.json())
                .then((data) => {
                    window.console.log(data)
                    data.display_name = data.localname
                    //this.placesList=[data];
                    //this.geoSearchValue=data;
                    this.geoDataLoading = false

                    const placeObj = this.mapToPlace(data)
                    window.console.log('placeObj', placeObj)
                    this.handleUpdateField('place', placeObj)
                    this.place = null

                })
                .catch((e) => {
                    window.console.error('Geocode was not successful for the following reason: ' + e)
                    this.geoDataLoading = false
                    return e
                })
        },
        onMapLoaded (event) {
            window.console.log('onMapLoaded')
            this.map = event.map
            this.$store.map = event.map
            this.sync()
        },
        removeAddressCountry(){
            const placeObj=JSON.parse(JSON.stringify(this.value));
            placeObj.postalAddress = null;
            this.handleUpdateField('place', placeObj)
        },
        removeAddressRegion(){
            const placeObj=JSON.parse(JSON.stringify(this.value));
            placeObj.postalAddress.addressRegion = null;
            placeObj.postalAddress.addressRegionKey = null;
            placeObj.postalAddress.addressLocality = null;
            placeObj.postalAddress.addressLocalityKey = null;
            placeObj.postalAddress.streetAddress = null;
            placeObj.postalAddress.streetAddressKey = null;
            this.handleUpdateField('place', placeObj)
        },
        removeAddressLocality(){
            const placeObj=JSON.parse(JSON.stringify(this.value));
            placeObj.postalAddress.addressLocality = null;
            placeObj.postalAddress.addressLocalityKey = null;
            placeObj.postalAddress.streetAddress = null;
            placeObj.postalAddress.streetAddressKey = null;
            this.handleUpdateField('place', placeObj)
        },
        removeStreetAddress(){
            const placeObj=JSON.parse(JSON.stringify(this.value));
            placeObj.postalAddress.streetAddress = null;
            placeObj.postalAddress.streetAddressKey = null;
            this.handleUpdateField('place', placeObj)
        },
        removeGeo(){
            const placeObj=JSON.parse(JSON.stringify(this.value));
            placeObj.geo = null;
            this.handleUpdateField('place', placeObj)

            this.sync()
        }

    }
}
</script>
