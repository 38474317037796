<template>
    <v-dialog v-resize="handleResize" v-model="item" content-class="createRetrosnapDialog" fullscreen hide-overlay
              transition="fade-transition">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-card>
            <div style="top:0; width:100%; height:100%; position: fixed; text-align:center"
                @touchstart="onTouchStart"
                 @touchmove="onTouchMove"
                 @touchend="onTouchEnd"
                 @click.stop="onTakePhotoButtonClick"
            >
                <div class="videoCrop">
                    <video id="camVideo" style="width:100%; height:100%" autoplay
                           :class="(imageCaptured ? 'hidden' : ' ')"></video>
                </div>
                <canvas id='takePhotoCanvas' :class="(imageCaptured ? '' : 'canvashidden')"></canvas>
                <img v-if="photograph" v-on:load="onImageLoaded" class="imageOverlay" ref="imageOverlay"

                     :src="entrypoint+'/thumbnail/big/'+photograph['filePath'].replace(/\.jpeg/,'.webp')"
                     alt=""
                     :style="'opacity: '+(opacity/100)"/>

            </div>

            <v-btn @click.stop="goBack" icon style="position:fixed; top:10px;right:10px">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-row style="position:fixed; bottom:0; left:10px; right:10px">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-slider
                        v-model="opacity"
                        append-icon="mdi-eye-off-outline"
                        prepend-icon="mdi-eye-outline"
                        @start="onStartSliderMovement"
                        @end="onEndSliderMovement"
                        @click:append="opacityPlus"
                        @click:prepend="opacityMinus"
                        hide-details
                    ></v-slider>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-slider
                        v-if="zoomSliderValue!==null && !imageCaptured"
                        v-model="zoomSliderValue"
                        :min="zoomSlider.min"
                        :max="zoomSlider.max"
                        :step="zoomSlider.step"
                        append-icon="mdi-magnify-plus-outline"
                        prepend-icon="mdi-magnify-minus-outline"
                        @start="onStartSliderMovement"
                        @end="onEndSliderMovement"
                        @click:append="zoomPlus"
                        @click:prepend="zoomMinus"

                    ></v-slider>
                </v-col>
            </v-row>




            <v-btn id="recordButton" @click.stop="onTakePhotoButtonClick" icon
                   x-large
                   :class="'record-button'+(imageCaptured ? ' hidden' : '')">
                <v-icon>mdi-record-circle</v-icon>
            </v-btn>
            <v-btn @click.stop="uploadRetrosnap" icon :class="'save-button'+(imageCaptured ? '' : ' hidden')">
                <v-icon>mdi-check-bold</v-icon>
            </v-btn>
            <v-btn @click.stop="imageCaptured=false" icon :class="'retry-button'+(imageCaptured ? '' : ' hidden')">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-card-actions class="" style="position:fixed; bottom:20px;">


            </v-card-actions>
        </v-card>
        <div v-if="showLoading" class="loadingAnimation">
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
            </v-row>
        </div>
       <v-dialog
            v-if="item && retroSnapGallery"
            v-model="updateRetrosnapGallerySuccessDialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('app.Retrosnap.Created') }}
                </v-card-title>
                <v-card-text>{{ $t('app.Retrosnap.CreatedText') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        :to="{ name: 'ImageGalleryShow', params: { id: retroSnapGalleryId } }"
                    >
                        {{ $t('app.Retrosnap.ShowAlbum') }}
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        :to="{ name: 'RetrosnapShow', params: { id: created['@id'] } }"
                    >
                        {{ $t('app.Retrosnap.ShowRetrosnap') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<style>
    .record-button, .save-button, .retry-button {
        position: fixed;
    }

    @media screen and (orientation: landscape) {
        .record-button, .save-button {
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
            right: 10px
        }

        .retry-button {
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
            right: 50px;
        }
    }

    @media screen and (orientation: portrait) {
        .record-button, .save-button {
            margin-left:auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 120px;
        }

        .retry-button {
            margin-left:auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 180px;
        }
    }

    .hidden {
        display: none;
    }

    .canvashidden {
        opacity: 0;
    }

    .imageOverlay {
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;

    }

    .videoCrop {
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        text-align: center;
    }

    .videoCrop video {
        object-fit: cover;
    }

    #takePhotoCanvas {
        object-fit: contain;
        width: 100%;
        height: 100%;
        /*position: fixed;*/
        top: 50%;
        /*left: 50%;*/
    }
    .loadingAnimation {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .loadingAnimation > div{
        margin-left:auto;
        margin-right: auto;
        margin-top:auto;
        margin-bottom:auto;
        left: 50%;
        right: 0;
        top: 50%;
    }

</style>
<script>
    //import { createNamespacedHelpers } from 'vuex'
    //import RetrosnapForm from './Form'
    import {ENTRYPOINT} from '@/config/entrypoint'
    //import fixOrientation from '../../utils/fix-orientation/index'
    //import fixOrientation from 'fix-orientation'
    //const { mapGetters, mapActions } = createNamespacedHelpers('retrosnap/create')
    import {mapActions, mapGetters} from 'vuex'
    import fetch from '../../utils/fetch'
    export default {
        components: {
            //RetrosnapForm
        },

        data() {
            return {
                entrypoint: ENTRYPOINT,
                item: {},
                opacity: 50,
                imageCapture: null,
                track: null,
                imageCaptured: false,
                position: {
                    'latitude': null,
                    'longitude': null
                },
                altitude: null,
                orientation: {
                    alpha: null,
                    beta: null,
                    gamma: null
                },
                sensor: null,
                zoomSlider: {},
                zoomSliderValue: null,
                scaling: false,
                touchStartDist: null,
                touchZoomSliderValue: null,
                cameraAudio: new Audio(require('@/assets/sound/camera-shutter-click-08.mp3')),
                showLoading: false,
                imagegalleries: [],
                retroSnapGallery: null,
                retroSnapGalleryId: null,
                updateRetrosnapGallerySuccessDialog: false
            }
        },

        computed: {
            ...mapGetters({
                error: 'retrosnap/create/error',
                isLoading: 'retrosnap/create/isLoading',
                created: 'retrosnap/create/created',
                violations: 'retrosnap/create/violations',
                photograph: 'photograph/show/retrieved',
                personItem: 'person/show/retrieved',
                token_data: 'user/token/token_data',
                retrosnapGalleryCreated: 'imagegallery/create/created',

            })
        },
        watch: {
            // eslint-disable-next-line object-shorthand,func-names
            created: function (created) {
                if (!created) {
                    return
                }
                //Checken ob MyRetroSnaps Album existiert:
                this.retroSnapGallery=null;
                this.imagegalleries.forEach((imagegallery)=>{
                    if(imagegallery.headline==='My RetroSnaps'){
                        this.retroSnapGallery=imagegallery;
                        this.retroSnapGalleryId=imagegallery['@id'];
                    }
                });

                //evtl. MyRetroSnaps-Album erstellen:
                if(this.retroSnapGallery==null){
                    this.retroSnapGallery={
                        headline: 'My RetroSnaps',
                        isPrivate: true,
                        isUserAlbum: true,
                        retrosnaps: ['/retrosnaps/' + created['id']]
                    }
                    this.imageGalleryCreate(this.retroSnapGallery);
                }else{
                    //Retrosnap zum MyRetroSnaps Album hinzufügen:
                    this.addToRetrosnapGallery(this.created);
                }

                //Weiter zu Retrosnap-Album des Users:


                //this.$router.push({name: 'RetrosnapUpdate', params: {id: created['@id']}})
            },
            zoomSliderValue: function(){
              //window.console.log(zoomValue)
              this.track.applyConstraints({ advanced: [{ zoom: this.zoomSliderValue }]})
                .catch(error => window.console.error('Uh, oh, applyConstraints() error:', error));
            },
            personItem: function () {
                this.imagegalleries = this.personItem.imageGalleries
            },
            retrosnapGalleryCreated: function(retrosnapGalleryCreated){
                window.console.log('retrosnapGalleryCreated',retrosnapGalleryCreated);
                this.retrosnapGallery=retrosnapGalleryCreated;
                this.retroSnapGalleryId=retrosnapGalleryCreated['@id'];
                this.updateRetrosnapGallerySuccessDialog=true;
                this.imagegalleriespush(retrosnapGalleryCreated);
            }
        },
        mounted(){
            document.body.style='overscroll-behavior-y: contain;';
        },
        beforeDestroy() {
            document.body.style='';
        },
        created() {
            this.initsensors()
            this.retrievePhotograph(decodeURIComponent(this.$route.params.photograph))
            if (this.token_data && this.token_data.person_id !== null) {
                //window.console.log(this.token_data)
                this.personRetrieve('/people/' + this.token_data.person_id)
            } else {
                window.console.log('no tokenData!')
            }
            navigator.mediaDevices.getUserMedia({
                video: {
                    width: {
                      min: 1280,
                      max: 1920,
                    },
                    height: {
                      min: 720,
                      max: 1080
                    },
                    //width: { ideal: 4608 },
                    //height: { ideal: 3472 },
                    facingMode: 'environment'
                }
            })
                .then(async mediaStream => {
                    //window.console.log(mediaStream);
                    document.querySelector('video').srcObject = mediaStream
                    // Once crbug.com/711524 is fixed, we won't need to wait anymore. This is
                  // currently needed because capabilities can only be retrieved after the
                  // device starts streaming. This happens after and asynchronously w.r.t.
                  // getUserMedia() returns.
                    await new Promise(r => setTimeout(r, 1000));
                    this.track = mediaStream.getVideoTracks()[0]
                   setTimeout(()=>{
                     const capabilities = this.track.getCapabilities()
                     const settings = this.track.getSettings();
                     //window.console.log(settings,capabilities);
                     if (capabilities.zoom) {
                       this.zoomSlider.min = capabilities.zoom.min;
                       this.zoomSlider.max = capabilities.zoom.max;
                       this.zoomSlider.step = capabilities.zoom.step;
                       this.zoomSliderValue = settings.zoom;
                     }
                   },1000)



                    this.imageCapture = new ImageCapture(this.track)
                    return this.imageCapture.getPhotoCapabilities()
                })
                .then(photoCapabilities => {
                    window.console.log(photoCapabilities)
                    //const settings = this.imageCapture.track.getSettings();
                    /*input.min = photoCapabilities.imageWidth.min;
                    input.max = photoCapabilities.imageWidth.max;
                    input.step = photoCapabilities.imageWidth.step;

                     */

                    return this.imageCapture.getPhotoSettings()
                })
                .catch(error => window.console.log(error))
        },
        methods: {
            ...mapActions({
                create: 'retrosnap/create/create',
                retrievePhotograph: 'photograph/show/retrieve',
                personRetrieve: 'person/show/retrieve',
                imageGalleryCreate: 'imagegallery/create/create',
            }),
            onSendForm() {
                this.create(this.item)
            },

            updateField(field, value) {
                Object.assign(this.item, {[field]: value})
            },
            goBack() {
                document.querySelector('video').pause()
                document.querySelector('video').src = ''
                document.querySelector('video').srcObject = null
                if (this.track) this.track.stop()
                this.$router.go(-1)
            },
            onTakePhotoButtonClick() {
                if(this.imageCaptured) return;
                this.imageCapture.takePhoto()
                    .then(blob => {
                        this.cameraAudio.play();
                        if(navigator.vibrate) navigator.vibrate(100);
                        this.imageCaptured = true
                        const camimg=new Image()

                        //camimg.src=URL.createObjectURL(blob);
                        //camimg.src=URL.createBlogURL(blob);
                        this.blobToDataURL(blob, (dataurl)=>{
                            this.showLoading=true;
                            camimg.src=dataurl;

                        })
                        camimg.onload = () => {
                            this.showLoading=false;
                            const canvas = document.querySelector('#takePhotoCanvas')
                            const imageOverlay = document.querySelector('.imageOverlay')

                            canvas.width = imageOverlay.naturalWidth
                                    canvas.height = imageOverlay.naturalHeight
                                    let ratio = Math.max(canvas.width / camimg.width, canvas.height / camimg.height)
                                    let x = (canvas.width - camimg.width * ratio) / 2
                                    let y = (canvas.height - camimg.height * ratio) / 2
                                    const ctx = canvas.getContext("2d");
                                    ctx.drawImage(camimg, 0, 0, camimg.width, camimg.height,
                                        x, y, camimg.width * ratio, camimg.height * ratio)

                            /*fixOrientation(camimg.src, { image: true },  (fixed) => {


                                camimg.onload = () => {
                                    canvas.width = imageOverlay.naturalWidth
                                    canvas.height = imageOverlay.naturalHeight
                                    let ratio = Math.max(canvas.width / camimg.width, canvas.height / camimg.height)
                                    let x = (canvas.width - camimg.width * ratio) / 2
                                    let y = (canvas.height - camimg.height * ratio) / 2
                                    const ctx = canvas.getContext("2d");
                                    ctx.drawImage(camimg, 0, 0, camimg.width, camimg.height,
                                        x, y, camimg.width * ratio, camimg.height * ratio)
                                }
                                camimg.src = fixed;
                            });*/

                        }
                        navigator.geolocation.getCurrentPosition(position => {
                            this.position = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                            this.altitude = position.coords.altitude
                        })
                    })
                    .catch(error => window.console.log(error))
            },
            blobToDataURL(blob, callback) {
                const a = new FileReader()
                a.onload = function(e) {callback(e.target.result);}
                a.readAsDataURL(blob);
            },
            onStartSliderMovement(){
                //document.body.style='overscroll-behavior-y: contain;';
            },
            onEndSliderMovement(){
                //document.body.style='';
            },
            initsensors: function () {
                //window.console.log('initsensors');
                if (window.DeviceOrientationEvent) {
                    window.addEventListener("deviceorientation", (event) => {
                        const {alpha, beta, gamma} = event;
                        this.orientation = {
                            alpha: alpha,
                            beta: beta,
                            gamma: gamma
                        }
                    }, true);
                } else {
                    window.console.error("DeviceOrientationEvent is not supported by your browser.");
                }

            },
            uploadRetrosnap() {
                document.querySelector('#takePhotoCanvas').toBlob((blob) => {
                    let data = new FormData()
                    data.append('file', blob, 'RetroSnap-' + Math.floor(Date.now() / 1000) + '.jpeg')

                    fetch('retrosnap/upload', {
                        method: 'POST',
                        body: data
                    })
                        .then((response) => {
                            return response.json()
                        })
                        .then((data) => {
                            //window.console.log(data['hydra:member'])
                            this.item = data['hydra:member'][0]

                            this.item.altitude = this.altitude

                            this.item.place={
                                geo: this.position
                            };
                            this.item.orientation = this.orientation;
                            this.item.photograph = decodeURIComponent(this.$route.params.photograph)
                            this.item.text = ''
                            this.onSendForm()


                        })
                }, 'image/jpeg', 0.95)

            },
            onImageLoaded(evt) {
                const img = evt.target

                const videoElement = document.querySelector('video')
                let ratio = img.naturalWidth / img.naturalHeight
                let imageWidth = img.height * ratio
                let imageHeight = img.height
                if (imageWidth > img.width) {
                    imageWidth = img.width
                    imageHeight = img.width / ratio
                }
                //console.log('image size', imageWidth, imageHeight, ratio)
                //console.log('video size', videoElement.offsetWidth, videoElement.offsetHeight, videoElement.offsetWidth / videoElement.offsetHeight)

                videoElement.width = imageWidth
                videoElement.height = imageHeight
                videoElement.setAttribute('style', 'width:' + imageWidth + 'px;height:' + imageHeight + 'px; top: calc(50% - ' + (imageHeight / 2) + 'px); position:relative')

                document.querySelector('#takePhotoCanvas').setAttribute('style', 'width:' + imageWidth + 'px;height:' + imageHeight + 'px; top: calc(50% - ' + (imageHeight / 2) + 'px); position:relative')
            },
            handleResize() {
                if (this.$refs.imageOverlay && this.$refs.imageOverlay.complete) this.onImageLoaded({target: this.$refs.imageOverlay})
            },
            onTouchStart(e){
                //window.console.log(e);
                if (e.touches.length === 2) {
                    this.scaling = true;
                    this.touchStartDist=this.getDist(e.touches);
                    this.touchZoomSliderValue=this.zoomSliderValue;
                    window.console.log(this.touchStartDist,this.zoomSlider);
                }
            },
            onTouchMove(e){
                //window.console.log(e);
                if (e.touches.length === 2) {
                    const touchNewDist=this.getDist(e.touches);
                    let newZoomSiderValue=this.touchZoomSliderValue+(touchNewDist-this.touchStartDist)/50;
                    if (newZoomSiderValue<this.zoomSlider.min) newZoomSiderValue=this.zoomSlider.min;
                    if (newZoomSiderValue>this.zoomSlider.max) newZoomSiderValue=this.zoomSlider.max;
                    window.console.log(touchNewDist-this.touchStartDist);

                    this.zoomSliderValue = newZoomSiderValue;
                }
            },
            onTouchEnd(){
                //window.console.log(e);
                this.scaling = false;
            },
            getDist(touches){
                return Math.hypot(
                    touches[0].pageX - touches[1].pageX,
                    touches[0].pageY - touches[1].pageY
                );
            },
            zoomPlus(){
                let newZoomSiderValue=this.zoomSliderValue+this.zoomSlider.step*10;
                if (newZoomSiderValue>this.zoomSlider.max) newZoomSiderValue=this.zoomSlider.max;
                this.zoomSliderValue=newZoomSiderValue;
            },
            zoomMinus(){
                let newZoomSiderValue=this.zoomSliderValue-this.zoomSlider.step*10;
                if (newZoomSiderValue<this.zoomSlider.min) newZoomSiderValue=this.zoomSlider.min;
                this.zoomSliderValue=newZoomSiderValue;
            },
            opacityPlus(){
                this.opacity+=10;
                if (this.opacity>100) this.opacity=100;

            },
            opacityMinus(){
                this.opacity-=10;
                if (this.opacity<0) this.opacity=0;
            },
            addToRetrosnapGallery (item) {
                this.retroSnapGallery.retrosnaps.push('/retrosnaps/' + item['id'])
                this.retroSnapGallery.retrosnaps = [...new Set(this.retroSnapGallery.retrosnaps)]
                //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
                this.updateGallery(this.retroSnapGallery)
            },
            updateGallery (gallery) {
                let ig = JSON.parse(JSON.stringify(gallery))
                if(typeof ig.retrosnaps!=='undefined') ig.retrosnaps=this.arrayToIris(ig.retrosnaps);
                if(typeof ig.subGalleries!=='undefined') ig.subGalleries=this.arrayToIris(ig.subGalleries);
                if(ig.parentGalleries) delete ig.parentGalleries;
                return fetch(ig['@id'], {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(ig)
                })
                    .then(response => response.json())
                    .then(() => {
                        this.updateRetrosnapGallerySuccessDialog=true;
                        //window.console.log(data)
                    })
                    .catch((e) => {
                        this.$store.error=e.message;
                        return e

                    })
            },
        }
    }
</script>
