<template>
    <div class="privacyNimmerso">
        <div class="mb-5">
            <div>
                 <p class="mb-0">Nimmerso ist der Webmaster, der die Webseite programmiert hat und hostet.
                </p>
            </div>
        </div>

        <div class="mb-5">
            <h5>Verarbeitungsunternehmen</h5>
            <div>
                <p class="mb-0">Nimmerso GmbH</p>
                 <p class="mb-0">Franz-Joseph-Str. 11 | 80801 München</p>
            </div>
        </div>

        <div class="mb-5">
            <h5>Datenverarbeitungszwecke</h5>
            <ul>
                <li>Bereitstellung der funktionierenden Webseite </li>
                <li>Einhaltung der gesetzlichen Verpflichtungen </li>
                <li>Speicherung der Einwilligung </li>
            </ul>
        </div>

        <div class="mb-5">
            <h5>Verwendete Technologien</h5>
            <ul>
                <li>Cookies</li>
                <li>Local Storage</li>
            </ul>
        </div>

        <div class="mb-5">
            <h5>Datenattribute</h5>
            <ul>
                <li>Einwilligung "Ja" oder "Nein"</li>
                <li>Protokolldateidaten (IP (anonymisiert)</li>
            </ul>
        </div>


        <div class="mb-5">
            <h5>Erhobene Daten</h5>
            <div>
                 <p class="mb-0">Diese Liste enthält alle (persönlichen) Daten, die von oder durch die Nutzung dieses Dienstes gesammelt werden.</p>
            </div>
            <ul>
                <li>Datum uns Uhrzeit des Besuchs</li>
                <li>Geräteinformationen</li>
                <li>Browser-Informationen</li>
                <li>Anonymisierte IP-Adresse</li>
                <li>Alle Feldinformationen der verwendeten Formulare</li>
                <li>Opt-in- und Opt-out-Daten</li>
            </ul>
        </div>

        <div class="mb-5">
            <h5>Rechtliche Grundlage</h5>
            <div> <p class="mb-0">Im Folgenden wird die nach Art. 6 I 1 DSGVO geforderte Rechtsgrundlage für die Verarbeitung von personenbezogenen Daten genannt.</p></div>
            <ul>
                <li>Art. 6 (1) (f) GDPR</li>
            </ul>
        </div>

        <div class="mb-5">
            <h5>Ort der Verarbeitung</h5>
            <div>
                 <p class="mb-0">European Union</p>
            </div>
        </div>

        <div class="mb-5">
            <h5>Aufbewahrungsfrist</h5>
            <div>
                 <p class="mb-0">Die Widerrufsbescheinigung einer zuvor erteilten Einwilligung wird drei Jahre lang aufbewahrt. Die Aufbewahrung basiert zum einen auf unserer Rechenschaftspflicht nach Art. 5 Abs. 2 DSGVO. Dies verpflichtet zur Einhaltung der Verarbeitung personenbezogener Daten gemäß der Allgemeinen Datenschutzverordnung. Dagegen beträgt die Aufbewahrungsdauer in der regulären Verjährungsfrist gemäß § 195 BGB drei Jahre. Diese Verjährungsfrist beginnt mit dem Ende des Jahres, in dem der Anspruch entstanden ist (§ 199 BGB). Die dreijährige Verjährungsfrist beginnt daher mit Ablauf des 31.12. und endet drei Jahre später am 31.12., 24.00 Uhr.</p>
            </div>

        </div>

        <div class="mb-5">
            <h5>
                Datenempfänger
            </h5>
            <div>
                 <p class="mb-0"></p>
            </div>
            <ul><li>Nimmerso</li></ul>
        </div>

        <div class="mb-5">
            <h5>
                Datenschutzbeauftragter der verarbeitenden Firma
            </h5>
            <div>
                 <p class="mb-0">Nachfolgend finden Sie die E-Mail-Adresse des Datenschutzbeauftragten des verarbeitenden Unternehmens.</p>
            </div>
            <div>
                 <p class="mb-0"><a href="mailto:privacy@nimmerso.com">privacy@nimmerso.com</a>
                </p>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        props: {
        },
    };
</script>
