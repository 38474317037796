<template>
  <div>
    <h1>Retrosnap List</h1>
      <v-snackbar
          v-if="isLoading"
          v-model="isLoading"
          :timeout="5000"
          :top="true"
          color="info"
      >Loading...
      </v-snackbar>

      <v-snackbar
          v-if="error"
          v-model="error"
          :timeout="5000"
          :top="true"
          color="error"
      >
      <v-icon>mdi-alert</v-icon> {{ error }}
      </v-snackbar>
      <v-snackbar
          v-if="deletedItem"
          v-model="deletedItem"
          :timeout="5000"
          :top="true"
          color="success"
      >
          <v-icon>mdi-delete</v-icon> {{ deletedItem['@id'] }} deleted.
      </v-snackbar>



    <span v-if="view">
      <button
        :disabled="!view['hydra:previous']"
        type="button"
        class="btn btn-basic btn-sm"
        @click="getPage(view['hydra:first'])">First</button>
      &nbsp;
      <button
        :disabled="!view['hydra:previous']"
        type="button"
        class="btn btn-basic btn-sm"
        @click="getPage(view['hydra:previous'])">Previous</button>
      &nbsp;
      <button
        :disabled="!view['hydra:next']"
        type="button"
        class="btn btn-basic btn-sm"
        @click="getPage(view['hydra:next'])">Next</button>
      &nbsp;
      <button
        :disabled="!view['hydra:last']"
        type="button"
        class="btn btn-basic btn-sm"
        @click="getPage(view['hydra:last'])">Last</button>
      &nbsp;
    </span>

    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>author</th>
            <th>award</th>
            <th>citation</th>
            <th>comments</th>
            <th>contentLocation</th>
            <th>copyrightHoldingPerson</th>
            <th>copyrightHoldingOrganization</th>
            <th>copyrightYear</th>
            <th>creator</th>
            <th>dateCreated</th>
            <th>dateModified</th>
            <th>headline</th>
            <th>isFamilyFriendly</th>
            <th>categories</th>
            <th>license</th>
            <th>locationCreated</th>
            <th>material</th>
            <th>position</th>
            <th>spatialCoverages</th>
            <th>galleries</th>
            <th>text</th>
            <th>description</th>
            <th>name</th>
            <th>collection</th>
            <th>backside</th>
            <th>frontside</th>
            <th>retrosnapOf</th>
            <th>retrosnaps</th>
            <th>mediaObject</th>
            <th colspan="2" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item['@id']">
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['@id'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['author'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['award'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['citation'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['comments'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['contentLocation'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['copyrightHoldingPerson'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['copyrightHoldingOrganization'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['copyrightYear'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['creator'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['dateCreated'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['dateModified'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['headline'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['isFamilyFriendly'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['categories'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['license'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['locationCreated'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['material'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['position'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['spatialCoverages'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['galleries'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['text'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['description'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['name'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['collection'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['backside'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['frontside'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['retrosnapOf'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['retrosnaps'] }}
              </router-link>
            </td>
            <td>
              <router-link
                v-if="item"
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                {{ item['mediaObject'] }}
              </router-link>
            </td>
            <td>
              <router-link
                :to="{name: 'RetrosnapShow', params: { id: item['@id'] }}">
                <span
                  class="fa fa-search"
                  aria-hidden="true" />
                <span class="sr-only">Show</span>
              </router-link>
            </td>
            <td>
              <router-link :to="{name: 'RetrosnapUpdate', params: { id: item['@id'] }}">
                <span
                  class="fa fa-pencil"
                  aria-hidden="true" />
                <span class="sr-only">Edit</span>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <router-link
      :to="{ name: 'RetrosnapCreate' }"
      class="btn btn-primary">Create</router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    deletedItem: 'retrosnap/del/deleted',
    error: 'retrosnap/list/error',
    items: 'retrosnap/list/items',
    isLoading: 'retrosnap/list/isLoading',
    view: 'retrosnap/list/view'
  }),

  created () {
    this.getPage()
  },

  methods: mapActions({
    getPage: 'retrosnap/list/default'
  })
}
</script>
